import React, { Component } from 'react';
import {Input} from 'spoton-lib-v2';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import get from 'lodash.get';
import styles from './shippingForm.module.scss';

export default class ShippingForm extends Component {

    render() {
        const {
            firstName,
            lastName,
            address_1,
            address_2,
            country,
            province,
            city,
            zipCode,
        } = this.props.shipping;

        return (
            <form className={styles.ShippingForm}>
                <Input
                    className={styles.FullWidth}
                    name="firstName"
                    onChange={this.props.onChange}
                    label="First Name*"
                    value={firstName}
                />
                <Input
                    className={styles.FullWidth}
                    name="lastName"
                    onChange={this.props.onChange}
                    label="Last Name*"
                    value={lastName}
                />

                <Input
                    className={styles.FullWidth}
                    name="address_1"
                    onChange={this.props.onChange}
                    label="Address 1*"
                    value={address_1}
                />
                <Input
                    className={styles.FullWidth}
                    name="address_2"
                    onChange={this.props.onChange}
                    label="Address 2"
                    value={address_2}
                />
               
                <div className={styles.FlexItems}>
                    <div className={styles.ThirdWidth}>
                        <Input
                            name="city"
                            onChange={this.props.onChange}
                            label="City*"
                            value={city}
                        />
                    </div>

                    <div className={`${styles.SmallInput} ${styles.SmallInput___left} ${styles.SelectContainer}`}>
                        <div className={styles.Select}>
                            <span className={styles.Select_label}>State *</span>
                            <RegionDropdown
                                country={country}
                                countryValueType="short"
                                labelType="full"
                                valueType="short"
                                defaultOptionLabel=""
                                value={province}
                                onChange={this.props.handleChangeProvince}
                                name='state'
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.FlexItems}>
                    <div className={`${styles.SmallInput} ${styles.SmallInput___right}`}>
                        <Input
                            name="zipCode"
                            onChange={this.props.onChange}
                            label="Zip"
                            value={zipCode}
                        />
                    </div>

                    <div className={`${styles.ThirdWidth} ${styles.SelectContainer}`}>
                        <div className={styles.Select}>
                            <span className={styles.Select_label}>Country *</span>
                            <CountryDropdown
                                value={country}
                                priorityOptions={['US']}
                                onChange={this.props.handleChangeCountry}
                                labelType="full"
                                defaultOptionLabel=""
                                valueType="short"
                                name='country'
                            />
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}
