import queryString from 'query-string';
import {
    createAxiosWrapper,
    serializeToCamelCase,
} from 'legacy/utils/axiosWrapper';

export const createPaymentMethod = (id, data) => {
    return createAxiosWrapper().post(
        `/nmi_terminal/accounts/${id}/payment_methods`,
        data,
    );
};

export const getPaymenthMethods = (id) => {
    return createAxiosWrapper().get(
        `/nmi_terminal/accounts/${id}/payment_methods`,
    );
};

export const updatePaymentMethod = (id, methodId, data) => {
    return createAxiosWrapper().patch(
        `/nmi_terminal/accounts/${id}/payment_methods/${methodId}`,
        data,
    );
};

export const createSubscription = (data) => {
    return createAxiosWrapper().post(
        '/nmi_terminal/recurring/subscriptions',
        data,
    );
};

export const cancelSubscription = (subscriptionId) => {
    return createAxiosWrapper().delete(
        `/nmi_terminal/recurring/subscriptions/${subscriptionId}`,
    );
};

export const updateSubscription = (subscriptionId, data) => {
    return createAxiosWrapper().patch(
        `/nmi_terminal/recurring/subscriptions/${subscriptionId}`,
        data,
    );
};

/**
 * fetch all subscriptions and add qquery params to url
 *
 * @typedef SubscriptionParams
 * @property {number} page -  A page number within the paginated result set.
 * @property {number} pageSize -  Number of results to return per page.
 * @property {string} status -  filter by status [active, cancelled, paused, expired]
 * @property {string} q -  string to use during a search through subscriptions
 * @property {string} order -  When "desc", switch to sorting descending by name available [desc,asc]
 * @property {number} planId -  filter by plan_id
 * @property {number} accountId -  filter by account_id
 * @property {string} sortBy -  sorting by header id
 *
 * @typedef SubscriptionResponse
 * @property {number} currentPage - page where we are at
 * @property {number} totalPages - number of totalPages
 * @property {array} results - array of subs objects
 * @property {string} next
 * @property {string} previous
 * @property {number} totalNumResults - total of subscriptions
 *
 * @param {SubscriptionParams}
 * @returns {AxiosResponse<SubscriptionResponse>}
 */
export const fetchAllSubscriptions = ({
    page,
    pageSize,
    status,
    q,
    order,
    planId,
    accountId,
    orderBy,
}) => {
    const qs = queryString.stringify({
        page,
        page_size: pageSize,
        status,
        q,
        order,
        plan_id: planId,
        account_id: accountId,
        order_by: orderBy,
    });
    return createAxiosWrapper().get(
        `/nmi_terminal/recurring/subscriptions?${qs}`,
    );
};

/**
 * fetch up to six billing periods
 *
 * @typedef DataParams o
 * @property {string} startDate -  when a subscriptions starts
 * @property {string} endDate -  when a subscriptions ends
 * @property {number} payments - number of payments selected
 * @property {number} planId - id from the plan selected
 *
 * @typedef BillingPeriodsResponse - array of periods
 * @property {string} startDate - Start date for this period
 * @property {string} endDate - End date for this period
 * @property {boolean} isProrated - if period is prorated
 * @property {number} amount - cost of period
 *
 * @param {DataParams}
 * @returns {AxiosResponse<BillingPeriodsResponse>}
 */
export const previewBillingPeriods = (data) => {
    return createAxiosWrapper().post(
        '/nmi_terminal/recurring/billing_periods_preview',
        data,
    );
};
