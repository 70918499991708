import * as api from './api';
import { fetchInvoiceSettings, fetchInvoiceSettingsStarted } from '../../features/invoicing/redux/invoices/invoices.actions';

/**
 * INITIAL STATE
 */
const defaultInfo = {
    businessName: '',
    profileImage: '',
    address: '',
    nmi_tokenization_key: '',
    nmi_api_key: '',
    tax_enabled: false,
    tax_rate: '0.0',
    shipping_enabled: false,
    tax_rounding: 'standard',
    hasStrengthPassword: true,
    convenience_fee_enabled: false,
    convenience_fee_before_taxes: false,
    convenience_fee_rate: '0.00',
    id: '',
    timezone: '',
};

/**
 * ACTION TYPES
 */
const SET_MERCHANT_INFO = 'SET_MERCHANT_INFO';

/**
 * ACTION CREATORS
 */
export const setMerchantInfo = (info) => ({ type: SET_MERCHANT_INFO, info });

/**
 * THUNK CREATORS
 */
export const fetchMerchant = () => (dispatch) => (
    new Promise((resolve, reject) => {
        dispatch(fetchInvoiceSettingsStarted());
        api.fetchMerchantInfo()
            .then(({ info, invoices }) => {
                dispatch(setMerchantInfo(info));
                dispatch(fetchInvoiceSettings(invoices));
                return  resolve(info);
            })
            .catch((err) => {
                console.error(err)
                reject(err);
            });
    })
);

/**
 * REDUCER
 */
export default function (state = defaultInfo, action) {
    switch (action.type) {
        case SET_MERCHANT_INFO:
            /* eslint-disable */
            for (let key in action.info) {
                if (!action.info[key] && !state[key]) {
                    action.info[key] = state[key];  // ensure defaults are preserved (use `0.0`, not `null`)
                }
            }
            /* eslint-enable */
            return Object.assign({}, state, action.info);
        default:
            return state;
    }
}
