import moment from 'moment';
import isEmpty from 'lodash.isempty';
import { Decimal } from 'decimal.js';
import get from 'lodash.get';
import { formatReminders } from '../../../remindersPage/remindersPage.constants';
import {
    SET_INVOICE,
    SET_WARNING,
    SET_ACCOUNT,
    UNSET_ACCOUNT,
    HANDLE_ON_CHANGE_DETAILS,
    CHECK_UNIQUE_CUSTOM_ID_SUCCESS,
    SET_INVOICE_DETAILS,
    REQUEST_INITIAL_INITIAL_DEPOSIT,
    SET_BALANCE_DUE_DATE,
    SET_DEPOSIT_DUE_DATE,
    SET_DEPOSIT_AMOUNT,
    ADD_LINE_ITEM,
    SET_SELECTED_LINE_ITEM,
    EDIT_LINE_ITEM,
    CLEAR_SELECTED_LINE_ITEM,
    REMOVE_LINE_ITEM,
    SAVE_DISCOUNT,
    REMOVE_DISCOUNT,
    SET_INVOICE_FEES,
    SET_SEND_INVOICE_DATE,
    HANDLE_OPTIONS_CHECKBOX,
    SET_INVOICE_REMINDERS,
    FETCH_INVOICE_SETTINGS,
    FETCH_INVOICE_SETTINGS_STARTED,
    SET_INVOICE_DUE_DEFAULT,
    SET_SEND_DUE_DEFAULT,
    SUBMIT_SETTINGS_STARTED,
    SUBMIT_SETTINGS_FAILED,
    SUBMIT_SETTINGS_SUCCESS,
    SET_INVOICE_DEFAULTS,
    SUBMIT_INVOICE_STARTED,
    SUBMIT_INVOICE_FAILED,
    SUBMIT_INVOICE_SUCCESS,
    UPDATE_INVOICE_STARTED,
    UPDATE_INVOICE_FAILED,
    UPDATE_INVOICE_SUCCESS,
    RESET_INVOICE,
    GET_INVOICES_STARTED,
    GET_INVOICES_SUCCESS,
    GET_INVOICES_FAILED,
    GET_INVOICE_DETAILS_STARTED,
    GET_INVOICE_DETAILS_SUCCESS,
    GET_INVOICE_DETAILS_FAILED,
    RESET_SELECTED_INVOICE,
    ADD_ATTACHMENT,
    REMOVE_ATTACHMENT,
    SET_ATTACHMENT_TO_BE_REMOVED,
    DELETE_INVOICE_STARTED,
    DELETE_INVOICE_SUCCESS,
    DELETE_INVOICE_FAILED,
    SUBMIT_MERCHANT_PAYMENT_STARTED,
    SUBMIT_MERCHANT_PAYMENT_SUCCESS,
    SUBMIT_MERCHANT_PAYMENT_FAILED,
    SET_CONVENIENCE_FEE_INCLUDED,
    GET_INVOICE_SETTINGS_STARTED,
    GET_INVOICE_SETTINGS_SUCCESS,
    GET_INVOICE_SETTINGS_FAILED, REMOVE_DEFAULT_ATTACHMENT,
} from './invoices.actions';

const initialState = {
    account: {},
    customIdUnique: true,
    customIdValid: true,
    invoiceDetails: {
        title: '',
        customInvoiceId: '',
        message: '',
        terms: '',
    },
    lineItems: [],
    selectedLineItem: {},
    hasDiscount: false,
    discount: {},
    taxAmount: 0,
    convenienceFee: 0,
    itemsGrossSale: 0,
    itemsSubTotal: 0,
    itemsTotal: 0,
    balanceDueDate: moment().locale('en'),
    balanceTotal: 0,
    requestInitialDeposit: false,
    depositDueDate: moment().locale('en'),
    depositAmount: 0,
    sendInvoiceDate: moment().locale('en'),
    sendReminders: false,
    requestShippingAddress: false,
    invoiceReminders: [],
    attachments: [],
    attachmentsToDelete: null,
    selectedAttachment: {},
    isLoadingSettings: false,
    warnings: {},
    hasUpdateInvoiceReminders: false,
    hasLoadedDefaults: false,
    isSubmittingInvoice: false,
    isFetchingInvoices: false,
    isFetchingInvoiceDetails: false,
    isDeleting: false,
    isSubmittingSettings: false,
    convenienceFeeIncluded: true,
    settings: {
        invoicingReminders: [],
        invoicingAutoscheduleReminders: false,
        invoicingDefaultDeliveryDelayDays: null, // sendInvoiceDate days: [0,7,15,30,null] int
        invoicingDefaultInvoiceTitle: null,
        invoicingRequestShippingAddress: false, // send shipping address
        invoicingDefaultDueDateTermsDays: null, // balanceDueDate days [0,7,14,30,null] int
        invoicingDefaultReminderMessage: null,
        invoicingDefaultTermsConditions: null, // terms and conditions
    },

    invoices: [],
    selectedInvoice: {},

    currentPage: 1,
    next: null,
    previous: null,
    totalNumResults: 0,
    totalPages: 1,

    isSubmittingPayment: false,

    areSettingsLoading: false,
    invoiceSettings: {},
    defaultAttachments: [],
};

function invoicesReducer(
    state = initialState,
    action,
) {
    switch (action.type) {
        case SET_INVOICE_DEFAULTS: {
            const { settings } = state;
            const sendInvoiceSettigsValue = !state.hasLoadedDefaults
                ? settings.invoicingDefaultDeliveryDelayDays || 0
                : 0;
            const balanceDateSettingsValue = !state.hasLoadedDefaults
                ? settings.invoicingDefaultDueDateTermsDays || 0
                : 0;
            return {
                ...state,
                sendInvoiceDate: moment(state.sendInvoiceDate).add(sendInvoiceSettigsValue, 'days'),
                balanceDueDate: moment(state.balanceDueDate).add(balanceDateSettingsValue, 'days'),
                requestShippingAddress: settings.invoicingRequestShippingAddress || false,
                invoiceDetails: {
                    ...state.invoiceDetails,
                    title: settings.invoicingDefaultInvoiceTitle || '',
                    message: settings.invoicingDefaultReminderMessage || '',
                    terms: settings.invoicingDefaultTermsConditions || '',
                },
                hasLoadedDefaults: true,
            };
        }

        case SET_INVOICE: {
            const {
                id,
                account,
                title,
                customId,
                message,
                terms,
                lineItems,
                discounts,
                dueDate,
                depositAmount,
                depositDate,
                sendDate,
                reminders,
                requestShippingAddress,
                attachments,
                convenienceFeeAmount,
            } = action.payload;
            const hasDiscount = !isEmpty(get(discounts, '[0]', []));
            return {
                ...state,
                id,
                account,
                invoiceDetails: {
                    title,
                    customInvoiceId: customId,
                    message,
                    terms,
                },
                lineItems,
                hasDiscount,
                discount: hasDiscount ? {
                    name: discounts[0].name,
                    disposition: discounts[0].disposition,
                    specValue: new Decimal(discounts[0].specValue).abs().toString(),
                    monetaryValue: new Decimal(discounts[0].monetaryValue).abs().toString(),
                } : {},
                balanceDueDate: moment(dueDate).locale('en'),
                requestInitialDeposit: new Decimal(depositAmount).gt(0),
                depositDueDate: moment(depositDate).locale('en'),
                depositAmount,
                sendInvoiceDate: moment(sendDate).locale('en'),
                sendReminders: !isEmpty(reminders),
                invoiceReminders: formatReminders(reminders),
                requestShippingAddress,
                attachments,
                attachmentsToDelete: [],
                convenienceFeeIncluded: !(new Decimal(convenienceFeeAmount).isZero()),
            };
        }

        case SET_WARNING: {
            const warnings = {
                ...state.warnings,
                ...action.payload,
            };

            return {
                ...state,
                warnings,
            };
        }

        case SUBMIT_MERCHANT_PAYMENT_STARTED:
            return {
                ...state,
                isSubmittingPayment: true,
            };

        case SUBMIT_MERCHANT_PAYMENT_SUCCESS:
        case SUBMIT_MERCHANT_PAYMENT_FAILED:
            return {
                ...state,
                isSubmittingPayment: false,
            };

        case RESET_SELECTED_INVOICE:
            return {
                ...state,
                selectedInvoice: {},
            };

        case GET_INVOICE_DETAILS_STARTED:
            return {
                ...state,
                isFetchingInvoiceDetails: true,
            };

        case GET_INVOICE_DETAILS_SUCCESS:

            return {
                ...state,
                isFetchingInvoiceDetails: false,
                selectedInvoice: action.payload,
                defaultAttachments: action.payload.defaultAttachments
            };

        case GET_INVOICES_STARTED:
            return {
                ...state,
                isFetchingInvoices: true,
            };
        case GET_INVOICES_SUCCESS:
            return {
                ...state,
                isFetchingInvoices: false,
                invoices: action.payload.results,
                currentPage: action.payload.currentPage,
                next: action.payload.next,
                previous: action.payload.previous,
                totalNumResults: action.payload.totalNumResults,
                totalPages: action.payload.totalPages,
            };
        case GET_INVOICES_FAILED:
            return {
                ...state,
                isFetchingInvoices: false,
            };

        case SET_ACCOUNT:
            return {
                ...state,
                account: action.payload,
            };

        case UNSET_ACCOUNT:
            return {
                ...state,
                account: {},
            };

        case HANDLE_ON_CHANGE_DETAILS:
            return {
                ...state,
                invoiceDetails: {
                    ...state.invoiceDetails,
                    [action.payload.name]: action.payload.value,
                },
            };

        case HANDLE_OPTIONS_CHECKBOX:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };

        case CHECK_UNIQUE_CUSTOM_ID_SUCCESS:
            return {
                ...state,
                customIdUnique: action.payload.customIdUnique,
                customIdValid: action.payload.customIdValid,
            };

        case SET_INVOICE_DETAILS:
            return {
                ...state,
                invoiceDetails: action.payload,
            };

        case REQUEST_INITIAL_INITIAL_DEPOSIT:
            return {
                ...state,
                requestInitialDeposit: !state.requestInitialDeposit,
                balanceTotal: +state.balanceTotal,
                depositAmount: !state.requestInitialDeposit ? +state.depositAmount : 0,
            };

        case SET_BALANCE_DUE_DATE:
            return {
                ...state,
                balanceDueDate: moment(action.payload),
            };

        case SET_DEPOSIT_DUE_DATE:
            return {
                ...state,
                depositDueDate: moment(action.payload),
            };

        case SET_SEND_INVOICE_DATE:
            return {
                ...state,
                sendInvoiceDate: moment(action.payload),
            };

        case SET_DEPOSIT_AMOUNT:
            return {
                ...state,
                depositAmount: action.payload,
            };

        case ADD_LINE_ITEM: {
            const lineItems = state.lineItems.concat(action.payload);

            return {
                ...state,
                lineItems,
            };
        }

        case SET_SELECTED_LINE_ITEM: {
            return {
                ...state,
                selectedLineItem: action.payload,
            };
        }

        case CLEAR_SELECTED_LINE_ITEM: {
            return {
                ...state,
                selectedLineItem: {},
            };
        }

        case EDIT_LINE_ITEM: {
            const lineItems = state.lineItems.slice();
            const item = action.payload;

            const index = item.index;
            delete item.index;
            lineItems[index] = item;

            return {
                ...state,
                lineItems,
            };
        }

        case REMOVE_LINE_ITEM: {
            const lineItems = state.lineItems.slice();
            lineItems.splice(action.payload, 1);

            return {
                ...state,
                lineItems,
            };
        }

        case SAVE_DISCOUNT: {
            return {
                ...state,
                hasDiscount: true,
                discount: action.payload,
            };
        }

        case REMOVE_DISCOUNT: {
            return {
                ...state,
                hasDiscount: false,
                discount: {},
            };
        }

        case SET_INVOICE_FEES: {
            const fees = action.payload;

            return {
                ...state,
                itemsGrossSale: fees.grossSale,
                itemsSubTotal: fees.subTotal,
                taxAmount: fees.tax,
                convenienceFee: fees.convenienceFee,
                balanceTotal: fees.total,
                itemsTotal: fees.total,
                discount: {
                    ...state.discount,
                    monetaryValue: fees.monetaryValue,
                },
            };
        }

        case SET_INVOICE_REMINDERS:
            return {
                ...state,
                invoiceReminders: action.payload.reminders,
                hasUpdateInvoiceReminders: action.payload.hasUpdateInvoiceReminders,
            };

        case FETCH_INVOICE_SETTINGS_STARTED:
            return {
                ...state,
                isLoadingSettings: true,
            };

        case FETCH_INVOICE_SETTINGS:
            return {
                ...state,
                isLoadingSettings: false,
                settings: {
                    ...state.settings,
                    ...action.payload,
                    invoicingReminders: formatReminders(action.payload.invoicingReminders),
                },
            };

        case SET_INVOICE_DUE_DEFAULT:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    invoicingDefaultDueDateTermsDays: action.payload,
                },
            };

        case SET_SEND_DUE_DEFAULT:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    invoicingDefaultDeliveryDelayDays: action.payload,
                },
            };

        case SUBMIT_SETTINGS_STARTED:
            return {
                ...state,
                isSubmittingSettings: true,
            };

        case SUBMIT_SETTINGS_FAILED:
            return {
                ...state,
                isSubmittingSettings: false,
            };

        case SUBMIT_SETTINGS_SUCCESS:
            return {
                ...state,
                isSubmittingSettings: false,
                settings: {
                    ...state.settings,
                    invoicingDefaultDeliveryDelayDays: action.payload.invoicingDefaultDeliveryDelayDays,
                    invoicingAutoscheduleReminders: action.payload.invoicingAutoscheduleReminders,
                    invoicingDefaultInvoiceTitle: action.payload.invoicingDefaultInvoiceTitle,
                    invoicingRequestShippingAddress: action.payload.invoicingRequestShippingAddress,
                    invoicingDefaultDueDateTermsDays: action.payload.invoicingDefaultDueDateTermsDays,
                    invoicingReminders: formatReminders(action.payload.invoicingReminders),
                    invoicingDefaultReminderMessage: action.payload.invoicingDefaultReminderMessage,
                    invoicingDefaultTermsConditions: action.payload.invoicingDefaultTermsConditions,
                    invoicingEmailNotificationSettings: action.payload.invoicingEmailNotificationSettings,
                },
            };

        case SUBMIT_INVOICE_STARTED:
            return {
                ...state,
                isSubmittingInvoice: true,
            };

        case SUBMIT_INVOICE_SUCCESS:
        case SUBMIT_INVOICE_FAILED:
            return {
                ...state,
                isSubmittingInvoice: false,
            };

        case UPDATE_INVOICE_STARTED:
            return {
                ...state,
                isSubmittingInvoice: true,
            };

        case UPDATE_INVOICE_SUCCESS:
        case UPDATE_INVOICE_FAILED:
            return {
                ...state,
                isSubmittingInvoice: false,
            };

        case RESET_INVOICE: {
            return {
                ...state,
                invoiceDetails: {
                    customInvoiceId: '',
                    title: '',
                    message: '',
                    terms: '',
                },
                settings: {
                    ...state.settings,
                },
                account: {},
                invoiceReminders: [],
                lineItems: [],
                selectedLineItem: {},
                hasDiscount: false,
                discount: {},
                taxAmount: 0,
                convenienceFee: 0,
                customIdUnique: true,
                customIdValid: true,
                requestInitialDeposit: false,
                itemsGrossSale: 0,
                itemsSubTotal: 0,
                itemsTotal: 0,
                balanceTotal: 0,
                depositAmount: 0,
                balanceDueDate: moment().locale('en'),
                depositDueDate: moment().locale('en'),
                sendInvoiceDate: moment().locale('en'),
                sendReminders: false,
                requestShippingAddress: false,
                isSubmittingInvoice: false,
                convenienceFeeIncluded: true,
                hasLoadedDefaults: false,
                attachments: [],
                attachmentsToDelete: null,
                id: null,
                warnings: {},
                hasUpdateInvoiceReminders: false,
                selectedInvoice: {},
                areSettingsLoading: false,
                invoiceSettings: {},
                defaultAttachments: [],
            };
        }

        case ADD_ATTACHMENT: {
            const attachments = state.attachments.concat(action.payload);

            return {
                ...state,
                attachments,
            };
        }

        case REMOVE_ATTACHMENT: {
            const attachments = state.attachments.slice();
            attachments.splice(action.payload, 1);

            return {
                ...state,
                attachments,
            };
        }

        case SET_ATTACHMENT_TO_BE_REMOVED: {
            return {
                ...state,
                attachmentsToDelete: state.attachmentsToDelete.concat(action.payload),
            };
        }

        case DELETE_INVOICE_STARTED:
            return {
                ...state,
                isDeleting: true,
            };

        case DELETE_INVOICE_SUCCESS:
        case DELETE_INVOICE_FAILED:
            return {
                ...state,
                isDeleting: false,
            };

        case SET_CONVENIENCE_FEE_INCLUDED: {
            return {
                ...state,
                convenienceFeeIncluded: action.payload,
            };
        }

        case GET_INVOICE_SETTINGS_STARTED: {
            return {
                ...state,
                areSettingsLoading: true,
            };
        }

        case GET_INVOICE_SETTINGS_SUCCESS: {
            return {
                ...state,
                ...action.payload.mappedSettings,
                invoiceSettings: action.payload.invoiceSettings,
                areSettingsLoading: false,
            };
        }

        case GET_INVOICE_SETTINGS_FAILED: {
            return {
                ...state,
                areSettingsLoading: false,
            };
        }

        case REMOVE_DEFAULT_ATTACHMENT: {
            return {
                ...state,
                defaultAttachments: state.defaultAttachments.filter(attachment => {
                    return attachment.id !== action.payload;
                }),
            };
        }

        default:
            return state;
    }
}

export default invoicesReducer;
