import { createAxiosWrapper } from 'legacy/utils/axiosWrapper';

export const getCustomerInvoice = (merchantId, uuid) => {
    return createAxiosWrapper().get(
        `/nmi_terminal/invoices/payment_link/${merchantId}/${uuid}`,
    );
};

export const registerPayment = (merchantId,data) => {
    return createAxiosWrapper().post(`/nmi_terminal/invoices/v2/register_payment/${merchantId}`, data);
};
