export const deserializeSingleAccount = (account = {}) => {
    return {
        id:                account.id || 0,
        isActive:          Boolean(account.is_active),
        hotspotCustomerId: account.hotspot_customer_id || '',
        customAccountId:   account.custom_account_id || '',
        customerType:      account.customer_type || 'individual',
        firstName:         account.first_name || '',
        lastName:          account.last_name || '',
        businessName:      account.business_name || '',
        email:             account.email || '',
        website:           account.website || '',
        addresses:         (account.addresses || []).map(address => ({
            id:           address.id,
            isDefault:    address.is_default,
            firstName:    address.first_name,
            lastName:     address.last_name,
            address1:     address.address_1,
            address2:     address.address_2,
            addressType: address.address_type,
            city:         address.city,
            provinceCode: address.province_code,
            postalCode:   address.postal_code,
            countryCode:  address.country_code,
            phone:        address.phone_number,
            fax:          address.fax_number,
            addressType:  address.address_type,
        })),
        paymentMethods:     (account.payment_methods || []).map(paymentMethod => ({
            account: {
                vendorId: paymentMethod.account.vendor_id,
                id: paymentMethod.account.id,
            },
            ccExpiration: paymentMethod.cc_expiration,
            vendor: paymentMethod.vendor,
            vendorId: paymentMethod.vendor_id,
            addressId: paymentMethod.address_id,
            ccType: paymentMethod.cc_type,
            email: paymentMethod.email,
            isDefault: paymentMethod.is_default,
            ccLastFour: paymentMethod.cc_last_four,
            address: {
                id:           paymentMethod.address.id,
                firstName:    paymentMethod.address.first_name,
                lastName:     paymentMethod.address.last_name,
                address1:     paymentMethod.address.address_1,
                address2:     paymentMethod.address.address_2,
                city:         paymentMethod.address.city,
                provinceCode: paymentMethod.address.province_code,
                postalCode:   paymentMethod.address.postal_code,
                countryCode:  paymentMethod.address.country_code,
                phone:        paymentMethod.address.phone_number,
                fax:          paymentMethod.address.fax_number,
                isDefault:    paymentMethod.address.is_default,
                addressType:  paymentMethod.address_type,
            },
            id: paymentMethod.id,
        })),
    };
};

export const deserializeAccounts = (accounts = []) => {
    return accounts.map(deserializeSingleAccount);
};

export const serializeSingleAccount = (account = {}) => {
    return {
        id:                  account.id || null,
        is_active:           account.isActive || true, 
        hotspot_customer_id: account.hotspotCustomerId || null,
        custom_account_id:   account.customAccountId || null,
        customer_type:       account.customerType || null,
        first_name:          account.firstName || null,
        last_name:           account.lastName || null,
        business_name:       account.businessName || null,
        email:               account.email || null,
        website:             account.website || null,
        addresses:           (account.addresses || []).map(address => ({
            is_default:    address.isDefault || false,
            id:            address.id || null,
            first_name:    address.firstName || null,
            last_name:     address.lastName || null,
            address_1:     address.address1 || null,
            address_2:     address.address2 || null,
            city:          address.city || null,
            province_code: address.provinceCode || null,
            postal_code:   address.postalCode || null,
            country_code:  address.countryCode || null,
            phone_number:  address.phone || null,
            fax_number:    address.fax || null,
        })),
        payment_methods:    (account.paymentMethods || []).map(paymentMethod => ({
            account: {
                vendor_id: paymentMethod.account.vendorId,
                id: paymentMethod.account.id,
            },
            cc_expiration: paymentMethod.ccExpiration,
            vendor: paymentMethod.vendor,
            vendor_id: paymentMethod.vendorId,
            address_id: paymentMethod.addressId,
            cc_type: paymentMethod.ccType,
            email: paymentMethod.email,
            is_default: paymentMethod.isDefault,
            cc_last_four: paymentMethod.ccLastFour,
            addresses: {
                id: paymentMethod.address.id,
                first_name: paymentMethod.address.firstName,
                last_name: paymentMethod.address.lastName,
                address_1: paymentMethod.address.address1,
                address_2: paymentMethod.address.address2,
                city: paymentMethod.address.city,
                province_code: paymentMethod.address.provinceCode,
                postal_code: paymentMethod.address.postalCode,
                country_code: paymentMethod.address.countryCode,
                phone_number: paymentMethod.address.phoneNumber,
                fax_number: paymentMethod.address.faxNumber,
                is_default: paymentMethod.address.isDefault || false,
            },
            id: paymentMethod.id,
        })),
    };
};

export const serializeRemoveAccount = (account = {}) => {
    return {
        id:                  account.id || null,
        hotspot_customer_id: account.hotspotCustomerId || null,
        is_active:           false,
    };
};
