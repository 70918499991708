/**
 * ACTION TYPES
 */
export const RESET_TERMINAL_FIELDS = 'RESET_TERMINAL_FIELDS';

/**
 * ACTION CREATORS
 */
const resetTerminalFields = () => ({ type: RESET_TERMINAL_FIELDS });

export default {
    resetTerminalFields,
};
