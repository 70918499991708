export const DRAFT = 'draft';
export const SCHEDULED = 'scheduled';
export const DELIVERED = 'delivered';
export const DELIVERY_ERROR = 'delivery_error';
export const PARTIALLY_PAID = 'partially_paid';
export const OVERDUE = 'overdue';
export const PAID = 'paid';
export const ARCHIVED = 'archived';
export const VOIDED = 'voided';
export const UNPAID = 'unpaid';
export const UNDELIVERED =  'undelivered';
export const ASC = 'asc';
export const DESC = 'desc';