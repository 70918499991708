import axios from 'axios';
import camelCase from 'lodash.camelcase';
import snakeCase from 'lodash.snakecase';
import cloneDeep from 'lodash.clonedeep';
import { getConfigVar } from 'features/common/utils/config.utils';

const FETCH_PARAMS = {
    credentials: 'include',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
};

const BASE_URL = getConfigVar('REACT_APP_VTS_URL');

/**
 *
 *
 * Serialize backend response
 *
 * This is for use for converting/transforming backend responses that are in
 * `snake_case` to `camelCase` and vice versa.
 *
 * NOTE: the type of object will have to be determined explicitly, this just
 * transforms any type of object.
 */

/**
 *
 * Recursively modify keys in an object
 *  @param {Object|Array} item - an object or an array to modify
 *  @param {Function} func - a function that modifys the key
 *  @returns {Object|Array} an object with the modified keys
 *
 * WARNING: modifies the object
 */
function modifyKeys(item = {}, modifier) {
    if (Array.isArray(item)) {
        return item.map((value) => modifyKeys(value, modifier));
    }
    if (item !== null && item.constructor === Object) {
        return Object.keys(item).reduce((acc, key) => {
            return {
                ...acc,
                [modifier(key)]: modifyKeys(item[key], modifier),
            };
        }, {});
    }
    return item;
}

/**
 * Convert an objects keys to camelCase
 *  @param {Object|Array} unserializedObject - an object to convert
 *  @returns {Object|Array} returns a copy of the unserialized object with keys
 *  in camel case
 */
export function serializeToCamelCase(unserializedObject = {}) {
    const object = cloneDeep(unserializedObject);
    return modifyKeys(object, camelCase);
}

/**
 * Convert an objects keys to snake_case
 *  @param {Object|Array} serializedObject - an object to convert
 *  @returns {Object|Array} returns a copy of the deserialized object with keys
 *   in snake case
 */
export function serializeToSnakeCase(serializedObject = {}) {
    // Exceptions to the rule
    function convertToSnakeCase(item) {
        const custom = {
            poNumber: 'PO_number',
            address1: 'address1',
            address2: 'address2',
        };
        if (custom[item] !== undefined) {
            return custom[item];
        }
        return snakeCase(item);
    }
    const object = cloneDeep(serializedObject);
    return modifyKeys(object, convertToSnakeCase);
}

export let csrftoken = undefined;
export const setCsrfToken = (token) => {
    csrftoken = token;
};

/**
 * Creates a wrapper with the minimal configuration to interact with the api
 * @param {AxiosRequestConfig} axiosConfig
 * @param skipTransform help us reduce the reponse time on delete actions
 * @return {AxiosInstance} axiosInstance
 */
export function createAxiosWrapper({ headers = {}, ...rest } = {}) {
    const compoundHeaders = {
        ...FETCH_PARAMS.headers,
        ...headers,
    };

    const axiosInstance = axios.create({
        baseURL: BASE_URL,
        headers: compoundHeaders,
        withCredentials: true,
        transformResponse: [
            (data) => {
                if (data && !rest.skipResponseTransform) {
                    try {
                        return serializeToCamelCase(JSON.parse(data));
                    } catch (error) {
                        console.error(
                            '[AxiosWrapper] Error while transforming response: ',
                            error,
                        );
                    }
                }
                return data;
            },
        ],
        transformRequest: [
            (data) => {
                if (data && !rest.skipRequestTransform) {
                    try {
                        return JSON.stringify(serializeToSnakeCase(data));
                    } catch (error) {
                        console.error(
                            '[AxiosWrapper] Error while transforming request: ',
                            error,
                        );
                    }
                }

                return data;
            },
        ],
        ...rest,
    });

    axiosInstance.interceptors.request.use(
        (config) => {
            if (!/^(GET|HEAD|OPTIONS|TRACE)$/i.test(config.method)) {
                config.headers['X-csrftoken'] = csrftoken;
            }

            return config;
        },
        (error) => error,
    );

    return axiosInstance;
}

/**
 * Handle error that may be raised during an API call. This function is adapated
 * from the recommended error handling mentioned in the axios README. Note that this only
 * outputs the appropriate error to the console.
 *
 * @param {AxiosError|Error} error - Error object. Can either be AxiosError<T> type or JS Error
 * @param {string} source - Where the error in question orginated from
 */
export function handleAxiosError(error, source) {
    if (error.response) {
        // Server responded with error code other than 200
        console.error(
            `[${source}]: ${error.response.status} ${error.response.statusText}`,
        );
    } else if (error.request) {
        // Server processed request but client received no response
        console.error(`[${source}] No response received. Check request`);
    } else {
        // Client-side JS error ocurred
        console.error(`[${source}]: (JS Error) ${error.message}`);
    }
}
