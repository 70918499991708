/** Action names */
export const OPEN_RIGHT_NAV = '[RightNav] Open';
export const CLOSE_RIGHT_NAV = '[RightNav] Close';
export const SET_PAYLOAD = '[RightNav] Setting context information (payload)';

/** Actions */
export const openRightNav = () => ({
    type: OPEN_RIGHT_NAV,
});

export const closeRightNav = () => ({
    type: CLOSE_RIGHT_NAV,
});

export const setPayload = (payload) => ({
    type: SET_PAYLOAD,
    payload,
});

export function openAndNavigateRightNav(url, params) {
    return (dispatch) => {
        this.history.push(url, params);
        dispatch(openRightNav());
    };
}

export function closeAndNavigateRightNav(url, params) {
    return (dispatch) => {
        if (url) {
            this.history.push(url, params);
        } else {
            this.history.goBack();
        }
        dispatch(closeRightNav());
    };
}
