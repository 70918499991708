import { showToast } from 'spoton-lib-v2';
import {TOAST_CONTAINER_ID} from "../../../utils/constants";

export const SUCCESS = 'success';
export const WARNING = 'warning';
export const DANGER = 'danger';
export const INFO = 'informative';

/**
 * To add a toast in the queue on the screen
 */
export default function addNotification({
    /** The toast's title. Keep it short. */
    title = '',
    /** The toast's content */
    message,
    /** Text to show on the button. Will only be shown if onClick is passed. */
    buttonText,
    /** The toast will have colored background if true. It'll transparent background if false. */
    urgent = 'true',
    /** How the toast should be styled - success, warning, informative or danger. */
    type = 'success',
    /** Called when the action button is clicked. Will not render action button if not passed. */
    onClick,
    /** Called when the close button is clicked. Will not render close button if not passed. */
    onClose,
    /** className to add to toast */
    className = '',
}) {
    return showToast({
        title,
        content: message,
        buttonText,
        variant: type,
        onClick,
        onClose,
        urgent,
        className,
        containerId: TOAST_CONTAINER_ID,
    });
}
