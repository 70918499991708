import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatPhoneNumber } from 'react-phone-number-input';
import { Logo } from 'spoton-lib-v2';
import styles from './customerInvoiceFooter.module.scss';

class CustomerInvoiceFooter extends Component {
    static propTypes = {
        merchant: PropTypes.object,
    };

    render() {
        const { merchant } = this.props;
        const { merchantAddress } = merchant;
        const address = `${merchantAddress.street1} ${merchantAddress.street2 || ''}, ${merchantAddress.city} ${merchantAddress.region} ${merchantAddress.postalCode}`;
        // formatPhoneNumber returns an empty string if phone is already formated, adding fallback to original phone
        const phoneNumber = formatPhoneNumber(merchant.merchantPhone || '') || merchant.merchantPhone;

        return (
            <div className={styles.CustomerFacingFooter}>
                <footer className={styles.Footer}>
                    <span className={styles.Footer_businessName}>
                        {merchant.merchantName}
                    </span>
                    <span className={styles.Footer_subSection}>
                        {address}
                    </span>
                    {(phoneNumber || merchant.merchantEmail) &&
                        <span className={styles.Footer_subSection}>
                            <a href={`mailto:${merchant.merchantEmail}`}>
                                {merchant.merchantEmail}
                            </a>
                            &nbsp;
                            {phoneNumber && merchant.merchantEmail && ','}
                            <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                        </span>
                    }
                    <a href="https://www.spoton.com" target="_blank" rel="noreferrer" className={styles.Footer_logo}>
                        <Logo />
                    </a>
                    <a
                        className={styles.Footer_link}
                        href="https://www.spoton.com/legal/consumer-privacy/"
                        target="__blank"
                    >
                       SpotOn Privacy Terms
                    </a>
                </footer>
            </div>
        );
    }
}


export default CustomerInvoiceFooter;
