import { RESET_TERMINAL_FIELDS } from './shared-actions';

/**
 * INITIAL STATE
 */
const defaultBillingInfo = {
    firstname: '',
    lastname: '',
    addr1: '',
    addr2: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    phone: '',
    fax: '',
    email: '',
    website: '',
    addCustomer: false,
    sendEmail: false,
};

/**
 * ACTION TYPES
 */
const UPDATE_BILLING_FIRSTNAME = '[BillingInfo] Update Billing Firstname';
const UPDATE_BILLING_LASTNAME = '[BillingInfo] Update Billing Lastname';
const UPDATE_BILLING_ADDR1 = '[BillingInfo] Update Billing Address 1';
const UPDATE_BILLING_ADDR2 = '[BillingInfo] Update Billing Address 2';
const UPDATE_BILLING_CITY = '[BillingInfo] Update Billing City';
const UPDATE_BILLING_STATE = '[BillingInfo] Update Billing State';
const UPDATE_BILLING_COUNTRY = '[BillingInfo] Update Billing Country';
const UPDATE_BILLING_ZIP = '[BillingInfo] Update Billing Zip';
const UPDATE_BILLING_PHONE = '[BillingInfo] Update Billing Phone Number';
const UPDATE_BILLING_FAX = '[BillingInfo] Update Billing Fax Number';
const UPDATE_BILLING_EMAIL = '[BillingInfo] Update Billing Email';
const UPDATE_BILLING_WEBSITE = '[BillingInfo] Update Billing Website';
const UPDATE_BILLING_ADD_CUSTOMER = '[BillingInfo] Update Billing Customer';
const UPDATE_BILLING_SEND_EMAIL = '[BillingInfo] Update Billing Send Email';
const UPDATE_ALL = '[BillingInfo] Update All Items';
const CLEAR_TERMINAL_FIELDS = '[BillingInfo] Clear All Items';

/**
 * ACTION CREATORS
 */
export const updateFirstname = (firstname) => ({
    type: UPDATE_BILLING_FIRSTNAME,
    firstname
});

export const updateLastname = (lastname) => ({
    type: UPDATE_BILLING_LASTNAME,
    lastname
});

export const updateAddr1 = (addr1) => ({
    type: UPDATE_BILLING_ADDR1,
    addr1
});

export const updateAddr2 = (addr2) => ({
    type: UPDATE_BILLING_ADDR2,
    addr2
});

export const updateCity = (city) => ({
    type: UPDATE_BILLING_CITY,
    city
});

export const updateState = (state) => ({
    type: UPDATE_BILLING_STATE,
    state
});

export const updateCountry = (country) => ({
    type: UPDATE_BILLING_COUNTRY,
    country
});

export const updateZip = (zip) => ({
    type: UPDATE_BILLING_ZIP,
    zip
});

export const updatePhone = (phone) => ({
    type: UPDATE_BILLING_PHONE,
    phone
});

export const updateFax = (fax) => ({
    type: UPDATE_BILLING_FAX,
    fax
});

export const updateEmail = (email) => ({
    type: UPDATE_BILLING_EMAIL,
    email
});

export const updateWebsite = (website) => ({
    type: UPDATE_BILLING_WEBSITE,
    website
});

export const updateAddCustomer = (addCustomer) => ({
    type: UPDATE_BILLING_ADD_CUSTOMER,
    addCustomer
});

export const updateSendEmail = (sendEmail) => ({
    type: UPDATE_BILLING_SEND_EMAIL,
    sendEmail
});

export const updateAll = (billingInfo) => ({
    type: UPDATE_ALL,
    billingInfo,
});

export const clearBillingInfo = () => ({ type: CLEAR_TERMINAL_FIELDS });

/**
 * REDUCER
 */
export default function (state = defaultBillingInfo, action) {
    switch (action.type) {
        case UPDATE_BILLING_FIRSTNAME: {
            return {
                ...state,
                firstname: action.firstname
            };
        }

        case UPDATE_BILLING_LASTNAME: {
            return {
                ...state,
                lastname: action.lastname
            };
        }

        case UPDATE_BILLING_ADDR1: {
            return {
                ...state,
                addr1: action.addr1
            };
        }

        case UPDATE_BILLING_ADDR2: {
            return {
                ...state,
                addr2: action.addr2
            };
        }

        case UPDATE_BILLING_CITY: {
            return {
                ...state,
                city: action.city
            };
        }

        case UPDATE_BILLING_STATE: {
            return {
                ...state,
                state: action.state
            };
        }

        case UPDATE_BILLING_COUNTRY: {
            return {
                ...state,
                country: action.country
            };
        }

        case UPDATE_BILLING_ZIP: {
            return {
                ...state,
                zip: action.zip
            };
        }

        case UPDATE_BILLING_PHONE: {
            return {
                ...state,
                phone: action.phone
            };
        }

        case UPDATE_BILLING_FAX: {
            return {
                ...state,
                fax: action.fax
            };
        }

        case UPDATE_BILLING_EMAIL: {
            return {
                ...state,
                email: action.email
            };
        }

        case UPDATE_BILLING_WEBSITE: {
            return {
                ...state,
                website: action.website
            };
        }

        case UPDATE_BILLING_ADD_CUSTOMER: {
            return {
                ...state,
                addCustomer: action.addCustomer
            };
        }

        case UPDATE_BILLING_SEND_EMAIL: {
            return {
                ...state,
                sendEmail: action.sendEmail
            };
        }

        case RESET_TERMINAL_FIELDS: {
            return defaultBillingInfo;
        }

        case CLEAR_TERMINAL_FIELDS: {
            return defaultBillingInfo;
        }

        case UPDATE_ALL: {
            return {
                ...state,
                ...action.billingInfo,
            };
        }

        default: {
            return state;
        }
    }
}
