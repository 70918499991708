import {
    ERROR_LOADING_COLLECT_JS,
    ERROR_SUBMITTING_PAYMENT_INFO,
    INVALID_CC_NUMBER,
    INVALID_CVV_NUMBER,
    INVALID_EXP_DATE,
    INVALID_POSTAL_CODE,
    LOADED_COLLECT_JS,
    LOADING_COLLECT_JS,
    SET_POSTAL_CODE,
    SUBMITTED_PAYMENT_INFO,
    SUBMITTING_PAYMENT_INFO,
    VALID_CC_NUMBER,
    VALID_CVV_NUMBER,
    VALID_EXP_DATE,
    VALID_POSTAL_CODE,
    RESET,
    SET_BIN,
    SET_HASH,
    SET_EXP_DATE,
    SET_CC_NUMBER,
    SET_TOKEN_TYPE,
    SET_FIELDS_DIRTY,
} from './paymentForm.actions';

///////////////////////////////////////////////////////////
//
// Initial state
//
///////////////////////////////////////////////////////////

export const initialState = {
    /** is collect.js loading */
    isLoading: true,

    /** is collect.js submitting */
    isSubmitting: false,

    /** Postal code */
    postalCode: '',

    /** Input fields dirty */
    isCCExpDirty: false,
    isCCNumberDirty: false,
    isCVVDirty: false,
    isPostalCodeDirty: false,

    /** Input fields errors */
    ccExpError: '',
    ccNumberError: '',
    cvvError: '',
    postalCodeError: '',

    /** Collect.js response */
    tokenType: undefined,
    bin: '',
    hash: '',
    ccExp: '',
    ccNumber: '',

    /** the ciphered 1 time use token */
    paymentToken: '',

    /** An error if one occured */
    error: null,
};

///////////////////////////////////////////////////////////
//
// Reducer
//
///////////////////////////////////////////////////////////

export function paymentFormReducer(
    state = initialState,
    action,
) {
    switch (action.type) {
        case LOADING_COLLECT_JS: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case LOADED_COLLECT_JS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case ERROR_LOADING_COLLECT_JS: {
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        }

        case SUBMITTING_PAYMENT_INFO: {
            return {
                ...state,
                isSubmitting: true,
            };
        }

        case SUBMITTED_PAYMENT_INFO: {
            return {
                ...state,
                isSubmitting: false,
                paymentToken: action.payload,
                isCCNumberDirty: false,
                isCVVDirty: false,
                isCCExpDirty: false,
            };
        }

        case ERROR_SUBMITTING_PAYMENT_INFO: {
            return {
                ...state,
                isSubmitting: false,
                error: action.payload,
            };
        }

        case SET_POSTAL_CODE: {
            return {
                ...state,
                postalCode: action.payload,
            };
        }

        case SET_FIELDS_DIRTY: {
            return {
                ...state,
                isCVVDirty: true,
                isCCExpDirty: true,
                isCCNumberDirty: true,
            };
        }

        case VALID_CC_NUMBER: {
            return {
                ...state,
                ccNumberError: '',
                isCCNumberDirty: true,
            };
        }

        case INVALID_CC_NUMBER: {
            return {
                ...state,
                ccNumberError: action.payload,
                isCCNumberDirty: true,
            };
        }

        case VALID_CVV_NUMBER: {
            return {
                ...state,
                cvvError: '',
                isCVVDirty: true,
            };
        }

        case INVALID_CVV_NUMBER: {
            return {
                ...state,
                cvvError: action.payload,
                isCVVDirty: true,
            };
        }

        case VALID_EXP_DATE: {
            return {
                ...state,
                ccExpError: '',
                isCCExpDirty: true,
            };
        }

        case INVALID_EXP_DATE: {
            return {
                ...state,
                ccExpError: action.payload,
                isCCExpDirty: true,
            };
        }

        case VALID_POSTAL_CODE: {
            return {
                ...state,
                postalCodeError: '',
                isPostalCodeDirty: true,
            };
        }

        case INVALID_POSTAL_CODE: {
            return {
                ...state,
                postalCodeError: action.payload,
                isPostalCodeDirty: true,
            };
        }

        case RESET: {
            return initialState;
        }

        case SET_BIN: {
            return {
                ...state,
                bin: action.payload,
            };
        }

        case SET_HASH: {
            return {
                ...state,
                hash: action.payload,
            };
        }

        case SET_EXP_DATE: {
            return {
                ...state,
                ccExp: action.payload,
            };
        }

        case SET_CC_NUMBER: {
            return {
                ...state,
                ccNumber: action.payload,
            };
        }

        case SET_TOKEN_TYPE: {
            return {
                ...state,
                tokenType: action.payload,
            };
        }

        default: {
            return state;
        }
    }
}
