import queryString from 'query-string';
import { createAxiosWrapper } from 'legacy/utils/axiosWrapper';

export const fetchAllPlans = ({
    page,
    pageSize,
    status,
    q,
    order,
    orderBy,
}) => {
    const qs = queryString.stringify({
        page,
        page_size: pageSize,
        status,
        q,
        order,
        order_by: orderBy,
    });
    return createAxiosWrapper().get(`/nmi_terminal/recurring/plans?${qs}`);
};

export const fetchRecurringPlan = (id) => {
    return createAxiosWrapper().get(`/nmi_terminal/recurring/plans/${id}`);
};

export const postRecurringPlan = (data) => {
    return createAxiosWrapper().post('/nmi_terminal/recurring/plans', data);
};

export const patchRecurringPlan = (data, id) => {
    return createAxiosWrapper().patch(
        `/nmi_terminal/recurring/plans/${id}`,
        data,
    );
};

export const deleteRecurringPlan = (id) => {
    return createAxiosWrapper().delete(`/nmi_terminal/recurring/plans/${id}`);
};

/**
 * Retrieve information about the status of plan transactions
 *
 * @typedef LogEntry
 * @property {number} planId
 * @property {string} status - one of "failed", "to be processed" and "successful"
 * @property {number} id
 * @property {string} merchantId
 * @property {string} change
 * @property {string} startedAt - datetime
 * @property {string} lastRunAttempt - datetime
 * @property {string} finishedAt - datetime
 * @property {string} remark - traceback message
 * @property {number} numberOfRuns
 *
 * @returns {AxiosResponse<LogEntry[]>}
 */
export const getPlanStatus = () => {
    return createAxiosWrapper().get(
        '/nmi_terminal/recurring/plans/modification_log_entries',
    );
};
