import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { FaroRoute } from '@grafana/faro-react';
import { Provider } from 'react-redux';
import debounce from 'lodash/debounce';

import C, { TOAST_CONTAINER_ID } from 'legacy/terminal/utils/constants';

import RecurringPlansRoutes from '../features/common/routes/recurringPlan.route';
import InvoicingRoutes from '../features/invoicing/routes/invoicing.routes';
import ErrorState from '../features/common/components/errorState';
import { setCookiePrefix } from '../utils/cookies';
import CustomerFacingApp from '../features/customerFacingApp/components/customerFacingApp/customerFacingApp.component';
import { getConfigVar } from 'features/common/utils/config.utils';

import store, { merchantActions } from '../store';
import { setUrls } from '../features/common/redux/projectUrl/projectUrl.actions';

import { identifyCurrentUser, initializeFullStory } from '../utils/fullStory';

import styles from './Terminal.module.scss';
import '../../scss/terminal/_override-toastify.scss';
import { ToastContainer } from 'spoton-lib-v2';

const AccountTab = React.lazy(() => import('./AccountTab/AccountTab'));
const TransactionTab = React.lazy(() =>
    import('./TransactionTab/TransactionTab'),
);
const TerminalTab = React.lazy(() => import('./TerminalTab/TerminalTab'));
const GetVirtualTerminal = React.lazy(() =>
    import('../features/getVirtualTerminal'),
);
export default class Terminal extends Component {
    static displayName = 'Terminal';

    static propTypes = {
        baseUrl: PropTypes.string,
        staticUrl: PropTypes.string,
        isTest: PropTypes.bool,
        isDemo: PropTypes.bool,
        cookiePrefix: PropTypes.string,
    };

    static defaultProps = {
        baseUrl: '/',
        staticUrl: '/',
    };

    constructor(props) {
        super(props);

        this.state = {
            isMobileView: false,
        };

        setCookiePrefix(props.cookiePrefix);
    }

    componentDidMount() {
        // Initialize FullStory
        initializeFullStory();

        // Get initial data
        store
            .dispatch(merchantActions.fetchMerchant())
            .then((info) => {
                // send data to fullstory
                this.handleSessionInformation(info);
            })
            .catch(() => {
                console.error('Unable to load merchant');
            });

        // Set initial view size
        this.updateViewSize();

        // Handle window resize
        this.debounceUpdateView = debounce(
            this.updateViewSize,
            C.TIMING.resizeDelay,
        );
        window.addEventListener('resize', this.debounceUpdateView);

        store.dispatch(
            setUrls({
                baseUrl: this.props.baseUrl,
                staticUrl: this.props.staticUrl,
            }),
        );
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debounceUpdateView);
    }

    // Change view size
    // Tablet: 768px width
    // Desktop: 1024px width
    updateViewSize = () => {
        const documentWidth = document.body.getBoundingClientRect().width;

        let isMobileView = false; //initiate as false
        // device detection
        // if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        //     || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
        //     isMobileView = true;
        // }

        this.setState({ isMobileView });
    };

    handleSessionInformation = (sessionInfo) => {
        const {
            merchantId,
            businessEmail,
            businessName,
            merchantName,
        } = sessionInfo;

        identifyCurrentUser(merchantId, businessEmail, merchantName, {
            nameOfBusiness_str: businessName,
            coreMerchantId_str: merchantName,
            coreNameOfBusiness_str: businessName,
            isTestCore_str: this.props.isTest,
            isDemoCore_str: this.props.isDemo,
        });
    };

    render() {
        const { baseUrl, staticUrl } = this.props;
        const { isMobileView } = this.state;
        // because upsell is a full page, we deleted the clasess that add padding to Virtual terminal
        const isUpsell = window.location.pathname.match(/getvirtualterminal/);
        const isNewRecurringPayments = window.location.pathname.match(
            /recurring-payments/,
        );
        const articleClassnames = styles.Article;
        const sectionClassnames = styles.Page;

        return (
            <Provider store={store}>
                <ToastContainer containerId={TOAST_CONTAINER_ID} />
                <BrowserRouter basename={`${window.redirectBase}terminal/`}>
                    <div id="virtual-terminal-main" className={styles.Terminal}>
                        <article
                            className={`${
                                !isUpsell && !isNewRecurringPayments
                                    ? articleClassnames
                                    : ''
                            }`}
                        >
                            <section
                                className={`${
                                    !isUpsell && !isNewRecurringPayments
                                        ? sectionClassnames
                                        : ''
                                }`}
                            >
                                <Suspense fallback={<div></div>}>
                                    <Switch>
                                        {RecurringPlansRoutes.map((route) => {
                                            return (
                                                <FaroRoute
                                                    key={route.path}
                                                    {...route}
                                                />
                                            );
                                        })}

                                        {InvoicingRoutes.map((route) => {
                                            return (
                                                <FaroRoute
                                                    key={route.path}
                                                    {...route}
                                                />
                                            );
                                        })}

                                        <FaroRoute
                                            path="/accounts"
                                            render={(routerProps) => (
                                                <AccountTab
                                                    staticUrl={staticUrl}
                                                    isMobileView={
                                                        this.state.isMobileView
                                                    }
                                                    {...routerProps}
                                                />
                                            )}
                                        />

                                        <FaroRoute
                                            path="/transactions"
                                            render={(routerProps) => (
                                                <TransactionTab
                                                    staticUrl={staticUrl}
                                                    isMobileView={
                                                        this.state.isMobileView
                                                    }
                                                    {...routerProps}
                                                />
                                            )}
                                        />

                                        <FaroRoute
                                            path="/checkout"
                                            render={(routerProps) => (
                                                <TerminalTab
                                                    staticUrl={staticUrl}
                                                    isMobileView={
                                                        this.state.isMobileView
                                                    }
                                                    {...routerProps}
                                                />
                                            )}
                                        />

                                        <FaroRoute
                                            path="/getvirtualterminal"
                                            render={(routerProps) => (
                                                <GetVirtualTerminal
                                                    {...routerProps}
                                                />
                                            )}
                                        />

                                        <FaroRoute
                                            path="/"
                                            exact
                                            render={() => (
                                                <Redirect to="/checkout" />
                                            )}
                                        />

                                        <FaroRoute
                                            path={'/invoice_payment/'}
                                            render={(routerProps) => (
                                                <CustomerFacingApp
                                                    staticUrl="/"
                                                    baseUrl={getConfigVar(
                                                        'REACT_APP_VTS_URL',
                                                    )}
                                                    {...routerProps}
                                                />
                                            )}
                                        />

                                        <FaroRoute
                                            render={() => (
                                                <ErrorState errorNumber={404} />
                                            )}
                                        />

                                        <Redirect to="/checkout" />
                                    </Switch>
                                </Suspense>
                            </section>
                        </article>
                    </div>
                </BrowserRouter>
            </Provider>
        );
    }
}
