import queryString from 'query-string';
import {
    createAxiosWrapper,
    serializeToCamelCase,
} from 'legacy/utils/axiosWrapper';

/**
 * Retrieve all notes on a subscription
 *
 * @param {SubscriptionParams} params
 * @returns {AxiosResponse<NoteResponse>}
 */
export function getAllNotes({ page, pageSize, planId, accountId }) {
    /**
     * Convert response to camel case and strip out uncessary crap
     *
     * @typedef SubscriptionPagination<T>
     * @property {number} count
     * @property {string} next
     * @property {string} previous
     * @property {T[]} results
     *
     * @typedef SubscriptionAccount
     * @property {number} id
     * @property {string} email
     * @property {string} firstName
     * @property {string} lastName
     *
     * @typedef Note
     * @property {string} text
     * @property {string} created - a timestamp
     *
     * @typedef NoteResponse
     * @property {number} id
     * @property {Note[]} notes
     * @property {SubscriptionAccount} account
     *
     * @param {string} data - the stringified data response
     * @returns {AxiosResponse<SubscriptionPagination<NoteResponse>>}}
     */
    function transformResponse(data) {
        // axios returns the response as a string and we need to convert to an object
        const parsedData = JSON.parse(data);

        const transformedData = {
            ...parsedData,
            results: parsedData.results.map((subscription) => ({
                id: subscription.id,
                notes: subscription.notes.notes,
                account: subscription.account,
            })),
        };

        return serializeToCamelCase(transformedData);
    }

    const queryParams = queryString.stringify({
        page,
        page_size: pageSize,
        plan_id: planId,
        account_id: accountId,
    });

    return createAxiosWrapper({ transformResponse }).get(
        `/nmi_terminal/recurring/subscriptions?${queryParams}`,
    );
}

/**
 * Retrieve notes for a single subscription
 *
 * @param {string} subscriptionId
 * @returns {AxiosResponse<NoteResponse[]>}
 */
export function getNotes(subscriptionId) {
    /**
     * Convert response to camel case and strip out uncessary crap
     *
     * @typedef SubscriptionAccount
     * @property {number} id
     * @property {string} email
     * @property {string} firstName
     * @property {string} lastName
     *
     * @typedef Note
     * @property {string} text
     * @property {string} created - a timestamp
     *
     * @typedef NoteResponse
     * @property {number} id
     * @property {Note[]} notes
     * @property {SubscriptionAccount} account
     *
     * @param {string} data - the stringified data response
     * @returns {AxiosResponse<NoteResponse>}}
     */
    function transformResponse(data) {
        // axios returns the response as a string and we need to convert to an object
        const parsedData = JSON.parse(data);

        const transformedData = {
            id: parsedData.id,
            notes: parsedData.notes.notes,
            account: parsedData.account,
        };

        return serializeToCamelCase(transformedData);
    }

    return createAxiosWrapper({ transformResponse }).get(
        `/nmi_terminal/recurring/subscriptions/${subscriptionId}`,
    );
}

/**
 * Save a note to a subscription
 *
 * @typedef SubscriptionNotesResponse
 * @property {string} message
 *
 * @param {string} subscriptionId
 * @param {string} note
 * @returns {AxiosResponse<SubscriptionNotesResponse>}
 */
export function saveNote(subscriptionId, note) {
    if (!subscriptionId || !note) {
        console.error('[PlansData] saveNote: Invalid arguments');
    }

    return createAxiosWrapper().post(
        `/nmi_terminal/recurring/subscriptions/${subscriptionId}/notes`,
        { text: note },
    );
}
