import React, { useState } from 'react';
import {
    LeftNavHeader,
    LeftNavVerticalsMenu,
    LeftNavAppMenu,
    LeftNavSettings,
} from 'spoton-lib-v2';
import TerminalApp from 'legacy/terminal/components/Terminal';
//TODO fix this import when spoton-lib will export Types
import { Link } from 'spoton-lib-v2/dist/cjs/types/src/components/LeftNavAppMenu/LeftNavAppMenu.types';

import { Logger } from 'features/common/utils/logger.utils';
import 'i18nConfig/i18n.config';
import 'react-dates/initialize';

import styles from './App.module.scss';
import NavigationUtils from './Navigation.utils';
import { getConfigVar } from 'features/common/utils/config.utils';

export function App(): JSX.Element {
    const appName = 'React App Template';
    const pageName = 'Home';
    const userName = 'Michael Tyson';
    const userNameInitials = 'MT';
    const userAddress = '1265 Autumn Wind Way Henderson, NV 89052';

    const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
    const [isVerticalsMenuOpen, setIsVerticalsMenuOpen] = useState(false);
    const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);

    const isBuildingStandaloneApp =
        getConfigVar('REACT_APP_RENDER_SHELL') === 'true';

    function toggleMainMenu(): void {
        Logger.debug('toggle main menu');

        setIsMainMenuOpen(!isMainMenuOpen);
    }

    function toggleSettingsMenu(): void {
        Logger.debug('toggle settings menu');

        setIsSettingsMenuOpen(!isSettingsMenuOpen);
    }

    function toggleVerticalsMenu(): void {
        Logger.debug('toggle verticals menu');

        setIsVerticalsMenuOpen(!isVerticalsMenuOpen);
    }

    function handleAppMenuLinkSelection(link: any): void {
        // TODO: Fix
        window.location.href = '' + link.link;
    }

    function handleAppMenuClose(): void {
        setIsMainMenuOpen(false);
    }

    function handleAppMenuOpenVerticalsMenu(): void {
        setIsVerticalsMenuOpen(true);
    }

    function handleVerticalsMenuSelection(link: any): void {
        Logger.debug('handle verticals link selection', link);
    }

    function handleVerticalsMenusClose(): void {
        setIsVerticalsMenuOpen(false);
    }

    function handleSettingsSelection(link: any): void {
        Logger.debug('handle settings link selection', link);
    }

    function handleSettingsClose(): void {
        setIsSettingsMenuOpen(false);
    }

    function getLinks(links: any[]): Link[] {
        return links.map((el: Link) => ({
            ...el,
            displayName: el.displayName,
            isActive: el.link === location.pathname,
        }));
    }

    if (!isBuildingStandaloneApp) {
        return (
            <div className={styles.AppWrapper}>
                <div className={styles.AppContent}>
                    <TerminalApp
                        baseUrl={getConfigVar('REACT_APP_VTS_URL')}
                        staticUrl=""
                        isTest={false}
                        isDemo={false}
                        cookiePrefix={
                            getConfigVar('REACT_APP_IS_QA') === 'true'
                                ? 'QA'
                                : ''
                        }
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.App}>
            <LeftNavHeader
                applicationName={appName}
                currentPageName={pageName}
                userName={userName}
                userNameInitials={userNameInitials}
                userAddress={userAddress}
                toggleMainMenu={toggleMainMenu}
                toggleSettingsMenu={toggleSettingsMenu}
                toggleVerticalsMenu={toggleVerticalsMenu}
            />

            <div>
                <LeftNavAppMenu
                    isOpen={isMainMenuOpen}
                    applicationName={appName}
                    applicationLinks={getLinks(NavigationUtils)}
                    onSelectLink={handleAppMenuLinkSelection}
                    onClose={handleAppMenuClose} // mobile only
                    onOpenVerticalsMenu={handleAppMenuOpenVerticalsMenu} // mobile only
                    className={styles.AppMenu}
                />

                <LeftNavVerticalsMenu
                    isOpen={isVerticalsMenuOpen}
                    applicationName={appName}
                    links={[]}
                    onSelectLink={handleVerticalsMenuSelection}
                    onClose={handleVerticalsMenusClose}
                />

                <LeftNavSettings
                    isOpen={isSettingsMenuOpen}
                    closeSettingsMenu={handleSettingsClose}
                    settingsLinks={[]}
                    onSelectLink={handleSettingsSelection}
                    userName={userName}
                    userNameInitials={userNameInitials}
                />

                <div className={styles.AppWrapper}>
                    <div className={styles.AppContent}>
                        <TerminalApp
                            baseUrl={getConfigVar('REACT_APP_VTS_URL')}
                            staticUrl=""
                            isTest={false}
                            isDemo={false}
                            cookiePrefix={
                                getConfigVar('REACT_APP_IS_QA') === 'true'
                                    ? 'QA'
                                    : ''
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
