import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { ErrorBoundaryGeneric } from 'features/common/components';
import initialState from 'reduxConfig/initialState';
import configureStore, { history } from 'reduxConfig/config';

import { App } from 'app/components';

const rootComponent = (): JSX.Element => (
    <React.StrictMode>
        <Provider store={configureStore(initialState)}>
            <ConnectedRouter history={history}>
                <ErrorBoundaryGeneric>
                    <App />
                </ErrorBoundaryGeneric>
            </ConnectedRouter>
        </Provider>
    </React.StrictMode>
);

export default rootComponent;
