import { objectArrayToMap } from '../../../../utils/utils';

import {
    SAVED_NOTE,
    SAVING_NOTE,
    LOADED_NOTES,
    LOADING_NOTES,
    ERROR_SAVING_NOTE,
    ERROR_LOADING_NOTES,
} from './notes.actions';

const initialState = {
    notes: {
        byDate: {},
        allDates: [],

        account: {
            id: 0,
            email: '',
            firstName: '',
            lastName: '',
        },
        subscriptionId: 0,

        isLoading: false,
        error: null,
    },

    note: {
        text: '',

        isSaving: false,
        error: null,
    },
};

function notesReducer(
    state = initialState,
    action,
) {
    switch (action.type) {
        case LOADING_NOTES:
            return {
                ...state,
                notes: {
                    ...state.notes,
                    isLoading: true,
                    error: null,
                },
            };

        case LOADED_NOTES:
            return {
                ...state,
                notes: {
                    ...state.notes,
                    byDate: objectArrayToMap(action.payload.notes, 'created'),
                    allDates: action.payload.notes.map(n => n.created),
                    account: action.payload.account,
                    subscriptionId: action.payload.id,
                    isLoading: false,
                    error: null,
                },
            };

        case ERROR_LOADING_NOTES:
            return {
                ...state,
                notes: {
                    ...state.notes,
                    isLoading: false,
                    error: action.payload,
                },
            };

        case SAVING_NOTE:
            return {
                ...state,
                note: {
                    ...state.note,
                    isSaving: true,
                    error: null,
                },
            };

        case SAVED_NOTE:
            return {
                ...state,
                note: {
                    ...state.note,
                    isSaving: false,
                    error: null,
                },
            };

        case ERROR_SAVING_NOTE:
            return {
                ...state,
                note: {
                    ...state.note,
                    isSaving: false,
                    error: action.payload,
                },
            };

        default:
            return state;
    }
}

export default notesReducer;
