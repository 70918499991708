import React from 'react';
import { wrapWithRightNav } from '../../../../common/components/rightNav';

const InvoiceViewPage = React.lazy(() => import('../components'));
const RecentActivityPage = React.lazy(() => import('../../recentActivityPage'));
const InvoiceViewDetails = React.lazy(() => import('../../invoiceViewDetails'));
const CustomerPage = React.lazy(() => import('../../../../customerPage'));
const AddPaymentPage = React.lazy(() => import('../../addPaymentPage'));

const WrappedAddPaymentPage = wrapWithRightNav(AddPaymentPage);
const WrappedRecentActivityPage = wrapWithRightNav(RecentActivityPage);
const WrappedInvoiceViewDetails = wrapWithRightNav(InvoiceViewDetails);
const WrappedCustomerPage = wrapWithRightNav(CustomerPage);
const WrappedInvoiceViewPage = wrapWithRightNav(InvoiceViewPage);

const routes = [
    {
        path: '/invoices/:id/addPayment',
        exact: true,
        displayName: 'Add payment',
        render: props => <WrappedAddPaymentPage {...props} />,
    },
    {
        path: '/invoices/:id/activity',
        exact: true,
        displayName: 'Invoice recent activity',
        render: props => <WrappedRecentActivityPage {...props} />,
    },
    {
        path: '/invoices/:id/details',
        exact: true,
        displayName: 'Invoice details',
        render: props => <WrappedInvoiceViewDetails {...props} />,
    },
    {
        path: '/invoices/:id/customer/add',
        exact: true,
        displayName: 'Customer Add',
        render: props => <WrappedCustomerPage {...props} />,
    },
    {
        path: '/invoices/:id/customer/:accountId',
        exact: true,
        displayName: 'Customer edit',
        render: props => <WrappedCustomerPage {...props} />,
    },
    {
        path: '/invoices/:id',
        exact: true,
        displayName: 'Invoice view details',
        render: props => <WrappedInvoiceViewPage {...props} />,
    },
];

export default routes;
