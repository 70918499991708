import { createSelector } from 'reselect';

export const selectIsLoadingNotes = (state) => {
    return state.notes.isLoading;
};

export const selectErrorNotes = (state) => {
    return state.notes.error;
};

export const selectRawNotes = (state) => {
    return state.notes;
};

export const getNotes = createSelector(
    [selectRawNotes],
    (notes) => {
        return notes.allDates
            .map(id => notes.byDate[id]);
    },
);

export const selectNotesAccount = (state) => {
    return state.notes.account;
};

export const selectIsSavingNote = (state) => {
    return state.note.isSaving;
};

export const selectErrorNote = (state) => {
    return state.note.error;
};

export const selectNoteText = (state) => {
    return state.note.text;
};
