import numbro from 'numbro';
import moment from 'moment';
import querystring from 'query-string';
import { EXTERNAL_VENDORS } from 'legacy/utils/constants';
import { Decimal } from 'decimal.js';
import { getCookieValue } from './cookies';
import { getConfigVar } from 'features/common/utils/config.utils';

const BASE_URL = getConfigVar('REACT_APP_VTS_URL');
const PAYPAL_BASE_URL = getConfigVar('REACT_APP_PAYPAL_BASE_URL');
const DOLLAR_FORMAT = '($0,0.00)';
const TRANSACTION_DATE_FORMAT = 'MM/DD/YYYY h:mm A';
const RECEIPT_DATE_FORMAT = 'MMM. D, YYYY';
const RECEIPT_TIME_FORMAT = 'h:mm a';
const FETCH_PARAMS = {
    credentials: 'include',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
};
const BASIC_EMAIL_REGEX = /.+@.+/;

export const noOp = () => {};

let csrftoken = undefined;
export const setCsrfTokenApiCall = (token) => {
    csrftoken = token;
};

// Shallow compare values of two arrays
// Good for arrays of primitives only
export const areArraysEqual = (arrayA = [], arrayB = []) => {
    if (arrayA.length !== arrayB.length) {
        return false;
    }
    return arrayA.every((value, index) => arrayA[index] === arrayB[index]);
};

// Custom string comparison function
// Same as alphabetical sort, except sorts empty strings last
// Returns -1, 0, or 1
export const compareStrings = (stringA = '', stringB = '') => {
    if (!stringA && !stringB) {
        return 0;
    }
    if (!stringA) {
        return 1;
    }
    if (!stringB) {
        return -1;
    }
    return stringA.localeCompare(stringB);
};

export const fullname = ({ firstname, lastname }) => {
    const name = [];
    if (firstname) name.push(firstname);
    if (lastname) name.push(lastname);
    return name.join(' ');
};

export const formatDollars = (amount) => {
    return numbro(amount || 0).format(DOLLAR_FORMAT);
};

export const formatDate = (date) =>
    moment(date).format(TRANSACTION_DATE_FORMAT);

export const formatReceiptDate = (date) => {
    const momentDate = moment(date);
    return {
        date: momentDate.format(RECEIPT_DATE_FORMAT),
        time: momentDate.format(RECEIPT_TIME_FORMAT),
    };
};

export function filterNonNumericFromString(string) {
    if (typeof string !== 'string') {
        console.error('Unexpected value');
        return '';
    }
    return string
        .split('')
        .filter((char) => Number.isInteger(+char))
        .join('');
}

export function filterNonAlphaNumericFromString(string) {
    if (typeof string !== 'string') {
        console.error('Unexpected value');
        return '';
    }
    return string
        .split('')
        .filter((char) => /[0-9A-Z ]/i.test(char))
        .join('');
}

export function getUrl(path, query) {
    const url = `${BASE_URL}${path[0] === '/' ? path.slice(1) : path}`;
    if (query) {
        return url + '?' + querystring.stringify(query);
    }
    return url;
}

export function apiCall(path, { method = 'GET', ...passedOptions } = {}) {
    const options = {
        ...FETCH_PARAMS,
        method,
        ...passedOptions,
    };
    const url = getUrl(path, options.query);

    if (options.body && typeof options.body !== 'string') {
        options.body = JSON.stringify(options.body);
    }

    if (!/^(GET|HEAD|OPTIONS|TRACE)$/i.test(method)) {
        options.headers['X-csrftoken'] = csrftoken;
    }

    return fetch(url, options)
        .then((response) => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            if (response.status === 204) {
                return;
            }
            return response.json();
        })
        .catch((response) => {
            console.error(response);
            if (response instanceof Response) {
                return response.json().then((data) => {
                    return Promise.reject({
                        response,
                        ...data,
                    });
                });
            }
            return Promise.reject(response);
        });
}

export function getPaypalUrl(path, query) {
    const url = `${PAYPAL_BASE_URL}${path}`;
    if (query) {
        return url + '?' + querystring.stringify(query);
    }
    return url;
}

export function paypalApiCall(path, { method = 'GET', ...passedOptions } = {}) {
    const refreshToken = getCookieValue('RefreshToken');
    const options = {
        credentials: 'include',
        withCredentials: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${refreshToken}`,
        },
        method,
        ...passedOptions,
    };
    const url = getPaypalUrl(path, options.query);

    if (options.body && typeof options.body !== 'string') {
        options.body = JSON.stringify(options.body);
    }

    return fetch(url, options)
        .then((response) => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            if (response.status === 204) {
                return;
            }
            return response.json();
        })
        .catch((response) => {
            console.error(response);
            if (response instanceof Response) {
                return response.json().then((data) => {
                    return Promise.reject({
                        response,
                        ...data,
                    });
                });
            }
            return Promise.reject(response);
        });
}

export const formatMerchantAddress = ({
    address1,
    address2,
    city,
    state,
    postal,
    postalCode,
}) => {
    let address = '';

    if (!address1 && !address2 && !city && !state && !(postal || postalCode)) {
        return 'No address on file';
    }

    if (address1) {
        address += address1;
    }

    if (address2) {
        if (address1) {
            address += ' ';
        }

        address += address2;
    }

    if ((address1 || address2) && (city || state || postal || postalCode)) {
        address += ', ';
    }

    if (city) {
        address += city;
    }

    if (state) {
        if (city) {
            address += ' ';
        }
        address += state;
    }

    if (postal || postalCode) {
        if (city || state) {
            address += ' ';
        }
        address += postal || postalCode;
    }

    return address;
};

export function objectArrayToMap(objects, idKey = 'id') {
    const map = {};
    objects.forEach((object) => {
        const id = object[idKey];
        map[id] = object;
    });
    return map;
}

export function isValidEmail(email) {
    return typeof email === 'string' && Boolean(email.match(BASIC_EMAIL_REGEX));
}

export function capitalize(string) {
    // Will only capitalize first character
    if (typeof string !== 'string') {
        return '';
    }
    return (string[0] || '').toUpperCase() + string.slice(1);
}

export function hasChanged(objectA, objectB, keys) {
    return keys.some((key) => objectA[key] !== objectB[key]);
}

// Determine the current state of the billing method
//
//  The card state returned must match the styles names for this to work.
//
//  param {string} expirationDate - a date string
//  returns {string} 'ok' if expiration day is more than 30 days away,
//  'expiring-soon' if less than 30 days left before expiration, and
//  'expired' if the card is expired.
export function determineBillingMethodState(expirationDate) {
    const expiring = moment(expirationDate);
    const today = moment();

    const daysLeft = expiring.diff(today, 'days');

    if (daysLeft > 30) {
        return 'ok';
    }
    if (daysLeft > 0) {
        return 'expiring-soon';
    }
    return 'expired';
}

// Determine the tooltip text for the current state of the billing method
// (credit card status)
//
//  param {string} expirationDate - a date string
//  returns {strint} tooltip text to help the user determine what the state means
export function billingMethodStateTooltipText(expirationDate) {
    const expiring = moment(expirationDate);
    const billingMethodState = determineBillingMethodState(expirationDate);

    switch (billingMethodState) {
        case 'ok':
            return __('Expires ') + expiring.format('MM/YY');
        case 'expiring-soon':
            return __('Expires next month');
        case 'expired':
            return __('Expired');
        default:
            return __('Unknown State');
    }
}

export function transactionStatusLabel(status) {
    return status.toLowerCase();
}

export function isExternalVendor(vendor) {
    return EXTERNAL_VENDORS.includes(vendor);
}

export function isNotZero(value) {
    return !new Decimal(value || 0).equals(0);
}

export function calculateMaxRefund(actionsHistory, total) {
    const previousTotalRefunded = actionsHistory
        .filter((action) => action.status === 'refunded')
        .reduce(
            (previousValue, currentValue) =>
                previousValue + parseFloat(currentValue.amount),
            0,
        );
    return (parseFloat(total) + previousTotalRefunded).toFixed(2);
}

export function setQueryParams(history, pathname) {
    return (queryParams) =>
        history.push({
            pathname,
            search:
                '?' +
                querystring.stringify(queryParams, { arrayFormat: 'comma' }),
        });
}
