import queryString from 'query-string';
import { apiCall, paypalApiCall } from '../../utils/utils';
import {
    deserializeSingleTransaction,
    deserializeTransactions,
    serializeNewSale,
    serializeNewVoid,
    serializeNewRefund,
    serializePaypalRefund,
} from './serializers';

const PAGE_SIZE = 10;

export const formatStatusFilterQs = (statusFilters) => {
    return Object.keys(statusFilters)
        .filter(key => statusFilters[key])
        .map(item => item);
}

const createQueryStringUrl = (q, dateStart, dateEnd, statusFilters, timeStart, timeEnd) => {
    const qs = queryString.stringify({
        q: q ? q : undefined,
        date_start: dateStart ? dateStart : undefined,
        date_end: dateEnd ? dateEnd : undefined,
        time_start: timeStart ? timeStart : undefined,
        time_end: timeEnd ? timeEnd : undefined,
        statuses: formatStatusFilterQs(statusFilters).join(',') ? formatStatusFilterQs(statusFilters) : undefined,
    });

    return `&${qs}`;
}

export const fetchAllTransactions = ({
    page,
    sortBy,
    sortOrder,
    q,
    dateStart,
    dateEnd,
    statusFilters,
    timeStart,
    timeEnd,
}) => {
    const qs = createQueryStringUrl(q, dateStart, dateEnd, statusFilters, timeStart, timeEnd)
    return apiCall(`/v2/virtual_terminal/transactions?page=${page}&page_size=${PAGE_SIZE}&order_by=${sortBy}&order=${sortOrder}${qs}`)
        .then((response) => {
            return {
                currentPage: response.current_page,
                totalPages: response.total_pages,
                totalNumResults: response.total_num_results,
                results: deserializeTransactions(response.transactions),
            };
        });
};

export const fetchTransactionsByAccount = ({
    accountId,
    page,
    sortBy,
    sortOrder,
    q,
    dateStart,
    dateEnd,
    statusFilters
}) => {
    const qs = createQueryStringUrl(q, dateStart, dateEnd, statusFilters)
    return apiCall(`/v2/virtual_terminal/transactions?payment_account_id=${accountId}&page=${page}&page_size=${PAGE_SIZE}&order_by=${sortBy}&order=${sortOrder}${qs}`)
        .then((response) => {
            return {
                currentPage: response.current_page,
                totalPages: response.total_pages,
                totalNumResults: response.total_num_results,
                results: deserializeTransactions(response.transactions),
            };
        });
};

export const fetchSingleTransaction = (id) => {
    return apiCall(`/v2/virtual_terminal/transactions/${id}`).then(deserializeSingleTransaction);
};

const getEndpoint = ({ vendor, parentId }) => {
    switch (vendor) {
        case 'transit':
            return 'transit_terminal/transactions';
        case 'spoton_terminal':
            return 'v3/spoton_terminal/transactions';
        case 'nmi_terminal':
            return 'v2/nmi_terminal/transactions';
        default:
            return 'v1/nmi_terminal/transactions';
    }
}

const createPostHandler = (serializer) => (
    (transaction) => {
        const serializedData = serializer(transaction);
        const url = getEndpoint(transaction)
        return apiCall(url, {
            method: 'POST',
            body: serializedData,
        }).then(data => {
            return deserializeSingleTransaction(data);
        });
    }
);

export const createTransaction = createPostHandler(serializeNewSale);

export const createVoid = createPostHandler(serializeNewVoid);

export const createRefund = createPostHandler(serializeNewRefund);

export const createPaypalRefund = async ({ transactionId, ...payload }) => {
    const paypalServiceResponse = await paypalApiCall(`/api/capture/${transactionId}/refund`, {
        method: 'POST',
        body: serializePaypalRefund(payload),
    });

    return paypalServiceResponse;
};

export const createTransactionAccountPaymentMethod = (transaction) => {
    const serializedData = serializeNewSale(transaction);
    return apiCall('/nmi_terminal/accounts/transaction', {
        method: 'POST',
        body: serializedData,
    });
}
