import axios from 'axios';
import queryString from 'query-string';
import { createAxiosWrapper } from 'legacy/utils/axiosWrapper';
import { getConfigVar } from 'features/common/utils/config.utils';
import { csrftoken } from 'legacy/utils/axiosWrapper';

const BASE_URL = getConfigVar('REACT_APP_VTS_URL');
const FETCH_PARAMS = {
    credentials: 'include',
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
};

export const checkUniqueCustomId = (customId) => {
    return createAxiosWrapper().get(
        `/nmi_terminal/invoices/check_id/${customId}`,
    );
};

export const submitSettings = (data) => {
    return createAxiosWrapper().patch('/nmi_terminal/merchant_settings', data);
};

export const submitInvoice = (formData) => {
    return axios.post(
        `${BASE_URL}nmi_terminal/invoices/v2/create_invoice_with_attachments`,
        formData,
        {
            credentials: 'include',
            withCredentials: true,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                ...(csrftoken ? { 'X-csrftoken': csrftoken } : {}),
            },
        },
    );
};

export const updateInvoice = (invoiceId, data) => {
    return createAxiosWrapper().patch(
        `/nmi_terminal/invoices/${invoiceId}`,
        data,
    );
};

export const addAttachment = (invoiceId, formData) => {
    return axios.post(
        `${BASE_URL}nmi_terminal/invoices/${invoiceId}/attachments`,
        formData,
        {
            credentials: 'include',
            withCredentials: true,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                ...(csrftoken ? { 'X-csrftoken': csrftoken } : {}),
            },
        },
    );
};

export const removeAttachment = (invoiceId, attachmentId) => {
    return createAxiosWrapper().delete(
        `/nmi_terminal/invoices/${invoiceId}/attachments/${attachmentId}`,
    );
};

export const fetchInvoiceDetails = (id) => {
    return createAxiosWrapper().get(`/nmi_terminal/invoices/${id}`);
};

export const deleteInvoice = (invoiceId) => {
    /** really dont know whats happening here, could be our proxy? coulde be core? could be our BE? */
    const options = {
        ...FETCH_PARAMS,
        method: 'DELETE',
    };
    return fetch(`${BASE_URL}nmi_terminal/invoices/${invoiceId}`, options);
};

export const submitMerchantPayment = (data) => {
    return createAxiosWrapper().post(
        '/nmi_terminal/invoices/v2/merchant_register_payment',
        data,
    );
};

export const getInvoiceSettings = () => {
    return createAxiosWrapper().get(`/invoicing-service/api/v1/settings/`);
};

export const previewInvoice = (data) => {
    return createAxiosWrapper().post(
        '/nmi_terminal/invoices/v2/preview_invoice',
        data,
    );
};

export const printInvoice = (invoiceId) => {
    return createAxiosWrapper().post(
        `/nmi_terminal/invoices/v2/${invoiceId}/preview_invoice`,
    );
};

export const sendReminder = (invoiceId, data) => {
    return createAxiosWrapper().post(
        `/nmi_terminal/invoices/v2/${invoiceId}/send_email`,
        data,
    );
};

export const fetchInvoices = ({
    page,
    pageSize,
    order,
    orderBy,
    q,
    invoiceStatus,
    dueDateStart,
    dueDateEnd,
    accountId,
}) => {
    const qs = queryString.stringify({
        page,
        page_size: pageSize,
        order,
        order_by: orderBy,
        q,
        invoice_status: invoiceStatus,
        // Changed mapping based on this ticket https://spotonteam.atlassian.net/browse/VT-2170
        created_date_start: dueDateStart,
        created_date_end: dueDateEnd,
        account_id: accountId,
    });
    return createAxiosWrapper().get(`/nmi_terminal/invoices/?${qs}`);
};
