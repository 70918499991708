import {
    applyMiddleware,
    bindActionCreators,
    combineReducers,
    compose,
    createStore,
} from 'redux';

import thunk from 'redux-thunk';

import { accountActions, accountsReducer } from './accounts';
import merchant, * as merchantActions from './merchant';
import terminal, * as terminalActions from './terminal';
import { transactionActions, transactionReducer } from './transactions';
import { paymentMethodActions, paymentMethodsReducer } from './paymentMethods';
import { recurringPlansActions, recurringPlansReducer } from '../features/recurringPayments/redux/plans';
import { subscriptionsActions, subscriptionsReducer } from '../features/recurringPayments/redux/subscriptions';
import { recurringAccountsReducer, recurringAccountsActions } from '../features/recurringPayments/redux/accounts';
import { disputeTransactionActions, disputeTransactionsReducer } from '../features/disputeTransactions/redux/disputeTransactions';
import {
    notesActions,
    notesReducer,
} from '../features/notes/redux/notes';
import {
    invoicesActions,
    invoicesReducer,
} from '../features/invoicing/redux/invoices';
import {
    customerFacingActions,
    customerFacingReducer,
} from '../features/customerFacingApp/redux/customerFacing';

import {
    rightNavActions,
    rightNavReducer,
    paymentFormActions,
    paymentFormReducer,
    projectUrlActions,
    projectUrlReducer
} from '../features/common/redux';

import {
    failedTransactionActions,
    failedTransactionsReducer,
} from '../features/failedSubscriptions/redux';

const reducer = combineReducers({
    accounts: accountsReducer,
    merchant,
    terminal,
    transactions: transactionReducer,
    paymentMethods: paymentMethodsReducer,
    recurringPlans: recurringPlansReducer,
    subscriptions: subscriptionsReducer,
    rightNav: rightNavReducer,
    paymentForm: paymentFormReducer,
    projectUrl: projectUrlReducer,
    failedTransactions: failedTransactionsReducer,
    recurringAccounts: recurringAccountsReducer,
    disputes: disputeTransactionsReducer,
    notes: notesReducer,
    invoices: invoicesReducer,
    customerFacing: customerFacingReducer,
});

const storeName = 'Virtual Terminal (' + process.env.NODE_ENV + ')';
const enhancers = compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__({ name: storeName })
        : (f) => { return f; }
);

const store = createStore(reducer, enhancers);

const bindActions = (actionCreators) => bindActionCreators(actionCreators, store.dispatch);

const bindDispatch = (actionCollection, ...keys) => (
    keys.reduce((boundCollection, key) => (
        { ...boundCollection, [key]: bindActions(actionCollection[key]) }
    ), {})
);

export default store;
export {
    bindDispatch,
    accountActions,
    merchantActions,
    terminalActions,
    transactionActions,
    paymentMethodActions,
    recurringPlansActions,
    subscriptionsActions,
    rightNavActions,
    paymentFormActions,
    failedTransactionActions,
    recurringAccountsActions,
    disputeTransactionActions,
    notesActions,
    projectUrlActions,
    invoicesActions,
    customerFacingActions,
};
