import * as SubscriptionsService from '../../api/subscriptions';
import * as PlansService from '../../api/plans';
import addNotification, { DANGER, SUCCESS } from '../../../common/utils/notificationHelper';
import { setNewPaymentMethods } from '../../../../store/paymentMethods/paymentMethods';
import pick from 'lodash.pick';
import get from 'lodash.get';
import { getRecurringPlans } from '../plans/actions';
import { getRecurringPlansSuccess } from '../plans/actions';
import { updatePaymentInformation } from '../accounts/actions';

const ns = '[subscriptions]';
export const SET_EXISTING_PLAN = `${ns} Set existing plan dropdown`;
export const SET_START_DATE = `${ns} Set start date`;
export const SET_END_DATE_OPTION = `${ns} Set End date option`;
export const SET_NUMBER_OF_PAYMENTS = `${ns} Set number of payments`;
export const SET_END_DATE = `${ns} Set end date`;
export const CREATE_PAYMENT_METHOD_STARTED = `${ns} create payment method started`;
export const CREATE_PAYMENT_METHOD_SUCCESS = `${ns} create payment method success`;
export const CREATE_PAYMENT_METHOD_FAILED = `${ns} create payment method failed`;
export const SET_DEFAULT_PAYMENT_METHOD_STARTED = `${ns} set default payment method started`;
export const SET_DEFAULT_PAYMENT_METHOD_SUCCESS = `${ns} set default payment method success`;
export const SET_DEFAULT_PAYMENT_METHOD_FAILED = `${ns} set default payment method failed`;
export const UPDATE_PAYMENT_METHOD_STARTED = `${ns} update payment method started`;
export const UPDATE_PAYMENT_METHOD_SUCCESS = `${ns} update payment method success`;
export const UPDATE_PAYMENT_METHOD_FAILED = `${ns} update payment method failed`;
export const CREATE_SUBSCRIPTION_FROM_ACCOUNT_STARTED = `${ns} create subscription from account started`;
export const CREATE_SUBSCRIPTION_FROM_ACCOUNT_SUCCESS = `${ns} create subscription from account success`;
export const CREATE_SUBSCRIPTION_FROM_ACCOUNT_FAILED = `${ns} create subscription from account failed`;
export const CANCEL_SUBSCRIPTION_STARTED = `${ns} Cancel subscription started`;
export const CANCEL_SUBSCRIPTION_SUCCESS = `${ns} Cancel subscription success`;
export const CANCEL_SUBSCRIPTION_FAILED = `${ns} Cancel subscription failed`;
export const CANCEL_MULTIPLE_SUBSCRIPTIONS_STARTED = `${ns} Cancel multiple subscriptions started`;
export const CANCEL_MULTIPLE_SUBSCRIPTIONS_SUCCESS = `${ns} Cancel multiple subscriptions success`;
export const CANCEL_MULTIPLE_SUBSCRIPTIONS_FAILED = `${ns} Cancel multiple subscriptions failed`;
export const RESET_SUBSCRIPTION_PAGE = `${ns} reset page`;
export const GET_SUBSCRIPTIONS_STARTED = `${ns} Load started`;
export const GET_SUBSCRIPTIONS_SUCCESS = `${ns} Load success`;
export const GET_SUBSCRIPTIONS_FAILED = `${ns} Load failed`;
export const SET_SELECTED_SUBCRIPTION = `${ns} set subscription to edit`;
export const UPDATE_SUBSCRIPTION_STARTED = `${ns} Update subscription started`;
export const UPDATE_SUBSCRIPTION_SUCCESS = `${ns} Update subscription success`;
export const UPDATE_SUBSCRIPTION_FAILED = `${ns} Update subscription failed`;
export const PREVIEW_BILLING_PERIODS_STARTED = `${ns} fetch up to 6 billing periods started`;
export const PREVIEW_BILLING_PERIODS_SUCCESS = `${ns} fetch up to 6 billing periods success`;
export const PREVIEW_BILLING_PERIODS_FAILED = `${ns} fetch up to 6 billing periods failed`;

const PAGE_SIZE = 10000;

const getSubscriptionsStarted = () => ({
    type: GET_SUBSCRIPTIONS_STARTED
});

const getSubscriptionsFailed = (error) => ({
    type: GET_SUBSCRIPTIONS_FAILED,
    payload: error
});

const getSubscriptionsSuccess = (payload) => ({
    type: GET_SUBSCRIPTIONS_SUCCESS,
    payload: {
        data: payload.data,
        planId: payload.planId,
        accountId: payload.accountId,
    }
});

const cancelSubscriptionStarted = () => ({
    type: CANCEL_SUBSCRIPTION_STARTED
});

const cancelSubscriptionSuccess = (payload) => ({
    type: CANCEL_SUBSCRIPTION_SUCCESS,
    payload
});

const cancelSubscriptionFailed = (err) => ({
    type: CANCEL_SUBSCRIPTION_FAILED,
    payload: err
});

const cancelMultipleSubscriptionsStarted = () => ({
    type: CANCEL_MULTIPLE_SUBSCRIPTIONS_STARTED
});

const cancelMultipleSubscriptionsSuccess = (payload) => ({
    type: CANCEL_MULTIPLE_SUBSCRIPTIONS_SUCCESS,
    payload
});

const cancelMultipleSubscriptionsFailed = (err) => ({
    type: CANCEL_MULTIPLE_SUBSCRIPTIONS_FAILED,
    payload: err
});

const updateSubscriptionStarted = () => ({
    type: UPDATE_SUBSCRIPTION_STARTED
});

const updateSubscriptionSuccess = (payload) => ({
    type: UPDATE_SUBSCRIPTION_SUCCESS,
    payload
});

const updateSubscriptionFailed = (err) => ({
    type: UPDATE_SUBSCRIPTION_FAILED,
    payload: err
});

export const setExistingPlan = (existingPlan) => ({
    type: SET_EXISTING_PLAN,
    payload: existingPlan
});

export const setSelectedSubscription = (sub) => ({
    type: SET_SELECTED_SUBCRIPTION,
    payload: sub
});

export const setStartDate = (startDate) => ({
    type: SET_START_DATE,
    payload: startDate
});

export const setEndDateOption = (endDateOption) => ({
    type: SET_END_DATE_OPTION,
    payload: endDateOption
});

export const setNumberOfPayments = (number) => ({
    type: SET_NUMBER_OF_PAYMENTS,
    payload: number
});

export const setEndDate = (endDate) => ({
    type: SET_END_DATE,
    payload: endDate
});

export const createPaymentMethodFromAccountStarted = () => ({
    type: CREATE_PAYMENT_METHOD_STARTED
});

export const createPaymentMethodFromAccountSuccess = () => ({
    type: CREATE_PAYMENT_METHOD_SUCCESS
});

export const createPaymentMethodFromAccountFailed = (error) => ({
    type: CREATE_PAYMENT_METHOD_FAILED,
    payload: error
});

export const setDefaultPaymentMethodFromAccountStarted = () => ({
    type: SET_DEFAULT_PAYMENT_METHOD_STARTED
});

export const setDefaultPaymentMethodFromAccountSuccess = () => ({
    type: SET_DEFAULT_PAYMENT_METHOD_SUCCESS
});

export const setDefaultPaymentMethodFromAccountFailed = (error) => ({
    type: SET_DEFAULT_PAYMENT_METHOD_FAILED,
    payload: error
});

export const updatePaymentMethodFromAccountStarted = () => ({
    type: UPDATE_PAYMENT_METHOD_STARTED
});

export const updatePaymentMethodFromAccountSuccess = () => ({
    type: UPDATE_PAYMENT_METHOD_SUCCESS
});

export const updatePaymentMethodFromAccountFailed = (error) => ({
    type: UPDATE_PAYMENT_METHOD_FAILED,
    payload: error
});

export const createSubscriptionFromAccountStarted = () => ({
    type: CREATE_SUBSCRIPTION_FROM_ACCOUNT_STARTED
});

export const createSubscriptionFromAccountSuccess = () => ({
    type: CREATE_SUBSCRIPTION_FROM_ACCOUNT_SUCCESS
});

export const createSubscriptionFromAccountFailed = (error) => ({
    type: CREATE_SUBSCRIPTION_FROM_ACCOUNT_FAILED,
    payload: error
});

export const previewBillingPeriodsStarted = () => ({
    type: PREVIEW_BILLING_PERIODS_STARTED
});

export const previewBillingPeriodsFailed = (error) => ({
    type: PREVIEW_BILLING_PERIODS_FAILED,
    payload: error
});

export const previewBillingPeriodsSuccess = (payload) => ({
    type: PREVIEW_BILLING_PERIODS_SUCCESS,
    payload
});

export const resetSubscriptionPage = () => ({
    type: RESET_SUBSCRIPTION_PAGE
});

export const createPaymentMethodFromAccount = ({
    id,
    firstName,
    lastName,
}) => (dispatch, getState) => {

    const paymentFormState = getState().paymentForm;

    const formatData = {
        expirationDate: null,
        cardType: null,
        cc: null,
        paymentToken: paymentFormState.paymentToken,
        isDefault: true,
        address: {
            firstName,
            lastName,
            address1: "",
            address2: "",
            city: "",
            provinceCode: "",
            countryCode: "",
            postalCode: paymentFormState.postalCode,
            phoneNumber: "",
            faxNumber: "",
        }
    }

    return SubscriptionsService.createPaymentMethod(id, formatData)
        .then(({data}) => {
            return SubscriptionsService.getPaymenthMethods(id)
                .then(({data: methods}) => {
                    dispatch(createPaymentMethodFromAccountSuccess());
                    dispatch(setNewPaymentMethods(methods));
                    dispatch(updatePaymentInformation(data));
                    return {
                        data,
                        payments: methods
                    };
                })
        })
        .catch((error) => {
            dispatch(createPaymentMethodFromAccountFailed(error));
            addNotification({
                title: get(error, 'response.data.error', 'Something went wrong'),
                type: DANGER,
            });
        });
};

export const setDefaultPaymentMethodFromAccount = (id, method) => (dispatch) => {
    dispatch(setDefaultPaymentMethodFromAccountStarted());

    const updated = {
        ...pick(method, ['id', 'ccExpiration', 'cardType', 'ccLastFour', 'isDefault', 'address']),
        isDefault: true
    };

    return SubscriptionsService.updatePaymentMethod(id, method.id, updated)
        .then(({ data }) => {
            return SubscriptionsService.getPaymenthMethods(id)
                .then(({ data: methods }) => {
                    dispatch(setDefaultPaymentMethodFromAccountSuccess());
                    dispatch(setNewPaymentMethods(methods));
                    dispatch(updatePaymentInformation(data));
                    return {
                        data,
                        payments: methods
                    };
                })
        })
        .catch((error) => {
            dispatch(setDefaultPaymentMethodFromAccountFailed(error));
        });
};

export const updatePaymentMethodFromAccount = (id, method) => (dispatch, getState) => {
    dispatch(updatePaymentMethodFromAccountStarted());

    const updated = {
        ...pick(method, ['id', 'address', 'paymentToken']),
        isDefault: true,
        address: {
            ...method.address,
            postalCode: getState().paymentForm.postalCode
        }
    };
    return SubscriptionsService.updatePaymentMethod(id, method.id, updated)
        .then(({ data }) => {
            return SubscriptionsService.getPaymenthMethods(id)
                .then(({ data: methods }) => {
                    dispatch(updatePaymentMethodFromAccountSuccess());
                    dispatch(setNewPaymentMethods(methods));
                    dispatch(updatePaymentInformation(data));
                    return {
                        data,
                        payments: methods
                    };
                })
        })
        .catch((error) => {
            dispatch(updatePaymentMethodFromAccountFailed(error));
            addNotification({
                title: get(error, 'response.data.error', 'Something went wrong'),
                type: DANGER,
            });
        });
};

export const createSubscriptionFromAccount = (data) => (dispatch) => {
    dispatch(createSubscriptionFromAccountStarted());

    return SubscriptionsService.createSubscription(data)
        .then(({ data }) => {
            dispatch(createSubscriptionFromAccountSuccess());
            addNotification({
                title: 'Success!',
                message: 'You have successfully created a new subscription',
                type: SUCCESS,
            });
            return data;
        })
        .catch((error) => {
            dispatch(createSubscriptionFromAccountFailed(error));
            addNotification({
                title: get(error, 'response.data.error', 'Something went wrong'),
                type: DANGER,
            });
        });
}

export const getSubscriptions = ({
    page = 1,
    pageSize = 10,
    status,
    q,
    order = 'asc',
    orderBy = 'plan_name',
    planId,
    accountId,
}) => (dispatch) => {
    dispatch(getSubscriptionsStarted());
    const fetchPlansPromise = PlansService.fetchAllPlans({
        page: 1,
        pageSize: PAGE_SIZE,
    });
    const fetchSubscriptionPromise = SubscriptionsService.fetchAllSubscriptions({
        page,
        pageSize,
        status,
        q,
        order,
        planId,
        orderBy,
        accountId
    });
    return Promise.all([
        fetchSubscriptionPromise,
        fetchPlansPromise,
    ])
        .then(([subs, plans]) => {
            if (![200, 201].includes(subs.status) || ![200, 201].includes(plans.status)) {
                throw new Error(subs.data.error);
            }

            dispatch(
                getRecurringPlansSuccess(plans.data)
            );

            dispatch(getSubscriptionsSuccess({
                data: subs.data,
                planId,
                accountId
            }));

            return subs.data;
        })
        .catch(error => {
            dispatch(getSubscriptionsFailed(get(error, 'response')));
        });
};


export const cancelSubscription = (subscriptionId) => (dispatch) => {
    dispatch(cancelSubscriptionStarted());

    return SubscriptionsService.cancelSubscription(subscriptionId)
        .then(({ data }) => {
            dispatch(cancelSubscriptionSuccess());
            addNotification({
                title: 'Success!',
                message: 'You have successfully cancelled a subscription',
                type: SUCCESS,
            });
            return data;
        })
        .catch((error) => {
            dispatch(cancelSubscriptionFailed(error));
            addNotification({
                title: get(error, 'response.data.error', 'Something went wrong'),
                type: DANGER,
            });
        });
}

export const cancelMultipleSubscriptions = (subscriptionIds) => (dispatch) => {
    dispatch(cancelMultipleSubscriptionsStarted());

    const cancelSubscriptionsPromises = subscriptionIds.map(subscriptionId => {
        return SubscriptionsService.cancelSubscription(subscriptionId);
    });

    return Promise.all(cancelSubscriptionsPromises)
        .then(({ data }) => {
            dispatch(cancelMultipleSubscriptionsSuccess());
            return data;
        })
        .catch((error) => {
            dispatch(cancelMultipleSubscriptionsFailed(error));
        });
}

export const updateSubscription = (subscriptionId, data) => (dispatch) => {
    dispatch(updateSubscriptionStarted());
    return SubscriptionsService.updateSubscription(subscriptionId, data)
        .then(({ data }) => {
            dispatch(updateSubscriptionSuccess(data));
            addNotification({
                title: 'Success!',
                message: 'You have successfully updated a subscription',
                type: SUCCESS,
            });
            return data;
        })
        .catch((error) => {
            dispatch(updateSubscriptionFailed(error));
            addNotification({
                title: get(error, 'response.data.error', 'Something went wrong'),
                type: DANGER,
            });
        });
}

export const previewBillingPeriods = (data) => (dispatch) => {
    dispatch(previewBillingPeriodsStarted());
    return SubscriptionsService.previewBillingPeriods(data)
        .then(({ data }) => {
            dispatch(previewBillingPeriodsSuccess(data))
            return data;
        })
        .catch((error) => {
            dispatch(previewBillingPeriodsFailed(error));
            addNotification({
                title: get(error, 'response.data.error', 'Something went wrong'),
                type: DANGER,
            });
        });
};
