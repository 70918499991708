import get from 'lodash.get';
import * as CustomerFacingService from '../../api/customerFacing';
import addNotification, { DANGER, SUCCESS } from '../../../common/utils/notificationHelper';
import { setFieldsDirty } from '../../../common/redux/paymentForm/paymentForm.actions';

const ns = '[Customer Facing App]';
export const GET_CUSTOMER_FACING_INVOICE_STARTED = `${ns} get invoice data STARTED`;
export const GET_CUSTOMER_FACING_INVOICE_SUCCESS = `${ns} get invoice data SUCCESS`;
export const GET_CUSTOMER_FACING_INVOICE_FAILED = `${ns} get invoice data FAILED`;
export const REGISTER_PAYMENT_STARTED = `${ns} register payment STARTED`;
export const REGISTER_PAYMENT_SUCCESS = `${ns} register payment SUCCESS`;
export const REGISTER_PAYMENT_FAILED = `${ns} register payment FAILED`;

export const getCustomerInvoiceStarted = () => ({
    type: GET_CUSTOMER_FACING_INVOICE_STARTED,
});

export const getCustomerInvoiceSuccess = (data) => {
    const {invoice, tokenizationKey, invoicingSettings, ...merchant} = data;
    const {account, ...rest} = invoice;
    const { settings } = invoicingSettings;
    return {
        type: GET_CUSTOMER_FACING_INVOICE_SUCCESS,
        payload: {
            invoice: rest,
            account,
            merchant,
            tokenizationKey,
            invoiceSettings: settings
        }
    };
};

export const getCustomerInvoiceFailed = (error) => ({
    type: GET_CUSTOMER_FACING_INVOICE_FAILED,
    payload: error,
});

export const getCustomerInvoice = (merchantId,uuid) => (dispatch) => {
    dispatch(getCustomerInvoiceStarted());
    return CustomerFacingService.getCustomerInvoice(merchantId,uuid)
        .then(res => {
            if (![200, 201].includes(res.status)) {
                throw new Error(res.data.error);
            }
            dispatch(getCustomerInvoiceSuccess(res.data));
            return res.data;
        })
        .catch(error => {
            const err = get(error, 'response.data.detail') || 'Something went wrong';
            dispatch(getCustomerInvoiceFailed(err));
            throw new Error(err);
        });
};

export const registerPaymentStarted = () => ({
    type: REGISTER_PAYMENT_STARTED,
});

export const registerPaymentSuccess = (data) => ({
    type: REGISTER_PAYMENT_SUCCESS,
    payload: data,
});

// change to thunk
export const registerPaymentFailed = (error) => ({
    type: REGISTER_PAYMENT_FAILED,
    payload: error,
});

export const registerPayment = (merchantId,data,uuid,showError) => (dispatch) => {
    dispatch(registerPaymentStarted());
    return CustomerFacingService.registerPayment(merchantId, data)
        .then(res => {
            if (![200, 201].includes(res.status)) {
                throw new Error(res.data.error);
            }
            dispatch(getCustomerInvoice(merchantId, uuid));
            dispatch(registerPaymentSuccess(res.data));
            return res.data;
        })
        .catch(error => {
            const err = get(error, 'response.data.detail') || 'Something went wrong';
            dispatch(registerPaymentFailed(err));
            dispatch(setFieldsDirty());
            addNotification({
                title: err,
                type: DANGER,
            });

            if (typeof showError === 'function') showError();

            throw new Error(err);
        });
};
