/* eslint-disable no-console, no-undef, @typescript-eslint/explicit-module-boundary-types */
/**
 * Display information related to state change
 *
 * Prints a grouped message based on *action type*
 *  - action dispatched
 *  - next state
 *
 *  @param {Object} store - the redux store
 *  @param {Func} next - the next action
 *  @param {Object} action - the action
 *  @returns {Object} the next redux state
 */
export const logger = (store: any) => (next: any) => (action: any) => {
    console.group(action.type);
    console.info('dispatching', action);

    const result = next(action);

    console.log('next state', store.getState());
    console.groupEnd();

    return result;
};

export default logger;
