import isEmpty from 'lodash.isempty';

export const BEFORE = 'before';
export const ON = 'on';
export const AFTER = 'after';
export const BEFORE_LABEL = 'Days before due date';
export const ON_LABEL = 'On due date';
export const AFTER_LABEL = 'Days after due date';

export const remindersOptions = [
    { value: BEFORE, label: BEFORE_LABEL },
    { value: ON, label: ON_LABEL },
    { value: AFTER, label: AFTER_LABEL },
];

export const formatReminders = (reminders) => {
    if (!reminders) return [];
    return reminders.map(r => {
        return {
            days: r.days < 0 ? (r.days * -1) : r.days,
            options: {
                ...formatOptionObj(r.days),
            },
            isOpen: false,
            message: r.message,
            isDeposit: r.isDeposit ?? false
        };
    });
};

export const formatBackendReminders = (reminders) => {
    if (isEmpty(reminders)) return null;
    return reminders.map((r) => {
        return {
            days: getDaysValue(r),
            message: r.message || '',
            isDeposit: r.isDeposit ?? false,
        };
    });
};

const getDaysValue = (reminder) => {
    const { value } = reminder.options;
    let day = 0;
    if (value === BEFORE) {
        day -= reminder.days;
    }
    if (value === AFTER) {
        day += Number(reminder.days);
    }
    return day;
};

const formatOptionObj = (value) => {
    if (value < 0) {
        return {
            value: BEFORE,
            label: BEFORE_LABEL,
        };
    } if (value > 0) {
        return {
            value: AFTER,
            label: AFTER_LABEL,
        };
    }
    return {
        value: ON,
        label: ON_LABEL,
    };
};
// FOr some reason sometimes there are values in reminder object, that are not used
export const getRemindersRequiredValues = (reminders) => {
    if (isEmpty(reminders)) return null;
    return reminders.map((reminder) => {
        const { days, message, isDeposit } = reminder;
        return {
            days,
            message,
            isDeposit: isDeposit ?? false,
        };
    });
};
