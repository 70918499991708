import moment from 'moment';


const CHANNEL = 'virtual_terminal';
const ACTION_TYPES = Object.freeze({
    SALE: 'sale',
    VOID: 'void',
    REFUND: 'refund',
    REVERSAL: 'reversal'  // new action type acording to MC
});
const DEFAULT_CURRENCY = 'USD';
const DEFAULT_TENDER_TYPE = 'credit_card';

export const deserializeAddress = (info) => ({
    addr1: info.address_1 || '',
    addr2: info.address_2 || '',
    city: info.city || '',
    state: info.state || '',
    zip: info.postal_code || '',
    country: info.country || '',
    isDefault: info.is_default,
});

export const deserializeShippingInfo = (info) => ({
    ...deserializeAddress(info),
    firstname: info.first_name || '',
    lastname: info.last_name || '',
    company: info.company || '',
    email: info.email || '',
});

export const deserializeBillingInfo = (info) => ({
    ...deserializeAddress(info),
    firstname: info.first_name || '',
    lastname: info.last_name || '',
    company: info.company || '',
    email: info.email || '',
    phone: info.phone || '',
    fax: info.fax || '',
    website: info.website || '',
});

export const deserializeOrderInfo = (info) => ({
    orderId: info.order_id || '',
    PONumber: info.PO_number || '',
    orderDescription: info.order_description || '',
});

export const deserializeSingleTransaction = (transaction) => ({
    id: transaction.id,
    tax: transaction.tax_amount,
    tip: transaction.tip_amount,
    shipping: transaction.shipping_amount,
    subtotal: transaction.sub_total_amount,
    total: transaction.total_amount,
    date: new Date(transaction.transaction_date),
    wallDate: `${transaction.wall_date} ${transaction.wall_time}`,
    actionType: transaction.action_type,
    status: transaction.status,
    ccType: (transaction.cc_type || '').toLowerCase(),
    ccNumber: (transaction.cc_info || '').slice(-4),
    ccExp: moment(transaction.cc_exp, "MMYY").format(),
    customerId: transaction.nmi_customer_id,
    authCode: transaction.authentication_code,
    billingInfo: deserializeBillingInfo(transaction.billing_info || {}),
    shippingInfo: deserializeShippingInfo(transaction.shipping_info || {}),
    orderInfo: deserializeOrderInfo(transaction.order_info || {}),
    actionsHistory: transaction.actions_history || [],
    paymentAccountId: transaction.payment_account_id,
    convenienceFeeRate: transaction.convenience_fee_rate || '0.00',
    convenienceFeeBeforeTaxes: transaction.convenience_fee_before_taxes || false,
    convenienceFeeAmount: transaction.convenience_fee_amount || '0.00',
    vendor: transaction.vendor,
    tenderType: transaction.tender_type,
    discounts: transaction.discounts,
    discountAmount: transaction.discount_amount,
    cardholderName :transaction.cardholder_name,
    // cash discounts
    cashDiscountAmount: transaction.cash_discount_amount,
    cashDiscountPriceSetting: transaction.cash_discount_price_setting,
    cashDiscountRate: transaction.cash_discount_rate,
    disputeId: transaction.dispute_id
});

export const deserializeTransactions = (transactions) => {
    return transactions.map(deserializeSingleTransaction);
};

export const serializeNewSale = ({
    purchaseInfo,
    billingInfo,
    shippingInfo,
    orderInfo,
    merchantInfo,
    accountInfo,
    isCardOnFile,
    isCreateAccount,
    isSaveCard,
    postalCode,
}) => {
    const parsedShippingInfo = shippingInfo.isSameAsBilling ? billingInfo : shippingInfo;
    let payload = {};
    const sale = {
        channel: CHANNEL,
        currency: DEFAULT_CURRENCY,
        sub_total: purchaseInfo.subtotal.toString() || null,
        shipping_included: purchaseInfo.isShippingIncluded,
        shipping: purchaseInfo.shipping.toString() || null,
        tax_included: purchaseInfo.isTaxIncluded,
        tax_rate: purchaseInfo.isTaxIncluded ? (merchantInfo.tax_rate.toString() || null) : null,
        tax_amount: purchaseInfo.tax_amount.toString(),
        tender_type: DEFAULT_TENDER_TYPE,
        tip_amount: purchaseInfo.tip.toString() || null,
        transaction_date: new Date().toISOString(),
        type_of_action: ACTION_TYPES.SALE,
        vendor: 'nmi_terminal',

        // PLACEHOLDER:
        surcharge: null,
        discount: null,

        billing_info: {
            first_name: billingInfo.firstname || null,
            last_name: billingInfo.lastname || null,
            address_1: billingInfo.addr1 || null,
            address_2: billingInfo.addr2 || null,
            city: billingInfo.city || null,
            state: billingInfo.state || null,
            country: billingInfo.country || null,
            postal_code: billingInfo.zip && billingInfo.zip !== '' ? billingInfo.zip : postalCode || null,
            phone: billingInfo.phone || null,
            fax: billingInfo.fax || null,
            email: billingInfo.email || null,
            website: billingInfo.website || null,

            company: null,  // TODO -- not implemented on the backend yet
            // add_customer_account: billingInfo.addCustomer,  // TODO

            send_reciept_email: billingInfo.sendEmail,
            is_default: billingInfo.isDefault || null,
        },
        shipping_info: {
            first_name: parsedShippingInfo.firstname || null,
            last_name: parsedShippingInfo.lastname || null,
            address_1: parsedShippingInfo.addr1 || null,
            address_2: parsedShippingInfo.addr2 || null,
            city: parsedShippingInfo.city || null,
            state: parsedShippingInfo.state || null,
            country: parsedShippingInfo.country || null,
            postal_code: parsedShippingInfo.zip || null,

            email: null,  // TODO
            company: null,  // TODO
            is_default: parsedShippingInfo.isDefault || null,
        },
        order_info: {
            order_id: orderInfo.orderId || null,
            order_description: orderInfo.orderDescription || null,
            PO_number: orderInfo.PONumber || null,
        },
    };

    if (merchantInfo.convenience_fee_enabled) {
        sale.convenience_fee_rate = merchantInfo.convenience_fee_rate;
        sale.convenience_fee_before_taxes = merchantInfo.convenience_fee_before_taxes;
        sale.convenience_fee_amount = purchaseInfo.convenienceFee;
    }

    if (isCardOnFile) {
        sale.payment_method_id = purchaseInfo.paymentMethodId;
    } else {
        sale.payment_token = purchaseInfo.paymentToken;
    }

    if (accountInfo && accountInfo.id) {
        sale.payment_account_id = accountInfo.id;
    }

    let account;
    if (isCreateAccount) {
        account = {
            hotspot_customer_id: merchantInfo.id,
            id: accountInfo.id,
            is_active: true,
            custom_account_id: accountInfo.customAccountId && accountInfo.customAccountId !== '' ? accountInfo.customAccountId : null,
            customer_type: accountInfo.customerType,
            first_name: accountInfo.firstName,
            last_name: accountInfo.lastName,
            business_name: accountInfo.businessName && accountInfo.businessName !== '' ? accountInfo.businessName : null,
            email: accountInfo.email,
            website: accountInfo.website && accountInfo.website !== '' ? accountInfo.website :  null,
            addresses: accountInfo.addresses.map(address => ({
                is_default:    address.isDefault || false,
                id:            address.id || null,
                first_name:    address.firstName || null,
                last_name:     address.lastName || null,
                address_1:     address.address1 || null,
                address_2:     address.address2 || null,
                city:          address.city || null,
                province_code: address.provinceCode || null,
                postal_code:   address.postalCode || billingInfo.zip || postalCode ||null,
                country_code:  address.countryCode || null,
                phone_number:  address.phone || null,
                fax_number:    address.fax || null,
            })),
        };
    }

    if (isSaveCard && account ) {
        payload.account = account;
    } else if(isSaveCard) {
        sale.account = account;
    }

    if (isSaveCard) {
        payload.payment_method = {
            address: {
                is_default: billingInfo.isDefault || false,
                first_name: billingInfo.firstname || accountInfo.firstName || null,
                last_name: billingInfo.lastname || accountInfo.lastName || null,
                address_1: billingInfo.addr1 || accountInfo.address_1 || null,
                address_2: billingInfo.addr2 || accountInfo.address_2 || null,
                city: billingInfo.city || accountInfo.city || null,
                province_code: billingInfo.state || accountInfo.city || null,
                country_code: billingInfo.country || accountInfo.country || null,
                postal_code: billingInfo.zip || accountInfo.postal_code || postalCode || null,
                phone: billingInfo.phone || accountInfo.phone || null,
                fax: billingInfo.fax || accountInfo.fax || null,
                email: billingInfo.email || accountInfo.email || null,
                website: billingInfo.website || accountInfo.website || null,
                company: null,  // TODO -- not implemented on the backend yet
            },
        };
    }

    if (isSaveCard) {
        payload.transaction = sale;
        payload.transaction.type_of_action = 'sale';
    } else {
        payload = sale;
    }

    return payload;
};

export const serializeNewVoid = ({ parentId }) => {
    return {
        channel: CHANNEL,
        type_of_action: ACTION_TYPES.REVERSAL,
        currency: DEFAULT_CURRENCY,
        tender_type: DEFAULT_TENDER_TYPE,
        parent_transaction_id: parentId,
        transaction_date: moment().format(),
    };
};

export const serializeNewRefund = ({
    parentId,
    amount,
    vendor,
}) => ({
        channel: CHANNEL,
        type_of_action: vendor === 'spoton_terminal' ? ACTION_TYPES.REFUND : ACTION_TYPES.REVERSAL,
        currency: DEFAULT_CURRENCY,
        tender_type: DEFAULT_TENDER_TYPE,
        parent_transaction_id: parentId,
        sub_total: amount,
        amount,
        transaction_date: moment().format(),
});

export const serializePaypalRefund = (payload) => {
    return {
        device_id: payload.deviceId,
        amount: payload.amount,
        idempotency_id: payload.idempotencyId,
        amount_currency: DEFAULT_CURRENCY,
        reason: "User refund",
        partial: false,
    };
};