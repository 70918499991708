import { apiCall } from '../../utils/utils';


export function fetchCalculatedTax({ taxable_amount, tax_rate, tax_rounding }) {
    // return Promise.resolve(taxable_amount * tax_rate * 0.01);
    return apiCall('/nmi_terminal/transactions/calculate_tax/', {
        query: { taxable_amount, tax_rate, tax_rounding },
    }).then(response => {
        return Number(response.tax_amount);
    });
}
