import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Button} from 'spoton-lib-v2';
import { ARCHIVED, PAID, VOIDED } from '../../../invoicing/api/invoices.constants';
import { InvoiceVoidIcon, InvoicePaidIcon } from '../../assets';
import styles from './customerInvoiceIllustration.module.scss';
import { connect } from 'react-redux';


class CustomerInvoiceIllustration extends Component {

    static propTypes = {
        status: PropTypes.oneOf([PAID,VOIDED, ARCHIVED]).isRequired,
        projectUrl: PropTypes.string.isRequired,
    }

    onHandleDownload = () => {
        const { pathname } = window.location;
        const [merchantId, uuid] = pathname.split('/').slice(-2);
        window.open(
            `${this.props.baseUrl}nmi_terminal/invoices/v2/${uuid}/customer_pdf`,
            '_blank',
            'noopener'
        );
    }

    getInvoiceImage = () => {
        const { status } = this.props;

        const componentMap = {
            [PAID]: () => (
                <div className={styles.Section}>
                    <div className={`${styles.Section_image} ${styles.Section_image___paid}`}>
                        <InvoicePaidIcon className={styles.Image} />
                    </div>
                    <Button
                        className={styles.Section_btn}
                        variant='primary'
                        onClick={this.onHandleDownload}
                    >
                        Download Paid Invoice
                    </Button>
                </div>
            ),

            [VOIDED]: () => (
                <div className={styles.Section}>
                    <div className={`${styles.Section_image} ${styles.Section_image___voided}`}>
                        <InvoiceVoidIcon className={styles.Image} />
                    </div>
                    <div className={styles.Section_text}>This invoice has been canceled by the merchant.</div>
                </div>
            ),
            [ARCHIVED]: () => (
                <div className={styles.Section}>
                    <div className={`${styles.Section_image} ${styles.Section_image___voided}`}>
                      <InvoiceVoidIcon className={styles.Image} />
                    </div>
                    <div className={styles.Section_text}>This invoice has been canceled by the merchant.</div>
                </div>
            ),

        };

        const createComponent = componentMap[status];

        if (!createComponent) {
            throw new Error(`no option avaliable for ${status}`);
        }
        return createComponent();
    }

    render() {
        return (
            <div className={styles.CustomerInvoiceIllustration}>
                {this.getInvoiceImage()}
            </div>
        )
    }
}

const mapStateToProps = ({ projectUrl }) => ({
    baseUrl: projectUrl.baseUrl,
});

export default connect(mapStateToProps)(CustomerInvoiceIllustration);
