// Convert server-side address data into client-side friendly object
//  param {Object} address - a server side address object
//  return {Object} the convered data contained in the address object
export const deserializeAddress = (address = {}) => {
    return {
        id: address.id || '',
        firstName: address.first_name || '',
        lastName: address.last_name || '',
        address1: address.address_1 || '',
        address2: address.address_2 || '',
        city: address.city || '',
        provinceCode: address.province_code || '',
        countryCode: address.country_code || '',
        postalCode: address.postal_code || '',
        phoneNumber: address.phone_number || '',
        faxNumber: address.fax_number || '',
        isDefault: address.is_default,
    };
};

// Convert client-side address data into server-side friendly object
//  param {Object} address - a client side address object
//  return {Object} the convered data contained in the address object
export const serializeAddress = (address = {}) => {
    return {
        id: address.id || null,
        first_name: address.firstName || null,
        last_name: address.lastName || null,
        address_1: address.address1 || null,
        address_2: address.address2 || null,
        city: address.city || null,
        province_code: address.provinceCode || null,
        country_code: address.countryCode || null,
        postal_code: address.postalCode || null,
        phone_number: address.phoneNumber || null,
        fax_number: address.faxNumber || null,
        is_default: address.isDefault || false,
    };
};

// Convert server-side payment method data into client-side friendly object
//  param {Object} paymentMethods - an object containing a single payment method
//  return {Object} the converted data contained in payment method
export const deserializePaymentMethod = (paymentMethod = {}) => {
    return {
        id: paymentMethod.id || null,
        expirationDate: paymentMethod.cc_expiration || null,
        cardType: paymentMethod.cc_type || null,
        cc: paymentMethod.cc_last_four || null,
        isDefault: paymentMethod.is_default || false,
        address: deserializeAddress(paymentMethod.address),
    };
};

// Convert server-side data into client-side friendly object
//  param {Object} paymentMethods - an object containing the payment methods
//  return {Object} the converted data contained in payment methods
export const deserializePaymentMethods = (paymentMethods = {}) => {
    return {
        currentPage: paymentMethods.current_page || 1,
        totalPages: paymentMethods.total_pages || 1, 
        count: paymentMethods.total_num_results || 1,
        next: paymentMethods.next || null,
        previous: paymentMethods.previous || null,
        results: (paymentMethods.results || []).map(result => deserializePaymentMethod(result)),
    };
};

// Convert client-side payment method into server-side friendly object
//  param {Object} paymentMethod - an object containing the payment method
//  param {Object} the converted data contained in the payment method
export const serializePaymentMethod = (paymentMethod = {}) => {
    // serialize
    const serializedObj = {
        id: paymentMethod.id || null,
        cc_expiration: paymentMethod.expirationDate || null,
        cardType: paymentMethod.cardType || null,
        cc_last_four: paymentMethod.cc || null,
        is_default: paymentMethod.isDefault || false,
        payment_token: paymentMethod.paymentToken || null,
        address: serializeAddress(paymentMethod.address),
    };

    // remove any unused properties
    Object.keys(serializedObj).forEach(key => {
        if ((serializedObj[key] === null) || (serializedObj[key] === undefined)) {
            delete serializedObj[key];
        }
    });

    return serializedObj;
};

// Convert client-size payment info into server-side friendly object
//  param {Object} paymentInfo - an object containing payment info
export const serializePaymentInfo = (paymentInfo = {}) => {
    return {
        payment_method_id: paymentInfo.paymentMethodId,
        tax_amount: paymentInfo.taxAmount,
        tax_rate: paymentInfo.taxRate,
        transaction_date: paymentInfo.transactionDate,
        sub_total: paymentInfo.subTotal,
        tender_type: paymentInfo.tenderType,
        currency: paymentInfo.currency,
        action_type: paymentInfo.actionType,
        channel: paymentInfo.channel,
        vendor: paymentInfo.vendor,
    };
};

// Convert server-size transaction response into client-side friendly object
//  param {Object} response - an object containing a transaction response
export const deserializeTransactionResponse = (response = {}) => {
    return {
        transactionId: response.transaction_id,
    };
};
