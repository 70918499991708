import React from 'react';

import { wrapWithRightNav } from '../../common/components/rightNav';

const SendInvoicePage = React.lazy(() => import('../../sendInvoicePage'));
const RemindersPage = React.lazy(() => import('../../remindersPage'));

const WrappedSendInvoicePage = wrapWithRightNav(SendInvoicePage);
const WrappedRemindersPage = wrapWithRightNav(RemindersPage);

const routes = [
    {
        path: '/invoices/new/sendInvoiceDate',
        exact: true,
        displayName: 'Send Invoice Page',
        render: props => <WrappedSendInvoicePage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/edit/sendInvoiceDate',
        exact: true,
        displayName: 'Send Invoice Page',
        render: props => <WrappedSendInvoicePage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/duplicate/sendInvoiceDate',
        exact: true,
        displayName: 'Send Invoice Page',
        render: props => <WrappedSendInvoicePage {...props} />,
    },
    {
        path: '/invoices/new/reminders',
        exact: true,
        displayName: 'Invoice Reminders Page',
        render: props => <WrappedRemindersPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/edit/reminders',
        exact: true,
        displayName: 'Invoice Reminders Page',
        render: props => <WrappedRemindersPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/duplicate/reminders',
        exact: true,
        displayName: 'Invoice Reminders Page',
        render: props => <WrappedRemindersPage {...props} />,
    },
];

export default routes;
