import {
    OPEN_CONFIRM_CHARGE_MODAL,
    CLOSE_CONFIRM_CHARGE_MODAL,
    OPEN_CONFIRM_RESOLVE_MODAL,
    CLOSE_CONFIRM_RESOLVE_MODAL,
    LOADED_FAILED_TRANSACTIONS,
    LOADING_FAILED_TRANSACTIONS,
    ERROR_LOADING_FAILED_TRANSACTIONS,
    CHARGED_FAILED_TRANSACTIONS,
    CHARGING_FAILED_TRANSACTIONS,
    ERROR_CHARGING_FAILED_TRANSACTIONS,
    RESOLVED_FAILED_TRANSACTIONS,
    RESOLVING_FAILED_TRANSACTIONS,
    ERROR_RESOLVING_FAILED_TRANSACTIONS,
    SET_SELECTED_PAYMENT_METHOD,
} from './failedTransactions.actions';

import { objectArrayToMap } from '../../../../utils/utils';

const initialState = {
    failedTransactions: {
        byCustomerName: {},
        allCustomerNames: [],

        isLoading: false,
        error: null,
    },

    chargeFailedTransactions: {
        isLoading: false,
        error: null,
    },

    resolveFailedTransactions: {
        isLoading: false,
        error: null,
    },

    selectedPaymentMethod: null,

    isOpenConfirmChargeModal: false,
    isOpenConfirmResolveModal: false,
};

function failedTransactionsReducer(
    state = initialState,
    action,
) {
    switch (action.type) {
        case OPEN_CONFIRM_CHARGE_MODAL:
            return {
                ...state,
                isOpenConfirmChargeModal: true,
            };

        case CLOSE_CONFIRM_CHARGE_MODAL:
            return {
                ...state,
                isOpenConfirmChargeModal: false,
            };

        case OPEN_CONFIRM_RESOLVE_MODAL:
            return {
                ...state,
                isOpenConfirmResolveModal: true,
            };

        case CLOSE_CONFIRM_RESOLVE_MODAL:
            return {
                ...state,
                isOpenConfirmResolveModal: false,
            };

        case LOADING_FAILED_TRANSACTIONS:
            return {
                ...state,
                failedTransactions: {
                    ...state.failedTransactions,
                    isLoading: true,
                    error: null,
                },
            };

        case LOADED_FAILED_TRANSACTIONS:
            return {
                ...state,
                failedTransactions: {
                    ...state.failedTransactions,
                    byCustomerName: action.payload,
                    allCustomerNames: Object.keys(action.payload),
                    isLoading: false,
                    error: null,
                },
            };

        case ERROR_LOADING_FAILED_TRANSACTIONS:
            return {
                ...state,
                failedTransactions: {
                    ...state.failedTransactions,
                    isLoading: false,
                    error: action.payload,
                },
            };

        case CHARGING_FAILED_TRANSACTIONS:
            return {
                ...state,
            };

        case CHARGED_FAILED_TRANSACTIONS:
            return {
                ...state,
            };

        case ERROR_CHARGING_FAILED_TRANSACTIONS:
            return {
                ...state,
            };

        case RESOLVING_FAILED_TRANSACTIONS:
            return {
                ...state,
            };

        case RESOLVED_FAILED_TRANSACTIONS:
            return {
                ...state,
            };

        case ERROR_RESOLVING_FAILED_TRANSACTIONS:
            return {
                ...state,
            };
        
        case SET_SELECTED_PAYMENT_METHOD:
            return {
                ...state,
                selectedPaymentMethod: action.payload,
            };

        default:
            return state;
    }
}

export default failedTransactionsReducer;
