import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

const rootReducer = (history: History): any =>
    combineReducers({
        router: connectRouter(history),
        // insert reducers here
    });

export default rootReducer;
