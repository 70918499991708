import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from '../../../../store';
import CustomerInvoice from '../customerInvoice';

class CustomerFacingApp extends Component {
    render() {
        return (
            <Provider store={store}>
                <CustomerInvoice
                    staticUrl={this.props.staticUrl}
                    baseUrl={this.props.baseUrl}
                />
            </Provider>
        )
    }
}


export default CustomerFacingApp;