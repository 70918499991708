import { apiCall } from '../../utils/utils';
import {
    deserializePaymentMethods,
    serializePaymentMethod,
    deserializePaymentMethod,
    serializePaymentInfo,
    deserializeTransactionResponse,
} from './serializers';

// Get list of payment methods for a specific account
//  param {string} id - the account id
//  returns {Promise} a promise with the payment methods
//
// Example object inside promise:
//
// {
//   "count": 3,
//   "next": "http://local.spoton.com:25202/api/v1/nmi_terminal/5bf468aa63623953d9aa26b5/accounts/10/payment_methods?page=2",
//   "previous": null,
//   "results": [
//     {
//       "id": 22,
//       "cc_expiration": "2026-12-31",
//       "cc_type": "mastercard",
//       "cc_last_four": "543212",
//       "is_default": true,
//       "address": {
//         "id":4,
//         "first_name":"Ida",
//         "last_name":"Lu",
//         "address_1":"Address 1",
//         "address_2":"Address 2",
//         "city":"Chicago",
//         "province_code":"IL",
//         "country_code":"US",
//         "postal_code":"11400",
//         "phone_number":"773-12345-432",
//         "fax_number":"173-12345-432"
//       },
//     },
//
//     ...
//
//   ]
// }
//
export const fetchPaymentMethods = (id) => {
    return apiCall(`/nmi_terminal/accounts/${id}/payment_methods`)
        .then(deserializePaymentMethods);
};

export const fetchPaymentMethodsPage = (id, page) => {
    return apiCall(`/nmi_terminal/accounts/${id}/payment_methods?page=${page}`)
        .then(deserializePaymentMethods);
};

// Save a specific payment method
//  param {string} id - the id of the account
//  param {string} methodId - the id of the payment method
//  param {Partial<Object>} method - the object with payment method updates
//  returns {Promise} a promise with the updated payment method
//
// Example payload:
//
// {
//   "cc_type": "mastercard",
//   "cc_last_four": "1234"
// }
//
// Example object inside promise:
//
// {
//   "id": 22,
//   "cc_expiration": "2026-12-31",
//   "cc_type": "mastercard",
//   "cc_last_four": "1234",
//   "is_default": true
// }
//
export const savePaymentMethod = (id, methodId, method) => {
    return apiCall(`/nmi_terminal/accounts/${id}/payment_methods/${methodId}`, {
        method: 'PATCH',
        body: serializePaymentMethod(method),
    })
    .then(deserializePaymentMethod);
};

// Delete a specific payment method
//  param {string} id - the id of the account
//  param {string} methodId - the id of the payment method
//  returns {Promise} a promise with no data, status will be code 204
export const deletePaymentMethod = (id, methodId) => {
    return apiCall(`/nmi_terminal/accounts/${id}/payment_methods/${methodId}`, {
        method: 'DELETE',
    })
    .then();
};

// Create a payment method
//  param {string} id - the id of the account
//  param {Object} method - the payment method to create
//  returns {Promise} a promise with the created billing method
//
// Example Payload:
// {
//   "cc_expiration":"2022-10-31",
//   "cc_type":"visa",
//   "cc_last_four":"1234",
//   "is_default":true,
//   "address":{
//     "first_name":"Ida",
//     "last_name":"Lu",
//     "address_1":"Address 1",
//     "address_2":"Address2",
//     "city":"Chicago",
//     "province_code":"IL",
//     "country_code":"US",
//     "postal_code":"11400",
//     "phone_number":"773-12345-432",
//     "fax_number":"173-12345-432"
//   },
// }
//
// Example response in promise
// {
//   "id" 1:
//   "cc_expiration":"2022-10-31",
//   "cc_type":"visa",
//   "cc_last_four":"1234",
//   "is_default":true,
//   "address":{
//     "first_name":"Ida",
//     "last_name":"Lu",
//     "address_1":"Address 1",
//     "address_2":"Address2",
//     "city":"Chicago",
//     "province_code":"IL",
//     "country_code":"US",
//     "postal_code":"11400",
//     "phone_number":"773-12345-432",
//     "fax_number":"173-12345-432"
//   },
// }
//
export const createPaymentMethod = (id, method) => {
    return apiCall(`/nmi_terminal/accounts/${id}/payment_methods`, {
        method: 'POST',
        body: serializePaymentMethod(method),
    })
    .then(deserializePaymentMethod);
};

// Get a blank payment object
//
//  NOTE: The object doesn't have an id
//
//  returns {Promise} with a blank billing method
export const getBlankPaymentMethod = () => {
    return Promise.resolve({
        cc_expiration: '',
        cc_type: '',
        cc_last_four: '',
        is_default: '',
        address: {
            first_name: '',
            last_name: '',
            address_1: '',
            address_2: '',
            city: '',
            province_code: '',
            country_code: '',
            postal_code: '',
            phone_number: '',
            fax_number: '',
        },
    })
    .then(deserializePaymentMethod);
};

// Create a transaction from a payment method
//  param {Object} data - a payment information object
//  returns {Promise} the result of the transaction
//
// Example body:
//
// {
//   "payment_method_id": "123",
//   "tax_amount": "34.32",
//   "tax_rate": "18.255",
//   "transaction_date": "2018-11-05T03:03:51-0500",
//   "sub_total": "1.00",
//   "tender_type": "credit_card",
//   "currency": "USD",
//   "action_type": "sale",
//   "channel": "virtual terminal",
//   "vendor": "nmi_terminal"
// }
//
//
// Example response:
//
// {
//   "transaction_id": "NT-1"
// }
//
export const createTransactionFromPaymentMethod = (data) => {
    return apiCall(`/nmi_terminal/transactions`, {
        method: 'POST',
        body: serializePaymentInfo(data),
    })
    .then(deserializeTransactionResponse);
};
