import React from 'react';
import { wrapWithRightNav } from '../../common/components/rightNav';

const InvoiceSettingsPage = React.lazy(() => import('../invoiceSettingsPage'));
const InvoiceRemindersPage = React.lazy(() => import('../invoiceRemindersPage'));
const InvoiceDefaultsPage = React.lazy(() => import('../invoiceDefaultsPage'));
const InvoiceDueDefaultPage = React.lazy(() => import('../invoiceDueDefaultPage'));
const InvoiceSendDuePage = React.lazy(() => import('../invoiceSendDuePage'));
const InvoiceEmailsPage = React.lazy(() => import('../invoiceEmailsPage'));

const WrappedInvoiceSettingsPage = wrapWithRightNav(InvoiceSettingsPage);
const WrappedInvoiceRemindersPage = wrapWithRightNav(InvoiceRemindersPage);
const WrappedInvoiceDefaultsPage = wrapWithRightNav(InvoiceDefaultsPage);
const WrappedInvoiceDueDefaultPage = wrapWithRightNav(InvoiceDueDefaultPage);
const WrappedInvoiceSendDuePage = wrapWithRightNav(InvoiceSendDuePage);
const WrappedInvoiceEmailsPage = wrapWithRightNav(InvoiceEmailsPage);

const routes = [
    {
        path: '/invoices/settings',
        exact: true,
        displayName: 'Invoice Settings',
        render: props => <WrappedInvoiceSettingsPage {...props} />,
    },
    {
        path: '/invoices/settings/reminders',
        exact: true,
        displayName: 'Invoice Settings Reminders',
        render: props => <WrappedInvoiceRemindersPage {...props} />,
    },
    {
        path: '/invoices/settings/templates',
        exact: true,
        displayName: 'Invoice Settings Defaults',
        render: props => <WrappedInvoiceDefaultsPage {...props} />,
    },
    {
        path: '/invoices/settings/templates/invoiceDue',
        exact: true,
        displayName: 'Invoice Due Default',
        render: props => <WrappedInvoiceDueDefaultPage {...props} />,
    },
    {
        path: '/invoices/settings/templates/sendinvoiceDue',
        exact: true,
        displayName: 'Invoice Due Default',
        render: props => <WrappedInvoiceSendDuePage {...props} />,
    },
    {
        path: '/invoices/settings/emails',
        exact: true,
        displayName: 'Email Notifications',
        render: props => <WrappedInvoiceEmailsPage {...props} />,
    }
];

export default routes;
