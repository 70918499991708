import { formatMerchantAddress } from '../../utils/utils';


export function deserializeMerchantInfo(settings) {
    const {
        nmi_tokenization_key,
        tax_enabled,
        nmi_api_key,
        tax_rate,
        shipping_enabled,
        tax_rounding,
        convenience_fee_enabled,
        convenience_fee_rate,
        convenience_fee_before_taxes,
        invoicing_default_delivery_delay_days,
        invoicing_autoschedule_reminders,
        invoicing_default_invoice_title,
        invoicing_default_due_date_terms_days,
        invoicing_reminders,
        invoicing_default_reminder_message,
        invoicing_request_shipping_address,
        invoicing_default_terms_conditions,
        invoicing_email_notification_settings,
    } = settings.nmi_settings;

    let hasStrengthPassword = false;
    let username = window.dataLayer[0].merchantUsername;

    // Bypass password check for "doing business" accounts
    if (username.startsWith('db_') && username.endsWith('@spoton.com')) {
        hasStrengthPassword = true;
    } else {
        for (const user of settings.users) {
            if (user.email === username && user.has_strength_password) {
                hasStrengthPassword = true;
            }
        }
    }

    let user = settings.users[0];

    return {
        info: {
            id: settings.id,
            businessName: settings.business_name,
            profileImage: settings.profile_image,
            address: formatMerchantAddress(settings),
            hasStrengthPassword,
            nmi_tokenization_key,
            tax_enabled,
            nmi_api_key,
            tax_rate,
            shipping_enabled,
            tax_rounding,
            convenience_fee_enabled,
            convenience_fee_rate,
            convenience_fee_before_taxes,
            businessPhone: settings.business_phone,
            businessEmail: user.email,
            merchantName: `${user.first_name} ${user.last_name}`,
            merchantId: user.id,
            timezone: settings.timezone,
        },

        // invoicing stuff
        invoices: {
            invoicingDefaultDeliveryDelayDays: invoicing_default_delivery_delay_days,
            invoicingAutoscheduleReminders: invoicing_autoschedule_reminders,
            invoicingDefaultInvoiceTitle: invoicing_default_invoice_title,
            invoicingRequestShippingAddress: invoicing_request_shipping_address,
            invoicingDefaultDueDateTermsDays: invoicing_default_due_date_terms_days,
            invoicingReminders: invoicing_reminders,
            invoicingDefaultReminderMessage: invoicing_default_reminder_message,
            invoicingDefaultTermsConditions: invoicing_default_terms_conditions,
            invoicingEmailNotificationSettings: invoicing_email_notification_settings,
        },
    };
}
