import { ACCOUNT_TYPE } from "../redux/accounts/actions"

export const buildEditAccount = (account, editedAccount) => {
    return {
        id: account.id,
        isActive: true,
        hotspotCustomerId: account.hotspotCustomerId,
        businessName: editedAccount.accountType === ACCOUNT_TYPE.BUSINESS ? editedAccount.businessName.value : null,
        customAccountId: editedAccount.accountId.value,
        customerType: editedAccount.accountType,
        firstName: editedAccount.firstName.value,
        lastName: editedAccount.lastName.value,
        email: editedAccount.email.value,
        website: editedAccount.website.value,
        addresses: [
            {
                is_default: true,
                id: account.addresses[0].id,
                firstName: editedAccount.firstName.value,
                lastName: editedAccount.lastName.value,
                address_1: editedAccount.address,
                address_2: editedAccount.address2,
                city: editedAccount.city,
                provinceCode: editedAccount.province,
                postalCode: editedAccount.zip,
                countryCode: editedAccount.country,
                phoneNumber: editedAccount.phone,
                faxNumber: editedAccount.fax
            },
        ],
        payment_methods: [...account.paymentMethods]
    }
}

export const buildAccountData = (data) => {
    return {
        id: null,
        isActive: true,
        hotspotCustomerId: null,
        businessName: data.accountType === ACCOUNT_TYPE.BUSINESS ? data.businessName.value : null,
        customAccountId: data.accountId.value,
        customerType: data.accountType,
        firstName: data.firstName.value,
        lastName: data.lastName.value,
        email: data.email.value,
        website: data.website.value,
        addresses: [
            {
                is_default: true,
                id: null,
                firstName: data.firstName.value,
                lastName: data.lastName.value,
                address_1: data.address,
                address_2: data.address2,
                city: data.city,
                provinceCode: data.province,
                postalCode: data.zip,
                countryCode: data.country,
                phoneNumber: data.phone,
                faxNumber: data.fax
            }
        ],
        payment_methods: []
    }
}

