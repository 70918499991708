import moment from 'moment';
import { objectArrayToMap } from '../../../../utils/utils';
import {
    GET_SUBSCRIPTIONS_STARTED,
    GET_SUBSCRIPTIONS_SUCCESS,
    GET_SUBSCRIPTIONS_FAILED,
    SET_EXISTING_PLAN,
    SET_START_DATE,
    SET_END_DATE_OPTION,
    SET_NUMBER_OF_PAYMENTS,
    SET_END_DATE,
    CREATE_PAYMENT_METHOD_STARTED,
    CREATE_PAYMENT_METHOD_SUCCESS,
    CREATE_PAYMENT_METHOD_FAILED,
    SET_DEFAULT_PAYMENT_METHOD_STARTED,
    SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
    SET_DEFAULT_PAYMENT_METHOD_FAILED,
    UPDATE_PAYMENT_METHOD_STARTED,
    UPDATE_PAYMENT_METHOD_SUCCESS,
    UPDATE_PAYMENT_METHOD_FAILED,
    RESET_SUBSCRIPTION_PAGE,
    SET_SELECTED_SUBCRIPTION,
    CANCEL_SUBSCRIPTION_STARTED,
    CANCEL_SUBSCRIPTION_SUCCESS,
    CANCEL_SUBSCRIPTION_FAILED,
    UPDATE_SUBSCRIPTION_STARTED,
    UPDATE_SUBSCRIPTION_SUCCESS,
    UPDATE_SUBSCRIPTION_FAILED,
    CREATE_SUBSCRIPTION_FROM_ACCOUNT_SUCCESS,
    CREATE_SUBSCRIPTION_FROM_ACCOUNT_STARTED,
    CREATE_SUBSCRIPTION_FROM_ACCOUNT_FAILED,
    PREVIEW_BILLING_PERIODS_STARTED,
    PREVIEW_BILLING_PERIODS_SUCCESS,
    PREVIEW_BILLING_PERIODS_FAILED,
} from './actions';

const INITIAL_STATE = {
    // All subscriptions with minimal detail
    // Keyed by subscription ID
    subscriptionsMap: {},

    // All subscriptions ids,
    // retains order from server
    subscriptionIds: [],

    // All subscriptions ids by plan id,
    byPlan: {},
    // All subscriptions ids by account id,
    byAccount: {},

    // states
    isLoadingSubscriptions: false,
    isCreatingSubscription: false,
    isCancellingSubscription: false,

    currentPage: 1,
    totalPages: 1,
    totalNumResults: 0,
    totalActiveResults: null,
    totalExpiredResults: null,
    totalScheduledResults: null,
    totalPausedResults: null,
    totalCancelledResults: null,

    error: null,
    errorNumber: null,

    selectedExistingPlan: {},
    /**
     * start date needs to be a tomorrow, if we send it as today
     * BE will send us back a payload error
     * moment date
     */
    startDate: moment().add(1, 'days').locale('en'),
    endDateOption: null,
    numberOfPayments: null,
    endDate: {},
    isSubscriptionLoading: false,
    errorMessage: null,
    selectedSubscription: {},

    // props to see billing periods
    billingPeriods: [],
    billingPeriodsLoading: false,
    billingPeriodsError: null,
};

export default function (state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case PREVIEW_BILLING_PERIODS_STARTED:
            return {
                ...state,
                billingPeriodsLoading: true,
            };
        case PREVIEW_BILLING_PERIODS_SUCCESS:
            return {
                ...state,
                billingPeriodsLoading: false,
                billingPeriods: payload,
            };
        case PREVIEW_BILLING_PERIODS_FAILED:
            return {
                ...state,
                billingPeriodsLoading: false,
                billingPeriodsError: payload,
            };
        case UPDATE_SUBSCRIPTION_STARTED:
            return {
                ...state,
                isSubscriptionLoading: true,
            };
        case UPDATE_SUBSCRIPTION_SUCCESS: {
            return {
                ...state,
                isSubscriptionLoading: false,
            };
        }
        case UPDATE_SUBSCRIPTION_FAILED:
            return {
                ...state,
                isSubscriptionLoading: false,
            };
        case CREATE_SUBSCRIPTION_FROM_ACCOUNT_STARTED:
            return {
                ...state,
                isCreatingSubscription: true,
            };
        case CREATE_SUBSCRIPTION_FROM_ACCOUNT_SUCCESS:
            return {
                ...state,
                isCreatingSubscription: false,
            };
        case CANCEL_SUBSCRIPTION_STARTED:
            return {
                ...state,
                isCancellingSubscription: true,
                isLoadingSubscriptions: true,
            };
        case CREATE_SUBSCRIPTION_FROM_ACCOUNT_FAILED:
            return {
                ...state,
                isCreatingSubscription: false,
            };
        case CANCEL_SUBSCRIPTION_FAILED:
            return {
                ...state,
                isCancellingSubscription: false,
                isLoadingSubscriptions: false,
            };
        case CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                isCancellingSubscription: false,
                isLoadingSubscriptions: false,
                endDateOption: null,
                startDate: moment().add(1, 'days'),
                endDateOption: null,
                numberOfPayments: null,
            };
        case GET_SUBSCRIPTIONS_STARTED: {
            return {
                ...state,
                isLoadingSubscriptions: true,
            };
        }
        case GET_SUBSCRIPTIONS_FAILED: {
            return {
                ...state,
                isLoadingSubscriptions: false,
                error: payload?.data.error,
                errorNumber: payload?.status,
            };
        }
        case GET_SUBSCRIPTIONS_SUCCESS: {
            const subscriptionsMap = {};
            const subscriptionIds = [];
            const byPlan = {};
            const byAccount = {};

            payload.data.results.forEach((subscription) => {
                const id = subscription.id;
                const planId = subscription.planId;
                const accountId = subscription.accountId;

                subscriptionsMap[id] = subscription;

                if (payload.planId) {
                    if (!byPlan[planId]) {
                        byPlan[planId] = [id];
                    } else if (!byPlan[planId][id]) {
                        byPlan[planId].push(id);
                    }
                }

                if (payload.accountId) {
                    if (!byAccount[accountId]) {
                        byAccount[accountId] = [id];
                    } else if (!byAccount[accountId][id]) {
                        byAccount[accountId].push(id);
                    }
                }

                subscriptionIds.push(id);
            });

            return {
                ...state,
                subscriptionsMap,
                subscriptionIds,
                byPlan,
                byAccount,
                currentPage: payload.data.currentPage,
                totalPages: payload.data.totalPages,
                totalNumResults: payload.data.totalNumResults,
                totalActiveResults: payload.data.totalActiveResults,
                totalExpiredResults: payload.data.totalExpiredResults,
                totalScheduledResults: payload.data.totalScheduledResults,
                totalPausedResults: payload.data.totalPausedResults,
                totalCancelledResults: payload.data.totalCancelledResults,
                isLoadingSubscriptions: false,
            };
        }
        case SET_EXISTING_PLAN:
            return {
                ...state,
                selectedExistingPlan: payload,
            };
        case CREATE_PAYMENT_METHOD_FAILED:
            return {
                ...state,
                isSubscriptionLoading: false,
                errorMessage: payload,
            };
        case SET_DEFAULT_PAYMENT_METHOD_FAILED:
            return {
                ...state,
                isSubscriptionLoading: false,
                errorMessage: payload,
            };
        case UPDATE_PAYMENT_METHOD_FAILED:
            return {
                ...state,
                isSubscriptionLoading: false,
                errorMessage: payload,
            };
        case CREATE_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                isSubscriptionLoading: false,
            };
        case SET_DEFAULT_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                isSubscriptionLoading: false,
            };
        case UPDATE_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                isSubscriptionLoading: false,
            };
        case CREATE_PAYMENT_METHOD_STARTED:
            return {
                ...state,
                isSubscriptionLoading: true,
            };
        case SET_DEFAULT_PAYMENT_METHOD_STARTED:
            return {
                ...state,
                isSubscriptionLoading: true,
            };
        case UPDATE_PAYMENT_METHOD_STARTED:
            return {
                ...state,
                isSubscriptionLoading: true,
            };
        case SET_END_DATE_OPTION:
            return {
                ...state,
                endDateOption: payload,
            };
        case SET_NUMBER_OF_PAYMENTS:
            return {
                ...state,
                numberOfPayments: payload,
            };
        case SET_END_DATE:
            return {
                ...state,
                endDate: payload,
            };
        case SET_START_DATE:
            return {
                ...state,
                startDate: payload,
            };
        case RESET_SUBSCRIPTION_PAGE:
            return {
                ...state,
                selectedExistingPlan: {},
                startDate: moment().add(1, 'days'),
                endDateOption: null,
                numberOfPayments: null,
                endDate: {},
            };
        case SET_SELECTED_SUBCRIPTION:
            return {
                ...state,
                selectedSubscription: payload,
            };
        default: {
            return { ...state };
        }
    }
}
