/**
 * Validate US Postal Code
 *
 * @param {string} postalCode
 * @returns {boolean}
 */
export function isValidUSPostalCode(postalCode) {
    return /^[0-9]{5}(?:-[0-9]{4})?$/.test(postalCode);
}

/**
 * Validate Canadian Postal Code
 *
 * @param {string} postalCode
 * @returns {boolean}
 */
export function isValidCanadianPostalCode(postalCode) {
    return /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z][ -]?[0-9][A-Z][0-9]$/.test(
        postalCode,
    );
}

/**
 * Validate USA or Canadian Postal Code
 * NOTE: canadian postal codes will always start with a letter
 *
 * @param {string} postalCode
 * @returns {boolean}
 */
export function isValidUSorCanadaPostalCode(postalCode) {
    const firstCharacter = postalCode.toUpperCase().charAt(0);

    if (/[0-9]/.test(firstCharacter)) {
        return isValidUSPostalCode(postalCode);
    }

    if (/[A-VXY]/.test(firstCharacter)) {
        return isValidCanadianPostalCode(postalCode.toUpperCase());
    }

    return false;
}
