export function getPaymentAmount(invoice) {
    const {
        totalAmount, depositAmount, depositPaid, depositRequired, paidAmount,
    } = invoice;

    // Checking if it was an invoice with deposit
    if (depositRequired) {
        // Checking if deposit was payed
        if (depositPaid) {
            return parseFloat(totalAmount) - parseFloat(paidAmount);
        }
        return parseFloat(depositAmount) - parseFloat(paidAmount);
    }

    return parseFloat(totalAmount) - parseFloat(paidAmount);
}
