import {
    LOADING_DISPUTE_TRANSACTIONS,
    LOADED_DISPUTE_TRANSACTIONS,
    ERROR_LOADING_DISPUTE_TRANSACTIONS,
} from './disputeTransactions.actions';

import { objectArrayToMap } from '../../../../utils/utils';

const initialState = {
    disputeTransactionsMap: {},
    disputeTransactionIds: [],

    isLoading: false,
    error: null,

    currentPage: 1,
    totalNumResults: 0,
    totalPages: 1,
};

function disputeTransactionsReducer(
    state = initialState,
    action,
) {
    switch (action.type) {
        case LOADING_DISPUTE_TRANSACTIONS: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }

        case LOADED_DISPUTE_TRANSACTIONS: {
            const disputeTransactionsMap = objectArrayToMap(action.payload.results);
            const disputeTransactionIds = Object.keys(disputeTransactionsMap);

            return {
                ...state,
                disputeTransactionsMap,
                disputeTransactionIds,
                currentPage: action.payload.currentPage,
                totalNumResults: action.payload.totalNumResults,
                totalPages: action.payload.totalPages,
                isLoading: false,
                error: null,
            };
        }

        case ERROR_LOADING_DISPUTE_TRANSACTIONS: {
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        }

        default: {
            return state;
        }
    }
}

export default disputeTransactionsReducer;
