import React from 'react';
import { wrapWithRightNav } from '../../common/components/rightNav';

import paymentScheduleRoutes from '../../paymentSchedule/routes';
import sendOptionsRoutes from '../../sendOptions/routes';

const InvoicePage = React.lazy(() => import('../components'));
const InvoicePreviewPage = React.lazy(() => import('../../invoicing/components/invoicePreviewPage'));
const SearchCustomer = React.lazy(() => import( '../../billToInvoice/billToMobile/searchCustomer'));
const CustomerPage = React.lazy(() => import('../../customerPage'));
const InvoiceDetailsPage = React.lazy(() => import( '../../invoiceDetails/invoiceDetailsMobile/invoiceDetailsPage'));
const LineItemsList = React.lazy(() => import( '../../lineItems/lineItemsMobile/lineItemsList'));
const LineItemPage = React.lazy(() => import( '../../lineItems/lineItemsMobile/lineItemPage'));
const DiscountPage = React.lazy(() => import( '../../lineItems/lineItemsMobile/discountPage'));
const MobilePreviewInvoicePage = React.lazy(() => import( '../../mobilePreviewInvoice'));
const SendInvoicePage = React.lazy(() => import( '../../sendInvoicePage'));
const RemindersPage = React.lazy(() => import( '../../remindersPage'));
const AttachmentsPage = React.lazy(() => import( '../../attachments/attachmentsPage'));

const WrappedLineItemPage = wrapWithRightNav(LineItemPage);
const WrappedMobilePreviewInvoicePage = wrapWithRightNav(MobilePreviewInvoicePage);
const WrappedSendInvoicePage = wrapWithRightNav(SendInvoicePage);
const WrappedRemindersPage = wrapWithRightNav(RemindersPage);
const WrappedCustomerPage = wrapWithRightNav(CustomerPage);
const WrappedDiscountPage = wrapWithRightNav(DiscountPage);

const routes = [
    {
        path: '/invoices/:invoiceId/edit',
        exact: true,
        displayName: 'Edit invoice Page',
        render: props => <InvoicePage {...props} action="edit" />,
    },
    {
        path: '/invoices/:invoiceId/duplicate',
        exact: true,
        displayName: 'Duplicate invoice Page',
        render: props => <InvoicePage {...props} action="duplicate" />,
    },
    {
        path: '/invoices/new',
        exact: true,
        displayName: 'New invoice Page',
        render: props => <InvoicePage {...props} action="create" />,
    },
    {
        path: '/invoices/preview',
        exact: true,
        displayName: 'Preview invoice Page',
        render: props => <InvoicePreviewPage {...props} />,
    },
    {
        path: '/invoices/mobilePreview',
        exact: true,
        displayName: 'Mobile invoice preview',
        render: props => <WrappedMobilePreviewInvoicePage {...props} />,
    },
    {
        path: '/invoices/mobilePreview/sendDate',
        exact: true,
        displayName: 'Send invoice date',
        render: props => <WrappedSendInvoicePage {...props} />,
    },
    {
        path: '/invoices/mobilePreview/reminders',
        exact: true,
        displayName: 'Reminders',
        render: props => <WrappedRemindersPage {...props} />,
    },
    {
        path: '/invoices/new/customer/:accountId',
        exact: true,
        displayName: 'Customer edit',
        render: props => <WrappedCustomerPage {...props} />,
    },
    {
        path: '/invoices/new/customer/add',
        exact: true,
        displayName: 'Customer Add',
        render: props => <WrappedCustomerPage {...props} />,
    },
    {
        path: '/invoices/new/customer',
        exact: true,
        displayName: 'Bill To Page',
        render: props => <SearchCustomer {...props} />,
    },
    {
        path: '/invoices/:invoiceId/edit/customer/:accountId',
        exact: true,
        displayName: 'Customer edit',
        render: props => <WrappedCustomerPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/edit/customer/add',
        exact: true,
        displayName: 'Customer Add',
        render: props => <WrappedCustomerPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/edit/customer',
        exact: true,
        displayName: 'Bill To Page',
        render: props => <SearchCustomer {...props} />,
    },
    {
        path: '/invoices/:invoiceId/duplicate/customer/:accountId',
        exact: true,
        displayName: 'Customer edit',
        render: props => <WrappedCustomerPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/duplicate/customer/add',
        exact: true,
        displayName: 'Customer Add',
        render: props => <WrappedCustomerPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/duplicate/customer',
        exact: true,
        displayName: 'Bill To Page',
        render: props => <SearchCustomer {...props} />,
    },
    {
        path: '/invoices/new/invoiceDetails',
        exact: true,
        displayName: 'Invoice Details Page',
        render: props => <InvoiceDetailsPage {...props} />,
    },
    {
        path: '/invoices/:invoideId/edit/invoiceDetails',
        exact: true,
        displayName: 'Invoice Details Page',
        render: props => <InvoiceDetailsPage {...props} />,
    },
    {
        path: '/invoices/:invoideId/duplicate/invoiceDetails',
        exact: true,
        displayName: 'Invoice Details Page',
        render: props => <InvoiceDetailsPage {...props} />,
    },
    {
        path: '/invoices/new/lineitems/add',
        exact: true,
        displayName: 'Add Line Item',
        render: props => <WrappedLineItemPage {...props} />,
    },
    {
        path: '/invoices/new/lineitems/discount',
        exact: true,
        displayName: 'Add Discount',
        render: props => <WrappedDiscountPage {...props} />,
    },
    {
        path: '/invoices/new/lineitems',
        exact: true,
        displayName: 'Line Items Page',
        render: props => <LineItemsList {...props} />,
    },
    {
        path: '/invoices/:invoiceId/edit/lineitems/add',
        exact: true,
        displayName: 'Add Line Item',
        render: props => <WrappedLineItemPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/edit/lineitems/discount',
        exact: true,
        displayName: 'Add Discount',
        render: props => <WrappedDiscountPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/edit/lineitems',
        exact: true,
        displayName: 'Line Items Page',
        render: props => <LineItemsList {...props} />,
    },
    {
        path: '/invoices/:invoiceId/duplicate/lineitems/add',
        exact: true,
        displayName: 'Add Line Item',
        render: props => <WrappedLineItemPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/duplicate/lineitems/discount',
        exact: true,
        displayName: 'Add Discount',
        render: props => <WrappedDiscountPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/duplicate/lineitems',
        exact: true,
        displayName: 'Line Items Page',
        render: props => <LineItemsList {...props} />,
    },
    {
        path: '/invoices/new/attachments',
        exact: true,
        displayName: 'Attachments Page',
        render: props => <AttachmentsPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/edit/attachments',
        exact: true,
        displayName: 'Attachments Page',
        render: props => <AttachmentsPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/duplicate/attachments',
        exact: true,
        displayName: 'Attachments Page',
        render: props => <AttachmentsPage {...props} />,
    },
    ...paymentScheduleRoutes,
    ...sendOptionsRoutes,
];

export default routes;
