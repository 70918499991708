import moment from 'moment';

import * as api from './api';
import { sortTransactions } from '../../utils/transactions';
import { objectArrayToMap } from '../../utils/utils';


///////////////////////////////////////////////////////////
//
// Initial State
//
// NOTE: for the uninitiated look up Redux Entity Adapters for insight into
// this structure.
//
///////////////////////////////////////////////////////////

const defaultTransactions = {
    // All transactions
    // Keyed by account ID
    transactionsMap: {},

    // Multiple ordered arrays of transaction ID's
    // Keyed by sortBy string
    transactionsBy: {},

    // states
    isLoadingTransactions: false,
    isLoadingSingleTransaction: false,
    isRunningTransaction: false,
    error: null,

    currentPage: 1,
    totalPages: 1,
    totalNumResults: 0,

    fetchByAccount: false,
};

///////////////////////////////////////////////////////////
//
// Action Types
//
// NOTE: Good practice dictates REDUX actions should be events NOT commands...
// NOTE: Good practice to add a 'source' of the action and add a description of
//  the action. It makes tracing much easier.
//
///////////////////////////////////////////////////////////

const LOAD_TRANSACTIONS = '[Transactions] Load List of Transactions';
const LOADED_TRANSACTIONS = '[Transactions] Loaded List of Transactions';
const ERROR_LOADING_TRANSACTIONS = '[Transactions] Error Loading List of Transactions';
const SET_TRANSACTIONS = '[Transactions] Set List of Transactions';
const LOAD_SINGLE_TRANSACTION = '[Transactions] Load Single Transaction';
const LOADED_SINGLE_TRANSACTION = '[Transactions] Loaded Single Transaction';
const ERROR_LOADING_SINGLE_TRANSACTION = '[Transactions] Error Loading Single Transaction';
const SET_SINGLE_TRANSACTION = '[Transactions] Set a Single Transaction';
const UNSET_TRANSACTION = '[Transactions] Unset a Single Transaction';
const CREATE_TRANSACTION = '[Transactions] Create a Transaction';
const CREATED_TRANSACTION = '[Transactions] Created a Transaction';
const ERROR_CREATING_TRANSACTION = '[Transactions] Error Creating a Transaction';
const SET_FETCH_BY_ACCOUNT = '[Transaction] Set Fetch By Account';


///////////////////////////////////////////////////////////
//
// Pure Actions
//
// NOTE: For every action type there should be a pure action
//
///////////////////////////////////////////////////////////

// Transaction are loading
export const loadTransactions = () => ({
    type: LOAD_TRANSACTIONS,
});

// Transaction are finished loading
export const loadedTransactions = () => ({
    type: LOADED_TRANSACTIONS,
});

// There was an error loading transactions
//  param {Error} error - an standard error object
export const errorLoadingTransactions = (error) => ({
    type: ERROR_LOADING_TRANSACTIONS,
    error,
});

// Set a list of transactions
//  param {Object[]} transactions - an array of transactions
export const setTransactions = (transactions, sortBy) => ({
    type: SET_TRANSACTIONS,
    transactions,
    sortBy
});

// A transaction is loading
export const loadSingleTransaction = () => ({
    type: LOAD_SINGLE_TRANSACTION,
});

// A transaction is finished loading
export const loadedSingleTransaction = () => ({
    type: LOADED_SINGLE_TRANSACTION,
});

// There was an error loading a transaction
//  param {Error} error - an standard error object
export const errorLoadingSingleTransaction = (error) => ({
    type: ERROR_LOADING_SINGLE_TRANSACTION,
    error,
});

// Set a single transaction
//  param {Object} transaction - a transaction
export const setSingleTransaction = (transaction) => ({
    type: SET_SINGLE_TRANSACTION,
    transaction,
});

// Unset a transaction
//  param {number} id - a transaction id
export const unsetTransaction = (id) => ({
    type: UNSET_TRANSACTION,
    id,
});

// Create a transaction
export const createTransaction = () => ({
    type: CREATE_TRANSACTION,
});

// A transaction was created
export const createdTransaction = () => ({
    type: CREATED_TRANSACTION,
});

// An error occured creating a transaction
//  param {Error} error - an error object
export const errorCreatingTransaction = (error) => ({
    type: ERROR_CREATING_TRANSACTION,
    error,
});

export const setFetchByAccount = (isFetchByAccount) => ({
    type: SET_FETCH_BY_ACCOUNT,
    isFetchByAccount,
});

///////////////////////////////////////////////////////////
//
// Actions with side-effects (thunk)
//
///////////////////////////////////////////////////////////

// Get a list of all transactions
export const fetchTransactions = ({
    page = 1,
    sortBy = 'date',
    sortOrder = 'desc',
    q = '',
    dateStart = '',
    dateEnd = '',
    timeStart = '00:00:01',
    timeEnd = '23:59:59',
    statusFilters = {}
} = {}) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(loadTransactions());

        api.fetchAllTransactions({ page, sortBy, sortOrder, q, dateStart, dateEnd, statusFilters, timeStart, timeEnd })
            .then((transactions) => {
                dispatch(loadedTransactions());

                dispatch(setTransactions(transactions, sortBy));

                resolve(transactions);
            }).catch((e) => {
                dispatch(errorLoadingTransactions(e));

                reject(e);
            });
    });
};

// Get a list of transactions by account id
export const fetchTransactionsByAccount = ({
    accountId,
    page = 1,
    sortBy = 'date',
    sortOrder = 'desc',
    q = '',
    dateStart = '',
    dateEnd = '',
    statusFilters = {}
} = {}) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(loadTransactions());

        api.fetchTransactionsByAccount({accountId, page, sortBy, sortOrder, q, dateStart, dateEnd, statusFilters})
            .then((transactions) => {
                dispatch(loadedTransactions());

                dispatch(setTransactions(transactions, sortBy));

                resolve(transactions);
            }).catch((e) => {
                dispatch(errorLoadingTransactions(e));

                reject(e);
            });
    });
};

// Get a single transaction
//  param {number} id - the transaction id to fetch
export const fetchTransactionById = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(loadSingleTransaction());
        api.fetchSingleTransaction(id)
            .then((transaction) => {
                dispatch(loadedSingleTransaction());

                dispatch(setSingleTransaction(transaction));

                resolve(transaction);
            }).catch((e) => {
                dispatch(errorLoadingSingleTransaction(e));

                reject(e);
            });
    });
};

// Run a transaction
//  param {Object} transaction - a fully formed transaction object
export const runTransaction = (transaction) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(createTransaction());

        api.createTransaction(transaction)
            .then((transactionResult) => {
                dispatch(createdTransaction());

                resolve(transactionResult);
            }).catch((e) => {
                dispatch(errorCreatingTransaction());

                reject(e);
            });
    });
};

export const runTransactionAccountPaymentMethod = (transaction) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(createTransaction());

        api.createTransactionAccountPaymentMethod(transaction)
            .then((transactionResult) => {
                dispatch(createdTransaction());

                resolve(transactionResult);
            }).catch((e) => {
                dispatch(errorCreatingTransaction());

                reject(e);
            });
    });
};

///////////////////////////////////////////////////////////
//
// Reducer
//
// NOTE: please do not put complex logic here (more than one line) You should
// only be updating a value in the state based on the action payload, or what
// the state dictates.
//
///////////////////////////////////////////////////////////

export default function (state = defaultTransactions, action) {
    switch (action.type) {

        case SET_TRANSACTIONS: {
            // Save all transactions in state
            const transactionsMap = objectArrayToMap(action.transactions.results);
            // Save all sort orders in state
            const transactionsBy = {};
            transactionsBy[action.sortBy] = action.transactions.results
                .slice()
                .map(transaction => transaction.id);
            return {
                ...state,
                transactionsMap,
                transactionsBy,
                currentPage: action.transactions.currentPage,
                totalPages: action.transactions.totalPages,
                totalNumResults: action.transactions.totalNumResults,
            };
        }

        case SET_SINGLE_TRANSACTION: {
            return ({
                ...state,
                transactionsMap: {
                    ...state.transactionsMap,
                    [action.transaction.id]: action.transaction
                },
            });
        }

        case UNSET_TRANSACTION: {
            return {
                ...state,
                transactionsMap: {
                    ...state.transactionsMap,
                    [action.id]: null,
                },
            };
        }

        case LOAD_TRANSACTIONS: {
            return {
                ...state,
                isLoadingTransactions: true,
            };
        }

        case LOADED_TRANSACTIONS: {
            return {
                ...state,
                isLoadingTransactions: false,
            };
        }

        case ERROR_LOADING_TRANSACTIONS: {
            return {
                ...state,
                isLoadingTransactions: false,
                error: action.error,
            };
        }

        case LOAD_SINGLE_TRANSACTION: {
            return {
                ...state,
                isLoadingSingleTransaction: true,
            };
        }

        case LOADED_SINGLE_TRANSACTION: {
            return {
                ...state,
                isLoadingSingleTransaction: false,
            };
        }

        case ERROR_LOADING_SINGLE_TRANSACTION: {
            return {
                ...state,
                isLoadingSingleTransaction: false,
                error: action.error,
            };
        }

        case CREATE_TRANSACTION: {
            return {
                ...state,
                isRunningTransaction: true,
            };
        }

        case CREATED_TRANSACTION: {
            return {
                ...state,
                isRunningTransaction: false,
            };
        }

        case ERROR_CREATING_TRANSACTION: {
            return {
                ...state,
                isRunningTransaction: false,
                error: action.error,
            };
        }

        case SET_FETCH_BY_ACCOUNT: {
            return {
                ...state,
                fetchByAccount: action.isFetchByAccount,
            };
        }

        default: {
            return state;
        }
    }
}
