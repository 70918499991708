import axiosDefault from 'axios';
import qs from 'qs';
import {
    getSSOURL,
    serializeToCamelCase,
    serializeToSnakeCase,
} from 'features/common/utils/';
import { getConfigVar } from 'features/common/utils/';
import { configureAxiosInstanceForOAuth } from '@spotoninc/okta-refresh-interceptor';
import {
    HTTP_STATUS_CODES,
    NodeAuthMiddlewareErrorCodes,
} from '@spotoninc/okta-refresh-interceptor/dist/interceptor/clientOAuthInterceptors.types';

function getDashboardBaseUrl() {
    return (getConfigVar('REACT_APP_EXTERNAL_APP_DASHBOARD') as string) ?? '';
}

configureAxiosInstanceForOAuth(axiosDefault as any, {
    dashboardBaseUrl: getDashboardBaseUrl(),
});

const axios = axiosDefault.create({
    baseURL: getConfigVar('REACT_APP_VTS_URL') as string,
    withCredentials: true,
    transformResponse: [
        (data: any): any => {
            return serializeToCamelCase(JSON.parse(data));
        },
    ],
    transformRequest: [
        (data: any): any => {
            return data ? JSON.stringify(serializeToSnakeCase(data)) : data;
        },
    ],
    paramsSerializer: (params: any) => {
        return qs.stringify(serializeToSnakeCase(params));
    },
});

axios.interceptors.response.use(
    (response) => response,
    (data) => {
        if (
            data.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED &&
            data.response.data?.errors?.internalErrorCode ===
                NodeAuthMiddlewareErrorCodes.CLA_VALIDATION_NEEDED
        ) {
            const nextUrl = window.location.href;
            window.location.href = `${getSSOURL()}home?next=${nextUrl}`;
        }

        return Promise.reject(data);
    },
);

export type { AxiosResponse } from 'axios';

export { axios };
