import React from 'react';
import {
    Button,
    Icon,
    Switch,
    IconButton,
    Banner,
    BannerVariants,
} from 'spoton-lib';
import moment from 'moment';
import PaymentSchedule from '../../components';
import ResizeManager from '../../../common/components/resizeManager';
import styles from './dueDatesPage.module.scss';
import { DATE_FORMAT_PAYMENT_DUE } from '../../../recurringPayments/helpers/dateHelpers';
import { formatDollars } from '../../../../utils/utils';

class DueDatesPage extends React.Component {
    render() {
        return (
            <PaymentSchedule
                render={({
                    toggleRequestInitialDeposit,
                    requestInitialDeposit,
                    navigateToBalanceDuePage,
                    navigateToRequestDepositPage,
                    balanceDueDate,
                    balanceTotal,
                    depositAmount,
                    depositDueDate,
                    navigateToInvoices,
                }) => (
                    <ResizeManager>
                        {({
                            initialWidth,
                            resizeWidth,
                        }) => {
                            if (initialWidth !== resizeWidth) {
                                navigateToInvoices();
                            }
                            const invalidDates = moment(depositDueDate).isAfter(moment(balanceDueDate));
                            return (
                                <div className={styles.Content}>
                                    <div className={styles.Content_header}>
                                        <header className={styles.Header}>
                                            <IconButton
                                                name="BackIcon"
                                                onClick={navigateToInvoices}
                                            />
                                            <p className={styles.Header_title}>
                                                Payment Schedule
                                            </p>
                                        </header>
                                    </div>

                                    <div className={`${styles.Content_customer} ${styles.MarginTop}`}>
                                        <div className={styles.Customer}>
                                            <div className={styles.Section}>
                                                <div className={styles.PaymentSchedule}>
                                                    <span className={styles.PaymentSchedule_label}>Invoice Balance</span>
                                                    <div className={styles.PaymentGroup}>
                                                        <p className={styles.PaymentGroup_title}>Due</p>
                                                        <div className={styles.PaymentGroup_details}>
                                                            <Button
                                                                className={styles.PaymentDetails}
                                                                onClick={() => navigateToBalanceDuePage(true)}
                                                                variant='tertiary'
                                                            >
                                                                <div className={styles.PaymentDetails_textWrapper}>
                                                                    <p className={styles.PaymentDetails_date}> {balanceDueDate.format(DATE_FORMAT_PAYMENT_DUE)}</p>
                                                                    <p className={styles.PaymentDetails_balance}>
                                                                        Balance Due ({formatDollars(balanceTotal - depositAmount)})
                                                                    </p>
                                                                </div>
                                                                <div className={styles.PaymentDetails_icon}>
                                                                    <Icon
                                                                        name="RightIcon"
                                                                        className={styles.ArrowIcon}
                                                                    />
                                                                </div>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className={styles.RequestDeposit}>
                                                        <div className={styles.RequestDeposit_switch}>
                                                            <Switch
                                                                checked={requestInitialDeposit}
                                                                onChange={() => {
                                                                    toggleRequestInitialDeposit();
                                                                    if (!requestInitialDeposit) {
                                                                        navigateToRequestDepositPage(true);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <p className={styles.RequestDeposit_text}>
                                                            Request Initial Deposit
                                                        </p>
                                                    </div>
                                                    {
                                                        requestInitialDeposit &&
                                                        (
                                                            <div className={`${styles.PaymentSchedule} ${styles.PaymentSchedule___margin}`}>
                                                                <span className={styles.PaymentSchedule_label}>Deposit</span>
                                                                <div className={styles.PaymentGroup}>
                                                                    <p className={styles.PaymentGroup_title}>Due</p>
                                                                    <div className={styles.PaymentGroup_details}>
                                                                        <Button
                                                                            className={styles.PaymentDetails}
                                                                            onClick={() => navigateToRequestDepositPage(true)}
                                                                            variant='tertiary'
                                                                        >
                                                                            <div className={styles.PaymentDetails_textWrapper}>
                                                                                <p className={styles.PaymentDetails_date}>{moment(depositDueDate).format(DATE_FORMAT_PAYMENT_DUE)}</p>
                                                                                <p className={styles.PaymentDetails_balance}>Balance Due ({formatDollars(depositAmount)})</p>
                                                                            </div>
                                                                            <div className={styles.PaymentDetails_icon}>
                                                                                <Icon
                                                                                    name="RightIcon"
                                                                                    className={styles.ArrowIcon}
                                                                                />
                                                                            </div>
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        {
                                            requestInitialDeposit &&
                                            invalidDates &&
                                            <div className={styles.Banner}>
                                                <Banner
                                                    message='You have selected a date after the Balance due date. Select a new date or update your Balance due date.'
                                                    variant={BannerVariants.DANGER}
                                                />
                                            </div>
                                        }
                                        </div>
                                    </div>

                                    <div className={styles.Content_footer}>
                                        <Button
                                            className={styles.MarginTop}
                                            variant="primary"
                                            onClick={navigateToInvoices}
                                            disabled={invalidDates}
                                            block
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            );
                        }}
                    </ResizeManager>
                )}
            />
        );
    }
}

export default DueDatesPage;
