import get from 'lodash.get';

import * as recurringPaymentsApi from '../../api/plans';
import { apiPayload } from '../../helpers/buildPlanData';
import addNotification, { DANGER, SUCCESS } from '../../../common/utils/notificationHelper';

const ns = '[RecurringPlans]';
export const GET_RECURRING_PLANS_STARTED = `${ns} Load started`;
export const GET_RECURRING_PLANS_SUCCESS = `${ns} Load success`;
export const GET_RECURRING_PLANS_FAILED = `${ns} Load failed`;
export const GET_RECURRING_PLAN_STARTED = `${ns} Load Single Plan started`;
export const GET_RECURRING_PLAN_SUCCESS = `${ns} Load Single Plan success`;
export const GET_RECURRING_PLAN_FAILED = `${ns} Load Single Plan failed`;
export const SELECT_RECURRING_PLAN = `${ns} Select Recurring Plan`;
export const POST_RECURRING_PLANS_STARTED = `${ns} Post started`;
export const POST_RECURRING_PLANS_SUCCESS = `${ns} Post sucess`;
export const POST_RECURRING_PLANS_FAILED = `${ns} Post failed`;
export const UPDATE_RECURRING_PLANS_STARTED = `${ns} Update started`;
export const UPDATE_RECURRING_PLANS_SUCCESS = `${ns} Update success`;
export const UPDATE_RECURRING_PLANS_FAILED = `${ns} Update Failed`;
export const DEACTIVATE_RECURRING_PLANS_SUCCESS = `${ns} Deactivate success`;
export const DEACTIVATE_RECURRING_PLANS_STARTED = `${ns} Deactivate started`;
export const DEACTIVATE_RECURRING_PLANS_FAILED = `${ns} Deactivate Failed`;

const getRecurringPlansStarted = () => ({
    type: GET_RECURRING_PLANS_STARTED
});

const getRecurringPlanStarted = () => ({ type: GET_RECURRING_PLAN_STARTED });
const getRecurringPlanSuccess = (payload) => ({ type: GET_RECURRING_PLAN_SUCCESS, payload });

const getRecurringPlansFailed = (error) => ({
    type: GET_RECURRING_PLANS_FAILED,
    payload: error
});

export const getRecurringPlansSuccess = (payload) => ({
    type: GET_RECURRING_PLANS_SUCCESS,
    payload
});

export const selectRecurringPlan = (payload) => ({
    type: SELECT_RECURRING_PLAN,
    payload
});

const postRecurringPlansStarted = () => ({
    type: POST_RECURRING_PLANS_STARTED
});
const postRecurringPlansSuccess = (newPlan) => ({
    type: POST_RECURRING_PLANS_SUCCESS,
    payload: newPlan,
});
const postRecurringPlansFailed = () => ({
    type: POST_RECURRING_PLANS_FAILED
});

const updateRecurringPlansStarted = () => ({
    type: UPDATE_RECURRING_PLANS_STARTED
});
const updateRecurringPlansSuccess = (payload) => ({
    type: UPDATE_RECURRING_PLANS_SUCCESS,
    payload
});
const updateRecurringPlansFailed = () => ({
    type: UPDATE_RECURRING_PLANS_FAILED
});

const deactivateRecurringPlansStarted = () => ({
    type: DEACTIVATE_RECURRING_PLANS_STARTED
});
const deactivateRecurringPlansSuccess = () => ({
    type: DEACTIVATE_RECURRING_PLANS_SUCCESS
});
const deactivateRecurringPlansFailed = () => ({
    type: DEACTIVATE_RECURRING_PLANS_FAILED
});

export const getRecurringPlans = ({
    page = 1,
    pageSize = 10,
    status,
    q,
    order = 'asc',
    orderBy = 'name'
}) => (dispatch) => {
    dispatch(getRecurringPlansStarted());
    return recurringPaymentsApi.fetchAllPlans({ page, pageSize, status, q, order, orderBy })
        .then(res => {
            if(![200,201].includes(res.status)) {
                throw new Error(res.data.error);
            }
            dispatch(getRecurringPlansSuccess(res.data));
            return res.data;
        })
        .catch(error => {
            dispatch(getRecurringPlansFailed(get(error, 'response')));
        });
};

export const getRecurringPlan = (id) => (dispatch) => {
    dispatch(getRecurringPlanStarted());
    return recurringPaymentsApi.fetchRecurringPlan(id)
        .then(res => {
            if (![200,201].includes(res.status)) {
                throw new Error(res.data.error);
            }
            dispatch(getRecurringPlanSuccess(res.data));
            return res.data;
        })
        .catch(error => {
            dispatch(getRecurringPlansFailed(get(error, 'response')));
        });
};

export const createRecurringPlan = (plan) => (dispatch) => {
    dispatch(postRecurringPlansStarted());
    const data = apiPayload(plan);
    return recurringPaymentsApi.postRecurringPlan(data)
        .then((res) => {
            dispatch(postRecurringPlansSuccess(res.data));
            addNotification({
                title: 'Success!',
                message: 'You have successfully created a new plan',
                type: SUCCESS,
            });
            return res.data;
        })
        .catch((error) => {
            dispatch(postRecurringPlansFailed());
            addNotification({
                title: get(error, 'response.data.error', 'Something went wrong'),
                type: DANGER,
            });
        });
};

export const updateRecurringPlan = (plan, id) => (dispatch) => {
    dispatch(updateRecurringPlansStarted());
    const data = apiPayload(plan);
    return recurringPaymentsApi.patchRecurringPlan(data, id)
        .then((res) => {
            dispatch(updateRecurringPlansSuccess(res.data));
            addNotification({
                title: 'Success!',
                message: 'You have successfully saved new plan details.',
                type: SUCCESS,
            });
            return res.data;
        })
        .catch((error) => {
            dispatch(updateRecurringPlansFailed());
            addNotification({
                title: get(error, 'response.data.error', 'Something went wrong'),
                type: DANGER,
            });
        });
};

export const deactivateRecurringPlan = (id) => (dispatch) => {
    dispatch(deactivateRecurringPlansStarted());
    return recurringPaymentsApi.deleteRecurringPlan(id)
        .then((res) => {
            dispatch(deactivateRecurringPlansSuccess());
            addNotification({
                title: 'Success!',
                message: 'You have successfully deactivated this plan.',
                type: SUCCESS,
            });
            return res.data;
        })
        .catch((error) => {
            dispatch(deactivateRecurringPlansFailed());
            addNotification({
                title: get(error, 'response.data.error', 'Something went wrong'),
                type: DANGER,
            });
        });
};
