import React from 'react';
import PropTypes from 'prop-types';
import { PaymentSuccessIcon } from '../../assets';
import styles from './customerinvoiceConfirmation.module.scss';

class CustomerinvoiceConfirmation extends React.Component {
    static propTypes = {
        accountFirstName: PropTypes.string.isRequired,
    }

    render() {
        return (
            <div className={styles.Success}>
                <PaymentSuccessIcon className={styles.Success_cardLogo} />

                <h5 className={styles.Success_heading}>
                    Thank you {this.props.accountFirstName}
                    <br />
                    for your payment
                </h5>

                <p className={styles.Success_subHeading}>You will receive an email confirmation for your payment.</p>

                <span className={styles.Success_information}>You may close this window.</span>
            </div>
        );
    }
}

export default CustomerinvoiceConfirmation;
