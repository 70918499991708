import {
    OPEN_RIGHT_NAV,
    CLOSE_RIGHT_NAV,
    SET_PAYLOAD,
} from './rightNav.actions';

/** InitialState */
const initialState = {
    isOpen: true,
    payload: null,
};

export default function RightNavReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_RIGHT_NAV: {
            return {
                ...state,
                isOpen: true,
            };
        }
        case CLOSE_RIGHT_NAV: {
            return {
                ...state,
                isOpen: false,
            };
        }
        case SET_PAYLOAD: {
            return {
                ...state,
                payload: action.payload,
            };
        }
        default: {
            return state;
        }
    }
}
