import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import numbro from 'numbro';

// // this are backend supported status for plans
export const PLAN_ACTIVE = 'active';
export const PLAN_CANCELLED = 'cancelled';
export const PLAN_PAUSED = 'paused';

export const FREQUENCY = Object.freeze({
    EVERY: 'every',
    ONDAY: 'onDay',
});
export const CUSTOM = 'custom';

const isOnDaySelected = (plan) => {
    return (
        !(plan.repeatEveryPeriod && plan.repeatEveryValue) &&
        Boolean(plan.monthFrequency)
    );
};

const isCustomSelected = (monthFrequency) => {
    const MONTH_FREQUENCY_AVAILABLE_OPTIONS = [1, 3, 6, 12];
    return MONTH_FREQUENCY_AVAILABLE_OPTIONS.includes(monthFrequency);
};

export default function formatPlan(plan) {
    const initialPlan = {
        id: null,
        accountName: '',
        amount: '',
        frequency: '',
        repeatEvery: '',
        period: '',
        onDay: '',
        onDayEvery: '',
        months: '',
    };

    if (isEmpty(plan)) {
        return initialPlan;
    }

    initialPlan.id = get(plan, 'id');
    initialPlan.accountName = get(plan, 'name');
    initialPlan.amount = numbro(get(plan, 'amount', '0')).format('0.00');

    if (isOnDaySelected(plan)) {
        // ON DAY e.g. "Repeats on the 1st of every 3 months (quarterly)"
        initialPlan.frequency = FREQUENCY.ONDAY;
        initialPlan.onDay = get(plan, 'dayOfMonth');
        initialPlan.onDayEvery = get(plan, 'monthFrequency');

        if (!isCustomSelected(plan.monthFrequency)) {
            // ON DAY + CUSTOM MONTHS e.g. "Repeats on the 1st of every 8 months"
            initialPlan.onDayEvery = CUSTOM;
            initialPlan.months = get(plan, 'monthFrequency');
        }
    } else {
        // REPEAT EVERY e.g. "Repeats every 3 weeks"
        initialPlan.frequency = FREQUENCY.EVERY;
        initialPlan.repeatEvery = get(plan, 'repeatEveryValue');
        initialPlan.period = get(plan, 'repeatEveryPeriod');
    }

    return initialPlan;
}

export const apiPayload = ({
    accountName, amount, onDay, onDayEvery, months, repeatEvery, period,
}) => {
    return {
        name: accountName,
        amount,
        month_frequency: onDayEvery ? (onDayEvery === CUSTOM ? +months : onDayEvery) : null,
        day_of_month: +onDay || null,
        repeat_every_value: +repeatEvery || null,
        repeat_every_period: period,
        status: PLAN_ACTIVE,
    };
};
