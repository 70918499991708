import React, { Component } from 'react'

export default class ResizeManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialWidth: window.innerWidth,
        };
    }

    handleResize = () => {
        return window.innerWidth;
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleResize);
    }

    render() {
        return (
            this.props.children({
                initialWidth: this.state.initialWidth,
                resizeWidth: this.handleResize(),
            })
        );
    }
}
