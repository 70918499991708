import React from 'react';
import PropTypes from 'prop-types';

export const PaymentSuccessIcon = ({ className }) => {
    return (
        <svg width="190" height="168" viewBox="0 0 190 168" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M1 126.48V42.8879C1 41.991 1.85847 41.3435 2.72087 41.5899L113.421 73.2184C114 73.384 114.4 73.9137 114.4 74.5165V158.109C114.4 159.005 113.542 159.653 112.679 159.407L1.97913 127.778C1.39957 127.612 1 127.083 1 126.48Z" fill="#7DABFF" />
            <path d="M114.4 168L190 146.4L75.25 114L1 135.6L114.4 168Z" fill="#D6D9E1" />
            <ellipse cx="153.419" cy="116.102" rx="23.9516" ry="7.36973" fill="#D6D9E1" />
            <path d="M7.07503 40.0176L117.167 71.4724C117.621 71.6021 117.964 71.9552 118.092 72.3918L114.068 73.6292H108.325L1.44727 45.8225V42.3387C1.44727 42.0494 1.56976 41.7711 1.81392 41.616C2.98295 40.8736 5.49457 40.0176 7.07503 40.0176Z" fill="#B5CFFE" />
            <path d="M114.401 158.109V74.5165C114.401 74.1819 114.277 73.8698 114.069 73.63L118.092 72.3926C118.128 72.5134 118.146 72.6406 118.146 72.7712V156.363C118.146 158.711 114.708 159.407 113.353 159.425C113.939 159.29 114.401 158.766 114.401 158.109Z" fill="#1254CC" />
            <path d="M1 126.48V42.8879C1 41.991 1.85847 41.3435 2.72087 41.5899L113.421 73.2184C114 73.384 114.4 73.9137 114.4 74.5165V158.109C114.4 159.005 113.542 159.653 112.679 159.407L1.97913 127.778C1.39957 127.612 1 127.083 1 126.48Z" fill="#7DABFF" />
            <path d="M1.44724 41.8834C2.35 41.0975 5.29985 40.0176 7.075 40.0176L117.167 71.4724C117.746 71.6379 118.146 72.1677 118.146 72.7704V156.362C118.146 158.711 114.707 159.406 113.352 159.424M1 42.8871V126.479C1 127.082 1.39957 127.612 1.97913 127.777L112.679 159.406C113.542 159.652 114.4 159.005 114.4 158.108V74.5157C114.4 73.913 114 73.3832 113.421 73.2176L2.72087 41.5891C1.85847 41.3427 1 41.9902 1 42.8871Z" stroke="black" strokeWidth="0.2" />
            <path d="M106.579 85.3192L85.8902 79.7728C84.5529 79.4142 83.4688 78.0395 83.4688 76.7022C83.4688 75.3648 84.5529 74.5715 85.8902 74.9298L106.579 80.4762C107.916 80.8348 109 82.2095 109 83.5468C109 84.8841 107.916 85.6778 106.579 85.3192Z" fill="#E8F0FF" />
            <path d="M106.579 85.3192L85.8902 79.7728C84.5529 79.4142 83.4688 78.0395 83.4688 76.7022C83.4688 75.3648 84.5529 74.5715 85.8902 74.9298L106.579 80.4762C107.916 80.8348 109 82.2095 109 83.5468C109 84.8841 107.916 85.6778 106.579 85.3192Z" stroke="#08031D" strokeWidth="0.2" />
            <path d="M8.37069 95.1152L10.898 95.7927C11.3044 95.9016 11.6339 95.6606 11.6339 95.2539V87.9866C11.6339 87.5799 11.3044 87.1622 10.898 87.0532L8.37069 86.3758C7.96434 86.2668 7.63477 86.5082 7.63477 86.9146V94.1818C7.63477 94.5885 7.96434 95.0063 8.37069 95.1152Z" fill="#0E3F99" />
            <path d="M13.9915 96.6222L16.5188 97.2997C16.9252 97.4087 17.2547 97.1676 17.2547 96.7609V89.4936C17.2547 89.087 16.9252 88.6692 16.5188 88.5603L13.9915 87.8828C13.5851 87.7738 13.2556 88.0152 13.2556 88.4216V95.6888C13.2556 96.0955 13.5851 96.5133 13.9915 96.6222Z" fill="#0E3F99" />
            <path d="M22.1396 98.8067L19.6122 98.1293C19.2059 98.0203 18.8763 97.6026 18.8763 97.1959V89.9286C18.8763 89.5222 19.2059 89.2809 19.6122 89.3898L22.1396 90.0673C22.5459 90.1762 22.8755 90.594 22.8755 91.0007V98.2679C22.8755 98.6743 22.5459 98.9157 22.1396 98.8067Z" fill="#0E3F99" />
            <path d="M25.233 99.636L27.7603 100.313C28.1666 100.422 28.4962 100.181 28.4962 99.7747V92.5074C28.4962 92.1007 28.1666 91.683 27.7603 91.574L25.233 90.8966C24.8266 90.7876 24.497 91.029 24.497 91.4354V98.7026C24.497 99.1093 24.8266 99.5271 25.233 99.636Z" fill="#0E3F99" />
            <path d="M37.4284 102.906L34.901 102.228C34.4947 102.119 34.1651 101.701 34.1651 101.295V94.0271C34.1651 93.6208 34.4947 93.3794 34.901 93.4883L37.4284 94.1658C37.8347 94.2748 38.1643 94.6925 38.1643 95.0992V102.366C38.1643 102.773 37.8347 103.015 37.4284 102.906Z" fill="#0E3F99" />
            <path d="M40.5218 103.735L43.0491 104.412C43.4555 104.521 43.7851 104.28 43.7851 103.873V96.6062C43.7851 96.1995 43.4555 95.7818 43.0491 95.6728L40.5218 94.9954C40.1155 94.8864 39.7859 95.1278 39.7859 95.5342V102.801C39.7859 103.208 40.1155 103.626 40.5218 103.735Z" fill="#0E3F99" />
            <path d="M48.6699 105.919L46.1425 105.242C45.7362 105.133 45.4066 104.715 45.4066 104.308V97.0409C45.4066 96.6346 45.7362 96.3932 46.1425 96.5021L48.6699 97.1796C49.0762 97.2885 49.4058 97.7063 49.4058 98.113V105.38C49.4058 105.787 49.0762 106.028 48.6699 105.919Z" fill="#0E3F99" />
            <path d="M51.7633 106.749L54.2906 107.426C54.697 107.535 55.0266 107.294 55.0266 106.888V99.6203C55.0266 99.2136 54.697 98.7958 54.2906 98.6869L51.7633 98.0094C51.357 97.9004 51.0274 98.1418 51.0274 98.5482V105.815C51.0274 106.222 51.357 106.64 51.7633 106.749Z" fill="#0E3F99" />
            <path d="M63.9587 110.018L61.4314 109.34C61.025 109.231 60.6954 108.814 60.6954 108.407V101.14C60.6954 100.733 61.025 100.492 61.4314 100.601L63.9587 101.278C64.365 101.387 64.6946 101.805 64.6946 102.212V109.479C64.695 109.886 64.3654 110.127 63.9587 110.018Z" fill="#0E3F99" />
            <path d="M67.0521 110.847L69.5794 111.525C69.9861 111.634 70.3157 111.392 70.3154 110.986V103.719C70.3154 103.312 69.9858 102.894 69.5794 102.785L67.0521 102.108C66.6458 101.999 66.3162 102.24 66.3162 102.647V109.914C66.3162 110.321 66.6458 110.738 67.0521 110.847Z" fill="#0E3F99" />
            <path d="M75.2002 113.032L72.6729 112.354C72.2665 112.245 71.9369 111.828 71.9369 111.421V104.154C71.9369 103.747 72.2665 103.506 72.6729 103.615L75.2002 104.292C75.6065 104.401 75.9361 104.819 75.9361 105.226V112.493C75.9365 112.9 75.6069 113.141 75.2002 113.032Z" fill="#0E3F99" />
            <path d="M78.2936 113.861L80.821 114.538C81.2277 114.647 81.5572 114.406 81.5569 114V106.732C81.5569 106.326 81.2273 105.908 80.821 105.799L78.2936 105.121C77.8873 105.013 77.5577 105.254 77.5577 105.66V112.928C77.5577 113.334 77.8873 113.752 78.2936 113.861Z" fill="#0E3F99" />
            <path d="M90.4893 117.13L87.962 116.453C87.5557 116.344 87.2261 115.926 87.2261 115.52V108.252C87.2261 107.846 87.5557 107.605 87.962 107.713L90.4893 108.391C90.8957 108.5 91.2253 108.918 91.2253 109.324V116.592C91.2253 116.998 90.8957 117.239 90.4893 117.13Z" fill="#0E3F99" />
            <path d="M93.5828 117.96L96.1101 118.637C96.5165 118.746 96.846 118.505 96.846 118.098V110.831C96.846 110.424 96.5165 110.007 96.1101 109.898L93.5828 109.22C93.1764 109.111 92.8469 109.353 92.8469 109.759V117.026C92.8469 117.433 93.1764 117.851 93.5828 117.96Z" fill="#0E3F99" />
            <path d="M101.731 120.144L99.2036 119.467C98.7972 119.358 98.4677 118.94 98.4677 118.533V111.266C98.4677 110.86 98.7972 110.618 99.2036 110.727L101.731 111.405C102.137 111.514 102.467 111.931 102.467 112.338V119.605C102.467 120.012 102.137 120.253 101.731 120.144Z" fill="#0E3F99" />
            <path d="M104.824 120.974L107.352 121.651C107.758 121.76 108.088 121.519 108.088 121.112V113.845C108.088 113.438 107.758 113.021 107.352 112.912L104.824 112.234C104.418 112.125 104.088 112.367 104.088 112.773V120.04C104.088 120.447 104.418 120.865 104.824 120.974Z" fill="#0E3F99" />
            <path d="M26.6542 127.297C28.8942 125.337 28.6343 121.376 26.0739 118.449C23.5134 115.523 19.6219 114.739 17.382 116.699C15.142 118.659 15.4018 122.62 17.9623 125.547C20.5227 128.473 24.4142 129.256 26.6542 127.297Z" fill="#B5CFFE" />
            <path d="M26.6542 127.297C28.8942 125.337 28.6343 121.376 26.0739 118.449C23.5134 115.523 19.6219 114.739 17.382 116.699C15.142 118.659 15.4018 122.62 17.9623 125.547C20.5227 128.473 24.4142 129.256 26.6542 127.297Z" stroke="#08031D" strokeWidth="0.2" />
            <path d="M19.7274 125.441C21.9674 123.481 21.7076 119.52 19.1471 116.594C16.5867 113.667 12.6952 112.884 10.4552 114.844C8.21526 116.804 8.47507 120.765 11.0355 123.691C13.596 126.618 17.4875 127.401 19.7274 125.441Z" fill="#1769FF" />
            <path d="M19.7274 125.441C21.9674 123.481 21.7076 119.52 19.1471 116.594C16.5867 113.667 12.6952 112.884 10.4552 114.844C8.21526 116.804 8.47507 120.765 11.0355 123.691C13.596 126.618 17.4875 127.401 19.7274 125.441Z" stroke="#08031D" strokeWidth="0.2" />
            <path d="M21.7809 84.345L11.0795 81.476C9.55098 81.0662 8.31152 79.4947 8.31152 77.9658V70.8255C8.31152 69.2969 9.55098 68.3898 11.0795 68.7996L21.7809 71.6686C23.3095 72.0784 24.5489 73.6499 24.5489 75.1788V82.3195C24.5489 83.8477 23.3095 84.7548 21.7809 84.345Z" fill="#F9B121" />
            <path d="M20.822 82.9524L12.0393 80.598C10.7847 80.2615 9.76758 78.9719 9.76758 77.7172V71.8568C9.76758 70.6021 10.7847 69.8579 12.0393 70.194L20.822 72.5484C22.0767 72.8849 23.0938 74.1745 23.0938 75.4292V81.2893C23.0934 82.5443 22.0767 83.2885 20.822 82.9524Z" fill="#FBC93B" />
            <path d="M21.7809 84.345L11.0795 81.476C9.55098 81.0662 8.31152 79.4947 8.31152 77.9658V70.8255C8.31152 69.2969 9.55098 68.3898 11.0795 68.7996L21.7809 71.6686C23.3095 72.0784 24.5489 73.6499 24.5489 75.1788V82.3195C24.5489 83.8477 23.3095 84.7548 21.7809 84.345Z" stroke="#08031D" strokeWidth="0.2" />
            <path d="M20.822 82.9524L12.0393 80.598C10.7847 80.2615 9.76758 78.9719 9.76758 77.7172V71.8568C9.76758 70.6021 10.7847 69.8579 12.0393 70.194L20.822 72.5484C22.0767 72.8849 23.0938 74.1745 23.0938 75.4292V81.2893C23.0934 82.5443 22.0767 83.2885 20.822 82.9524Z" stroke="#08031D" strokeWidth="0.2" />
            <path d="M12.8536 77.4011L8.31152 76.1834V76.4591L12.752 77.6494C12.661 77.9112 12.5981 78.2435 12.5981 78.7087C12.5981 80.0771 13.0362 81.272 13.8617 82.222L14.2913 82.3371C13.3513 81.4144 12.8737 80.222 12.8737 78.7827C12.8737 77.8517 13.1289 77.4959 13.3759 77.1518C13.592 76.8502 13.8157 76.5383 13.8157 75.8711C13.8157 75.2376 13.6186 74.8402 13.4101 74.4194C13.1587 73.912 12.8737 73.3369 12.8737 72.1511C12.8737 70.6505 13.8099 69.9495 14.3366 69.6728L13.9032 69.5566C13.334 69.9253 12.5981 70.6733 12.5981 72.077C12.5981 72.5284 12.6382 72.8942 12.7008 73.2062L8.31152 72.0293V72.3049L12.7707 73.5005C12.882 73.9041 13.0307 74.2091 13.1708 74.4916C13.3689 74.8914 13.5401 75.2365 13.5401 75.7968C13.5401 76.3875 13.3544 76.6461 13.1397 76.9456C13.0432 77.0809 12.9422 77.2226 12.8536 77.4011Z" fill="#08031D" />
            <path d="M19.6986 75.311C19.5993 75.6613 19.4561 75.8733 19.3178 76.0784C19.1207 76.3706 18.9346 76.6462 18.9346 77.2452C18.9346 77.8756 19.1463 78.2837 19.3503 78.6783C19.5834 79.1286 19.8248 79.5941 19.8248 80.4742C19.8248 82.0138 19.249 82.9469 18.1133 83.2481L18.1745 83.3795L18.4193 83.4452C19.5063 83.07 20.0855 82.0771 20.0855 80.5441C20.0855 80.164 20.0413 79.8541 19.9738 79.5871L24.5478 80.8135V80.5378L19.95 79.3053V79.501C19.849 79.1417 19.7072 78.8619 19.5737 78.604C19.3707 78.2118 19.1954 77.8736 19.1954 77.315C19.1954 76.7852 19.3572 76.5456 19.5443 76.2686C19.6726 76.078 19.8172 75.8643 19.9234 75.5351L24.5481 76.7749V76.5141L19.7242 75.2207L19.6986 75.311Z" fill="#08031D" />
            <path d="M70.4356 45.5286C78.388 43.481 82.8364 34.4668 81.3628 22.9124C79.8891 11.3579 71.5264 3.5887 62.777 4.3506L56.7324 4.87695L62.4061 46.6899L70.4356 45.5286Z" fill="#00672F" />
            <path d="M70.4356 45.5286C78.388 43.481 82.8364 34.4668 81.3628 22.9124C79.8891 11.3579 71.5264 3.5887 62.777 4.3506L56.7324 4.87695L62.4061 46.6899L70.4356 45.5286Z" stroke="#08031D" strokeWidth="0.2" />
            <path d="M63.7516 46.3926C72.3993 44.3442 77.4202 33.453 74.9661 22.0664C72.5119 10.6798 63.512 3.10977 54.8642 5.15819C46.2164 7.20661 41.1956 18.0978 43.6497 29.4844C46.1039 40.8709 55.1038 48.441 63.7516 46.3926Z" fill="#00AB4F" />
            <path d="M63.7516 46.3926C72.3993 44.3442 77.4202 33.453 74.9661 22.0664C72.5119 10.6798 63.512 3.10977 54.8642 5.15819C46.2164 7.20661 41.1956 18.0978 43.6497 29.4844C46.1039 40.8709 55.1038 48.441 63.7516 46.3926Z" stroke="#08031D" strokeWidth="0.2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M71.0535 19.9076C72.0483 21.0184 71.9543 22.7253 70.8434 23.7202L57.6111 35.5706C56.6101 36.467 55.1024 36.491 54.0734 35.6269L49.2641 31.5881C48.1222 30.6292 47.9739 28.9261 48.9329 27.7841C49.8919 26.6422 51.595 26.4939 52.7369 27.4529L55.7529 29.9858L67.2409 19.6975C68.3517 18.7027 70.0587 18.7967 71.0535 19.9076Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M71.0276 19.9302C72.0093 21.0264 71.9165 22.7109 70.8203 23.6926L57.588 35.5431C56.6002 36.4277 55.1123 36.4514 54.0969 35.5987L49.2876 31.5599C48.1607 30.6135 48.0143 28.9328 48.9607 27.8059C49.907 26.679 51.5877 26.5327 52.7146 27.479L55.7543 30.0317L67.2652 19.7229C68.3614 18.7412 70.0459 18.834 71.0276 19.9302Z" stroke="#08031D" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

PaymentSuccessIcon.propTypes = {
    className: PropTypes.string,
};

PaymentSuccessIcon.defaultProps = {
    className: '',
};
