export function selectIsLoading(state) {
    return state.isLoading;
}

export function selectIsSubmitting(state) {
    return state.isSubmitting;
}

export function selectPaymentToken(state) {
    return state.paymentToken;
}

export function selectPostalCode(state) {
    return state.postalCode;
}

export function selectCCNumberError(state) {
    return state.ccNumberError;
}

export function selectCCExpError(state) {
    return state.ccExpError;
}

export function selectCVVError(state) {
    return state.cvvError;
}

export function selectPostalCodeError(state) {
    return state.postalCodeError;
}

export function selectIsPaymentFormValid(state) {
    return (
        state.isCCNumberDirty &&
        state.ccNumberError === '' &&
        state.isCCExpDirty &&
        state.ccExpError === '' &&
        state.isCVVDirty &&
        state.cvvError === ''
        // commenting this due to is optional, might be need it later
        // state.isPostalCodeDirty &&
        // state.postalCodeError === ''
    );
}

export function selectIsPristine(state) {
    return (
        !state.isCCNumberDirty &&
        state.ccNumberError === '' &&
        !state.isCCExpDirty &&
        state.ccExpError === '' &&
        !state.isCVVDirty &&
        state.cvvError === ''
    );
}

export function selectCCNumber(state) {
    return state.ccNumber;
}

export function selectCCExpiryDate(state) {
    return state.ccExp;
}

export function selectCCBin(state) {
    return state.bin;
}

export function selectCCHash(state) {
    return state.hash;
}

export function selectIsDirty(state) {
    return state.isCCExpDirty || state.isCCExpDirty || state.isCVVDirty;
}
