import React from 'react';

import { wrapWithRightNav } from '../../common/components/rightNav';
import DueDatesPage from '../paymentScheduleMobile/dueDatesPage';

const InvoiceBalancePage = React.lazy(() => import('../../balanceDue/invoiceBalancePage'));
const InvoiceDepositPage = React.lazy(() => import('../../balanceDue/invoiceDepositPage'));
const RequestDepositPage = React.lazy(() => import('../../requestDepositPage'));

const WrappedInvoiceBalancePage = wrapWithRightNav(InvoiceBalancePage);
const WrappedInvoiceDepositPage = wrapWithRightNav(InvoiceDepositPage);
const WrappedRequestDepositPage = wrapWithRightNav(RequestDepositPage);

const routes = [
    {
        path: '/invoices/new/dueDates/balanceDue',
        exact: true,
        displayName: 'Invoice Balance Due',
        render: props => <WrappedInvoiceBalancePage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/edit/dueDates/balanceDue',
        exact: true,
        displayName: 'Invoice Balance Due',
        render: props => <WrappedInvoiceBalancePage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/duplicate/dueDates/balanceDue',
        exact: true,
        displayName: 'Invoice Balance Due',
        render: props => <WrappedInvoiceBalancePage {...props} />,
    },
    {
        path: '/invoices/new/dueDates/deposit/due',
        exact: true,
        displayName: 'Deposit Balance Due',
        render: props => <WrappedInvoiceDepositPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/edit/dueDates/deposit/due',
        exact: true,
        displayName: 'Deposit Balance Due',
        render: props => <WrappedInvoiceDepositPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/duplicate/dueDates/deposit/due',
        exact: true,
        displayName: 'Deposit Balance Due',
        render: props => <WrappedInvoiceDepositPage {...props} />,
    },
    {
        path: '/invoices/new/dueDates/deposit',
        exact: true,
        displayName: 'Request Deposit',
        render: props => <WrappedRequestDepositPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/edit/dueDates/deposit',
        exact: true,
        displayName: 'Request Deposit',
        render: props => <WrappedRequestDepositPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/duplicate/dueDates/deposit',
        exact: true,
        displayName: 'Request Deposit',
        render: props => <WrappedRequestDepositPage {...props} />,
    },
    {
        path: '/invoices/new/dueDates',
        exact: true,
        displayName: 'Due Dates Page',
        render: props => <DueDatesPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/edit/dueDates',
        exact: true,
        displayName: 'Due Dates Page',
        render: props => <DueDatesPage {...props} />,
    },
    {
        path: '/invoices/:invoiceId/duplicate/dueDates',
        exact: true,
        displayName: 'Due Dates Page',
        render: props => <DueDatesPage {...props} />,
    },
];

export default routes;
