import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import * as InvoiceService from '../../api/invoices';
import { formatBackendReminders } from '../../../remindersPage/remindersPage.constants';
import addNotification, {
    DANGER,
    SUCCESS,
} from '../../../common/utils/notificationHelper';
import {
    buildDataForInvoice,
    buildDataForUpdate,
    mapInvoiceSettingsToInvoice,
} from '../../helpers';
import {
    serializeToSnakeCase,
    serializeToCamelCase,
} from 'legacy/utils/axiosWrapper';

const ns = '[Invoices]';
export const SET_INVOICE = `${ns} Set Invoice with a template`;
export const SET_WARNING = `${ns} Set Invoice Warning`;
export const SET_ACCOUNT = `${ns} Set Account`;
export const SET_INVOICE_DETAILS = `${ns} Set Invoice Details`;
export const UNSET_ACCOUNT = `${ns} Unset Account`;
export const HANDLE_ON_CHANGE_DETAILS = `${ns} handle on change details`;
export const CHECK_UNIQUE_CUSTOM_ID_STARTED = `${ns} check unique custom id started`;
export const CHECK_UNIQUE_CUSTOM_ID_FAILED = `${ns} check unique custom id failed`;
export const CHECK_UNIQUE_CUSTOM_ID_SUCCESS = `${ns} check unique custom id success`;
export const SUBMIT_SETTINGS_STARTED = `${ns} submit settings started`;
export const SUBMIT_SETTINGS_FAILED = `${ns} submit settings failed`;
export const SUBMIT_SETTINGS_SUCCESS = `${ns} submit settings success`;
export const SUBMIT_INVOICE_STARTED = `${ns} submit Invoice started`;
export const SUBMIT_INVOICE_FAILED = `${ns} submit Invoice failed`;
export const SUBMIT_INVOICE_SUCCESS = `${ns} submit Invoice success`;
export const UPDATE_INVOICE_STARTED = `${ns} update Invoice started`;
export const UPDATE_INVOICE_FAILED = `${ns} update Invoice failed`;
export const UPDATE_INVOICE_SUCCESS = `${ns} update Invoice success`;
export const REQUEST_INITIAL_INITIAL_DEPOSIT = `${ns} toggles switch for initial deposit`;
export const SET_BALANCE_DUE_DATE = `${ns} set balance due date`;
export const SET_SEND_INVOICE_DATE = `${ns} set send invoice date`;
export const SET_DEPOSIT_DUE_DATE = `${ns} set deposit due date`;
export const SET_DEPOSIT_AMOUNT = `${ns} set deposit amount`;
export const ADD_LINE_ITEM = `${ns} Add Line Item`;
export const SET_SELECTED_LINE_ITEM = `${ns} Select Line Item`;
export const EDIT_LINE_ITEM = `${ns} Edit Line Item`;
export const CLEAR_SELECTED_LINE_ITEM = `${ns} Clear Selected Line Item`;
export const REMOVE_LINE_ITEM = `${ns} Remove Line Item`;
export const SAVE_DISCOUNT = `${ns} Save Discount`;
export const REMOVE_DISCOUNT = `${ns} Remove Discount`;
export const SET_INVOICE_FEES = `${ns} Set Invoice Fees`;
export const HANDLE_OPTIONS_CHECKBOX = `${ns} handle options checkbox`;
export const SET_INVOICE_REMINDERS = `${ns} set invoice reminders`;
export const FETCH_INVOICE_SETTINGS = `${ns} fetch invoice settings from new_settings`;
export const FETCH_INVOICE_SETTINGS_STARTED = `${ns} fetch invoice settings from new_settings started`;
export const SET_INVOICE_DUE_DEFAULT = `${ns} set invoice due default`;
export const SET_SEND_DUE_DEFAULT = `${ns} set send invoice due default`;
export const SET_INVOICE_DEFAULTS = `${ns} set invoice defaults for create invoice`;
export const CLEAR_LINE_ITEMS = `${ns} clear line items`;
export const ADD_ATTACHMENT = `${ns} Add Attachment`;
export const REMOVE_ATTACHMENT = `${ns} Remove Attachment`;
export const SET_ATTACHMENT_TO_BE_REMOVED = `${ns} Add Attachment to array to be removed`;
export const RESET_INVOICE = `${ns} reset invoice`;
export const GET_INVOICES_STARTED = `${ns} get invoices STARTED`;
export const GET_INVOICES_SUCCESS = `${ns} get invoices SUCCESS`;
export const GET_INVOICES_FAILED = `${ns} get invoices FAILED`;
export const GET_INVOICE_DETAILS_STARTED = `${ns} get invoice details STARTED`;
export const GET_INVOICE_DETAILS_SUCCESS = `${ns} get invoice details SUCCESS`;
export const GET_INVOICE_DETAILS_FAILED = `${ns} get invoice details FAILED`;
export const DELETE_INVOICE_STARTED = `${ns} delete invoice STARTED`;
export const DELETE_INVOICE_SUCCESS = `${ns} delete invoice SUCCESS`;
export const DELETE_INVOICE_FAILED = `${ns} delete invoice FAILED`;
export const SUBMIT_MERCHANT_PAYMENT_STARTED = `${ns} submit merchant payment STARTED`;
export const SUBMIT_MERCHANT_PAYMENT_SUCCESS = `${ns} submit merchant payment SUCCESS`;
export const SUBMIT_MERCHANT_PAYMENT_FAILED = `${ns} submit merchant payment FAILED`;
export const RESET_SELECTED_INVOICE = `${ns} get invoice details FAILED`;
export const SET_CONVENIENCE_FEE_INCLUDED = `${ns} Set convenience fee included`;
export const GET_INVOICE_SETTINGS_STARTED = `${ns} get invoice settings STARTED`;
export const GET_INVOICE_SETTINGS_SUCCESS = `${ns} get invoice settings SUCCESS`;
export const GET_INVOICE_SETTINGS_FAILED = `${ns} get invoice settings FAILED`;
export const REMOVE_DEFAULT_ATTACHMENT = `${ns} remove default attachment`;

export const setInvoice = (template) => ({
    type: SET_INVOICE,
    payload: template,
});

export const setInvoiceWarning = (warningMessage) => ({
    type: SET_WARNING,
    payload: warningMessage,
});

export const setAccount = (account) => ({
    type: SET_ACCOUNT,
    payload: account,
});

export const toggleRequestInitialDeposit = () => ({
    type: REQUEST_INITIAL_INITIAL_DEPOSIT,
});

export const setInvoiceDetails = (invoiceDetails) => ({
    type: SET_INVOICE_DETAILS,
    payload: invoiceDetails,
});

export const setBalanceDueDate = (balanceDueDate) => ({
    type: SET_BALANCE_DUE_DATE,
    payload: balanceDueDate,
});

export const setSendInvoiceDate = (sendInvoiceDate) => ({
    type: SET_SEND_INVOICE_DATE,
    payload: sendInvoiceDate,
});

export const setDepositDueDate = (depositDueDate) => ({
    type: SET_DEPOSIT_DUE_DATE,
    payload: depositDueDate,
});

export const setDepositAmount = (depositAmount) => ({
    type: SET_DEPOSIT_AMOUNT,
    payload: depositAmount,
});

export const unsetAccount = () => ({
    type: UNSET_ACCOUNT,
});

export const setInvoiceDefaults = () => ({
    type: SET_INVOICE_DEFAULTS,
});

export const handleDetails = (name, value) => ({
    type: HANDLE_ON_CHANGE_DETAILS,
    payload: {
        name,
        value,
    },
});

export const handleOptionsCheckbox = (name, value) => ({
    type: HANDLE_OPTIONS_CHECKBOX,
    payload: {
        name,
        value,
    },
});

export const setInvoiceReminders = (reminders, hasUpdateInvoiceReminders) => ({
    type: SET_INVOICE_REMINDERS,
    payload: { reminders, hasUpdateInvoiceReminders },
});

export const fetchInvoiceSettingsStarted = () => ({
    type: FETCH_INVOICE_SETTINGS_STARTED,
});

export const fetchInvoiceSettings = (settings) => ({
    type: FETCH_INVOICE_SETTINGS,
    payload: settings,
});

export const setInvoiceDueDefault = (payload) => ({
    type: SET_INVOICE_DUE_DEFAULT,
    payload,
});

export const setSendDueDefault = (payload) => ({
    type: SET_SEND_DUE_DEFAULT,
    payload,
});

export const resetInvoice = () => ({
    type: RESET_INVOICE,
});

export const updateConvenienceFeeIncluded = (convenienceFeeIncluded) => ({
    type: SET_CONVENIENCE_FEE_INCLUDED,
    payload: convenienceFeeIncluded,
});

const checkUniqueCustomIdStarted = () => ({
    type: CHECK_UNIQUE_CUSTOM_ID_STARTED,
});

const checkUniqueCustomIdSuccess = ({ customIdValid, customIdUnique }) => ({
    type: CHECK_UNIQUE_CUSTOM_ID_SUCCESS,
    payload: {
        customIdValid,
        customIdUnique,
    },
});

const checkUniqueCustomIdFailed = (err) => ({
    type: CHECK_UNIQUE_CUSTOM_ID_FAILED,
    payload: err,
});

const submitSettingsStarted = () => ({
    type: SUBMIT_SETTINGS_STARTED,
});

const submitSettingsSuccess = (payload) => ({
    type: SUBMIT_SETTINGS_SUCCESS,
    payload,
});

const submitSettingsFailed = (err) => ({
    type: SUBMIT_SETTINGS_FAILED,
    payload: err,
});

const submitInvoiceStarted = () => ({
    type: SUBMIT_INVOICE_STARTED,
});

const submitInvoiceSuccess = (payload) => ({
    type: SUBMIT_INVOICE_SUCCESS,
    payload,
});

const submitInvoiceFailed = (err) => ({
    type: SUBMIT_INVOICE_FAILED,
    payload: err,
});

const updateInvoiceStarted = () => ({
    type: UPDATE_INVOICE_STARTED,
});

const updateInvoiceSuccess = (payload) => ({
    type: UPDATE_INVOICE_SUCCESS,
    payload,
});

const updateInvoiceFailed = (err) => ({
    type: UPDATE_INVOICE_FAILED,
    payload: err,
});

export const checkUniqueCustomId = (customId) => (dispatch) => {
    if (customId === '') {
        const data = {
            customIdValid: true,
            customIdUnique: true,
        };
        dispatch(checkUniqueCustomIdSuccess(data));
        return;
    }

    dispatch(checkUniqueCustomIdStarted());
    return InvoiceService.checkUniqueCustomId(customId)
        .then(({ data }) => {
            dispatch(checkUniqueCustomIdSuccess(data));
        })
        .catch((error) => {
            dispatch(checkUniqueCustomIdFailed(error));
        });
};

const getDaysValue = (term) => {
    if (term === null) {
        return null;
    }
    if (typeof term === 'string' && isEmpty(term)) {
        return null;
    }
    return term;
};

export const submitSettings = (settings) => (dispatch) => {
    dispatch(submitSettingsStarted());
    const {
        invoicingDefaultDeliveryDelayDays,
        invoicingDefaultDueDateTermsDays,
    } = settings;
    return InvoiceService.submitSettings({
        invoicingAutoscheduleReminders:
            settings.invoicingAutoscheduleReminders || false,
        invoicingReminders: formatBackendReminders(settings.reminders),
        invoicingDefaultDeliveryDelayDays: getDaysValue(
            invoicingDefaultDeliveryDelayDays,
        ),
        invoicingDefaultInvoiceTitle: settings.title || null,
        invoicingRequestShippingAddress: settings.requestShipping || false,
        invoicingDefaultDueDateTermsDays: getDaysValue(
            invoicingDefaultDueDateTermsDays,
        ),
        invoicingDefaultReminderMessage: settings.message || null,
        invoicingDefaultTermsConditions: settings.terms,
        invoicingEmailNotificationSettings:
            settings.invoicingEmailNotificationSettings,
    })
        .then(({ data }) => {
            dispatch(submitSettingsSuccess(data));
            addNotification({
                title: 'Success!',
                message: 'You have successfully change your invoices Settings',
                type: SUCCESS,
            });
            return data.nmiSettings;
        })
        .catch((error) => {
            dispatch(submitSettingsFailed(error));
            addNotification({
                title: get(
                    error,
                    'response.data.error',
                    'Something went wrong',
                ),
                type: DANGER,
            });
        });
};

export const addLineItem = (item) => ({
    type: ADD_LINE_ITEM,
    payload: item,
});

export const setSelectedLineItem = (item) => ({
    type: SET_SELECTED_LINE_ITEM,
    payload: item,
});

export const editLineItem = (item) => ({
    type: EDIT_LINE_ITEM,
    payload: item,
});

export const clearSelectedLineItem = () => ({
    type: CLEAR_SELECTED_LINE_ITEM,
});

export const removeLineItem = (index) => ({
    type: REMOVE_LINE_ITEM,
    payload: index,
});

export const clearLineItems = () => ({
    type: CLEAR_LINE_ITEMS,
});

export const saveDiscount = (discount) => ({
    type: SAVE_DISCOUNT,
    payload: discount,
});

export const removeDiscount = () => ({
    type: REMOVE_DISCOUNT,
});

export const setInvoiceFees = (fees) => ({
    type: SET_INVOICE_FEES,
    payload: fees,
});

export const submitInvoice = (status) => (dispatch, getState) => {
    dispatch(submitInvoiceStarted());

    const formData = new FormData();
    formData.append(
        'data',
        JSON.stringify(
            serializeToSnakeCase(
                buildDataForInvoice(
                    getState().invoices,
                    status,
                    getState().merchant,
                ),
            ),
        ),
    );

    const attachments = getState().invoices.attachments;
    attachments.forEach((attachment) => {
        formData.append('files[]', attachment, attachment.name);
    });

    return InvoiceService.submitInvoice(formData)
        .then(({ data }) => {
            const result = serializeToCamelCase(data);
            const { account } = getState().invoices;

            dispatch(submitInvoiceSuccess(result));
            return [result, account];
        })
        .catch((error) => {
            dispatch(submitInvoiceFailed(error));
            throw error;
        });
};

export const updateInvoiceStatus = (status, id) => (dispatch, getState) => {
    dispatch(updateInvoiceStarted());

    return InvoiceService.updateInvoice(id, { status })
        .then(({ data }) => {
            const account = getState().recurringAccounts.accounts.find(
                (a) => a.id === data.accountId,
            );

            dispatch(updateInvoiceSuccess(data));
            return [data, account];
        })
        .catch((error) => {
            dispatch(updateInvoiceFailed(error));
            throw error;
        });
};

export const updateInvoice = (status) => (dispatch, getState) => {
    dispatch(updateInvoiceStarted());

    // PATCH Invoice
    const invoice = getState().invoices;
    const payload = buildDataForUpdate(invoice, status, getState().merchant);
    const patchInvoice = InvoiceService.updateInvoice(invoice.id, payload);

    // DELETE Attachments
    const attachmentsToDelete = invoice.attachmentsToDelete.map(
        (attachmentId) => {
            return InvoiceService.removeAttachment(invoice.id, attachmentId);
        },
    );

    // POST Attachments
    const formData = new FormData();
    invoice.attachments.forEach((attachment) => {
        if (!attachment.id) {
            formData.append('files[]', attachment, attachment.name);
        }
    });
    const attachmentsToCreate =
        formData.getAll('files[]').length > 0
            ? InvoiceService.addAttachment(invoice.id, formData)
            : [];

    return Promise.all(
        [patchInvoice].concat(attachmentsToDelete, attachmentsToCreate),
    )
        .then(([invoiceData, ...rest]) => {
            const { data } = invoiceData;
            const { account } = invoice;

            dispatch(updateInvoiceSuccess(data));
            return [data, account];
        })
        .catch((error) => {
            dispatch(updateInvoiceFailed(error));
            throw error;
        });
};

export const getInvoicesStarted = () => ({
    type: GET_INVOICES_STARTED,
});

export const getInvoicesSuccess = (data) => ({
    type: GET_INVOICES_SUCCESS,
    payload: data,
});

export const getInvoicesFailed = (error) => ({
    type: GET_INVOICES_FAILED,
    payload: error,
});

export const getInvoices = ({
    page = 1,
    pageSize = 10,
    order = 'asc',
    orderBy = 'creation_date',
    invoiceStatus,
    accountId,
    q,
    dueDateStart,
    dueDateEnd,
}) => (dispatch) => {
    dispatch(getInvoicesStarted());
    return InvoiceService.fetchInvoices({
        page,
        pageSize,
        order,
        orderBy,
        invoiceStatus,
        q,
        dueDateStart,
        dueDateEnd,
        accountId,
    })
        .then((res) => {
            if (![200, 201].includes(res.status)) {
                throw new Error(res.data.error);
            }
            dispatch(getInvoicesSuccess(res.data));
            return res.data;
        })
        .catch((error) => {
            dispatch(getInvoicesFailed(get(error, 'response')));
        });
};

export const resetSelectedInvoice = () => ({
    type: RESET_SELECTED_INVOICE,
});

export const getInvoiceDetailsStarted = () => ({
    type: GET_INVOICE_DETAILS_STARTED,
});

export const getInvoiceDetailsSuccess = (data) => ({
    type: GET_INVOICE_DETAILS_SUCCESS,
    payload: data,
});

export const getInvoiceDetailsFailed = (error) => ({
    type: GET_INVOICE_DETAILS_FAILED,
    payload: error,
});

export const getInvoiceDetails = (id) => (dispatch) => {
    dispatch(getInvoiceDetailsStarted());
    return InvoiceService.fetchInvoiceDetails(id)
        .then(({ data }) => {
            dispatch(getInvoiceDetailsSuccess(data));
            return data;
        })
        .catch((error) => {
            dispatch(getInvoiceDetailsFailed(get(error, 'response')));
            addNotification({
                title: get(
                    error,
                    'response.data.error',
                    'Something went wrong',
                ),
                type: DANGER,
            });
        });
};

export const addAttachment = (attachment) => ({
    type: ADD_ATTACHMENT,
    payload: attachment,
});

export const removeAttachment = (index) => ({
    type: REMOVE_ATTACHMENT,
    payload: index,
});

export const addAttachmentToBeRemoved = (id) => ({
    type: SET_ATTACHMENT_TO_BE_REMOVED,
    payload: id,
});

export const getInvoiceSettingsStarted = () => ({
    type: GET_INVOICE_SETTINGS_STARTED,
});

export const getInvoiceSettingsSuccess = (mappedSettings, invoiceSettings) => ({
    type: GET_INVOICE_SETTINGS_SUCCESS,
    payload: { mappedSettings, invoiceSettings },
});

export const getInvoiceSettingsFailed = () => ({
    type: GET_INVOICE_SETTINGS_FAILED,
});

export const getInvoiceSettings = () => (dispatch) => {
    dispatch(getInvoiceSettingsStarted());
    return InvoiceService.getInvoiceSettings()
        .then(({ data }) =>
            dispatch(
                getInvoiceSettingsSuccess(
                    mapInvoiceSettingsToInvoice(data),
                    data,
                ),
            ),
        )
        .catch((error) => {
            addNotification({
                title: get(
                    error,
                    'response.data.error',
                    'Could not fetch default invoice settings',
                ),
                type: DANGER,
            });
            dispatch(getInvoiceSettingsFailed());
        });
};

export const removeDefaultAttachment = (index) => ({
    type: REMOVE_DEFAULT_ATTACHMENT,
    payload: index,
});

export const getInvoicePreview = () => (dispatch, getState) => {
    return InvoiceService.previewInvoice(
        buildDataForInvoice(
            getState().invoices,
            (status = 'draft'),
            getState().merchant,
        ),
    )
        .then(({ data }) => data)
        .catch((error) => {
            addNotification({
                title: get(
                    error,
                    'response.data.error',
                    'Something went wrong',
                ),
                type: DANGER,
            });
        });
};

export const sendReminder = (
    invoiceId,
    message = null,
    recipient = null,
) => () => {
    return InvoiceService.sendReminder(invoiceId, {
        subject: null,
        message,
        recipient,
    })
        .then(({ data }) => {
            addNotification({
                title: 'Success!',
                message: 'Reminder has been sent',
                type: SUCCESS,
            });
            return data;
        })
        .catch((error) => {
            addNotification({
                title: get(
                    error,
                    'response.data.error',
                    'Something went wrong',
                ),
                type: DANGER,
            });
        });
};

export const deleteInvoiceStarted = () => ({
    type: DELETE_INVOICE_STARTED,
});

export const deleteInvoiceSuccess = (data) => ({
    type: DELETE_INVOICE_SUCCESS,
    payload: data,
});

export const deleteInvoiceFailed = (error) => ({
    type: DELETE_INVOICE_FAILED,
    payload: error,
});

export const deleteInvoice = ({ type, invoiceId, customId }) => (dispatch) => {
    dispatch(deleteInvoiceStarted());
    return InvoiceService.deleteInvoice(invoiceId)
        .then((response) => {
            if (!response.ok) {
                throw new Error();
            }
            const pastTenseAction = `${type}ed`;
            dispatch(deleteInvoiceSuccess());
            addNotification({
                title: 'Success!',
                message: `Invoice #${customId} has been successfully ${pastTenseAction.replace(
                    'ee',
                    'e',
                )}`,
                type: SUCCESS,
            });
        })
        .catch((error) => {
            dispatch(deleteInvoiceFailed(get(error, 'response')));
            addNotification({
                title: get(
                    error,
                    'response.data.error',
                    'Something went wrong',
                ),
                type: DANGER,
            });
        });
};

export const submitMerchantPaymentStarted = () => ({
    type: SUBMIT_MERCHANT_PAYMENT_STARTED,
});

export const submitMerchantPaymentSuccess = (data) => ({
    type: SUBMIT_MERCHANT_PAYMENT_SUCCESS,
    payload: data,
});

export const submitMerchantPaymentFailed = (error) => ({
    type: SUBMIT_MERCHANT_PAYMENT_FAILED,
    payload: error,
});

export const submitMerchantPayment = (merchantData) => (dispatch) => {
    dispatch(submitMerchantPaymentStarted());
    return InvoiceService.submitMerchantPayment(merchantData)
        .then(({ data }) => {
            dispatch(submitMerchantPaymentSuccess(data));
            addNotification({
                title: 'Success!',
                message: 'Payment has been successfully placed',
                type: SUCCESS,
            });
            return data;
        })
        .catch((error) => {
            dispatch(submitMerchantPaymentFailed(get(error, 'response')));
            addNotification({
                title: get(
                    error,
                    'response.data.error',
                    'Something went wrong',
                ),
                type: DANGER,
            });
        });
};
