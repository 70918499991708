import { combineReducers } from 'redux';

import * as billingInfoStore from './billing-info';
import * as purchaseInfoStore from './purchase-info';
import * as shippingInfoStore from './shipping-info';
import * as orderInfoStore from './order-info';
import terminalActions from './shared-actions';

const { default: billingInfo, ...billingInfoActions } = billingInfoStore;
const { default: purchaseInfo, ...purchaseInfoActions } = purchaseInfoStore;
const { default: shippingInfo, ...shippingInfoActions } = shippingInfoStore;
const { default: orderInfo, ...orderInfoActions } = orderInfoStore;

export default combineReducers({
    billingInfo,
    purchaseInfo,
    shippingInfo,
    orderInfo,
});

export {
    billingInfoActions,
    purchaseInfoActions,
    shippingInfoActions,
    orderInfoActions,
    terminalActions,
};
