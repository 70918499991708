import {
    SET_URLS
} from './projectUrl.actions';

/** InitialState */
const initialState = {
    baseUrl: '/',
    staticUrl: '/',
};

export default function projectUrlReducer(state = initialState, action) {
    switch (action.type) {
        case SET_URLS: {
            return {
                ...state,
                baseUrl: action.payload.baseUrl,
                staticUrl: action.payload.staticUrl,
            };
        }
        default: {
            return state;
        }
    }
}
