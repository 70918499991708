import React from 'react';

import RightNav from '../components/rightNav';

import notesRoutes from '../../notes/routes/notes.routes';

const SubscriptionPage = React.lazy(() => import('../../recurringPayments/components/subscriptionPage'));
const RecurringSchedulePage = React.lazy(() => import( '../../recurringPayments/components/recurringSchedulePage'));
const StartPage = React.lazy(() => import( '../../recurringPayments/components/startPage'));
const EndPage = React.lazy(() => import( '../../recurringPayments/components/endPage'));
const ChangePaymentMethodPage = React.lazy(() => import( '../../recurringPayments/components/changePaymentMethodPage'));
const BillPage = React.lazy(() => import( '../../recurringPayments/components/billPage'));
const CustomerPage = React.lazy(() => import( '../../recurringPayments/components/customerPage'));

/**
 * Please come see me <Julian> when you come accross this
 * file for the first time for some direction.
 *
 * AKA do as I say not as I do, this was just to get it working.
 *
 * @todo: Move wrapWithRightNav to a separate utility
 */

/**
 * Wrap a component with the right nav
 *
 * We want this guy to be able to show the the right nav, and still be able to
 * route things without having any redering weirdness. Anytime you want to
 * route something in the right nav use this sucker!
 *
 * Any route that is expected to be used in the right nav should use the
 * wrap utility when exporting that component. That way the routing file stays
 * clean.
 *
 * @example
 * class MyCompoment extends React.Compoment {
 *     // your class implementation here
 * }
 *
 * // wrap it up! :P
 * export default wrapWithRightNav(MyComponent);
 *
 * @param {Element} Component - A react component, with or without props
 * @returns {Element} A new component that has the same props, but with the
 *  right nav
 */
function wrapWithRightNav(Component) {
    return class extends React.Component {
        render() {
            return (
                <RightNav>
                    <Component {...this.props} />
                </RightNav>
            );
        }
    };
}

/** Wrapped component definitions */
const WrappedBillPage = wrapWithRightNav(BillPage);
const WrappedSubscriptionPage = wrapWithRightNav(SubscriptionPage);
const WrappedChangePaymentMethodPage = wrapWithRightNav(ChangePaymentMethodPage);
const WrappedRecurringSchedulePage = wrapWithRightNav(RecurringSchedulePage);
const WrappedStartPage = wrapWithRightNav(StartPage);
const WrappedEndPage = wrapWithRightNav(EndPage);
const WrappedCustomerPage = wrapWithRightNav(CustomerPage);

/** the actual routes! */
const routes = [
    {
        path: '/recurring-payments',
        exact: false,
        displayName: 'Plans',
        render: () => null,
    },
    {
        path: '/accounts/:id/subscriptions/create',
        exact: true,
        displayName: 'Subscription Create',
        render: (props) => <WrappedSubscriptionPage {...props} />,
    },
    {
        path: '/accounts/:id/subscriptions/create/new-payment-method',
        exact: true,
        displayName: 'Change Payment Method',
        render: (props) => <WrappedChangePaymentMethodPage newPaymentMethod {...props} />,
    },
    {
        path: '/accounts/:id/subscriptions/create/change-payment-method',
        exact: true,
        displayName: 'Change Payment Method',
        render: (props) => <WrappedChangePaymentMethodPage {...props} />,
    },
    {
        path: '/accounts/:id/subscriptions/create/recurring-schedule',
        exact: true,
        displayName: 'Recurring Schedule',
        render: (props) => <WrappedRecurringSchedulePage {...props} />,
    },
    {
        path: '/accounts/:id/subscriptions/create/recurring-schedule/start',
        exact: true,
        displayName: 'Start',
        render: (props) => <WrappedStartPage {...props} />,
    },
    {
        path: '/accounts/:id/subscriptions/create/recurring-schedule/end',
        exact: true,
        displayName: 'End',
        render: (props) => <WrappedEndPage {...props} />,
    },
    {
        path: '/accounts/:id/subscriptions/create/bill',
        displayName: 'Bill To',
        render: (props) => <WrappedBillPage {...props} />,
    },
    {
        path: '/accounts/:id/subscriptions/:subscriptionId',
        exact: true,
        displayName: 'Subscription Details',
        render: (props) => <WrappedSubscriptionPage isDetails {...props} />,
    },
    {
        path: '/accounts/:id/subscriptions/:subscriptionId/update-customer',
        exact: true,
        displayName: 'Subscription Edit Details',
        render: (props) => <WrappedCustomerPage updateForm accountsSubscription {...props} />,
    },
    {
        path: '/accounts/:id/subscriptions/:subscriptionId/change-payment-method',
        exact: true,
        displayName: 'Subscription Change Payment Method',
        render: (props) => <WrappedChangePaymentMethodPage isDetails {...props} />,
    },
    {
        path: '/accounts/:id/subscriptions/:subscriptionId/recurring-schedule',
        exact: true,
        displayName: 'Recurring Schedule',
        render: (props) => <WrappedRecurringSchedulePage isDetails {...props} />,
    },
    {
        path: '/accounts/:id/subscriptions/:subscriptionId/recurring-schedule/start',
        exact: true,
        displayName: 'Start',
        render: (props) => <WrappedStartPage isDetails {...props} />,
    },
    {
        path: '/accounts/:id/subscriptions/:subscriptionId/recurring-schedule/end',
        exact: true,
        displayName: 'End',
        render: (props) => <WrappedEndPage isDetails {...props} />,
    },
    ...notesRoutes,
    // NOTE: we cannot use this here because otherwise we would say that any
    // route defined after this (which we do) is undefined and gets treated as
    // a 404, even though that is not the case. This has to be the LAST thing
    // defined
    //
    // {
    //     path: undefined,
    //     exact: false,
    //     displayName: '404',
    //     render: () => <ErrorState errorNumber={404} />,
    // },
];

export default routes;
