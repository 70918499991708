import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PaymentScheduleProps, { mapStateToProps, mapDispatchToProps } from './paymentSchedule.types';

class PaymentSchedule extends React.Component {
    static propTypes = PaymentScheduleProps;

    /** navigation helpers */
    navigateToInvoices = () => {
        this.props.history.goBack();
    }

    navigateToDueDatesPage = () => {
        this.props.history.push(`${this.props.location.pathname}/dueDates`);
    }

    navigateToBalanceDuePage = (isMobile = false) => {
        if (isMobile) {
            this.props.history.replace(
                `${this.props.location.pathname}/balanceDue`,
                { baseUrl: this.props.location.pathname },
            );
            this.props.openRightNav();
        } else {
            this.props.openAndNavigateRightNav(
                `${this.props.location.pathname}/dueDates/balanceDue`,
                { baseUrl: this.props.location.pathname },
            );
        }
    }

    navigateToRequestDepositPage = (isMobile = false) => {
        if (isMobile) {
            this.props.history.replace(
                `${this.props.location.pathname}/deposit`,
                { baseUrl: this.props.location.pathname },
            );
            this.props.openRightNav();
        } else {
            this.props.openAndNavigateRightNav(
                `${this.props.location.pathname}/dueDates/deposit`,
                { baseUrl: this.props.location.pathname },
            );
        }
    }

    /** props to expose */
    getPropsForRender = () => {
        return {
            ...this.props,
            navigateToBalanceDuePage: this.navigateToBalanceDuePage,
            navigateToRequestDepositPage: this.navigateToRequestDepositPage,
            navigateToDueDatesPage: this.navigateToDueDatesPage,
            navigateToInvoices: this.navigateToInvoices,
        };
    }

    render() {
        return (
            <React.Fragment>
                {this.props.render(this.getPropsForRender())}
            </React.Fragment>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentSchedule));
