import React from 'react';
import RightNav from './rightNav.component';

/**
 * Wrap a component with the right nav
 *
 * We want this guy to be able to show the the right nav, and still be able to
 * route things without having any redering weirdness. Anytime you want to
 * route something in the right nav use this sucker!
 *
 * Any route that is expected to be used in the right nav should use the
 * wrap utility when exporting that component. That way the routing file stays
 * clean.
 *
 * @example
 * class MyCompoment extends React.Compoment {
 *     // your class implementation here
 * }
 *
 * // wrap it up! :P
 * export default wrapWithRightNav(MyComponent);
 *
 * @param {Element} Component - A react component, with or without props
 * @returns {Element} A new component that has the same props, but with the
 *  right nav
 */
function wrapWithRightNav(Component) {
    return class extends React.Component {
        render() {
            return (
                <RightNav>
                    <Component {...this.props} />
                </RightNav>
            );
        }
    };
}

export default wrapWithRightNav;
