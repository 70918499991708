import React from 'react';

import InvoicePageRoutes from '../../invoicePage/routes';
import invoiceSettingsRoutes from '../../invoiceSettings/routes';
import InvoiceViewRoutes from '../components/invoiceView/routes';

const Invoicing = React.lazy(() => import('../components/invoicing'));

const routes = [
    {
        path: '/invoices',
        exact: true,
        displayName: 'Invoice Main',
        render: props => <Invoicing {...props}/>,
    },
    ...invoiceSettingsRoutes,
    ...InvoicePageRoutes,
    ...InvoiceViewRoutes,
];

export default routes;