const constants = {
    ALLOWED_COUNTRIES: [
        { 'code': 'US', 'name': 'United States' },
        { 'code': 'CA', 'name': 'Canada' },
    ],
    CARD_TYPES: {
        visa: 'visa',
        mastercard: 'mastercard',
        discover: 'discover',
        'american express': 'amex',
        amex: 'amex',
        maestro: 'maestro',
        jcb: 'jcb',
        'diners club': 'diners club',
        "diner's club": 'diners club',
        atm: 'atm',
        ebt: 'ebt',
        other: 'unidentified',
    },
    CHARACTERS: {
        triangleUp: '\u25B2',
        triangleDown: '\u25BC',
    },
    TIMING: {
        inputDelay: 333,
        resizeDelay: 250,
    },
};
export const NON_CC_TENDER_TYPES = ['cash', 'check', 'other'];

export const TOAST_CONTAINER_ID = "vt-legacy-web-toast-container";
export default constants;
