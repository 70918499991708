import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import {
    Input,
    Button,
    Icon,
    showToast,
    Text,
    LoadingIndicator,
} from 'spoton-lib-v2';

import isEmpty from 'lodash.isempty';
import omit from 'lodash.omit';
import get from 'lodash.get';
import { formatDollars } from '../../../../utils/utils';
import { ARCHIVED, VOIDED } from '../../../invoicing/api/invoices.constants';
import { TOAST_CONTAINER_ID } from 'legacy/terminal/utils/constants';

import CustomerinvoiceConfirmation from '../customerinvoiceConfirmation';
import CustomerFacingHeader from '../customerInvoiceHeader';
import CustomerInvoiceFooter from '../customerInvoiceFooter';
import Shippingform from '../shippingForm';
// import CustomerPaymentForm from '../customerPaymentForm';
import customerFacingPageProps, { mapStateToProps, mapDispatchToProps } from './customerInvoice.types';
import styles from './customerInvoice.module.scss';
import { getPaymentAmount } from '../../utils/getPaymentAmount';
import {isValidUSorCanadaPostalCode} from "../../../common/components/paymentForm/paymentForm.utils";

const ACCEPTED_CARDS = ['Visa', 'Mastercard', 'Amex', 'Discover', 'JCB', 'Dinners'];
const COLLECT_JS_SCRIPT_ID = 'collectjs';
const CREDIT_CARD = 'Credit Card';
const GOOGLE_PAY = 'Google Pay';
const APPLE_PAY = 'Apple Pay';
const CC_NUMBER = 'ccnumber';
const CVV = 'cvv';
const CC_EXPIRY = 'ccexp';

class CustomerFacing extends Component {
    static propTypes = customerFacingPageProps;

    constructor(props) {
        super(props);

        this.state = {
            isCollectJsLoading: true,
            collectJsError: null,
            collectJsHasLoaded: false,
            merchantId: '',
            name: '',
            zipcode: '',
            shipping: {
                firstName: '',
                lastName: '',
                address_1: '',
                address_2: '',
                province: '',
                city: '',
                zipCode: '',
                country: 'US',
            },
            isFocused: false,
        };

        this.ccNumberWrapperRef = React.createRef();
        this.ccExpWrapperRef = React.createRef();
        this.cvvWrapperRef = React.createRef();
        // https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/checking_for_apple_pay_availability
        this.isApplePaySupported = window.ApplePaySession !== undefined;
        this.hasSentGtmEventOnSuccess = false;
    }

    componentDidMount = () => {
        const { pathname } = window.location;
        const [merchantId, uuid] = pathname.split('/').slice(-2);
        this.setState({ merchantId, uuid, isFocused: false });
        // get invoice, tokenizationKey and merchant information
        this.props.getCustomerInvoice(merchantId, uuid).catch(error => {
            showToast({
                title: 'Oh no',
                content: get(error, 'response.data.detail', 'Something went wrong'),
                autoClose: 4000,
                variant: 'danger',
                containerId: TOAST_CONTAINER_ID,
            });
        });
        this.props.setUrls({
            baseUrl: this.props.baseUrl,
            staticUrl: this.props.staticUrl,
        });
    }

    componentDidUpdate = (prevProps) => {
        const { tokenizationKey, submittedPayment, isSubmittingLoading } = this.props;

        // Load collectJS if the tokenizationKey is set
        if (
            prevProps.tokenizationKey !== tokenizationKey
            && Boolean(tokenizationKey)
            && !this.state.collectJsHasLoaded
        ) {
            this.loadCollectJSScript(tokenizationKey);
        }

        // If there's a payment token and it is not submitting, register payment for invoice
        if (
            prevProps.paymentToken !== this.props.paymentToken
            && (
                this.props.tokenType === GOOGLE_PAY || this.props.tokenType === APPLE_PAY || (
                    (prevProps.newPaymentIsSubmitting !== this.props.newPaymentIsSubmitting)
                    && (this.props.newPaymentIsSubmitting === false)
                    && this.props.tokenType === CREDIT_CARD
                )
            )
        ) {
            this.performPayment();
        }

        if (!isSubmittingLoading && !isEmpty(submittedPayment)) {
            // The component updates two times after the success so we need a guard to only send the success event once
            if (this.hasSentGtmEventOnSuccess) {
                return;
            }

            const { invoice, merchant, tokenType } = this.props;

            let paymentMethod = 'CREDITCARD';

            if (tokenType === GOOGLE_PAY) {
                paymentMethod = 'GOOGLEPAY';
            }

            if (tokenType === APPLE_PAY) {
                paymentMethod = 'APPLEPAY';
            }

            dataLayer.push({
                event: 'purchase',
                ecommerce: {
                    transaction_id: invoice.id,
                    merchant_id: invoice.merchantId,
                    merchant_name: merchant.name,
                    payment_method: paymentMethod,
                    // static for VT
                    payment_location: 'virtualterminal_invoice',
                    value: formatDollars(invoice.totalAmount),
                    tax: formatDollars(invoice.taxAmount),
                    convenience_fee: formatDollars(invoice.convenienceFeeAmount),
                    currency: 'USD',
                    items: invoice.lineItems.map(item => ({
                        item_name: item.name,
                        item_id: item.sku,
                        price: item.totalAmount,
                        quantity: item.quantity,
                    })),
                },
            });

            this.hasSentGtmEventOnSuccess = true;
        }
    }

    componentWillUnmount = () => {
        this.props.reset();
        const script = document.getElementById(COLLECT_JS_SCRIPT_ID);
        script.removeEventListener('load', this.onScriptLoad);
        script.removeEventListener('error', this.onScriptError);
    }

    performPayment = () => {
        const {
            invoice,
            paymentToken,
            tokenType,
        } = this.props;

        const {
            name,
            shipping,
            merchantId,
            uuid,
            zipcode
        } = this.state;

        const payload = {
            paymentToken,
            invoiceId: invoice.id,
            cardholderName: tokenType === CREDIT_CARD ? name : null,
            shippingAddress: {
                ...invoice.requestShippingAddress && tokenType === CREDIT_CARD ? shipping : {},
            },
            postalCode: zipcode,
        };

        this.props.registerPayment(merchantId, payload, uuid, this.showError);
    }

    showError = () => {
        showToast({
            title: 'Oh no',
            content: 'Payment was unable to process please double check the information is correct and please try again.',
            autoClose: 4000,
            variant: 'danger',
            containerId: TOAST_CONTAINER_ID,
        });
    };

    showInput = (ref) => {
        const { current } = ref;
        if (current) {
            current.classList.add(styles.Input___focus);
            this.setState({ isFocused: true });
        }
    }

    loadCollectJSScript = (nmiTokenizationKey) => {
        const { invoice } = this.props;
        const isDeposit = !invoice.depositPaid && invoice.depositRequired;
        const balanceAmount = parseFloat(invoice.totalAmount) - parseFloat(invoice.depositAmount);

        let script = document.createElement('script');

        script.src = 'https://secure.networkmerchants.com/token/Collect.js';
        script.id = COLLECT_JS_SCRIPT_ID;
        script.setAttribute('data-variant', 'inline');
        script.setAttribute('data-tokenization-key', nmiTokenizationKey);
        script.setAttribute('data-country', 'US');
        script.setAttribute('data-price', isDeposit ? invoice.depositAmount : balanceAmount);
        script.setAttribute('data-currency', 'USD');
        script.setAttribute('data-field-google-pay-billing-address-required', 'true');
        script.setAttribute('data-field-google-pay-shipping-address-required', invoice.requestShippingAddress.toString());

        if (this.isApplePaySupported) {
            const requiredShippingContactFields = invoice.requestShippingAddress ? '["postalAddress","name"]' : '[]';

            script.setAttribute('data-field-apple-pay-required-billing-contact-fields', '["postalAddress"]');
            script.setAttribute('data-field-apple-pay-required-shipping-contact-fields', requiredShippingContactFields);
            script.setAttribute('data-field-apple-pay-type', 'plain');
        }

        script.addEventListener('load', this.onScriptLoad);
        script.addEventListener('error', this.onScriptError);
        document.body.appendChild(script);
    }

    onScriptLoad = () => {
        this.setState({ isCollectJsLoading: false, collectJsHasLoaded: true }, () => {
            this.props.loadedCollectJS();
        });
        window.CollectJS.configure({
            variant: 'inline',
            styleSniffer: 'false',
            paymentSelector: 'customPayButton',
            fields: {
                ccnumber: {
                    selector: '#collectjs-ccnumber',
                    title: 'Card Number',
                    placeholder: '0000 0000 0000 0000',
                },
                ccexp: {
                    selector: '#collectjs-ccexp',
                    title: 'Card Expiration',
                    placeholder: 'MM / YY',
                },
                cvv: {
                    display: 'required',
                    selector: '#collectjs-cvv',
                    title: 'CVV Code',
                    placeholder: '***',
                },
            },
            googleFont: 'Poppins:400',
            customCss: {
                'border-color': '#77819C',
                color: '#08031d',
                'border-style': 'solid',
                'border-width': '1px',
                'border-radius': '4px',
                'font-size': '.875rem',
                height: '44px',
                'min-height': '44px',
                'line-height': '1.5',
                padding: '0 8px',
                'font-family': 'Poppins',
                'outline-style': 'none',
            },
            validCss: {
                'border-color': '#77819C',
                color: '#08031d',
                'border-style': 'solid',
                'border-width': '1px',
                'font-size': '0.875rem',
                height: '44px',
                'min-height': '44px',
                'line-height': '1.5',
                'outline-style': 'none',
                padding: '0 8px',
            },
            invalidCss: {
                'border-color': '#E80134',
                color: '#08031d',
                'border-style': 'solid',
                'border-width': '1px',
                'font-size': '0.875rem',
                height: '44px',
                'min-height': '44px',
                'line-height': '1.5',
                'outline-style': 'none',
                padding: '0 8px',
            },
            focusCss: {
                'border-color': '#1769FF',
                color: '#08031d',
                'border-style': 'solid',
                'border-width': '2px',
                'font-size': '0.875rem',
                height: '44px',
                'min-height': '44px',
                'line-height': '1.5',
                padding: '0 8px',
                'outline-style': 'none',
                'box-shadow': 'none',
            },
            placeholderCss: {
                'font-family': 'Poppins, sans-serif',
                'font-size': '0.85rem',
                color: '#C0C3C3',
                'text-indent': '4px',
            },
            callback: this.collectCallback,
            validationCallback: this.validationCallback,
        });
    }

    // What to do when CollectJS completes a payment submission
    //  param {Object} response - the collect.js callback response object
    collectCallback = (response) => {
        const {
            setCCNumber,
            setExpirationDate,
            setCardBin,
            setCardHash,
            setTokenType,
            submittedPaymentInfo,
        } = this.props;

        switch (response.tokenType) {
            case 'googlePay':
                setTokenType(GOOGLE_PAY);
                submittedPaymentInfo(response.token);
                break;
            case 'applePay':
                setTokenType(APPLE_PAY);
                submittedPaymentInfo(response.token);
                break;
            default:
                setTokenType(CREDIT_CARD);
                submittedPaymentInfo(response.token);
                setCCNumber(response.card.number);
                setExpirationDate(response.card.exp);
                setCardBin(response.card.bin);
                setCardHash(response.card.hash);
        }
    }

    /**
     * Handle any validation messages
     *
     * @param {string} fieldName - the name of the collectjs field validated,
     *  one of 'ccnumber', 'cvv', 'ccexp'
     * @param {boolean} valid - is the field valid or not
     * @param {string} message - the error message
     */
    validationCallback = (
        fieldName,
        valid,
        message,
    ) => {
        const {
            validCCNumber,
            validCVVNumber,
            validExpirationDate,
            invalidCCNumber,
            invalidCVVNumber,
            invalidExpirationDate,
        } = this.props;

        if (valid) {
            switch (fieldName) {
                case CC_NUMBER: {
                    this.setValidLabel(this.ccNumberWrapperRef);
                    validCCNumber();
                    break;
                }
                case CVV: {
                    this.setValidLabel(this.cvvWrapperRef);
                    validCVVNumber();
                    break;
                }
                case CC_EXPIRY: {
                    this.setValidLabel(this.ccExpWrapperRef);
                    validExpirationDate();
                    break;
                }
                default: {
                    console.warn('[PaymentsForm] Unknown validation field');
                }
            }
        } else {
            switch (fieldName) {
                case CC_NUMBER: {
                    this.setErrorLabel(this.ccNumberWrapperRef);
                    invalidCCNumber(message);
                    break;
                }
                case CVV: {
                    this.setErrorLabel(this.cvvWrapperRef);
                    invalidCVVNumber(message);
                    break;
                }
                case CC_EXPIRY: {
                    this.setErrorLabel(this.ccExpWrapperRef);
                    invalidExpirationDate(message);
                    break;
                }
                default: {
                    console.warn('[PaymentsForm] Unknown validation field');
                }
            }
        }
    }

    /**
     * Add a valid class to the collect.js input field wrappers
     *
     * @param {RefObject<HTMLDivElement>} ref
     */
    setValidLabel = (ref) => {
        const { current } = ref;

        if (current) {
            current.classList.remove(styles.Input___error);
            current.classList.add(styles.Input___valid);
        }
    }

    /**
     * Add an error class to the collect.js input field wrappers
     *
     * @param {RefObject<HTMLDivElement>} ref
     */
    setErrorLabel = (ref) => {
        const { current } = ref;

        if (current) {
            current.classList.remove(styles.Input___valid);
            current.classList.add(styles.Input___error);
        }
    }

    /**
     * Render input field error messages
     *
     * @param {string} error
     * @returns {Element|null} Returns an error message if one exists or
     *  returns null
     */
    renderError = (error) => {
        if (error !== '') {
            return <span className={styles.Error}>{error}</span>;
        }

        return null;
    }

    shouldDisable = (requestShippingAddress) => {
        const paymentValid = (
            this.props.newPaymentIsValid
            && Boolean(this.state.name) && this.state.name !== ''
            && Boolean(this.state.zipcode) && isValidUSorCanadaPostalCode(this.state.zipcode)
        );

        if (requestShippingAddress) {
            const requiredValues = omit(this.state.shipping, ['zipCode', 'address_2', 'undefined']);
            return (
                !paymentValid ||
                !Object.values(requiredValues).every(val => !isEmpty(val))
            );
        }

        // disable if the payment information is not valid
        return !paymentValid;
    }

    onScriptError = (error) => {
        // console.log('collectJsError', error);
        this.setState({ collectJsError: 'Something went Wrong, please refresh your browser' });
    }

    onChange = (e) => {
        const target = e.target;
        const { value, name } = target;
        this.setState((prevState) => ({
            ...prevState,
            shipping: {
                ...prevState.shipping,
                [name]: value,
            },
        }));
    }

    handleChangeCountry = (country) => {
        this.setState((prevState) => ({
            ...prevState,
            shipping: {
                ...prevState.shipping,
                country,
            },
        }));
    }

    handleChangeProvince = (province) => {
        this.setState((prevState) => ({
            ...prevState,
            shipping: {
                ...prevState.shipping,
                province,
            },
        }));
    }

    onSubmit = () => {
        const { invoice } = this.props;

        dataLayer.push({
            event: 'begin_checkout',
            ecommerce: {
                items: [{
                    item_name: `Invoice #${invoice.id}`,
                    item_id: invoice.id,
                    price: formatDollars(invoice.totalAmount),
                    quantity: 1,
                    // Payments submitted using "Pay" button support only credit cards
                    attempted_payment_method: 'CREDIT CARD',
                }],
            },
        });

        this.props.submitPaymentForm();
    }

    render() {
        const {
            invoice,
            isLoadingPaymentLink,
            account,
            isSubmittingLoading,
            submittedPayment,
            newPaymentIsSubmitting,
            merchant,
            ccNumberError,
            ccExpError,
            cvvError,
            invoiceSettings,
        } = this.props;

        const {
            isCollectJsLoading,
            collectJsHasLoaded,
            collectJsError,
            name,
            zipcode,
        } = this.state;

        if (isLoadingPaymentLink || isCollectJsLoading) {
            return (
                <div className={styles.CustomerFacing_loadingState}>
                    <LoadingIndicator size="lg" />
                </div>
            );
        }
        const isDeposit = !invoice.depositPaid && invoice.depositRequired;
        const isPaid = parseFloat(invoice.paidAmount) === parseFloat(invoice.totalAmount) && isEmpty(submittedPayment);
        const isPaymentApproved = (!isEmpty(submittedPayment) && submittedPayment.transactionResponse === 'APPROVED');
        const balanceAmount = parseFloat(invoice.totalAmount) - parseFloat(invoice.depositAmount);
        const shouldDisable = this.shouldDisable(invoice.requestShippingAddress);
        const canShowPaymentForm = (
            !isCollectJsLoading
            && isEmpty(collectJsError)
            && !(isPaid || isPaymentApproved)
            && invoice.status !== VOIDED
            && invoice.status !== ARCHIVED
        );

        return (
            <div className={styles.CustomerFacing}>
                <CustomerFacingHeader
                    invoice={invoice}
                    invoiceSettings={invoiceSettings}
                    merchant={merchant}
                    isPaid={isPaid}
                    isPaymentApproved={isPaymentApproved}
                />
                {
                    !isEmpty(submittedPayment) && (
                        <CustomerinvoiceConfirmation
                            accountFirstName={account.firstName}
                        />
                    )
                }

                {canShowPaymentForm && (
                    <React.Fragment>
                        <div className={styles.ExternalPaymentSection}>
                            <div id="googlepaybutton" className={styles.ExternalPaymentSection_btn} />
                            {this.isApplePaySupported && <div id="applepaybutton" className={styles.ExternalPaymentSection_btn} />}
                            <Text type="sub2">
                                or pay by a credit card
                            </Text>
                        </div>
                        <div className={styles.PaymentSection}>
                            {/*
                            The CustomerPaymentForm is the ideal component to handle everything CollectJS but in this case because
                            we are adding google pay and apple pay to the form and with that adding some extra configuration settings, that results
                            in some weird NMI error messages. My guess is that it has something to do with having two instances of the same form.
                            As a workaround, this component implements the form with gpay and apay instead. In the future we should have the form
                            in a separate component, preferably from the spoton-lib.

                            <CustomerPaymentForm
                                ccNumberInputStyles={`${styles.FullWidth} ${styles.InlineBlock} ${styles.Input___ccNumber}`}
                                ccExpiryInputStyles={`${styles.ThirdWidth} ${styles.InlineBlock} ${styles.ThirdWidth___marginLeft} ${styles.Input___ccExpiry}`}
                                cvvInputStyles={`${styles.ThirdWidth} ${styles.InlineBlock} ${styles.Input___cvv}`}
                                postalCodeInputStyles={`${styles.ThirdWidth} ${styles.ThirdWidth___marginLeft} ${styles.InlineBlock} ${styles.Input___postalCode}`}
                                postalCodeLabel="Billing Zip Code"
                                expirationDateLabel="Exp. Date*"
                                isFormValid={this.handleIfPaymentFormIsValid}
                            />
                            */}

                            <form>
                                <div className={styles.FullWidth}>
                                    <Input
                                        name="name"
                                        label="Cardholder’s Name*"
                                        value={name}
                                        onChange={(e) => this.setState({ name: e.target.value })}
                                    />
                                </div>

                                <div
                                    ref={this.ccNumberWrapperRef}
                                    className={`${styles.FullWidth} ${styles.InlineBlock} ${styles.Input___ccNumber}`}
                                >
                                    <label
                                        htmlFor="collectjs-ccnumber"
                                        onClick={() => this.showInput(this.ccNumberWrapperRef)}
                                    >
                                        Card Number *
                                    </label>
                                    <div id="collectjs-ccnumber"></div>
                                    {this.renderError(ccNumberError)}
                                </div>

                                <div className={styles.PaymentSection_cardInfo}>
                                    <div
                                        ref={this.cvvWrapperRef}
                                        className={`${styles.ThirdWidth} ${styles.InlineBlock} ${styles.Input___cvv}`}
                                    >
                                        <label
                                            htmlFor="collectjs-cvv"
                                            onClick={() => this.showInput(this.cvvWrapperRef)}
                                        >
                                            CVV *
                                        </label>
                                        <div id="collectjs-cvv"></div>
                                        {this.renderError(cvvError)}
                                    </div>

                                    <div
                                        ref={this.ccExpWrapperRef}
                                        className={`${styles.ThirdWidth} ${styles.InlineBlock} ${styles.ThirdWidth___marginLeft} ${styles.Input___ccExpiry}`}
                                    >
                                        <label
                                            htmlFor="collectjs-ccexp"
                                            onClick={() => this.showInput(this.ccExpWrapperRef)}
                                        >
                                            Exp. Date *
                                        </label>
                                        <div id="collectjs-ccexp"></div>
                                        {this.renderError(ccExpError)}
                                    </div>

                                    <div className={`${styles.ThirdWidth} ${styles.InlineBlock} ${styles.ThirdWidth___marginLeft} ${styles.Input___postalCode}`}>
                                        <Input
                                            name="zipcode"
                                            label="Billing Zip Code *"
                                            required
                                            value={zipcode}
                                            onChange={(e) => this.setState({ zipcode: e.target.value })}
                                        />
                                    </div>
                                </div>

                                {invoice.requestShippingAddress && (
                                    <Fragment>
                                        <p className={styles.PaymentSection_title}>Shipping Info</p>
                                        <Shippingform
                                            onChange={this.onChange}
                                            shipping={this.state.shipping}
                                            handleChangeCountry={this.handleChangeCountry}
                                            handleChangeProvince={this.handleChangeProvince}
                                        />
                                    </Fragment>
                                )}

                                <Button
                                    id="customPayButton"
                                    className={styles.SubmitButton}
                                    variant="primary"
                                    onClick={this.onSubmit}
                                    disabled={shouldDisable || newPaymentIsSubmitting || isSubmittingLoading}
                                    block
                                >
                                    {
                                        newPaymentIsSubmitting || isSubmittingLoading
                                            ? 'Submitting payment...'
                                            : `Pay ${formatDollars(getPaymentAmount(invoice))}`
                                    }
                                </Button>
                            </form>
                        </div>
                    </React.Fragment>
                )}
                {
                    collectJsHasLoaded && !(isPaid) && !(isPaymentApproved) && (
                        <div className={styles.PaymentCaption}>
                            <div className={styles.PaymentCaption_acceptedCards}>
                                {
                                    ACCEPTED_CARDS.map(cardName => (
                                        <Icon key={cardName} name={`Pm${cardName}Icon`} alt={`${cardName} card logo`} size={34} />
                                    ))
                                }
                            </div>
                            <p className={styles.PaymentCaption_text}>
                                Having trouble paying?
                            </p>
                            <p className={styles.PaymentCaption_contact}>
                                Call &nbsp;
                                <a
                                    href="tel:+8778144102"
                                >
                                    (877)814-4102
                                </a>
                                &nbsp; or Email
                                <br />
                                <a
                                    href={`mailto:support@spoton.com?subject=Customer Complaint: Problems with paying an invoice. Merchant ID: ${invoice.merchantId}`}
                                >
                                    SpotOn Support
                                </a>
                            </p>

                        </div>
                    )
                }
                {
                    !isEmpty(merchant) && (
                        <CustomerInvoiceFooter
                            merchant={merchant}
                        />
                    )
                }
            </div>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CustomerFacing);
