import moment from 'moment';

export function transactionStatusLabel(status) {
    return status.toLowerCase();
}

export function mapQueryToState(parsedQuery) {
    const {
        q, dateEnd, dateStart, orderBy = '', order = '', status = [], page,
    } = parsedQuery;

    const statusAsList = Array.isArray(status) ? status : [status];
    return {
        searchString: q,
        dateStart: moment(dateStart),
        dateEnd: moment(dateEnd),
        sortBy: orderBy,
        sortOrder: order,
        statusFilters: statusAsList.reduce((list, statusValue) => ({ ...list, [statusValue]: true }), {}),
        currentPage: page,
    };
}

export function mapStateToQuery(state) {
    const {
        searchString, dateEnd, dateStart, sortBy, sortOrder, statusFilters, currentPage,
    } = state;
    const formattedDateStart = moment(dateStart).format('YYYY-MM-DD');
    const formattedDateEnd = moment(dateEnd).format('YYYY-MM-DD');

    return {
        q: searchString,
        dateStart: formattedDateStart,
        dateEnd: formattedDateEnd,
        orderBy: sortBy,
        order: sortOrder,
        status: Object.keys(statusFilters),
        page: currentPage,
    };
}
