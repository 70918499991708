import React from 'react';
import PropTypes from 'prop-types';

export const InvoiceVoidIcon = ({ className }) => {
    return (
        <svg width="157" height="123" viewBox="0 0 157 123" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M75.4199 122.227L155.864 99.1368C156.347 98.9981 156.347 98.3135 155.864 98.1754L93.9756 80.4932C93.0759 80.2361 92.122 80.2367 91.2225 80.4948L10.7782 103.585C10.2951 103.724 10.2956 104.409 10.7788 104.547L72.6668 122.229C73.5665 122.486 74.5204 122.485 75.4199 122.227Z" fill="#D6D9E1" />
            <path d="M132.802 87.0574C132.802 88.7654 128.302 90.1499 122.751 90.1499C117.2 90.1499 112.7 88.7654 112.7 87.0574C112.7 85.3494 117.2 83.9648 122.751 83.9648C128.302 83.9648 132.802 85.3494 132.802 87.0574Z" fill="#D6D9E1" />
            <path d="M139.517 90.6293L111.707 82.5789C111.346 82.4744 110.962 82.4737 110.601 82.577L100.33 85.5115L126.617 94.7891L139.514 91.2066C139.805 91.1256 139.808 90.7134 139.517 90.6293Z" fill="#D6D9E1" />
            <path d="M56.8178 10.1444L62.3652 11.8087C63.6733 12.2011 64.5691 13.4051 64.5691 14.7708L65.4578 22.5673C65.4578 23.6031 64.4593 24.346 63.4672 24.0484L37.8381 15.6078C36.53 15.2154 33.6436 14.6271 33.6436 13.2614L31.8988 5.61453C31.8988 4.57874 34.6421 3.49172 35.6342 3.78935L41.7181 5.61453C42.6611 1.84193 45.5878 -1.04746 50.9967 0.548202C55.3749 1.8398 56.6504 6.60198 56.8178 10.1444Z" fill="#BBC0CE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M44.7373 1.15241C43.2389 2.12911 42.2825 3.7692 41.8152 5.63914C41.8085 5.66565 41.7913 5.6883 41.7676 5.70181C41.7438 5.71533 41.7156 5.71853 41.6894 5.71068L35.6055 3.8855C35.3808 3.81809 35.0425 3.82661 34.6509 3.90188C34.2625 3.97652 33.834 4.11434 33.4354 4.29348C33.0364 4.47287 32.6721 4.69155 32.4094 4.92555C32.1478 5.15861 32.0036 5.39163 31.9989 5.60415L33.7411 13.2395C33.7428 13.2468 33.7436 13.2543 33.7436 13.2618C33.7436 13.5639 33.9022 13.8316 34.1807 14.074C34.4599 14.3169 34.8501 14.5258 35.2886 14.7076C35.9336 14.9749 36.6633 15.1762 37.2681 15.343C37.4874 15.4035 37.6903 15.4595 37.8668 15.5124L37.8694 15.5132L63.4972 23.9533C64.4229 24.2302 65.3542 23.5387 65.3578 22.5733L64.4685 14.7711C64.4685 13.4496 63.6023 12.2845 62.3365 11.9048L56.823 10.2508L54.9907 11.0042L54.9147 10.8192L56.7145 10.0792C56.625 8.33186 56.2639 6.30162 55.3966 4.54692C54.5187 2.77055 53.1244 1.28053 50.9684 0.644479C48.2853 -0.147044 46.2331 0.177378 44.7373 1.15241ZM56.9142 10.0693C56.8239 8.30535 56.4593 6.24564 55.5759 4.4583C54.6797 2.64507 53.2471 1.1082 51.025 0.452653C48.2991 -0.35149 46.1836 -0.0290468 44.6281 0.984856C43.1028 1.97905 42.1317 3.62796 41.647 5.48915L35.6629 3.69393C35.3916 3.61253 35.0149 3.62826 34.6131 3.70547C34.2081 3.78331 33.7648 3.92617 33.3535 4.11106C32.9427 4.2957 32.5591 4.52438 32.2764 4.77621C31.9971 5.02505 31.7988 5.3124 31.7988 5.61489C31.7988 5.62238 31.7996 5.62984 31.8013 5.63714L33.5437 13.2733C33.5477 13.6489 33.7489 13.9632 34.0494 14.2248C34.3523 14.4885 34.7654 14.7073 35.212 14.8923C35.8696 15.1649 36.6196 15.3718 37.2268 15.5392C37.442 15.5986 37.6392 15.653 37.8081 15.7036L63.4359 24.1437L63.4384 24.1445C64.4947 24.4614 65.5578 23.6705 65.5578 22.5677L64.6691 14.7654C64.6666 13.3579 63.7425 12.1178 62.3939 11.7132L56.9142 10.0693Z" fill="#08031D" />
            <path d="M54.9427 10.9179L60.4901 12.5821C61.7982 12.9745 62.6941 14.1785 62.6941 15.5442L63.5827 23.3407C63.5827 24.3765 62.5842 25.1194 61.5921 24.8218L35.963 16.3813C34.6549 15.9888 31.7686 15.4006 31.7686 14.0349V6.04385C31.7686 5.00806 32.767 4.26516 33.7591 4.56279L39.8431 6.38797C40.786 2.61537 43.7127 -0.274026 49.1216 1.32164C53.4998 2.61324 54.7753 7.37541 54.9427 10.9179Z" fill="#77819C" />
            <path fillRule="evenodd" clipRule="evenodd" d="M42.8622 1.92584C41.3638 2.90255 40.4075 4.54263 39.9401 6.41258C39.9335 6.43909 39.9163 6.46174 39.8925 6.47525C39.8688 6.48876 39.8405 6.49197 39.8143 6.48411L33.7304 4.65894C32.8025 4.38055 31.8685 5.07541 31.8685 6.04422V14.0352C31.8685 14.3374 32.0272 14.605 32.3056 14.8474C32.5848 15.0904 32.975 15.2993 33.4135 15.481C34.0586 15.7484 34.7882 15.9496 35.393 16.1164C35.6124 16.1769 35.8153 16.2329 35.9917 16.2859L35.9943 16.2866L61.6221 24.7268C62.5478 25.0036 63.4791 24.3122 63.4827 23.3467L62.5934 15.5446C62.5934 14.223 61.7272 13.058 60.4614 12.6782L54.914 11.014C54.8734 11.0018 54.8448 10.9653 54.8428 10.923C54.7595 9.15996 54.4005 7.09866 53.5215 5.32035C52.6436 3.54399 51.2493 2.05396 49.0933 1.41792C46.4103 0.626394 44.3581 0.950815 42.8622 1.92584ZM42.753 1.75829C44.3085 0.744391 46.4241 0.421947 49.1499 1.22609C51.3721 1.88164 52.8047 3.41851 53.7008 5.23174C54.5842 7.01908 54.9488 9.07879 55.0391 10.8427L60.5189 12.4867C61.8674 12.8912 62.7915 14.1314 62.794 15.5388L63.6833 23.3411C63.6833 24.4439 62.6196 25.2348 61.5634 24.918L61.5608 24.9172L35.933 16.477C35.7642 16.4264 35.5669 16.372 35.3517 16.3127C34.7446 16.1452 33.9945 15.9383 33.337 15.6658C32.8904 15.4807 32.4773 15.2619 32.1743 14.9982C31.8707 14.734 31.6685 14.4159 31.6685 14.0352V6.04422C31.6685 4.94144 32.7316 4.15049 33.7879 4.46737L39.7719 6.26259C40.2567 4.40139 41.2278 2.75248 42.753 1.75829Z" fill="#08031D" />
            <path d="M74.8119 23.4078L14.0823 6.05653C13.0946 5.7743 12.1113 6.51599 12.1113 7.54331V89.7521C12.1113 91.1328 13.0266 92.3463 14.3542 92.7256L75.0838 110.077C76.0715 110.359 77.0548 109.617 77.0548 108.59V26.3814C77.0548 25.0006 76.1395 23.7871 74.8119 23.4078Z" fill="#D2A672" />
            <path d="M77.0548 108.591V26.3818C77.0548 25.6084 76.7677 24.8875 76.2817 24.3362L79.2536 23.4082C79.5529 23.8875 79.7226 24.451 79.7226 25.0455V107.254C79.7226 108.237 79.4036 110.44 75.6164 110.134C75.4429 110.146 75.2634 110.129 75.0838 110.077C75.2685 110.102 75.4459 110.12 75.6164 110.134C76.3967 110.08 77.0548 109.431 77.0548 108.591Z" fill="#A27138" />
            <path d="M74.8119 23.4076L14.0823 6.05627C13.0946 5.77405 12.1113 6.51574 12.1113 7.54305C11.8979 6.08375 14.7473 4.53644 17.3754 4.70845C17.4739 4.7149 17.571 4.7338 17.6658 4.76123L77.4796 22.0714C78.2356 22.2873 78.8579 22.7738 79.2536 23.4076L76.2817 24.3356C75.9001 23.9027 75.3959 23.5744 74.8119 23.4076Z" fill="#EBCBA6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.6302 5.42641C14.66 4.87028 16.048 4.52175 17.3819 4.60906C17.4888 4.61606 17.5931 4.63649 17.6936 4.66557L77.5071 21.9756C78.8776 22.3672 79.8226 23.6199 79.8226 25.0453V107.254C79.8226 107.775 79.7349 108.655 79.0841 109.331C78.4872 109.952 77.4377 110.38 75.6162 110.234C75.4337 110.246 75.2451 110.227 75.0563 110.173L14.3267 92.8219C12.9562 92.4303 12.0112 91.1776 12.0112 89.7522V7.55055C11.9555 7.15188 12.1089 6.75704 12.3961 6.39822C12.6851 6.03715 13.1153 5.70451 13.6302 5.42641ZM13.1194 5.9868C13.425 5.88044 13.7671 5.86261 14.1098 5.96051L74.8393 23.3118C76.2099 23.7034 77.1548 24.9561 77.1548 26.3815V108.59C77.1548 109.248 76.7763 109.796 76.2458 110.063C77.6488 110.073 78.4647 109.687 78.94 109.193C79.536 108.573 79.6226 107.76 79.6226 107.254V25.0453C79.6226 23.7092 78.7368 22.535 77.4521 22.1679L77.4518 22.1678L17.638 4.85768C17.5488 4.83189 17.4589 4.81453 17.3688 4.80863C16.0747 4.72393 14.7239 5.06304 13.7253 5.60239C13.5037 5.72207 13.3004 5.85109 13.1194 5.9868ZM75.6162 110.034C76.3431 109.98 76.9548 109.374 76.9548 108.59V26.3815C76.9548 25.0454 76.0691 23.8712 74.7844 23.5041L14.0548 6.15282C13.1309 5.88884 12.2112 6.58257 12.2112 7.54344L12.2112 89.7522C12.2112 91.0883 13.097 92.2625 14.3817 92.6296L75.1041 109.979C75.2817 110.002 75.4523 110.02 75.6162 110.034Z" fill="#08031D" />
            <path d="M74.8119 94.7639C74.8119 103.39 74.8119 110.586 69.0707 112.015C68.2134 112.158 67.4116 112.116 66.6689 111.914L48.6 105.6L14.3704 83.6521L14.5296 77.5127L14.5296 77.5126L14.5296 9.95074L74.8119 27.0996L74.8119 94.7639Z" fill="white" />
            <path d="M6.83566 94.7639C2.12212 93.9175 -0.379989 86.8377 0.176613 79.5902L14.3704 83.6521L60.4589 96.8415C59.926 103.781 62.1976 110.568 66.5299 111.874L66.6689 111.914C66.6223 111.901 66.576 111.888 66.5299 111.874L6.83566 94.7639Z" fill="#E5E7EC" />
            <path fillRule="evenodd" clipRule="evenodd" d="M69.0504 111.917C70.4628 111.565 71.519 110.859 72.3117 109.867C73.1066 108.872 73.64 107.585 73.9969 106.065C74.7113 103.023 74.7119 99.0806 74.7119 94.7635L74.7119 27.1747L14.6296 10.0828L14.6296 77.5136C14.6296 79.2323 14.6296 81.3827 14.4759 83.5779L60.5649 96.7675L60.5586 96.8488C60.2902 100.344 60.736 103.796 61.7966 106.521C62.8359 109.19 64.4575 111.144 66.5583 111.778L66.6951 111.817C66.6953 111.817 66.6949 111.817 66.6951 111.817C67.4219 112.015 68.2083 112.057 69.0504 111.917ZM66.5015 111.97C66.5483 111.984 66.5954 111.997 66.6426 112.01C67.3999 112.216 68.2163 112.259 69.0872 112.113L69.0949 112.112C70.5511 111.749 71.6467 111.02 72.4679 109.992C73.2878 108.966 73.8308 107.647 74.1916 106.111C74.9119 103.044 74.9119 99.0787 74.9119 94.7741L74.9119 27.0237L14.4296 9.81794L14.4296 77.5122L14.2738 83.5201L0.0862886 79.46L0.0769045 79.5822C-0.202347 83.2184 0.285156 86.8163 1.43736 89.6152C2.58767 92.4094 4.41023 94.4273 6.81296 94.8611L66.5015 111.97ZM65.2154 111.393C63.6577 110.447 62.4431 108.733 61.6102 106.593C60.5451 103.857 60.0959 100.406 60.3531 96.9149L0.26722 79.7198C0.0100099 83.2892 0.497063 86.8057 1.6223 89.5391C2.76147 92.3063 4.545 94.2506 6.85332 94.6651L6.86335 94.6669L65.2154 111.393Z" fill="#08031D" />
            <path d="M66.6397 79.8609L22.3698 67.4556C21.7321 67.277 21.1 67.7563 21.1 68.4186V69.0905C21.1 69.9872 21.6969 70.7743 22.5604 71.0163L66.8302 83.4215C67.4679 83.6002 68.1 83.1209 68.1 82.4586V81.7867C68.1 80.8899 67.5031 80.1028 66.6397 79.8609Z" fill="#BBC0CE" />
            <path d="M66.6397 88.1906L22.3698 75.7854C21.7321 75.6067 21.1 76.086 21.1 76.7483V77.4202C21.1 78.317 21.6969 79.1041 22.5604 79.346L66.8302 91.7513C67.4679 91.9299 68.1 91.4506 68.1 90.7883V90.1164C68.1 89.2197 67.5031 88.4326 66.6397 88.1906Z" fill="#BBC0CE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M21 68.419C21 67.6905 21.6953 67.1632 22.3968 67.3598L66.6666 79.765C67.5733 80.0191 68.2 80.8456 68.2 81.7871V82.4591C68.2 83.1875 67.5047 83.7148 66.8032 83.5183L22.5334 71.113C21.6267 70.859 21 70.0325 21 69.0909V68.419ZM22.3428 67.5524C21.7689 67.3916 21.2 67.823 21.2 68.419V69.0909C21.2 69.9428 21.767 70.6906 22.5873 70.9205L66.8572 83.3257C67.4311 83.4865 68 83.0551 68 82.4591V81.7871C68 80.9352 67.433 80.1875 66.6127 79.9576L22.3428 67.5524ZM21 76.7487C21 76.0203 21.6953 75.493 22.3968 75.6896L66.6666 88.0948C67.5733 88.3488 68.2 89.1753 68.2 90.1169V90.7888C68.2 91.5173 67.5047 92.0446 66.8032 91.848L22.5334 79.4428C21.6267 79.1887 21 78.3622 21 77.4207V76.7487ZM22.3428 75.8821C21.7689 75.7213 21.2 76.1527 21.2 76.7487V77.4207C21.2 78.2726 21.767 79.0203 22.5873 79.2502L66.8572 91.6554C67.4311 91.8162 68 91.3848 68 90.7888V90.1169C68 89.265 67.433 88.5172 66.6127 88.2873L22.3428 75.8821Z" fill="#08031D" />
            <path d="M53.8408 11.4979L59.3882 13.1622C60.6963 13.5546 61.5921 14.7586 61.5921 16.1243V24.1153C61.5921 25.1511 60.0458 27.1426 57.7264 26.3694L32.2129 18.6381C30.9048 18.2456 29.1203 16.1381 29.1203 14.7724V7.81414C29.1203 5.49473 31.6651 4.84523 32.6572 5.14287L38.7411 6.96805C39.684 3.19545 42.6108 0.306052 48.0197 1.90172C52.3978 3.19332 53.6733 7.95549 53.8408 11.4979Z" fill="#BBC0CE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M41.7605 2.50592C40.262 3.48263 39.3057 5.12271 38.8383 6.99266C38.8317 7.01917 38.8145 7.04182 38.7908 7.05533C38.767 7.06884 38.7388 7.07204 38.7126 7.06419L32.6287 5.23901C32.1636 5.0995 31.307 5.17998 30.5648 5.58525C29.8295 5.98676 29.2205 6.69893 29.2205 7.81451V14.7727C29.2205 15.4203 29.6484 16.266 30.2537 17.0073C30.8485 17.7358 31.5917 18.3356 32.2121 18.5335L53.7373 11.4272C53.6474 9.68114 53.2861 7.65331 52.4198 5.90043C51.5418 4.12406 50.1476 2.63404 47.9916 1.998C45.3085 1.20647 43.2563 1.53089 41.7605 2.50592ZM53.8424 11.6031L32.5444 18.6344L57.7556 26.2741L57.7582 26.2749C58.8825 26.6497 59.8143 26.3548 60.4675 25.8424C60.7952 25.5854 61.0524 25.2736 61.2274 24.9643C61.4031 24.6537 61.4923 24.3521 61.4923 24.1157V16.1247C61.4923 14.8031 60.6255 13.6381 59.3597 13.2583L53.8424 11.6031ZM32.1843 18.7342L57.6964 26.4651C58.8909 26.8628 59.8914 26.5485 60.591 25.9998C60.9398 25.7261 61.214 25.3941 61.4015 25.0628C61.5882 24.7328 61.6923 24.3971 61.6923 24.1157V16.1247C61.6923 14.7148 60.7675 13.4719 59.4171 13.0667L53.9374 11.4228C53.8471 9.65887 53.4825 7.59916 52.5991 5.81182C51.7029 3.99859 50.2703 2.46172 48.0482 1.80617C45.3223 1.00203 43.2067 1.32447 41.6512 2.33837C40.126 3.33256 39.1549 4.98147 38.6702 6.84267L32.6861 5.04745C32.1591 4.88933 31.2472 4.98478 30.469 5.40971C29.6839 5.83839 29.0205 6.61067 29.0205 7.81451V14.7727C29.0205 15.4908 29.4849 16.3819 30.0988 17.1338C30.7137 17.8869 31.5024 18.5296 32.1842 18.7342" fill="#08031D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M52.2945 12.4474L57.8419 14.1116C59.15 14.504 60.0458 15.708 60.0458 17.0737V25.0647C60.0458 26.1005 59.0473 26.8434 58.0552 26.5458L31.3242 18.5265C30.0161 18.1341 29.1203 16.9301 29.1203 15.5644V7.57338C29.1203 6.53759 30.1188 5.79468 31.1109 6.09231L37.1948 7.91747C38.1377 4.14477 41.0644 1.25524 46.4734 2.85094C50.8517 4.14256 52.1271 8.90492 52.2945 12.4474Z" fill="#F7F7FA" />
            <path fillRule="evenodd" clipRule="evenodd" d="M40.2139 3.45324C38.7155 4.42999 37.7591 6.07013 37.2918 7.94013C37.2852 7.96664 37.268 7.98929 37.2442 8.0028C37.2205 8.01632 37.1922 8.01952 37.166 8.01167L31.0822 6.18651C30.1542 5.90812 29.2203 6.60298 29.2203 7.57179V15.5628C29.2203 16.8843 30.0872 18.0494 31.353 18.4291L58.084 26.4484C59.0119 26.7268 59.9458 26.032 59.9458 25.0632V17.0721C59.9458 15.7506 59.079 14.5855 57.8132 14.2058L52.2658 12.5416C52.2251 12.5294 52.1966 12.4929 52.1946 12.4505C52.1113 10.6875 51.7523 8.62617 50.8734 6.84781C49.9954 5.07139 48.6012 3.58132 46.4451 2.94526C43.762 2.15372 41.7097 2.47817 40.2139 3.45324ZM40.1047 3.28569C41.6602 2.27175 43.7758 1.94928 46.5017 2.75343C48.7239 3.409 50.1565 4.94592 51.0527 6.7592C51.9361 8.54659 52.3006 10.6064 52.3909 12.3703L57.8706 14.0142C59.221 14.4194 60.1458 15.6623 60.1458 17.0721V25.0632C60.1458 26.1659 59.0828 26.9569 58.0265 26.64L31.2955 18.6207C29.9451 18.2156 29.0203 16.9727 29.0203 15.5628V7.57179C29.0203 6.46901 30.0834 5.67806 31.1397 5.99494L37.1237 7.79015C37.6083 5.9289 38.5794 4.27993 40.1047 3.28569Z" fill="#08031D" />
            <path d="M49.5336 63.3799C55.4242 61.8632 58.7193 55.186 57.6278 46.6271C56.5362 38.0683 50.3416 32.3133 43.8605 32.8777L39.383 33.2676L43.5858 64.2401L49.5336 63.3799Z" fill="#A41313" />
            <path fillRule="evenodd" clipRule="evenodd" d="M39.2698 33.1758L43.8538 32.7766C50.4036 32.2063 56.6307 38.0177 57.7267 46.6113C58.8211 55.1926 55.5203 61.9403 49.5565 63.4759L49.546 63.4786L43.5003 64.3524L39.2698 33.1758ZM39.4963 33.357L43.6713 64.1256L49.5159 63.2803C55.3301 61.7798 58.6172 55.1744 57.5288 46.6406C56.4417 38.1166 50.2796 32.418 43.8672 32.9764L39.4963 33.357Z" fill="#08031D" />
            <path d="M44.5822 64.0192C50.9879 62.5019 54.7071 54.4343 52.8892 45.9998C51.0713 37.5654 44.4047 31.9579 37.9989 33.4753C31.5932 34.9926 27.874 43.0602 29.6919 51.4946C31.5098 59.9291 38.1764 65.5366 44.5822 64.0192Z" fill="#F73E3C" />
            <path fillRule="evenodd" clipRule="evenodd" d="M30.8517 39.9325C29.3585 43.1715 28.8845 47.2743 29.7892 51.4721C30.694 55.6698 32.8046 59.1604 35.4812 61.4117C38.1577 63.663 41.395 64.6719 44.5611 63.922C47.7272 63.172 50.2362 60.8019 51.7294 57.563C53.2226 54.324 53.6966 50.2211 52.7919 46.0234C51.8871 41.8256 49.7765 38.335 47.0999 36.0837C44.4234 33.8325 41.1861 32.8235 38.02 33.5735C34.8539 34.3235 32.3449 36.6935 30.8517 39.9325ZM30.6714 39.8505C32.1848 36.5677 34.7382 34.1454 37.9779 33.378C41.2175 32.6106 44.5164 33.6467 47.2291 35.9285C49.9417 38.2102 52.0733 41.7405 52.9865 45.9773C53.8997 50.214 53.423 54.3622 51.9097 57.6449C50.3963 60.9277 47.8429 63.3501 44.6032 64.1175C41.3636 64.8849 38.0647 63.8487 35.352 61.567C32.6394 59.2853 30.5078 55.7549 29.5946 51.5182C28.6814 47.2814 29.1581 43.1332 30.6714 39.8505Z" fill="#08031D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M35.4057 41.0467C36.4436 40.3893 37.8179 40.6978 38.4752 41.7357L40.9609 45.6605L44.4739 42.5865C45.3985 41.7775 46.8038 41.8712 47.6128 42.7958C48.4219 43.7204 48.3282 45.1257 47.4036 45.9347L43.3699 49.4642L46.9282 55.0826C47.5856 56.1205 47.2771 57.4948 46.2392 58.1521C45.2013 58.8095 43.827 58.501 43.1697 57.4631L39.9815 52.4291L36.7261 55.2776C35.8015 56.0866 34.3961 55.9929 33.5871 55.0683C32.7781 54.1437 32.8718 52.7384 33.7964 51.9294L37.5724 48.6253L34.7166 44.1162C34.0593 43.0783 34.3678 41.704 35.4057 41.0467Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M35.3522 40.9626C36.4368 40.2757 37.8728 40.5981 38.5597 41.6826L40.9829 45.5087L44.4081 42.5117C45.3742 41.6663 46.8427 41.7642 47.6881 42.7304C48.5335 43.6965 48.4356 45.165 47.4694 46.0104L43.5002 49.4835L47.0127 55.0295C47.6996 56.1141 47.3772 57.5501 46.2927 58.237C45.2081 58.9239 43.7721 58.6015 43.0852 57.517L39.9595 52.5816L36.7919 55.3532C35.8258 56.1986 34.3573 56.1007 33.5119 55.1346C32.6665 54.1684 32.7644 52.6999 33.7306 51.8545L37.4421 48.6069L34.6322 44.1701C33.9453 43.0855 34.2676 41.6495 35.3522 40.9626ZM38.3907 41.7896C37.7629 40.7984 36.4505 40.5038 35.4592 41.1315C34.468 41.7593 34.1733 43.0718 34.8011 44.0631L37.7028 48.6446L33.8623 52.005C32.9792 52.7777 32.8898 54.1198 33.6624 55.0028C34.435 55.8859 35.7772 55.9753 36.6602 55.2027L40.0035 52.2773L43.2542 57.41C43.882 58.4012 45.1944 58.6958 46.1857 58.0681C47.1769 57.4403 47.4716 56.1278 46.8438 55.1365L43.2396 49.4457L47.3377 45.8599C48.2208 45.0872 48.3102 43.7451 47.5376 42.8621C46.765 41.979 45.4228 41.8896 44.5398 42.6622L40.9389 45.813L38.3907 41.7896Z" fill="#08031D" />
        </svg>

    );
};

InvoiceVoidIcon.propTypes = {
    className: PropTypes.string,
};

InvoiceVoidIcon.defaultProps = {
    className: '',
};
