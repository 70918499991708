const navigationUtils = [
    {
        displayName: 'Checkout',
        icon: 'CheckoutIcon',
        id: 1,
        link: '/terminal/',
        subLinks: [],
    },
    {
        displayName: 'Invoicing',
        icon: 'InvoiceIcon',
        id: 2,
        link: '/terminal/invoices',
        subLinks: [],
    },
    {
        displayName: 'Recurring',
        icon: 'RecurringIcon',
        id: 3,
        link: '/terminal/recurring',
        subLinks: [],
    },
    {
        displayName: 'Transactions',
        icon: 'TransactionsIcon',
        id: 4,
        link: '/terminal/transactions',
        selectedIcon: 'TransactionsSolidIcon',
        subLinks: [],
    },
    {
        displayName: 'Accounts',
        icon: 'AccountsIcon',
        id: 5,
        isActive: false,
        link: '/terminal/accounts',
        subLinks: [],
    },
];

export default navigationUtils;
