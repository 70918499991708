///////////////////////////////////////////////////////////
//
// Action Descriptions
//
///////////////////////////////////////////////////////////

export const LOADING_COLLECT_JS = '[PaymentForm] Loading Collect JS';
export const LOADED_COLLECT_JS = '[PaymentForm] Loaded Collect JS';
export const ERROR_LOADING_COLLECT_JS = '[PaymentForm] Error Loading Collect JS';
export const SUBMITTING_PAYMENT_INFO = '[PaymentForm] Submitting Payment Info';
export const SUBMITTED_PAYMENT_INFO = '[PaymentForm] Sumbitted Payment Info';
export const ERROR_SUBMITTING_PAYMENT_INFO = '[PaymentForm] Error Submitting Payment Info';
export const SET_POSTAL_CODE = '[PaymentForm] Set Postal Code';
export const VALID_CC_NUMBER = '[PaymentForm] Valid CC Number Entered';
export const INVALID_CC_NUMBER = '[PaymentForm] Invalid CC Number Entered';
export const VALID_EXP_DATE = '[PaymentForm] Valid Expration Date';
export const INVALID_EXP_DATE = '[PaymentForm] Invalid Expiration Date';
export const VALID_CVV_NUMBER = '[PaymentForm] Valid CVV Number';
export const INVALID_CVV_NUMBER = '[PaymentForm] Invalid CVV Number';
export const VALID_POSTAL_CODE = '[PaymentForm] Valid Postal Code';
export const INVALID_POSTAL_CODE = '[PaymentForm] Invalid Postal Code';
export const RESET = '[PaymentForm] Reset App';
export const SET_CC_NUMBER = '[PaymentForm] Set CC Number';
export const SET_EXP_DATE = '[PaymentForm] Set Expiration Date';
export const SET_BIN = '[PaymentForm] Set Bin Number';
export const SET_HASH = '[PaymentForm] Set Card Hash';
export const SET_TOKEN_TYPE = '[PaymentForm] Set Token Type';
export const SET_FIELDS_DIRTY = '[PaymentForm] Set Fields dirty';


///////////////////////////////////////////////////////////
//
// Actions
//
///////////////////////////////////////////////////////////

export function loadingCollectJS() {
    return {
        type: LOADING_COLLECT_JS,
    };
}

export function loadedCollectJS() {
    return {
        type: LOADED_COLLECT_JS,
    };
}

export function errorLoadingCollectJS(e) {
    return {
        type: ERROR_LOADING_COLLECT_JS,
        payload: e,
    };
}

export function submittingPaymentInfo() {
    return {
        type: SUBMITTING_PAYMENT_INFO,
    };
}

export function submittedPaymentInfo(token) {
    return {
        type: SUBMITTED_PAYMENT_INFO,
        payload: token,
    };
}

export function errorSubmittingPaymentInfo(e) {
    return {
        type: ERROR_SUBMITTING_PAYMENT_INFO,
        payload: e,
    };
}

export function setPostalCode(postalCode) {
    return {
        type: SET_POSTAL_CODE,
        payload: postalCode,
    };
}

export function validCCNumber() {
    return {
        type: VALID_CC_NUMBER,
    };
}

export function invalidCCNumber(message) {
    return {
        type: INVALID_CC_NUMBER,
        payload: message,
    };
}

export function validExpirationDate() {
    return {
        type: VALID_EXP_DATE,
    };
}

export function invalidExpirationDate(message) {
    return {
        type: INVALID_EXP_DATE,
        payload: message,
    };
}

export function validCVVNumber() {
    return {
        type: VALID_CVV_NUMBER,
    };
}

export function invalidCVVNumber(message) {
    return {
        type: INVALID_CVV_NUMBER,
        payload: message,
    };
}

export function validPostalCode() {
    return {
        type: VALID_POSTAL_CODE,
    };
}

export function invalidPostalCode(message) {
    return {
        type: INVALID_POSTAL_CODE,
        payload: message,
    };
}

export function reset() {
    return {
        type: RESET,
    };
}

export function setCCNumber(ccNumber) {
    return {
        type: SET_CC_NUMBER,
        payload: ccNumber,
    };
}

export function setExpirationDate(date) {
    return {
        type: SET_EXP_DATE,
        payload: date,
    };
}

export function setCardBin(bin) {
    return {
        type: SET_BIN,
        payload: bin,
    };
}

export function setCardHash(hash) {
    return {
        type: SET_HASH,
        payload: hash,
    };
}

export function setTokenType(tokenType) {
    return {
        type: SET_TOKEN_TYPE,
        payload: tokenType,
    };
}

export function setFieldsDirty() {
    return {
        type: SET_FIELDS_DIRTY,
    };
}

///////////////////////////////////////////////////////////
//
// Thunks
//
///////////////////////////////////////////////////////////

export function submitPaymentForm() {
    return function(dispatch) {
        dispatch(submittingPaymentInfo());

        if (window.CollectJS) {
            window.CollectJS.startPaymentRequest();
        } else {
            console.warn('[PaymentForm] Could not find collect.js');
        }
    };
}
