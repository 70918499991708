import React from 'react';
import { wrapWithRightNav } from '../../common/components/rightNav';

const NotesPage = React.lazy(() => import('../components/notesPage'));
const NewNotePage = React.lazy(() => import('../components/newNotePage'));

const WrappedNotesPage = wrapWithRightNav(NotesPage);
const WrappedNewNotePage = wrapWithRightNav(NewNotePage);

const routes = [
    // For the account level subscription view
    {
        path: '/accounts/:id/subscriptions/:subscriptionId/notes',
        exact: true,
        displayName: 'Notes',
        render: (props) => <WrappedNotesPage {...props} />,
    },
    {
        path: '/accounts/:id/subscriptions/:subscriptionId/notes/new',
        exact: true,
        displayName: 'New Note',
        render: (props) => <WrappedNewNotePage {...props} />,
    },
    // for the failed transactions level view
    {
        path: '/recurring-payments/plans/failed/details/:customerId/:subscriptionId/notes',
        exact: true,
        displayName: 'Notes',
        render: (props) => <WrappedNotesPage {...props} />,
    },
    {
        path: '/recurring-payments/plans/failed/details/:customerId/:subscriptionId/notes/new',
        exact: true,
        displayName: 'New Note',
        render: (props) => <WrappedNewNotePage {...props} />,
    },
];

export default routes;
