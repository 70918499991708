import { RESET_TERMINAL_FIELDS } from './shared-actions';

/**
 * INITIAL STATE
 */
const defaultOrderInfo = {
    orderId: '',
    PONumber: '',
    orderDescription: '',
};

/**
 * ACTION TYPES
 */
const UPDATE_ORDER_ID = 'UPDATE_ORDER_ID';
const UPDATE_PO_NUMBER = 'UPDATE_PO_NUMBER';
const UPDATE_ORDER_DESCRIPTION = 'UPDATE_ORDER_DESCRIPTION';
const CLEAR_ORDER_INFO = 'CLEAR_ORDER_INFO';
/**
 * ACTION CREATORS
 */
export const updateOrderId = orderId => ({ type: UPDATE_ORDER_ID, orderId });
export const updatePONumber = PONumber => ({ type: UPDATE_PO_NUMBER, PONumber });
export const updateOrderDescription = orderDescription => (
    { type: UPDATE_ORDER_DESCRIPTION, orderDescription }
);
export const clearOrderInfo = () => ({ type: CLEAR_ORDER_INFO });

/**
 * REDUCER
 */
export default function (state = defaultOrderInfo, action) {
    switch (action.type) {
        case UPDATE_ORDER_ID:
            return { ...state, orderId: action.orderId };
        case UPDATE_PO_NUMBER:
            return { ...state, PONumber: action.PONumber };
        case UPDATE_ORDER_DESCRIPTION:
            return { ...state, orderDescription: action.orderDescription };
        case RESET_TERMINAL_FIELDS:
            return defaultOrderInfo;
        case CLEAR_ORDER_INFO:
            return defaultOrderInfo;
        default:
            return state;
    }
}
