import moment from 'moment';
import get from 'lodash.get';



export const DATE_FORMAT_USER_FRIENDLY = 'MM/DD/YYYY';
export const DATE_FORMAT_BACKEND = 'YYYY-MM-DD';
export const DATE_FORMAT_PAYMENT_DUE = 'MMM DD, YYYY';

/**
 * to see if date from credit card is expired
 *
 * @param {String} expirationDate - shape YYYY/MM/DD i.e: 2031-11-30
 */
export const isCcExpired = (expirationDate) => {
    const today = moment();
    return today.isAfter(expirationDate);
}


/**
 * cc object coming from backend, available in redux
 * @typedef CCObject
 * @property {number} id
 * @property {string} expirationDate - format YYYY/MM/DD REQUIRED
 * @property {string} cardType
 * @property {string} cc
 * @property {boolean} isDefault
 * @property {object} address
 *
 * @param {CCObject} cc - the credit card information
 * @returns {string} the expiration date
 */
export const getExpirationDate = (cc) => {
    return (get(cc, 'expirationDate') || get(cc, 'ccExpiration') || '');
}

/**
 * get the end date calculated according to number of payments
 * @typedef {object} selectedExistingPlan -data coming from backend get plans
 * @property {string} repeatEveryPeriod - value of days/weeks/months/years
 * @property {number} repeatEveryValue - number of repeat every from plan
 * @property {number} monthFrequency - frequency from plan if on date selected
 * @param {object} selectedExistingPlan
 * @param {object} startDate - moment date
 * @param {number} numberOfPayments - number of payments if payments selected
 * @return {string} - end date MM/DD/YYYY
 */

export const calculateAfterPayments = (selectedExistingPlan, startDate, numberOfPayments) => {
    const {
        repeatEveryPeriod,
        repeatEveryValue,
        monthFrequency
    } = selectedExistingPlan;

    let calculated = '';
    if (monthFrequency) {
        calculated = moment(startDate).add(monthFrequency * numberOfPayments, 'months').format(DATE_FORMAT_USER_FRIENDLY);
    } else {
        calculated = moment(startDate).add(repeatEveryValue * numberOfPayments, repeatEveryPeriod).format(DATE_FORMAT_USER_FRIENDLY);
    }
    return calculated
};

/**
 * it adds duration after frequency according to this link
 * @see https://xd.adobe.com/spec/7d9260a6-13d8-4011-565c-bee7ae7a770c-0ba3/screen/b0acc996-9f0d-4ff3-8726-63a601e4eb20/Account-Details-Has-Subscriptions-1
 * @example
 * returns Repeats every 1 month, until cancelled
 * renderFrequencyShort(row.value)}{renderDurationShort(row.original)
 * @param {SubscriptionResponse} sub - data from backend
 * @returns {string} - returns the calculated end date depending on what option it selected
 */
export const renderDurationShort = (sub) => {
    let content = '';

    if (sub.endDate) {
        const endDate = moment(sub.endDate).format(DATE_FORMAT_USER_FRIENDLY);
        content = `, ending ${endDate}`;
    } else {
        content = ', until cancelled';
    }
    return content;
}
