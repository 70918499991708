import { objectArrayToMap } from '../../../../utils/utils';

import {
    GET_RECURRING_PLANS_STARTED,
    GET_RECURRING_PLANS_SUCCESS,
    GET_RECURRING_PLANS_FAILED,
    GET_RECURRING_PLAN_STARTED,
    GET_RECURRING_PLAN_SUCCESS,
    GET_RECURRING_PLAN_FAILED,
    POST_RECURRING_PLANS_STARTED,
    POST_RECURRING_PLANS_SUCCESS,
    POST_RECURRING_PLANS_FAILED,
    SELECT_RECURRING_PLAN,
    UPDATE_RECURRING_PLANS_STARTED,
    UPDATE_RECURRING_PLANS_SUCCESS,
    UPDATE_RECURRING_PLANS_FAILED,
} from './actions';

const INITIAL_STATE = {
    // All recurring plans with minimal detail
    // Keyed by account ID
    plansMap: {},

    // All recurring plan ids,
    // retains order from server
    planIds: [],

    // One plan with full details
    selectedPlan: {},

    // states
    isLoadingRecurringPlans: false,
    isLoadingSinglePlan: false,
    isSubmitting: false,

    currentPage: 1,
    totalPages: 1,
    totalNumResults: null,
    totalActiveResults: null,
    totalInactiveResults: null,

    error: null,
    errorNumber: null,
};

export default function (state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case GET_RECURRING_PLANS_STARTED: {
            return {
                ...state,
                isLoadingRecurringPlans: true,
            };
        }
        case GET_RECURRING_PLANS_FAILED: {
            return {
                ...state,
                isLoadingRecurringPlans: false,
                error: payload.data.error,
                errorNumber: payload.status,
            };
        }
        case GET_RECURRING_PLANS_SUCCESS: {
            // Retain sort order
            const planIds = payload.results.map(obj => obj.id);

            // Save all recurring plans in state
            const plansMap = objectArrayToMap(payload.results);

            return {
                ...state,
                plansMap,
                planIds,
                currentPage: payload.currentPage,
                totalPages: payload.totalPages,
                totalNumResults: payload.totalNumResults,
                totalActiveResults: payload.totalActiveResults,
                totalInactiveResults: payload.totalInactiveResults,
                isLoadingRecurringPlans: false,
            };
        }
        case GET_RECURRING_PLAN_STARTED: {
            return {
                ...state,
                isLoadingSinglePlan: true,
            };
        }
        case GET_RECURRING_PLAN_FAILED: {
            return {
                ...state,
                isLoadingSinglePlan: false,
                error: payload.data.error,
                errorNumber: payload.status,
            };
        }
        case GET_RECURRING_PLAN_SUCCESS: {
            // Save single recurring plans in state
            const recurringPlan = payload;
            const plansMap = {
                ...state.plansMap,
                [recurringPlan.id]: recurringPlan,
            };

            return {
                ...state,
                plansMap,
                isLoadingSinglePlan: false,
            };
        }
        case SELECT_RECURRING_PLAN: {
            return {
                ...state,
                selectedPlan: payload,
            };
        }
        case POST_RECURRING_PLANS_STARTED:
        case UPDATE_RECURRING_PLANS_STARTED:
            return {
                ...state,
                isSubmitting: true,
            };

        case POST_RECURRING_PLANS_SUCCESS: {
            return {
                ...state,
                plansMap: {
                    ...state.plansMap,
                    [payload.id]: payload,
                },
                planIds: [...state.planIds, payload.id],
                isSubmitting: false,
            };
        }

        case POST_RECURRING_PLANS_FAILED:
        case UPDATE_RECURRING_PLANS_FAILED:
            return {
                ...state,
                isSubmitting: false,
            };
        case UPDATE_RECURRING_PLANS_SUCCESS:
            return {
                ...state,
                plansMap: {
                    ...state.plansMap,
                    [payload.id]: {
                        ...state.plansMap[payload.id],
                        ...payload,
                    },
                },
                isSubmitting: false,
            };
        default: {
            return { ...state };
        }
    }
}
