import { RESET_TERMINAL_FIELDS } from './shared-actions';
import * as api from './api';

/**
 * INITIAL STATE
 */
const defaultPurchaseInfo = {
    subtotal: '',
    shipping: '',
    tax_amount: '',
    tip: '',
    convenienceFee: '',
    isTaxIncluded: false,
    isShippingIncluded: false,
    isTipIncluded: false,
    paymentToken: '',
    isLoadingTaxAmount: false,
    isConvenienceFeeIncluded: false,
    total: '0',
};

/**
 * ACTION TYPES
 */
const UPDATE_SUBTOTAL = '[PurchaseInfo] Update Subtotal';
const UPDATE_SHIPPING = '[PurchaseInfo] Update Shipping';
const UPDATE_TAX_INCLUDED = '[PurchaseInfo] Update Tax Included';
const UPDATE_TAX_AMOUNT = '[PurchaseInfo] Update Tax Amount';
const UPDATE_SHIPPING_INCLUDED = '[PurchaseInfo] Update Is Shipping Included';
const SET_PAYMENT_TOKEN = '[PurchaseInfo] Set Payment Token';

const LOADING_TAX_AMOUNT = '[PurchaseInfo] Loading Tax Amount';
const LOADED_TAX_AMOUNT = '[PurchaseInfo] Loaded Tax Amount';
const ERROR_LOADING_TAX_AMOUNT = '[PurchaseInfo] Error Loading Tax Amount';
const SET_CONVENIENCE_FEE_INCLUDED = '[PurchanseInfo] Set Convenience Fee Included';
const SET_CONVENIENCE_FEE_AMOUNT = '[PurchaseInfo] Set Convenience Fee Amount';
const UPDATE_TOTAL = '[PurchaseInfo] Update Total';
const CLEAR_PURCHASE_INFO = '[PurchaseInfo] Clear Purhcase Info ';

/**
 * ACTION CREATORS
 */
export const updateSubtotal = subtotal => ({ type: UPDATE_SUBTOTAL, subtotal });
export const updateShipping = shipping => ({ type: UPDATE_SHIPPING, shipping });
export const updateTaxAmount = tax_amount => ({ type: UPDATE_TAX_AMOUNT, tax_amount });
export const updateTaxIncluded = isIncluded => ({ type: UPDATE_TAX_INCLUDED, isIncluded });
export const updateShippingIncluded = isIncluded => (
    { type: UPDATE_SHIPPING_INCLUDED, isIncluded }
);
export const setPaymentToken = token => ({ type: SET_PAYMENT_TOKEN, token });

// Load a tax amount
export const loadingTaxAmount = () => ({
    type: LOADING_TAX_AMOUNT,
});

// Loaded a tax amount
//  param {number} taxAmount - the amount to tax
export const loadedTaxAmount = (taxAmount) => ({
    type: LOADED_TAX_AMOUNT,
    taxAmount,
});

// Error loading a tax amount
//  param {Object} error - an error object
export const errorLoadingTaxAmount = (error) => ({
    type: ERROR_LOADING_TAX_AMOUNT,
    error,
});

export const updateConvenienceFeeIncluded = (isIncluded) => ({
    type: SET_CONVENIENCE_FEE_INCLUDED,
    isIncluded,
});

export const updateConvenienceFeeAmount = (amount) => ({
    type: SET_CONVENIENCE_FEE_AMOUNT,
    amount,
});

export const updateTotal = (amount) => ({
    type: UPDATE_TOTAL,
    amount,
});

export const clearPurchaseInfo = () => ({
    type: CLEAR_PURCHASE_INFO
});

/**
 * THUNKS
 */

// Get the calculated tax amount and update the tax amount property
//  param {number} taxableAmount - The amount to calculate taxes on
//  param {number} taxRate - The tax rate
//  param {number} taxRounding - the tax rounding
export const getCalculatedTaxAndUpdate = (taxableAmount, taxRate, taxRounding) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(loadingTaxAmount());

        api.fetchCalculatedTax({
                taxable_amount: taxableAmount,
                tax_rate: taxRate,
                tax_rounding: taxRounding
            })
            .then((taxAmount) => {
                dispatch(loadedTaxAmount(taxAmount));

                resolve(taxAmount);
            })
            .catch((e) => {
                dispatch(errorLoadingTaxAmount(e));

                reject(e);
            });
    });
};


/**
 * REDUCER
 */
export default function (state = defaultPurchaseInfo, action) {
    switch (action.type) {
        case UPDATE_SUBTOTAL: {
            return {
                ...state,
                subtotal: action.subtotal,
            };
        }

        case UPDATE_SHIPPING: {
            return {
                ...state,
                shipping: action.shipping,
            };
        }

        case UPDATE_TAX_INCLUDED: {
            return {
                ...state,
                isTaxIncluded: action.isIncluded,
            };
        }

        case UPDATE_TAX_AMOUNT: {
            return {
                ...state,
                tax_amount: action.tax_amount,
            };
        }

        case UPDATE_SHIPPING_INCLUDED: {
            return {
                ...state,
                isShippingIncluded: action.isIncluded,
                shipping: action.isIncluded ? state.shipping : '0.00',  // set to zero if not included
            };
        }

        case SET_PAYMENT_TOKEN: {
            return {
                ...state,
                paymentToken: action.token,
            };
        }

        case RESET_TERMINAL_FIELDS: {
            return defaultPurchaseInfo;
        }

        case LOADING_TAX_AMOUNT: {
            return {
                ...state,
                isLoadingTaxAmount: true,
            };
        }

        case LOADED_TAX_AMOUNT: {
            return {
                ...state,
                isLoadingTaxAmount: false,
                tax_amount: action.taxAmount,
            };
        }

        case ERROR_LOADING_TAX_AMOUNT: {
            return {
                ...state,
                isLoadingTaxAmount: false,
            };
        }

        case SET_CONVENIENCE_FEE_INCLUDED: {
            return {
                ...state,
                isConvenienceFeeIncluded: action.isIncluded,
            };
        }

        case SET_CONVENIENCE_FEE_AMOUNT: {
            return {
                ...state,
                convenienceFee: action.amount,
            };
        }

        case UPDATE_TOTAL: {
            return {
                ...state,
                total: action.amount,
            };
        }

        case CLEAR_PURCHASE_INFO: {
            return defaultPurchaseInfo
        }

        default:
            return state;
    }
}
