const __ = (key) => key;

export const COUNTRIES = [
    { id: 'US', value: 'USA' },
    { id: 'MX', value: 'México' },
    { id: 'CA', value: 'Canada' },
];

export const REGIONS = {
    US: [
        { id: 'AL', value: 'Alabama' },
        { id: 'AK', value: 'Alaska' },
        { id: 'AZ', value: 'Arizona' },
        { id: 'AR', value: 'Arkansas' },
        { id: 'CA', value: 'California' },
        { id: 'CO', value: 'Colorado' },
        { id: 'CT', value: 'Connecticut' },
        { id: 'DE', value: 'Delaware' },
        { id: 'DC', value: 'District of Columbia' },
        { id: 'FL', value: 'Florida' },
        { id: 'GA', value: 'Georgia' },
        { id: 'HI', value: 'Hawaii' },
        { id: 'ID', value: 'Idaho' },
        { id: 'IL', value: 'Illinois' },
        { id: 'IN', value: 'Indiana' },
        { id: 'IA', value: 'Iowa' },
        { id: 'KS', value: 'Kansas' },
        { id: 'KY', value: 'Kentucky' },
        { id: 'LA', value: 'Louisiana' },
        { id: 'ME', value: 'Maine' },
        { id: 'MD', value: 'Maryland' },
        { id: 'MA', value: 'Massachusetts' },
        { id: 'MI', value: 'Michigan' },
        { id: 'MN', value: 'Minnesota' },
        { id: 'MS', value: 'Mississippi' },
        { id: 'MO', value: 'Missouri' },
        { id: 'MT', value: 'Montana' },
        { id: 'NE', value: 'Nebraska' },
        { id: 'NV', value: 'Nevada' },
        { id: 'NH', value: 'New Hampshire' },
        { id: 'NJ', value: 'New Jersey' },
        { id: 'NM', value: 'New Mexico' },
        { id: 'NY', value: 'New York' },
        { id: 'NC', value: 'North Carolina' },
        { id: 'ND', value: 'North Dakota' },
        { id: 'OH', value: 'Ohio' },
        { id: 'OK', value: 'Oklahoma' },
        { id: 'OR', value: 'Oregon' },
        { id: 'PA', value: 'Pennsylvania' },
        { id: 'PR', value: 'Puerto Rico' },
        { id: 'RI', value: 'Rhode Island' },
        { id: 'SC', value: 'South Carolina' },
        { id: 'SD', value: 'South Dakota' },
        { id: 'TN', value: 'Tennessee' },
        { id: 'TX', value: 'Texas' },
        { id: 'UT', value: 'Utah' },
        { id: 'VT', value: 'Vermont' },
        { id: 'VA', value: 'Virginia' },
        { id: 'WA', value: 'Washington' },
        { id: 'WV', value: 'West Virginia' },
        { id: 'WI', value: 'Wisconsin' },
        { id: 'WY', value: 'Wyoming' },
    ],
    MX: [
        { id: 'AGU', value: 'Aguascalientes' },
        { id: 'BCN', value: 'Baja California' },
        { id: 'BCS', value: 'Baja California Sur' },
        { id: 'CAM', value: 'Campeche' },
        { id: 'CHH', value: 'Chihuahua' },
        { id: 'CHP', value: 'Chiapas' },
        { id: 'COA', value: 'Coahuila' },
        { id: 'COL', value: 'Colima' },
        { id: 'DIF', value: 'Distrito Federal' },
        { id: 'DUR', value: 'Durango' },
        { id: 'GRO', value: 'Guerrero' },
        { id: 'GUA', value: 'Guanajuato' },
        { id: 'HID', value: 'Hidalgo' },
        { id: 'JAL', value: 'Jalisco' },
        { id: 'MEX', value: 'Estado de México' },
        { id: 'MIC', value: 'Michoacán' },
        { id: 'MOR', value: 'Morelos' },
        { id: 'NAY', value: 'Nayarit' },
        { id: 'NLE', value: 'Nuevo León' },
        { id: 'OAX', value: 'Oaxaca' },
        { id: 'PUE', value: 'Puebla' },
        { id: 'QUE', value: 'Querétaro' },
        { id: 'ROO', value: 'Quintana Roo' },
        { id: 'SIN', value: 'Sinaloa' },
        { id: 'SLP', value: 'San Luis Potosí' },
        { id: 'SON', value: 'Sonora' },
        { id: 'TAB', value: 'Tabasco' },
        { id: 'TAM', value: 'Tamaulipas' },
        { id: 'TLA', value: 'Tlaxcala' },
        { id: 'VER', value: 'Veracruz' },
        { id: 'YUC', value: 'Yucatán' },
        { id: 'ZAC', value: 'Zacatecas' },
    ],
    CA: [
        { id: 'AB', value: 'Alberta' },
        { id: 'BC', value: 'British Columbia' },
        { id: 'MB', value: 'Manitoba' },
        { id: 'NB', value: 'New Brunswick' },
        { id: 'NL', value: 'Newfoundland & Labrador' },
        { id: 'NT', value: 'Northwest Territories' },
        { id: 'NS', value: 'Nova Scotia' },
        { id: 'NU', value: 'Nunavut' },
        { id: 'ON', value: 'Ontario' },
        { id: 'PE', value: 'Prince Edward Island' },
        { id: 'QC', value: 'Quebec' },
        { id: 'SK', value: 'Saskatchewan' },
        { id: 'YT', value: 'Yukon' },
    ],
};

export const TIME_ZONES = {
    MX: [
        {
            id: 'America/Mexico_City',
            value: 'Tiempo del Centro EE.UU.- Mayoría de las Localidades',
        },
        {
            id: 'America/Cancun',
            value: 'Tiempo del Centro EE.UU. - Quintana Roo',
        },
        {
            id: 'America/Merida',
            value: 'Tiempo del Centro EE.UU. - Campeche, Yucatan',
        },
        {
            id: 'America/Monterrey',
            value:
                'Tiempo del Centro EE.UU. - Coahuila, Durango, Nuevo Leon, Tamaulipas (lejos de la frontera de EE.UU.)',
        },
        {
            id: 'America/Matamoros',
            value:
                'Tiempo del Centro EE.UU. - Coahuila, Durango, Nuevo Leon, Tamaulipas (cerca de la frontera de EE.UU.)',
        },
        {
            id: 'America/Mazatlan',
            value: 'Tiempo de la Montaña EE.UU. - S Baja, Nayarit, Sinaloa',
        },
        {
            id: 'America/Chihuahua',
            value:
                'Tiempo de la Montaña Mexicana- Chihuahua (lejos de la frontera de EE.UU.)',
        },
        {
            id: 'America/Ojinaga',
            value:
                'Tiempo de la Montaña EE.UU. - Chihuahua (cerca de la frontera de EE.UU.)',
        },
        { id: 'America/Hermosillo', value: 'Tiempo de la Montaña - Sonora' },
        {
            id: 'America/Tijuana',
            value:
                'Tiempo del Pacífico EE.UU. - Baja California (cerca de la frontera de EE.UU.)',
        },
        {
            id: 'America/Santa_Isabel',
            value:
                'Tiempo del Pacífico Mexicano- Baja California (lejos de la frontera de EE.UU.)',
        },
        {
            id: 'America/Bahia_Banderas',
            value: 'Tiempo del Centro Mexicano - Bahia de Banderas',
        },
    ],
    US: [
        {
            id: 'America/Puerto_Rico',
            value: __('Atlantic Standard Time - Puerto Rico'),
        },
        { id: 'America/New_York', value: __('Eastern Time') },
        {
            id: 'America/Detroit',
            value: __('Eastern Time - Michigan - most locations'),
        },
        {
            id: 'America/Kentucky/Louisville',
            value: __('Eastern Time - Kentucky - Louisville area'),
        },
        {
            id: 'America/Kentucky/Monticello',
            value: __('Eastern Time - Kentucky - Wayne County'),
        },
        {
            id: 'America/Indiana/Indianapolis',
            value: __('Eastern Time - Indiana - most locations'),
        },
        {
            id: 'America/Indiana/Vincennes',
            value: __(
                'Eastern Time - Indiana - Daviess, Dubois, Knox & Martin Counties',
            ),
        },
        {
            id: 'America/Indiana/Winamac',
            value: __('Eastern Time - Indiana - Pulaski County'),
        },
        {
            id: 'America/Indiana/Marengo',
            value: __('Eastern Time - Indiana - Crawford County'),
        },
        {
            id: 'America/Indiana/Petersburg',
            value: __('Eastern Time - Indiana - Pike County'),
        },
        {
            id: 'America/Indiana/Vevay',
            value: __('Eastern Time - Indiana - Switzerland County'),
        },
        { id: 'America/Chicago', value: __('Central Time') },
        {
            id: 'America/Indiana/Tell_City',
            value: __('Central Time - Indiana - Perry County'),
        },
        {
            id: 'America/Indiana/Knox',
            value: __('Central Time - Indiana - Starke County'),
        },
        {
            id: 'America/Menominee',
            value: __(
                'Central Time - Michigan - Dickinson, Gogebic, Iron & Menominee Counties',
            ),
        },
        {
            id: 'America/North_Dakota/Center',
            value: __('Central Time - North Dakota - Oliver County'),
        },
        {
            id: 'America/North_Dakota/New_Salem',
            value: __(
                'Central Time - North Dakota - Morton County (except Mandan area)',
            ),
        },
        {
            id: 'America/North_Dakota/Beulah',
            value: __('Central Time - North Dakota - Mercer County'),
        },
        { id: 'America/Denver', value: __('Mountain Time') },
        {
            id: 'America/Boise',
            value: __('Mountain Time - south Idaho & east Oregon'),
        },
        {
            id: 'America/Phoenix',
            value: __('Mountain Standard Time - Arizona (except Navajo)'),
        },
        { id: 'America/Los_Angeles', value: __('Pacific Time') },
        { id: 'America/Anchorage', value: __('Alaska Time') },
        { id: 'America/Juneau', value: __('Alaska Time - Alaska panhandle') },
        {
            id: 'America/Sitka',
            value: __('Alaska Time - southeast Alaska panhandle'),
        },
        {
            id: 'America/Yakutat',
            value: __('Alaska Time - Alaska panhandle neck'),
        },
        { id: 'America/Nome', value: __('Alaska Time - west Alaska') },
        { id: 'America/Adak', value: __('Aleutian Islands') },
        {
            id: 'America/Metlakatla',
            value: __('Metlakatla Time - Annette Island'),
        },
        { id: 'Pacific/Honolulu', value: __('Hawaii') },
    ],
    CA: [
        {
            id: 'America/St_Johns',
            value: 'Newfoundland Time, including SE Labrador',
        },
        {
            id: 'America/Halifax',
            value: 'Atlantic Time - Nova Scotia (most places), PEI',
        },
        {
            id: 'America/Glace_Bay',
            value:
                'Atlantic Time - Nova Scotia - places that did not observe DST 1966-1971',
        },
        { id: 'America/Moncton', value: 'Atlantic Time - New Brunswick' },
        {
            id: 'America/Goose_Bay',
            value: 'Atlantic Time - Labrador - most locations',
        },
        {
            id: 'America/Blanc-Sablon',
            value: 'Atlantic Standard Time - Quebec - Lower North Shore',
        },
        {
            id: 'America/Toronto',
            value: 'Eastern Time - Ontario & Quebec - most locations',
        },
        {
            id: 'America/Nipigon',
            value:
                'Eastern Time - Ontario & Quebec - places that did not observe DST 1967-1973',
        },
        {
            id: 'America/Thunder_Bay',
            value: 'Eastern Time - Thunder Bay, Ontario',
        },
        {
            id: 'America/Iqaluit',
            value: 'Eastern Time - east Nunavut - most locations',
        },
        {
            id: 'America/Pangnirtung',
            value: 'Eastern Time - Pangnirtung, Nunavut',
        },
        {
            id: 'America/Resolute',
            value: 'Central Standard Time - Resolute, Nunavut',
        },
        {
            id: 'America/Atikokan',
            value:
                'Eastern Standard Time - Atikokan, Ontario and Southampton I, Nunavut',
        },
        { id: 'America/Rankin_Inlet', value: 'Central Time - central Nunavut' },
        {
            id: 'America/Winnipeg',
            value: 'Central Time - Manitoba & west Ontario',
        },
        {
            id: 'America/Rainy_River',
            value: 'Central Time - Rainy River & Fort Frances, Ontario',
        },
        {
            id: 'America/Regina',
            value: 'Central Standard Time - Saskatchewan - most locations',
        },
        {
            id: 'America/Swift_Current',
            value: 'Central Standard Time - Saskatchewan - midwest',
        },
        {
            id: 'America/Edmonton',
            value:
                'Mountain Time - Alberta, east British Columbia & west Saskatchewan',
        },
        { id: 'America/Cambridge_Bay', value: 'Mountain Time - west Nunavut' },
        {
            id: 'America/Yellowknife',
            value: 'Mountain Time - central Northwest Territories',
        },
        {
            id: 'America/Inuvik',
            value: 'Mountain Time - west Northwest Territories',
        },
        {
            id: 'America/Creston',
            value: 'Mountain Standard Time - Creston, British Columbia',
        },
        {
            id: 'America/Dawson_Creek',
            value:
                'Mountain Standard Time - Dawson Creek & Fort Saint John, British Columbia',
        },
        {
            id: 'America/Vancouver',
            value: 'Pacific Time - west British Columbia',
        },
        { id: 'America/Whitehorse', value: 'Pacific Time - south Yukon' },
        { id: 'America/Dawson', value: 'Pacific Time - north Yukon' },
    ],
};

export const COLUMN_CLASSES = {
    1: 'col1',
    2: 'col2',
    3: 'col3',
    4: 'col4',
    5: 'col5',
};

// Names of the time range
export const YESTERDAY = __('Yesterday');
export const THIS_WEEK = __('This Week');
export const LAST_WEEK = __('Last Week');
export const THIS_MONTH = __('This Month');
export const LAST_MONTH = __('Last Month');
export const THIS_YEAR = __('This Year');
export const LAST_YEAR = __('Last Year');

export const MONTH_NAMES = [
    __('January'),
    __('February'),
    __('March'),
    __('April'),
    __('May'),
    __('June'),
    __('July'),
    __('August'),
    __('September'),
    __('October'),
    __('November'),
    __('December'),
];

export const DEFAULT_PAGE_SIZE = 10;

export const EXTERNAL_VENDORS = ['paypal', 'venmo'];

export const DATE_RANGE_PERIOD = {
    TODAY: 'today',
    THIS_WEEK: 'thisWeek',
    LAST_WEEK: 'lastWeek',
    THIS_MONTH: 'thisMonth',
    LAST_MONTH: 'lastMonth',
    CUSTOM: 'custom',
};

export const CURRENCY_CONVERTOR = {
    USD: '$',
};

export const SELECT_OPTION_DUMMY = { id: '', value: '' };

export const QUANTITY_DECIMAL_SCALE = 3; // Scale is the number of digits to the right of the decimal point in a number.
