import { createAxiosWrapper } from 'legacy/utils/axiosWrapper';

/**
 * Retrieve all failed transactions on a recurring payment plan
 *
 * @typedef AddressInformation
 * @property {number} id
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} address1
 * @property {string} address2
 * @property {string} city
 * @property {string} provinceCode
 * @property {string} countryCode
 * @property {string} postalCode
 * @property {string} phoneNumber
 * @property {string} faxNumber
 * @property {boolean} isDefault
 * @property {string} addressType
 *
 * @typedef Address
 * @property {string} id
 * @property {string} vendorId
 *
 * @typedef Account
 * @property {number} id
 * @property {string} email
 * @property {string} firstName
 * @property {string} lastName
 *
 * @typedef PaymentMethod
 * @property {number} id
 * @property {AddressInformation} address
 * @property {number} addressId
 * @property {Account} account
 * @property {string} ccType
 * @property {string} ccLastFour
 * @property {string} ccExpiration
 * @property {boolean} isDefault
 * @property {string} vendorId
 * @property {string} email
 *
 * @typedef FailedRecurringTransaction
 * @property {number} id
 * @property {number} planId
 * @property {number} paymentMethodId
 * @property {PaymentMethod} paymentMethod
 * @property {number} accountId
 * @property {Account} account
 * @property {string} amount
 * @property {string} vendorId
 * @property {string} planName
 * @property {string} subscriptionId
 * @property {string} transactionFailDate
 * @property {string} totalAmount
 * @property {string} status
 * @property {number} attemptsCount
 * @property {string} startDate
 * @property {string} endDate
 * @property {string} prorationVendorId
 * @property {number} successfulTransactionsCount
 * @property {number} payments
 * @property {number} dayFrequency
 * @property {number} monthFrequency
 * @property {number} dayOfMonth
 * @property {Note[]} notes
 *
 * @typedef CustomerFailedTransaction
 * @property {[customerName: string] : FailedRecurringTransaction[]}
 *
 * @returns {CustomerFailedTransaction} an object containing failed transactions
 *  by customer name
 */
export function getFailedTransactions(exportFormat = 'json') {
    /**
     * Determine if a format is valid or not
     *
     * @param {string} formatToCheck - a type of format
     * @returns {boolean} true if format is valid, else false
     */
    function isValidFormat(formatToCheck) {
        const validFormats = ['csv', 'json'];

        return validFormats.includes(formatToCheck);
    }

    // validate export formats
    if (!isValidFormat(exportFormat)) {
        console.error('getFailedTransactions: Unknown export format');

        return Promise.reject();
    }

    return createAxiosWrapper().get(
        `/nmi_terminal/recurring/failed_transactions/${exportFormat}`,
    );

    // Alleged shape of the backend data....
    // return Promise.resolve({
    //     data: {
    //         "Fail InsufficientFunds": [
    //             {
    //                 "paymentMethod": {
    //                     "account": {
    //                         "vendorId": "1075219279",
    //                         "id": "288"
    //                     },
    //                     "ccExpiration": "2026-11-30",
    //                     "vendor": "NMI",
    //                     "vendorId": "1123722103",
    //                     "addressDd": 616,
    //                     "ccType": "American Express",
    //                     "email": null,
    //                     "isDefault": true,
    //                     "ccLastFour": "6325",
    //                     "address": {
    //                         "phone_number": "",
    //                         "city": "",
    //                         "firstName": "Fail",
    //                         "lastName": "InsufficientFunds",
    //                         "countryCode": "",
    //                         "provinceCode": "",
    //                         "faxNumber": "",
    //                         "isDefault": true,
    //                         "postalCode": "60661",
    //                         "address1": "",
    //                         "address2": "",
    //                         "addressType": "billing",
    //                         "id": 616
    //                     },
    //                     "id": 348
    //                 },
    //                 "id": 102,
    //                 "paymentMethodId": 348,
    //                 "resultingTransactionId": null,
    //                 "billingCycleTransactionId": 5382837470,
    //                 "planId": 56,
    //                 "attemptsCount": 0,
    //                 "subscriptionId": 5382180576,
    //                 "customerName": "Fail InsufficientFunds",
    //                 "planName": "Should Fail",
    //                 "startDate": "2020-05-23",
    //                 "status": "scheduled",
    //                 "transactionFailDate": "2020-05-23",
    //                 "accountId": 288,
    //                 "endDate": null,
    //                 "vendorId": "5382180576",
    //                 "account": {
    //                     "firstName": "Fail",
    //                     "lastName": "InsufficientFunds",
    //                     "id": 288,
    //                     "email": "vmatsunaga+failinsufficientfunds@spoton.com"
    //                 },
    //                 "dayFrequency": 1,
    //                 "monthFrequency": null,
    //                 "merchantId": "5ec8303c62f04a2d185b9ac8",
    //                 "remark": "DECLINED",
    //                 "totalAmount": 1000,
    //                 "created": "2020-05-23T08:52:45.959992Z",
    //                 "notes": {
    //                     "notes": []
    //                 },
    //                 "dayOfMonth": null,
    //                 "successfulTransactionsCount": 0,
    //                 "amount": "1000.0000",
    //                 "payments": 5,
    //                 "prorationVendorId": null
    //             },
    //             {
    //                 "paymentMethod": {
    //                     "account": {
    //                         "vendorId": "1075219279",
    //                         "id": "288"
    //                     },
    //                     "ccExpiration": "2026-11-30",
    //                     "vendor": "NMI",
    //                     "vendorId": "1123722103",
    //                     "addressDd": 616,
    //                     "ccType": "American Express",
    //                     "email": null,
    //                     "isDefault": true,
    //                     "ccLastFour": "6325",
    //                     "address": {
    //                         "phone_number": "",
    //                         "city": "",
    //                         "firstName": "Fail",
    //                         "lastName": "InsufficientFunds",
    //                         "countryCode": "",
    //                         "provinceCode": "",
    //                         "faxNumber": "",
    //                         "isDefault": true,
    //                         "postalCode": "60661",
    //                         "address1": "",
    //                         "address2": "",
    //                         "addressType": "billing",
    //                         "id": 616
    //                     },
    //                     "id": 348
    //                 },
    //                 "id": 102,
    //                 "paymentMethodId": 348,
    //                 "resultingTransactionId": null,
    //                 "billingCycleTransactionId": 5384580901,
    //                 "planId": 56,
    //                 "attemptsCount": 0,
    //                 "subscriptionId": 5382180576,
    //                 "customerName": "Fail InsufficientFunds",
    //                 "planName": "Should Fail",
    //                 "startDate": "2020-05-23",
    //                 "status": "scheduled",
    //                 "transactionFailDate": "2020-05-24",
    //                 "accountId": 288,
    //                 "endDate": null,
    //                 "vendorId": "5382180576",
    //                 "account": {
    //                     "firstName": "Fail",
    //                     "lastName": "InsufficientFunds",
    //                     "id": 288,
    //                     "email": "vmatsunaga+failinsufficientfunds@spoton.com"
    //                 },
    //                 "dayFrequency": 1,
    //                 "monthFrequency": null,
    //                 "merchantId": "5ec8303c62f04a2d185b9ac8",
    //                 "remark": "DECLINED",
    //                 "totalAmount": 1000,
    //                 "created": "2020-05-24T08:37:44.214712Z",
    //                 "notes": {
    //                     "notes": []
    //                 },
    //                 "dayOfMonth": null,
    //                 "successfulTransactionsCount": 0,
    //                 "amount": "1000.0000",
    //                 "payments": 5,
    //                 "prorationVendorId": null
    //             },
    //             {
    //                 "paymentMethod": {
    //                     "account": {
    //                         "vendorId": "1075219279",
    //                         "id": "288"
    //                     },
    //                     "ccExpiration": "2026-11-30",
    //                     "vendor": "NMI",
    //                     "vendorId": "1123722103",
    //                     "addressDd": 616,
    //                     "ccType": "American Express",
    //                     "email": null,
    //                     "isDefault": true,
    //                     "ccLastFour": "6325",
    //                     "address": {
    //                         "phone_number": "",
    //                         "city": "",
    //                         "firstName": "Fail",
    //                         "lastName": "InsufficientFunds",
    //                         "countryCode": "",
    //                         "provinceCode": "",
    //                         "faxNumber": "",
    //                         "isDefault": true,
    //                         "postalCode": "60661",
    //                         "address1": "",
    //                         "address2": "",
    //                         "addressType": "billing",
    //                         "id": 616
    //                     },
    //                     "id": 348
    //                 },
    //                 "id": 102,
    //                 "paymentMethodId": 348,
    //                 "resultingTransactionId": null,
    //                 "billingCycleTransactionId": 5386367724,
    //                 "planId": 56,
    //                 "attemptsCount": 0,
    //                 "subscriptionId": 5382180576,
    //                 "customerName": "Fail InsufficientFunds",
    //                 "planName": "Should Fail",
    //                 "startDate": "2020-05-23",
    //                 "status": "scheduled",
    //                 "transactionFailDate": "2020-05-25",
    //                 "accountId": 288,
    //                 "endDate": null,
    //                 "vendorId": "5382180576",
    //                 "account": {
    //                     "firstName": "Fail",
    //                     "lastName": "InsufficientFunds",
    //                     "id": 288,
    //                     "email": "vmatsunaga+failinsufficientfunds@spoton.com"
    //                 },
    //                 "dayFrequency": 1,
    //                 "monthFrequency": null,
    //                 "merchantId": "5ec8303c62f04a2d185b9ac8",
    //                 "remark": "DECLINED",
    //                 "totalAmount": 1000,
    //                 "created": "2020-05-25T09:07:43.880308Z",
    //                 "notes": {
    //                     "notes": []
    //                 },
    //                 "dayOfMonth": null,
    //                 "successfulTransactionsCount": 0,
    //                 "amount": "1000.0000",
    //                 "payments": 5,
    //                 "prorationVendorId": null
    //             },
    //             {
    //                 "paymentMethod": {
    //                     "account": {
    //                         "vendorId": "1075219279",
    //                         "id": "288"
    //                     },
    //                     "ccExpiration": "2026-11-30",
    //                     "vendor": "NMI",
    //                     "vendorId": "1123722103",
    //                     "addressDd": 616,
    //                     "ccType": "American Express",
    //                     "email": null,
    //                     "isDefault": true,
    //                     "ccLastFour": "6325",
    //                     "address": {
    //                         "phone_number": "",
    //                         "city": "",
    //                         "firstName": "Fail",
    //                         "lastName": "InsufficientFunds",
    //                         "countryCode": "",
    //                         "provinceCode": "",
    //                         "faxNumber": "",
    //                         "isDefault": true,
    //                         "postalCode": "60661",
    //                         "address1": "",
    //                         "address2": "",
    //                         "addressType": "billing",
    //                         "id": 616
    //                     },
    //                     "id": 348
    //                 },
    //                 "id": 102,
    //                 "paymentMethodId": 348,
    //                 "resultingTransactionId": null,
    //                 "billingCycleTransactionId": 5387988131,
    //                 "planId": 56,
    //                 "attemptsCount": 0,
    //                 "subscriptionId": 5382180576,
    //                 "customerName": "Fail InsufficientFunds",
    //                 "planName": "Should Fail",
    //                 "startDate": "2020-05-23",
    //                 "status": "scheduled",
    //                 "transactionFailDate": "2020-05-26",
    //                 "accountId": 288,
    //                 "endDate": null,
    //                 "vendorId": "5382180576",
    //                 "account": {
    //                     "firstName": "Fail",
    //                     "lastName": "InsufficientFunds",
    //                     "id": 288,
    //                     "email": "vmatsunaga+failinsufficientfunds@spoton.com"
    //                 },
    //                 "dayFrequency": 1,
    //                 "monthFrequency": null,
    //                 "merchantId": "5ec8303c62f04a2d185b9ac8",
    //                 "remark": "DECLINED",
    //                 "totalAmount": 1000,
    //                 "created": "2020-05-26T08:37:43.540868Z",
    //                 "notes": {
    //                     "notes": []
    //                 },
    //                 "dayOfMonth": null,
    //                 "successfulTransactionsCount": 0,
    //                 "amount": "1000.0000",
    //                 "payments": 5,
    //                 "prorationVendorId": null
    //             },
    //             {
    //                 "paymentMethod": {
    //                     "account": {
    //                         "vendorId": "1075219279",
    //                         "id": "288"
    //                     },
    //                     "ccExpiration": "2026-11-30",
    //                     "vendor": "NMI",
    //                     "vendorId": "1123722103",
    //                     "addressDd": 616,
    //                     "ccType": "American Express",
    //                     "email": null,
    //                     "isDefault": true,
    //                     "ccLastFour": "6325",
    //                     "address": {
    //                         "phone_number": "",
    //                         "city": "",
    //                         "firstName": "Fail",
    //                         "lastName": "InsufficientFunds",
    //                         "countryCode": "",
    //                         "provinceCode": "",
    //                         "faxNumber": "",
    //                         "isDefault": true,
    //                         "postalCode": "60661",
    //                         "address1": "",
    //                         "address2": "",
    //                         "addressType": "billing",
    //                         "id": 616
    //                     },
    //                     "id": 348
    //                 },
    //                 "id": 102,
    //                 "paymentMethodId": 348,
    //                 "resultingTransactionId": null,
    //                 "billingCycleTransactionId": 5390060397,
    //                 "planId": 56,
    //                 "attemptsCount": 0,
    //                 "subscriptionId": 5382180576,
    //                 "customerName": "Fail InsufficientFunds",
    //                 "planName": "Should Fail",
    //                 "startDate": "2020-05-23",
    //                 "status": "scheduled",
    //                 "transactionFailDate": "2020-05-27",
    //                 "accountId": 288,
    //                 "endDate": null,
    //                 "vendorId": "5382180576",
    //                 "account": {
    //                     "firstName": "Fail",
    //                     "lastName": "InsufficientFunds",
    //                     "id": 288,
    //                     "email": "vmatsunaga+failinsufficientfunds@spoton.com"
    //                 },
    //                 "dayFrequency": 1,
    //                 "monthFrequency": null,
    //                 "merchantId": "5ec8303c62f04a2d185b9ac8",
    //                 "remark": "DECLINED",
    //                 "totalAmount": 1000,
    //                 "created": "2020-05-27T08:52:43.857690Z",
    //                 "notes": {
    //                     "notes": []
    //                 },
    //                 "dayOfMonth": null,
    //                 "successfulTransactionsCount": 0,
    //                 "amount": "1000.0000",
    //                 "payments": 5,
    //                 "prorationVendorId": null
    //             },
    //             {
    //                 "paymentMethod": {
    //                     "account": {
    //                         "vendorId": "1075219279",
    //                         "id": "288"
    //                     },
    //                     "ccExpiration": "2026-11-30",
    //                     "vendor": "NMI",
    //                     "vendorId": "1123722103",
    //                     "addressDd": 616,
    //                     "ccType": "American Express",
    //                     "email": null,
    //                     "isDefault": true,
    //                     "ccLastFour": "6325",
    //                     "address": {
    //                         "phone_number": "",
    //                         "city": "",
    //                         "firstName": "Fail",
    //                         "lastName": "InsufficientFunds",
    //                         "countryCode": "",
    //                         "provinceCode": "",
    //                         "faxNumber": "",
    //                         "isDefault": true,
    //                         "postalCode": "60661",
    //                         "address1": "",
    //                         "address2": "",
    //                         "addressType": "billing",
    //                         "id": 616
    //                     },
    //                     "id": 348
    //                 },
    //                 "id": 102,
    //                 "paymentMethodId": 348,
    //                 "resultingTransactionId": null,
    //                 "billingCycleTransactionId": 5392189556,
    //                 "planId": 56,
    //                 "attemptsCount": 0,
    //                 "subscriptionId": 5382180576,
    //                 "customerName": "Fail InsufficientFunds",
    //                 "planName": "Should Fail",
    //                 "startDate": "2020-05-23",
    //                 "status": "scheduled",
    //                 "transactionFailDate": "2020-05-28",
    //                 "accountId": 288,
    //                 "endDate": null,
    //                 "vendorId": "5382180576",
    //                 "account": {
    //                     "firstName": "Fail",
    //                     "lastName": "InsufficientFunds",
    //                     "id": 288,
    //                     "email": "vmatsunaga+failinsufficientfunds@spoton.com"
    //                 },
    //                 "dayFrequency": 1,
    //                 "monthFrequency": null,
    //                 "merchantId": "5ec8303c62f04a2d185b9ac8",
    //                 "remark": "DECLINED",
    //                 "totalAmount": 1000,
    //                 "created": "2020-05-28T09:07:44.746975Z",
    //                 "notes": {
    //                     "notes": []
    //                 },
    //                 "dayOfMonth": null,
    //                 "successfulTransactionsCount": 0,
    //                 "amount": "1000.0000",
    //                 "payments": 5,
    //                 "prorationVendorId": null
    //             }
    //         ],
    //         "Mini GifCard": [
    //             {
    //                 "paymentMethod": {
    //                     "account": {
    //                         "vendorId": "1688747973",
    //                         "id": "287"
    //                     },
    //                     "ccExpiration": "2028-04-30",
    //                     "vendor": "NMI",
    //                     "vendorId": "665519949",
    //                     "addressDd": 614,
    //                     "ccType": "Visa",
    //                     "email": null,
    //                     "isDefault": true,
    //                     "ccLastFour": "7692",
    //                     "address": {
    //                         "phone_number": "",
    //                         "city": "",
    //                         "firstName": "Mini",
    //                         "lastName": "Abd",
    //                         "countryCode": "",
    //                         "provinceCode": "",
    //                         "faxNumber": "",
    //                         "isDefault": true,
    //                         "postalCode": "60060",
    //                         "address1": "",
    //                         "address2": "",
    //                         "addressType": "billing",
    //                         "id": 614
    //                     },
    //                     "id": 347
    //                 },
    //                 "id": 93,
    //                 "paymentMethodId": 347,
    //                 "resultingTransactionId": null,
    //                 "billingCycleTransactionId": 5382837657,
    //                 "planId": 55,
    //                 "attemptsCount": 0,
    //                 "subscriptionId": 5382003859,
    //                 "customerName": "Mini GifCard",
    //                 "planName": "Mini",
    //                 "startDate": "2020-05-23",
    //                 "status": "scheduled",
    //                 "transactionFailDate": "2020-05-23",
    //                 "accountId": 287,
    //                 "endDate": "2020-05-26",
    //                 "vendorId": "5382003870",
    //                 "account": {
    //                     "firstName": "Mini",
    //                     "lastName": "GifCard",
    //                     "id": 287,
    //                     "email": "mini+p@spoton.com"
    //                 },
    //                 "dayFrequency": null,
    //                 "monthFrequency": 1,
    //                 "merchantId": "5ec8303c62f04a2d185b9ac8",
    //                 "remark": "INVALID TRANSACTION INFORMATION",
    //                 "totalAmount": 0.03,
    //                 "created": "2020-05-23T08:52:45.961649Z",
    //                 "notes": {
    //                     "notes": []
    //                 },
    //                 "dayOfMonth": 25,
    //                 "successfulTransactionsCount": 0,
    //                 "amount": "0.5000",
    //                 "payments": null,
    //                 "prorationVendorId": "5382003859"
    //             },
    //             {
    //                 "paymentMethod": {
    //                     "account": {
    //                         "vendorId": "1688747973",
    //                         "id": "287"
    //                     },
    //                     "ccExpiration": "2028-04-30",
    //                     "vendor": "NMI",
    //                     "vendorId": "665519949",
    //                     "addressDd": 614,
    //                     "ccType": "Visa",
    //                     "email": null,
    //                     "isDefault": true,
    //                     "ccLastFour": "7692",
    //                     "address": {
    //                         "phone_number": "",
    //                         "city": "",
    //                         "firstName": "Mini",
    //                         "lastName": "Abd",
    //                         "countryCode": "",
    //                         "provinceCode": "",
    //                         "faxNumber": "",
    //                         "isDefault": true,
    //                         "postalCode": "60060",
    //                         "address1": "",
    //                         "address2": "",
    //                         "addressType": "billing",
    //                         "id": 614
    //                     },
    //                     "id": 347
    //                 },
    //                 "id": 93,
    //                 "paymentMethodId": 347,
    //                 "resultingTransactionId": null,
    //                 "billingCycleTransactionId": 5386368039,
    //                 "planId": 55,
    //                 "attemptsCount": 0,
    //                 "subscriptionId": 5382003859,
    //                 "customerName": "Mini GifCard",
    //                 "planName": "Mini",
    //                 "startDate": "2020-05-23",
    //                 "status": "scheduled",
    //                 "transactionFailDate": "2020-05-25",
    //                 "accountId": 287,
    //                 "endDate": "2020-05-26",
    //                 "vendorId": "5382003870",
    //                 "account": {
    //                     "firstName": "Mini",
    //                     "lastName": "GifCard",
    //                     "id": 287,
    //                     "email": "mini+p@spoton.com"
    //                 },
    //                 "dayFrequency": null,
    //                 "monthFrequency": 1,
    //                 "merchantId": "5ec8303c62f04a2d185b9ac8",
    //                 "remark": "INVALID TRANSACTION INFORMATION",
    //                 "totalAmount": 0.03,
    //                 "created": "2020-05-25T09:07:43.882551Z",
    //                 "notes": {
    //                     "notes": []
    //                 },
    //                 "dayOfMonth": 25,
    //                 "successfulTransactionsCount": 0,
    //                 "amount": "0.5000",
    //                 "payments": null,
    //                 "prorationVendorId": "5382003859"
    //             }
    //         ]
    //     }
    // });
}

/**
 * Resolve a list of failed transactions
 *
 * @typedef ResolveFailedTransactionsResponse
 * @property {number} transactionId
 *
 * @param {number} subscriptionId
 * @param {number[]} transactionIds
 * @returns {AxiosResponse<ResolveFailedTransactionsResponse>}
 */
export function resolveFailedTransactions(subscriptionId, transactionIds) {
    return createAxiosWrapper().post(
        '/nmi_terminal/recurring/manual_subscription_resolve',
        {
            subscriptionId,
            billingCycleTransactionIds: transactionIds,
        },
    );
}

/**
 * Try charging a list of failed transactions
 *
 * @typedef ChargeFailedTransactionsResponse
 * @property {number} transactionId
 *
 * @param {number} subscriptionId
 * @param {number} paymentMethodId
 * @param {number[]} transactionIds
 * @returns {AxiosResponse<ChargeFailedTransactionsResponse>}
 */
export function chargeFailedTransactions(
    subscriptionId,
    paymentMethodId,
    transactionIds,
) {
    return createAxiosWrapper().post(
        '/nmi_terminal/recurring/manual_subscription_charge',
        {
            subscriptionId,
            paymentMethodId,
            billingCycleTransactionIds: transactionIds,
        },
    );
}
