import * as api from './api';
import { objectArrayToMap } from '../../utils/utils';


///////////////////////////////////////////////////////////
//
// Initial State
//
// NOTE: for the uninitiated look up Redux Entity Adapters for insight into
// this structure.
//
///////////////////////////////////////////////////////////
const defaultAccounts = {
    // All accounts with minimal detail
    // Keyed by account ID
    accountsMap: {},

    // One account with full details
    accountDetail: {},

    // Multiple ordered arrays of account ID's
    // Keyed by sortBy string
    accountsBy: {},

    duplicateAccounts: [],

    // modals
    isDuplicateAccountModalOpen: false,
    accountSearchText: '',
    isAccountSearchOpen: false,
    accountSearchResults: null,
    selectedSearchResult: null,

    // states
    isLoadingAccounts: false,
    isLoadingSingleAccount: false,
    isLoadingDuplicateAccounts: false,
    isCreatingAccount: false,
    isDeletingAccount: false,
    isSavingAccount: false,
    isSearchingAccount: false,

    isProcessAndCreate: false,
    error: null,

    isBillingAddressSame: true,

    websiteValidation: {
        isDirty: false,
        error: null
    }
};

const sortByFields = ['name', 'business', 'email', 'payment'];
sortByFields.forEach(sortBy => {
    defaultAccounts.accountsBy[sortBy] = [];
});


///////////////////////////////////////////////////////////
//
// Action Types
//
// NOTE: Good practice dictates REDUX actions should be events NOT commands...
// NOTE: Good practice to add a 'source' of the action and add a description of
//  the action. It makes tracing much easier.
//
///////////////////////////////////////////////////////////

const LOAD_ACCOUNTS = '[Accounts] Load All Accounts';
const LOADED_ACCOUNTS = '[Accounts] Loaded All Accounts';
const ERROR_LOADING_ACCOUNTS = '[Accounts] Error Loading All Accounts';
const SET_ACCOUNTS = '[Accounts] Set All Accounts';
const LOAD_SINGLE_ACCOUNT = '[Accounts] Load a Single Account';
const LOADED_SINGLE_ACCOUNT = '[Accounts] Loaded a Single Account';
const ERROR_LOADING_SINGLE_ACCOUNT = '[Accounts] Error Loading a Single Account';
const SET_SINGLE_ACCOUNT = '[Accounts] Set a Single Account';
const CREATE_ACCOUNT = '[Accounts] Create an Account';
const CREATED_ACCOUNT = '[Accounts] Created an Account';
const ERROR_CREATING_ACCOUNT = '[Accounts] Error Creating an Account';
const DELETE_ACCOUNT = '[Accounts] Delete an Account';
const DELETED_ACCOUNT = '[Accounts] Deleted an Account';
const ERROR_DELETING_ACCOUNT = '[Accounts] Error Deleting an Account';
const SAVE_ACCOUNT = '[Accounts] Save an Account';
const SAVED_ACCOUNT = '[Accounts] Saved an Account';
const ERROR_SAVING_ACCOUNT = '[Accounts] Error Saving an Account';
const LOAD_DUPLICATE_ACCOUNTS = '[Accounts] Load Duplicate Accounts';
const LOADED_DUPLICATE_ACCOUNTS = '[Accounts] Loaded Duplicate Accounts';
const ERROR_LOADING_DUPLICATE_ACCOUNTS = '[Accounts] Error Loading Duplicate Accounts';
const OPEN_DUPLICATE_ACCOUNTS_MODAL = '[Accounts] Open Duplicate Accounts Modal';
const CLOSE_DUPLICATE_ACCOUNTS_MODAL = '[Accounts] Close Duplicate Acccounts Modal';
const SEARCH_ACCOUNTS = '[Accounts] Search Accounts';
const SEARCHED_ACCOUNTS = '[Accounts] Searched Accounts';
const ERROR_SEARCHING_ACCOUNTS = '[Accounts] Error Searching Accounts';
const OPEN_ACCOUNT_SEARCH = '[Accounts] Open Account Search Window';
const CLOSE_ACCOUNT_SEARCH = '[Accounts] Close Account Search Window';
const SELECT_SEARCH_RESULT = '[Accounts] Select Search Result';
const SET_TRANSACT_AND_CREATE_ACCOUNT = '[Accounts] Set Run Transaction and Create Account';
const SET_IS_BILLING_ADDRESS_SAME = '[Accounts] Set Is Billing Address Same';
const WEBSITE_VALIDATION = '[Accounts] check for website valdiation';
const RESET_WEBSITE_VALIDATION = '[Accounts] reset check for website valdiation';


///////////////////////////////////////////////////////////
//
// Pure Actions
//
// NOTE: For every action type there should be a pure action
//
///////////////////////////////////////////////////////////

// Accounts are loading
export const loadAccounts = () => ({
    type: LOAD_ACCOUNTS,
});

// Accounts are finished loading
export const loadedAccounts = () => ({
    type: LOADED_ACCOUNTS,
});

export const setIsBillingAddressSame = (checked) => ({
    type: SET_IS_BILLING_ADDRESS_SAME,
    payload: checked
})

// There was an error loading accounts
//  param {Error} error - an standard error object
export const errorLoadingAccounts = (error) => ({
    type: ERROR_LOADING_ACCOUNTS,
    error,
});

// Set a list of accounts
//  param {Object[]} accounts - an array of accounts
export const setAccounts = (accounts, name) => ({
    type: SET_ACCOUNTS,
    accounts,
    name,
});

// Load a single account
export const loadAccount = () => ({
    type: LOAD_SINGLE_ACCOUNT,
});

// A single account is finished loading
export const loadedAccount = () => ({
    type: LOADED_SINGLE_ACCOUNT,
});

// There was an error loading a single account
//  param {Error} error - an standard error object
export const errorLoadingAccount = (error) => ({
    type: ERROR_LOADING_SINGLE_ACCOUNT,
    error,
});

// Set a single account
//  param {Object] account - an account object
export const setAccount = account => ({
    type: SET_SINGLE_ACCOUNT,
    account,
});

// Creating a single account
export const creatingAccount = () => ({
    type: CREATE_ACCOUNT,
});

// A single account is finished creating
export const createdAccount = () => ({
    type: CREATED_ACCOUNT,
});

// There was en error creating an account
//  param {Error} error - an standard error object
export const errorCreatingAccount = (error) => ({
    type: ERROR_CREATING_ACCOUNT,
    error,
});

// A single account is being deleted
export const deletingAccount = () => ({
    type: DELETE_ACCOUNT,
});

// A single account was deleted
export const deletedAccount = () => ({
    type: DELETED_ACCOUNT,
});

// There was an error deleing an account
//  param {Error} error - an standard error object
export const errorDeletingAccount = (error) => ({
    type: ERROR_DELETING_ACCOUNT,
    error,
});

// An account is being saved
export const savingAccount = () => ({
    type: SAVE_ACCOUNT,
});

// An account was saved
export const savedAccount = () => ({
    type: SAVED_ACCOUNT,
});

// There was an error saving an account
//  param {Error} error - an standard error object
export const errorSavingAccount = (error) => ({
    type: ERROR_SAVING_ACCOUNT,
    error,
});

// Load duplicate accounts list
export const loadDuplicateAccounts = () => ({
    type: LOAD_DUPLICATE_ACCOUNTS,
});

// Duplicate accounts list loaded
//  param {Object[]} duplicateAccounts - a list of duplicate accounts
export const loadedDuplicateAccounts = (duplicateAccounts) => ({
    type: LOADED_DUPLICATE_ACCOUNTS,
    duplicateAccounts,
});

// There was an error loading accounts
//  param {Error} error - an standard error object
export const errorLoadingDuplicateAccounts = (error) => ({
    type: ERROR_LOADING_DUPLICATE_ACCOUNTS,
    error,
});

// Open a duplicate account modal
export const openDuplicateAccountsModal = () => ({
    type: OPEN_DUPLICATE_ACCOUNTS_MODAL,
});

// Close a duplicate account modal
export const closeDuplicateAccountsModal = () => ({
    type: CLOSE_DUPLICATE_ACCOUNTS_MODAL,
});

// Search for accounts
//  param {string} searchText - the text to search for
export const searchingAccounts = (searchText) => ({
    type: SEARCH_ACCOUNTS,
    searchText,
});

// Finished searching accounts
//  param {Object} results - the search results
export const searchedAccounts = (results) => ({
    type: SEARCHED_ACCOUNTS,
    results,
});

// There was an error searching for accounts
//  param {Error} error - an standard error object
export const errorSearchingAccounts = (error) => ({
    type: ERROR_SEARCHING_ACCOUNTS,
    error,
});

// Open the account search dropdown window
export const openAccountSearch = () => ({
    type: OPEN_ACCOUNT_SEARCH,
});

// Close the account search dropdown window
export const closeAccountSearch = () => ({
    type: CLOSE_ACCOUNT_SEARCH,
});

// Select a search result
//  param {Object] account - an account object
export const selectSearchResult = account => ({
    type: SELECT_SEARCH_RESULT,
    account,
});

// Set whether or not we want to run a transaction AND create an account
//  param {boolean} processAndCreate - true if we want to also create an
//  account, false if we just want to run a transaction
export const setRunTransactionAndCreateAccount = (isProcessAndCreate) => ({
    type: SET_TRANSACT_AND_CREATE_ACCOUNT,
    isProcessAndCreate,
});

// check for website validation 
export const setWebsiteValidations = (payload) => ({
    type: WEBSITE_VALIDATION,
    payload
})

// reset check for website validation 
export const resetWebsiteValidations = () => ({
    type: RESET_WEBSITE_VALIDATION,
})

///////////////////////////////////////////////////////////
//
// Actions with side-effects (thunk)
//
///////////////////////////////////////////////////////////

// Get a list of all accounts
//  returns {Promise<Object[]>} a list of all accounts
export const fetchAccounts = ({
    page = 1,
    name = 'name',
    sortOrder = 'asc',
    q = '',
    statusFilters,
} = {}) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(loadAccounts());

        api.fetchAllAccounts({ page, name, sortOrder, q, statusFilters})
            .then((accounts) => {
                dispatch(setAccounts(accounts, name));
                dispatch(loadedAccounts());

                resolve(accounts);
            })
            .catch((e) => {
                dispatch(errorLoadingAccounts(e));

                reject(e);
            });
    });
};

// Get a single account
//  param {number} id - an account id
//  returns {Promise<Object>} an account object
export const fetchAccountById = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(loadAccount());

        api.fetchSingleAccount(id)
            .then((account) => {
                dispatch(setAccount(account));
                dispatch(loadedAccount());

                resolve(account);
            })
            .catch((e) => {
                dispatch(errorLoadingAccount(e));

                reject(e);
            });
    });
};

// Create a new account
//  param {Object} account - a new account object to create
//  returns {Promise<Object>} the newly created account
export const createAccount = (account) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(creatingAccount());

        api.createAccount(account)
            .then((response) => {
                dispatch(createdAccount());

                resolve(response);
            })
            .catch((e) => {
                dispatch(errorCreatingAccount(e));

                reject(e);
            });
    });
};

// Delete an account
//  param {Object} account - the account to delete
//  returns {Promise<Object>} the server response
export const deleteAccount = (account) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(deletingAccount());

        api.removeAccount(account)
            .then((response) => {
                dispatch(deletedAccount());

                resove(response);
            })
            .catch((e) => {
                dispatch(errorDeletingAccount(e));

                reject(e);
            });
    });
};

// Save an account
//  param {Object} account - an account to update
//  returns {Promise<Object>} the updated account
export const saveAccount = (account) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(savingAccount());

        api.editAccount(account)
            .then((response) => {
                dispatch(savedAccount());

                resolve(response);
            })
            .catch((e) => {
                dispatch(errorSavingAccount(e));

                reject(e);
            });
    });
};


// Get a blank account and select it
export const getBlankAccount = () => (dispatch) => {
    dispatch(setAccount(
        api.getBlankAccount()
    ));
};

// Search accounts
//  param {string} firstname - the first name in an account
//  param {string} lastname - the last name in an account
//  param {string} email - the email of an account
//  param {string} companyname - the company name of the account
//  returns {Promise<Object>} the account list that matches the results
export const getDuplicateAccounts = (firstname, lastname, email, companyname = '') => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(loadDuplicateAccounts());

        api.checkAccountDuplicate(firstname, lastname, email, companyname)
            .then((response) => {
                dispatch(loadedDuplicateAccounts(response));

                resolve(response);
            })
            .catch((e) => {
                dispatch(errorLoadingDuplicateAccounts(e));

                reject(e);
            });
    });
};

// Search accounts
//  param {string} searchText - the text to search on
//  returns {Promise<Object>} the account list that matches the results
export const searchAccounts = (searchText) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(searchingAccounts(searchText));

        api.searchAccounts(searchText)
            .then((response) => {
                dispatch(searchedAccounts(response));

                resolve(response);
            })
            .catch((e) => {
                dispatch(errorSearchingAccounts(e));

                reject(e);
            });
    });
};

///////////////////////////////////////////////////////////
//
// Reducer
//
// NOTE: please do not put complex logic here (more than one line) You should
// only be updating a value in the state based on the action payload, or what
// the state dictates.
//
///////////////////////////////////////////////////////////

export default function (state = defaultAccounts, action) {
    switch (action.type) {

        case LOAD_ACCOUNTS: {
            return {
                ...state,
                isLoadingAccounts: true,
            };
        }

        case LOADED_ACCOUNTS: {
            return {
                ...state,
                isLoadingAccounts: false,
            };
        }

        case ERROR_LOADING_ACCOUNTS: {
            return {
                ...state,
                isLoadingAccounts: false,
                error: action.error,
            };
        }

        case SET_ACCOUNTS: {
            // Save all accounts in state
            const accountsMap = objectArrayToMap(action.accounts.results);

            // Save all sort orders in state
            const accountsBy = {};
            Object.keys(action.name).forEach(() => {
                accountsBy[action.name] = action.accounts.results
                    .slice()
                    .map(account => account.id);
            });

            return {
                ...state,
                accountsMap,
                accountsBy,
                currentPage: action.accounts.currentPage,
                totalPages: action.accounts.totalPages,
                totalNumResults: action.accounts.totalNumResults,
            };
        }

        case LOAD_SINGLE_ACCOUNT: {
            return {
                ...state,
                isLoadingSingleAccount: true,
            };
        }

        case LOADED_SINGLE_ACCOUNT: {
            return {
                ...state,
                isLoadingSingleAccount: false,
            };
        }

        case ERROR_LOADING_SINGLE_ACCOUNT: {
            return {
                ...state,
                isLoadingSingleAccount: false,
                error: action.error,
            };
        }

        case SET_SINGLE_ACCOUNT: {
            return ({
                ...state,
                accountDetail: action.account,
            });
        }

        case CREATE_ACCOUNT: {
            return {
                ...state,
                isCreatingAccount: true,
            };
        }

        case CREATED_ACCOUNT: {
            return {
                ...state,
                isCreatingAccount: false,
            };
        }

        case ERROR_CREATING_ACCOUNT: {
            return {
                ...state,
                isCreatingAccount: false,
                error: action.error,
            };
        }

        case DELETE_ACCOUNT: {
            return {
                ...state,
                isDeletingAccount: true,
            };
        }

        case DELETED_ACCOUNT: {
            return {
                ...state,
                isDeletingAccount: false,
            };
        }

        case ERROR_DELETING_ACCOUNT: {
            return {
                ...state,
                isDeletingAccount: false,
                error: action.error,
            };
        }

        case SAVE_ACCOUNT: {
            return {
                ...state,
                isSavingAccount: true,
            };
        }

        case SAVED_ACCOUNT: {
            return {
                ...state,
                isSavingAccount: false,
            };
        }

        case ERROR_SAVING_ACCOUNT: {
            return {
                ...state,
                isSavingAccount: false,
                error: action.error,
            };
        }

        case LOAD_DUPLICATE_ACCOUNTS: {
            return {
                ...state,
                isLoadingDuplicateAccounts: true,
            };
        }

        case LOADED_DUPLICATE_ACCOUNTS: {
            return {
                ...state,
                isLoadingDuplicateAccounts: false,
                duplicateAccounts: action.duplicateAccounts,
            };
        }

        case ERROR_LOADING_DUPLICATE_ACCOUNTS: {
            return {
                ...state,
                isLoadingDuplicateAccounts: false,
                error: action.error,
            };
        }

        case OPEN_DUPLICATE_ACCOUNTS_MODAL: {
            return {
                ...state,
                isDuplicateAccountModalOpen: true,
            };
        }

        case CLOSE_DUPLICATE_ACCOUNTS_MODAL: {
            return {
                ...state,
                isDuplicateAccountModalOpen: false,
            };
        }

        case SEARCH_ACCOUNTS: {
            return {
                ...state,
                isSearchingAccount: true,
                accountSearchText: action.seachText,
                accountSearchResults: null,
            };
        }

        case SEARCHED_ACCOUNTS: {
            return {
                ...state,
                isSearchingAccount: false,
                accountSearchResults: action.results,
            };
        }

        case ERROR_SEARCHING_ACCOUNTS: {
            return {
                ...state,
                error: action.error,
                isSearchingAccount: false,
            };
        }

        case OPEN_ACCOUNT_SEARCH: {
            return {
                ...state,
                isAccountSearchOpen: true,
            };
        }

        case CLOSE_ACCOUNT_SEARCH: {
            return {
                ...state,
                isAccountSearchOpen: false,
            };
        }

        case SELECT_SEARCH_RESULT: {
            return {
                ...state,
                selectedSearchResult: action.account,
            };
        }

        case SET_TRANSACT_AND_CREATE_ACCOUNT: {
            return {
                ...state,
                isProcessAndCreate: action.isProcessAndCreate,
            };
        }

        case SET_IS_BILLING_ADDRESS_SAME: {
            return {
                ...state,
                isBillingAddressSame: action.payload,
            }
        }

        case WEBSITE_VALIDATION: {
            return {
                ...state,
                websiteValidation: action.payload
            }
        }

        case RESET_WEBSITE_VALIDATION: 
            return {
                ...state,
                websiteValidation: {
                    isDirty: false,
                    error: null,
                }
            }

        default: {
            return state;
        }
    }
}
