import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import sortBy from 'lodash.sortby';

import moment from 'moment';
import { Decimal } from 'decimal.js';
import PropTypes from 'prop-types';
import { DATE_FORMAT_BACKEND } from '../../recurringPayments/helpers/dateHelpers';
import { formatBackendReminders, getRemindersRequiredValues } from '../../remindersPage/remindersPage.constants';
import * as STATUSES from '../api/invoices.constants';

const formatNumbers = (number) => {
    return new Decimal(number || 0)
        .toDecimalPlaces(4, Decimal.ROUND_FLOOR)
        .toString();
};

const formatItems = (lineItems) => {
    return lineItems.map(item => {
        return {
            ...item,
            unitPrice: formatNumbers(item.unitPrice),
            totalAmount: formatNumbers(item.totalAmount),
            quantity: item.quantity.toString(),
        };
    });
};

const formatDiscount = ({ itemsGrossSale, discount }) => {
    // If there was no discount, send []
    if (new Decimal(discount.specValue || 0).equals(0)) {
        return [];
    }

    let monetaryValue = discount.monetaryValue;
    let specValue = discount.specValue;

    // Discounts that are in excess of gross sales,
    //   should be capped at the gross sale amount
    if (
        new Decimal(monetaryValue || 0).greaterThan(itemsGrossSale)
        && discount.disposition === 'absolute'
    ) {
        monetaryValue = itemsGrossSale;
        specValue = itemsGrossSale;
    }

    return [{
        // Text values should not be empty
        name: discount.name || 'Discount',
        disposition: discount.disposition || 'relative',
        // Monetary values should be negative
        monetaryValue: new Decimal(monetaryValue || 0)
            .times(-1)
            .toDecimalPlaces(2, Decimal.ROUND_FLOOR)
            .toString(),
        specValue: new Decimal(specValue || 0)
            .times(-1)
            .toDecimalPlaces(2, Decimal.ROUND_FLOOR)
            .toString(),
    }];
};

export const buildDataForInvoice = (invoice, status, merchant) => {
    return {
        merchantId: merchant.id,
        title: get(invoice, 'invoiceDetails.title', ''),
        message: get(invoice, 'invoiceDetails.message', ''),
        terms: get(invoice, 'invoiceDetails.terms', ''),
        sendDate: moment(invoice.sendInvoiceDate).format(DATE_FORMAT_BACKEND),
        dueDate: moment(invoice.balanceDueDate).format(DATE_FORMAT_BACKEND),
        totalAmount: formatNumbers(invoice.itemsTotal),
        lineItems: formatItems(invoice.lineItems),
        discounts: !isEmpty(invoice.discount) ? formatDiscount(invoice) : '',
        convenienceFeeRate: merchant.convenience_fee_enabled && !(new Decimal(invoice.convenienceFee).isZero()) ? merchant.convenience_fee_rate : '0',
        convenienceFeeAmount: formatNumbers(invoice.convenienceFee),
        taxRate: merchant.tax_enabled && !(new Decimal(invoice.taxAmount).isZero()) ? merchant.tax_rate || '0' : '0',
        taxAmount: formatNumbers(invoice.taxAmount),
        grossSaleAmount: formatNumbers(invoice.itemsGrossSale),
        subTotalAmount: formatNumbers(invoice.itemsSubTotal),
        depositAmount: formatNumbers(invoice.depositAmount),
        requestShippingAddress: invoice.requestShippingAddress,
        reminders: formatBackendReminders(invoice.invoiceReminders),
        convenienceFeeBeforeTaxes: merchant.convenience_fee_before_taxes,
        depositDate: moment(invoice.depositDueDate).format(DATE_FORMAT_BACKEND),
        accountId: get(invoice, 'account.id') ? get(invoice, 'account.id') : null,
        customId: get(invoice, 'invoiceDetails.customInvoiceId', ''),
        defaultAttachmentIds: invoice.defaultAttachments.map(attachment => attachment.id),
        status,
    };
};

export const buildDataForUpdate = (invoice, status, merchant) => {
    const oldInvoice = invoice.selectedInvoice;

    /**
     * When updating an invoice:
     * - the payload should include account as a valid integer or null
     * - the payload should not include customId
     */
    const newAccountId = get(invoice, 'account.id', null);
    const oldAccountId = oldInvoice.account ? oldInvoice.account.id : null;
    const formattedInvoiceReminders = formatBackendReminders(invoice.invoiceReminders);
    const areRemindersEqual = isEqual(sortBy(getRemindersRequiredValues(oldInvoice.reminders), (reminder) => reminder.days), sortBy(formattedInvoiceReminders, (reminder) => reminder.days));

    const data = [{
        name: 'merchant',
        condition: oldInvoice.merchantId !== merchant.id,
        value: merchant.id,
    },
    {
        name: 'accountId',
        condition: oldAccountId !== newAccountId,
        value: newAccountId,
    },
    {
        name: 'title',
        condition: oldInvoice.title !== get(invoice, 'invoiceDetails.title'),
        value: get(invoice, 'invoiceDetails.title', ''),
    }, {
        name: 'message',
        condition: oldInvoice.message !== get(invoice, 'invoiceDetails.message'),
        value: get(invoice, 'invoiceDetails.message', ''),
    }, {
        name: 'terms',
        condition: oldInvoice.terms !== get(invoice, 'invoiceDetails.terms'),
        value: get(invoice, 'invoiceDetails.terms', ''),
    }, {
        name: 'sendDate',
        condition: oldInvoice.sendDate !== moment(invoice.sendInvoiceDate).format(DATE_FORMAT_BACKEND),
        value: moment(invoice.sendInvoiceDate).format(DATE_FORMAT_BACKEND),
    }, {
        name: 'dueDate',
        condition: oldInvoice.dueDate !== moment(invoice.balanceDueDate).format(DATE_FORMAT_BACKEND),
        value: moment(invoice.balanceDueDate).format(DATE_FORMAT_BACKEND),
    }, {
        name: 'totalAmount',
        condition: !(new Decimal(oldInvoice.totalAmount).equals(invoice.itemsTotal)),
        value: formatNumbers(invoice.itemsTotal),
    }, {
        name: 'lineItems',
        condition: !isEqual(oldInvoice.lineItems, invoice.lineItems),
        value: formatItems(invoice.lineItems),
    }, {
        name: 'discounts',
        condition: !isEqual(oldInvoice.discounts, formatDiscount(invoice)),
        value: formatDiscount(invoice),
    }, {
        name: 'convenienceFeeAmount',
        condition: !(new Decimal(oldInvoice.convenienceFeeAmount).equals(invoice.convenienceFee)),
        value: formatNumbers(invoice.convenienceFee),
    }, {
        name: 'taxAmount',
        condition: !(new Decimal(oldInvoice.taxAmount).equals(invoice.taxAmount)),
        value: formatNumbers(invoice.taxAmount),
    }, {
        name: 'grossSaleAmount',
        condition: !(new Decimal(oldInvoice.grossSaleAmount).equals(invoice.itemsGrossSale)),
        value: formatNumbers(invoice.itemsGrossSale),
    }, {
        name: 'subTotalAmount',
        condition: !(new Decimal(oldInvoice.subTotalAmount).equals(invoice.itemsSubTotal)),
        value: formatNumbers(invoice.itemsSubTotal),
    }, {
        name: 'depositAmount',
        condition: !(new Decimal(oldInvoice.depositAmount).equals(invoice.depositAmount)),
        value: formatNumbers(invoice.depositAmount),
    }, {
        name: 'requestShippingAddress',
        condition: oldInvoice.requestShippingAddress !== invoice.requestShippingAddress,
        value: invoice.requestShippingAddress,
    }, {
        name: 'reminders',
        condition: !areRemindersEqual,
        value: formatBackendReminders(invoice.invoiceReminders),
    }, {
        name: 'convenienceFeeBeforeTaxes',
        condition: oldInvoice.convenienceFeeBeforeTaxes !== merchant.convenience_fee_before_taxes,
        value: merchant.convenience_fee_before_taxes,
    }, {
        name: 'depositDate',
        condition: oldInvoice.depositDate !== moment(invoice.depositDueDate).format(DATE_FORMAT_BACKEND),
        value: moment(invoice.depositDueDate).format(DATE_FORMAT_BACKEND),
    }, {
        name: 'status',
        condition: status !== 'draft',
        value: status,
    }, {
        name: 'customId',
        condition: oldInvoice.customId !== get(invoice, 'invoiceDetails.customInvoiceId', ''),
        value: get(invoice, 'invoiceDetails.customInvoiceId', ''),
    },
    {
        name: 'defaultAttachmentIds',
        condition: invoice.defaultAttachments.length !== oldInvoice.defaultAttachments.length,
        value: invoice.defaultAttachments.map(attachment => attachment.id),
    }];

    return data.reduce((acc, current) => {
        if (current.condition) {
            Object.assign(acc, { [current.name]: current.value });
        }

        return acc;
    }, {});
};

export const getInvoiceFriendlyStatus = (status) => {
    if (isUnPaid(status)) {
        return STATUSES.UNPAID;
    }
    if (status === STATUSES.DELIVERY_ERROR) {
        return STATUSES.UNDELIVERED;
    }
    return status;
};

const isUnPaid = (status) => {
    const doesNotBelong = [
        STATUSES.DRAFT,
        STATUSES.DELIVERY_ERROR,
        STATUSES.VOIDED,
        STATUSES.PAID,
        STATUSES.ARCHIVED,
        STATUSES.OVERDUE,
        STATUSES.PARTIALLY_PAID,
    ];

    const belongs = [
        STATUSES.SCHEDULED,
        STATUSES.DELIVERED,
    ];

    return (
        doesNotBelong.every(el => status !== el) &&
        belongs.some(el => status === el)
    );
};

/**
 * Convert an arrays elements (snake_case strings) to user friendly labels
 *  @param {Array} arrayToSerialize - an array to convert
 *  @returns {Array} returns a copy of the array with user friendly labels
 */
export function serializeToLabel(arrayToSerialize = []) {
    // Exceptions to the rule
    function transformToLabel(word) {
        const custom = {
            account: 'Account',
            attachments: 'Attachments',
            convenience_fee_amount: 'Convenience Fee',
            convenience_fee_before_taxes: 'Convenience Fee Setting Before Taxes',
            convenience_fee_rate: 'Convenience Fee Rate',
            custom_id: 'Custom Id',
            deposit_amount: 'Deposit',
            deposit_date: 'Deposit Date',
            deposit_required: 'Deposit Required',
            discounts: 'Discount',
            due_date: 'Due Date',
            line_items: 'Line Items',
            message: 'Message',
            terms: 'Terms and Conditions',
            reminders: 'Reminders',
            send_date: 'Send Date',
            status: 'Status',
            gross_sale_amount: 'Gross Sale',
            sub_total_amount: 'Subtotal',
            tax_amount: 'Tax',
            tax_rate: 'Tax Rate',
            title: 'Title',
            total_amount: 'Total',
        };
        if (custom[word] !== undefined) {
            return custom[word];
        }
        return word.replace(/_/g, ' ').replace(/\b(\w)/g, c => c.toUpperCase());
    }

    return arrayToSerialize.map(word => transformToLabel(word));
}
const mapReminders = (reminders) => {
    return reminders.map(reminder => {
        const base = {
            days: Math.abs(reminder.days),
            isOpen: reminder.message.length > 0,
            message: reminder.message,
            isDeposit: reminder.isDeposit ?? false
        };

        if (reminder.days === 0) {
            return ({
                ...base,
                options: {
                    value: 'on',
                    label: 'On due date',
                },
            });
        }
        if (reminder.days > 0) {
            return ({
                ...base,
                options: {
                    value: 'after',
                    label: 'Days after due date',
                },
            });
        }
        return ({
            ...base,
            options: {
                value: 'before',
                label: 'Days before due date',
            },
        });
    });
};

export const mapInvoiceSettingsToInvoice = (invoiceSettings) => {
    const {
        defaultInvoiceTitle,
        defaultInvoiceMessage,
        defaultTermsAndConditions,
        requestShippingAddress,
        defaultDeliveryDelayDays,
        autoscheduleReminders,
        reminders,
        defaultDueDays,
        defaultAttachments,
    } = invoiceSettings;

    return {
        invoiceDetails: {
            title: defaultInvoiceTitle || '',
            message: defaultInvoiceMessage || '',
            terms: defaultTermsAndConditions || '',
        },
        sendInvoiceDate: moment().add(defaultDeliveryDelayDays || 0, 'days'),
        requestShippingAddress,
        sendReminders: autoscheduleReminders,
        invoiceReminders: mapReminders(reminders),
        balanceDueDate: moment().add((defaultDeliveryDelayDays || 0) + (defaultDueDays || 0), 'days'),
        defaultAttachments,
    };
};
