import * as FullStory from '@fullstory/browser';

export function initializeFullStory() {
    FullStory.init({
        orgId: 'TKNJE',
        namespace: 'FS',
        debug: false,
    });
}

export function identifyCurrentUser(id, email, name, data = {}) {
    try {
        FullStory.identify(id, {
            displayName: name,
        });
        FullStory.setUserVars({
            email,
            name,
            ...data,
        });
    } catch (e) {
        console.info('Did not set full-story session info', e);
    }
}
