import React, { Component } from 'react';
import moment from 'moment';
import customerInvoiceHeaderProps from './customerInvoiceHeader.types';
import CustomerInvoiceIllustration from '../customerInvoiceIllustration';
import { capitalize, formatDollars } from '../../../../utils/utils';
import styles from './customerInvoiceHeader.module.scss';
import { DATE_FORMAT_PAYMENT_DUE, DATE_FORMAT_USER_FRIENDLY } from '../../../recurringPayments/helpers/dateHelpers';
import { ARCHIVED, PAID, VOIDED } from '../../../invoicing/api/invoices.constants';
import { getPaymentAmount } from '../../utils/getPaymentAmount';

class CustomerInvoiceHeader extends Component {
    static propTypes = customerInvoiceHeaderProps;

    renderDisplayDate = (isDeposit) => {
        const { invoice } = this.props;
        const paymentDue = moment(isDeposit ? invoice.depositDate : invoice.dueDate);
        return invoice.isOverdue
            ? paymentDue.format(DATE_FORMAT_PAYMENT_DUE)
            : paymentDue.format(DATE_FORMAT_USER_FRIENDLY);
    }

    isInvoiceVoided = (invoice) => {
        return invoice.status === VOIDED;
    }

    isInvoiceArchived = (invoice) => {
        return invoice.status === ARCHIVED;
    }

    getPaymentText = (isDeposit) => {
        const { invoice, isPaid } = this.props;
        if (isPaid) {
            return `Invoice ${capitalize(PAID)} ${moment(invoice.lastPaymentReceivedDate).format(DATE_FORMAT_USER_FRIENDLY)}`;
        }
        if (this.isInvoiceVoided(invoice)) {
            return `Invoice ${capitalize(VOIDED)} ${moment(invoice.voidedOrArchivedDate).format(DATE_FORMAT_USER_FRIENDLY)}`;
        }
        if (this.isInvoiceArchived(invoice)) {
            return `Invoice ${capitalize(ARCHIVED)} ${moment(invoice.voidedOrArchivedDate).format(DATE_FORMAT_USER_FRIENDLY)}`;
        }
        return `${invoice.isOverdue ? 'Overdue since' : 'Payment due'} ${this.renderDisplayDate(isDeposit)}`;
    }

    render() {
        const {
            invoice,
            merchant,
            isPaid,
            isPaymentApproved,
            invoiceSettings
        } = this.props;
        const isDeposit = !invoice.depositPaid && invoice.depositRequired;
        return (
            <div className={styles.CustomerFacingHeader}>
                <header className={styles.Header}>
                    <h6 className={styles.Header_title}>
                        Invoice #
                        {invoice.customId ? invoice.customId : invoice.id}
                    </h6>
                </header>
                {!isPaymentApproved && (
                    <div className={styles.Content}>
                        <div className={styles.Merchant}>
                            {
                                invoiceSettings.logoUrl && (
                                    <img src={invoiceSettings.logoUrl} alt="Logo of the restaurant"   className={styles.Merchant_img}/>
                                )}
                            <p className={`${styles.Merchant_name} ${merchant.merchantLogoUrl ? '' : styles.Merchant_name__noLogo}`}>
                                {merchant.merchantName}
                            </p>
                        </div>
                        <div className={styles.Invoice}>
                            <p className={styles.Invoice_amount}>
                                {
                                    formatDollars(getPaymentAmount(invoice))
                                }
                            </p>
                            {
                                isDeposit && (
                                    <p className={styles.Invoice_deposit}>
                                        Deposit
                                    </p>
                                )}
                            <p
                                className={`
                                    ${styles.Invoice_paymentDue}
                                    ${
                                        isPaid
                                            ? styles.Invoice_paymentDue___paid
                                            : invoice.isOverdue || this.isInvoiceVoided(invoice) || this.isInvoiceArchived(invoice)
                                                ? styles.Invoice_paymentDue___overdue
                                                : ''
                                    }
                            `}
                            >
                                {
                                    this.getPaymentText(isDeposit)
                                }
                            </p>
                            {[VOIDED, PAID, ARCHIVED].includes(invoice.status) && (
                                <CustomerInvoiceIllustration status={invoice.status} />
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}


export default CustomerInvoiceHeader;
