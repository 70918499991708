import PropTypes from 'prop-types';

import { 
    toggleRequestInitialDeposit,
} from '../../invoicing/redux/invoices/invoices.actions';
import { rightNavActions } from '../../common/redux/rightNav';

const PaymentScheduleProps = {
    requestInitialDeposit: PropTypes.bool,
    balanceDueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    itemsTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    balanceTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    depositAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    depositDueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    /* open right nav and navigate to url */
    openAndNavigateRightNav: PropTypes.func,
    openRightNav: PropTypes.func,
};

export const mapStateToProps = ({ invoices }) => ({
    requestInitialDeposit: invoices.requestInitialDeposit,
    balanceDueDate: invoices.balanceDueDate,
    itemsTotal: invoices.itemsTotal,
    balanceTotal: invoices.balanceTotal,
    depositAmount: invoices.depositAmount,
    depositDueDate: invoices.depositDueDate,
});

export const mapDispatchToProps = {
    toggleRequestInitialDeposit,
    openAndNavigateRightNav: rightNavActions.openAndNavigateRightNav,
    openRightNav: rightNavActions.openRightNav,
};

export default PaymentScheduleProps;
