import * as subscriptionApi from '../../api/notes.data';

export const LOADING_NOTES = '[Notes] Loading notes';
export const LOADED_NOTES = '[Notes] Loaded notes';
export const ERROR_LOADING_NOTES = '[Notes] Error loading notes';
export const SAVING_NOTE = '[Notes] Saving note';
export const SAVED_NOTE = '[Notes] Saved note';
export const ERROR_SAVING_NOTE = '[Notes] Error saving note';

/** we are currently loading notes */
export function loadingNotes() {
    return {
        type: LOADING_NOTES,
    };
}

/**
 * We successfully loaded all the notes
 *
 * @param {SubscriptionNotesResponse} notes
 */
export function loadedNotes(notes) {
    return {
        type: LOADED_NOTES,
        payload: notes,
    };
}

/**
 * Something went wrong loading the notes
 *
 * @param {Error} error
 */
export function errorLoadingNotes(error) {
    return {
        type: ERROR_LOADING_NOTES,
        payload: error,
    };
}

/**
 * We are currently saving a note
 */
export function savingNote() {
    return {
        type: SAVING_NOTE,
    };
}

/**
 * We successfully saved a note
 */
export function savedNote() {
    return {
        type: SAVED_NOTE,
    };
}

/**
 * Something went wrong saving a note
 *
 * @param {Error} error
 */
export function errorSavingNote(error) {
    return {
        type: ERROR_SAVING_NOTE,
        payload: error,
    };
}

/**
 * Get a list of all the notes for a subscription and set the state
 *
 * @param {number?} subscriptionId
 * @param {number?} planId
 */
export function getNotes(subscriptionId) {
    /* eslint-disable func-names */
    return function (dispatch) {
        dispatch(loadingNotes());

        return subscriptionApi.getNotes(subscriptionId)
            .then(res => {
                dispatch(loadedNotes(res.data));
            })
            .catch(error => {
                dispatch(errorLoadingNotes(error));
            });
    };
}

/**
 * Save a note and set the state
 *
 * @param {string} subscriptionId
 * @param {string} note
 */
export function saveNote(subscriptionId, note) {
    /* eslint-disable func-names */
    return function (dispatch) {
        dispatch(savingNote());

        return subscriptionApi.saveNote(subscriptionId, note)
            /* eslint-disable no-unused-vars */
            .then(res => {
                dispatch(savedNote());
            })
            .catch(error => {
                dispatch(errorSavingNote(error));
            });
    };
}
