import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectIsOpen } from '../../redux/rightNav/rightNav.selectors';

import styles from './rightNav.module.scss';

class RightNav extends React.Component {
    static propTypes = {
        /** The sidenav content */
        children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),

        /** Is the the right nav open/close */
        isOpen: PropTypes.bool.isRequired,
    };

    componentWillUnmount() {
        const body = document.body;
        body.classList.remove(styles.DisableScroll);
    }

    render() {
        const {
            children,
            isOpen,
        } = this.props;

        /** We need to attach a class to the `body` of the document in order to
         * prevent scrolling on the background (main) content when a menu is
         * opened up.
         */
        const body = document.body;
        if (isOpen) {
            body.classList.add(styles.DisableScroll);
        } else {
            body.classList.remove(styles.DisableScroll);
        }

        return (
            <React.Fragment>
                <span className={`${isOpen ? styles.Overlay : ''}`} />
                <div className={`${styles.RightNav} ${isOpen ? styles.RightNav___open : ''}`}>
                    {children}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isOpen: selectIsOpen(state),
});

export default connect(
    mapStateToProps,
)(RightNav);
