

import get from 'lodash.get';
import * as AccountService from '../../api/accounts';
import addNotification, { DANGER, SUCCESS } from '../../../common/utils/notificationHelper';
import { buildAccountData, buildEditAccount } from '../../helpers/buildAccount';

const ns = '[Recurring payments account]';
export const ACCOUNT_TYPE = {
    INDIVIDUAL: 'individual',
    BUSINESS: 'business'
}
export const GET_ACCOUNTS_STARTED = `${ns} get accounts started`;
export const GET_ACCOUNTS_SUCCESS = `${ns} get accounts success`;
export const GET_ACCOUNTS_FAILED = `${ns} get accounts failed`;
export const GET_ACCOUNTS_WITH_PARAMS_STARTED = `${ns} get accounts with params started`;
export const GET_ACCOUNTS_WITH_PARAMS_SUCCESS = `${ns} get accounts with params success`;
export const GET_ACCOUNTS_WITH_PARAMS_FAILED = `${ns} get accounts with params failed`;
export const GET_ACCOUNT_STARTED = `${ns} get single account started`;
export const GET_ACCOUNT_SUCCESS = `${ns} get single account success`;
export const GET_ACCOUNT_FAILED = `${ns} get single account failed`;
export const SET_ACCOUNT = `${ns} sets the account selected`;
export const UPDATE_PAYMENT_INFORMATION = `${ns} update payment information`;
export const CREATE_ACCOUNT_STARTED = `${ns} create account started`;
export const CREATE_ACCOUNT_SUCCESS = `${ns} create account success`;
export const CREATE_ACCOUNT_FAILED = `${ns} create account failed`;
export const UPDATE_ACCOUNT_STARTED = `${ns} update account started`;
export const UPDATE_ACCOUNT_SUCCESS = `${ns} update account success`;
export const UPDATE_ACCOUNT_FAILED = `${ns} update account failed`;

export const getAccountsStarted = () => ({
    type: GET_ACCOUNTS_STARTED
});

export const getAccountsSuccess = (payload) => ({
    type: GET_ACCOUNTS_SUCCESS,
    payload
});

export const getAccountsFailed = (err) => ({
    type: GET_ACCOUNTS_FAILED,
    payload: err
});

export const getAccountsWithParamsStarted = () => ({
    type: GET_ACCOUNTS_WITH_PARAMS_STARTED
});

export const getAccountsWithParamsSuccess = (payload) => ({
    type: GET_ACCOUNTS_WITH_PARAMS_SUCCESS,
    payload
});

export const getAccountsWithParamsFailed = (err) => ({
    type: GET_ACCOUNTS_WITH_PARAMS_FAILED,
    payload: err
});

export const getAccountStarted = () => ({
    type: GET_ACCOUNT_STARTED
});

export const getAccountSuccess = (payload) => ({
    type: GET_ACCOUNT_SUCCESS,
    payload
});

export const getAccountFailed = (err) => ({
    type: GET_ACCOUNT_FAILED,
    payload: err
});

export const createAccountStarted = () => ({
    type: CREATE_ACCOUNT_STARTED
});

export const createAccountsSuccess = (payload) => ({
    type: CREATE_ACCOUNT_SUCCESS,
    payload
});

export const createAccountsFailed = (err) => ({
    type: CREATE_ACCOUNT_FAILED,
    payload: err
});

export const updateAccountStarted = () => ({
    type: UPDATE_ACCOUNT_STARTED
});

export const updateAccountsSuccess = (payload) => ({
    type: UPDATE_ACCOUNT_SUCCESS,
    payload
});

export const updateAccountsFailed = (err) => ({
    type: UPDATE_ACCOUNT_FAILED,
    payload: err
});

export const setAccountSelected = (account) => ({
    type: SET_ACCOUNT,
    payload: account
});

export const updatePaymentInformation = (payment) => ({
    type: UPDATE_PAYMENT_INFORMATION,
    payload: payment
});

export const getAllAccounts = () => (dispatch) => {
    dispatch(getAccountsStarted());
    return AccountService.fetchAllAccounts()
        .then(res => {
            if (![200, 201].includes(res.status)) {
                throw new Error(res.data.error);
            }
            dispatch(getAccountsSuccess(res.data));
            return res.data;
        })
        .catch(error => {
            dispatch(getAccountsFailed(get(error, 'response')));
        });
};

export const getAccountsWithParams = (params) => (dispatch) => {
    dispatch(getAccountsWithParamsStarted());

    return AccountService.fetchAccounts(params)
        .then((response) => {
            if (![200, 201].includes(response.status)) {
                throw new Error(response.data.error);
            }
            
            dispatch(getAccountsWithParamsSuccess(response.data));

            return response.data;
        })
        .catch((error) => {
            dispatch(getAccountsWithParamsFailed(get(error, 'response')));
        });
}

export const createAccount = (data) => (dispatch) => {
    dispatch(createAccountStarted());
    return AccountService.createAccount(buildAccountData(data))
        .then(res => {
            if (![200, 201].includes(res.status)) {
                throw new Error(res.data.error);
            }
            dispatch(createAccountsSuccess(res.data));
            addNotification({
                title: 'Account Created',
                message: 'You have successfully created an Account',
                type: SUCCESS,
            });
            return res.data;
        })
        .catch(error => {
            addNotification({
                title: get(error, 'response.data.error', 'Something went wrong'),
                type: DANGER,
            });
            dispatch(createAccountsFailed(get(error, 'response')));
        });
};

export const updateAccount = (selectedAccount, data) => (dispatch, getState) => {
    dispatch(updateAccountStarted());
    const account = buildEditAccount(selectedAccount, data);
    return AccountService.updateAccount(selectedAccount.id, account)
        .then(res => {
            if (![200, 201].includes(res.status)) {
                throw new Error(res.data.error);
            }
            dispatch(updateAccountsSuccess(res.data));
            addNotification({
                title: 'Account updated',
                message: 'You have successfully updated an Account',
                type: SUCCESS,
            });
            return res.data;
        })
        .catch(error => {
            addNotification({
                title: get(error, 'response.data.error', 'Something went wrong'),
                type: DANGER,
            });
            dispatch(createAccountsFailed(get(error, 'response')));
        });
};

export const getAccount = (accountId) => (dispatch) => {
    dispatch(getAccountStarted());
    return AccountService.fetchSingleAccount(accountId)
        .then(({ data }) => {
            dispatch(getAccountSuccess(data));
            return data;
        })
        .catch((e) => {
            dispatch(getAccountFailed(e));
        });
};
