import PropTypes from 'prop-types';
import { customerFacingActions } from '../../redux/customerFacing';
import { paymentFormSelectors, paymentFormActions } from '../../../common/redux/paymentForm';
import { setUrls } from '../../../common/redux/projectUrl/projectUrl.actions';

const customerFacingPageProps = {
    businessName: PropTypes.string,
    profileImage: PropTypes.string,
    tokenizationKey: PropTypes.string,
    invoice: PropTypes.object,
    account: PropTypes.object,
    // whether the invoice, merchant info and tokenizationKey are loading
    isLoadingPaymentLink: PropTypes.bool,
    registerPayment: PropTypes.func,
    // register payment loading indicator
    isSubmittingLoading: PropTypes.bool,
    submittedPayment: PropTypes.object,
    // get invoice OR register payment error
    customerFacingError: PropTypes.bool,

    // collect js proptypes
    paymentToken: PropTypes.string,
    newPaymentIsValid: PropTypes.bool,
    newPaymentIsPristine: PropTypes.bool,
    // submitting a payment, true when dispatching submitPaymentForm()
    newPaymentIsSubmitting: PropTypes.bool,
    paymentFormIsLoading: PropTypes.bool,
    submitPaymentForm: PropTypes.func,
    reset: PropTypes.func,
    setCCNumber: PropTypes.func,
    setExpirationDate: PropTypes.func,
    setCardBin: PropTypes.func,
    setCardHash: PropTypes.func,
    setTokenType: PropTypes.func,
    submittedPaymentInfo: PropTypes.func,

    tokenType: PropTypes.string,

    validCCNumber: PropTypes.func,
    validCVVNumber: PropTypes.func,
    validExpirationDate: PropTypes.func,
    invalidCCNumber: PropTypes.func,
    invalidCVVNumber: PropTypes.func,
    invalidExpirationDate: PropTypes.func,
    loadedCollectJS: PropTypes.func,
};

export const mapStateToProps = ({ customerFacing, merchant, paymentForm }) => ({
    ...customerFacing,
    isLoadingPaymentLink: customerFacing.isLoadingPaymentLink,
    customerFacingError: customerFacing.error,
    businessName: merchant.businessName,
    profileImage: merchant.profileImage,
    
    // payment form details 
    paymentToken: paymentFormSelectors.selectPaymentToken(paymentForm),
    newPaymentIsValid: paymentFormSelectors.selectIsPaymentFormValid(paymentForm),
    newPaymentIsPristine: paymentFormSelectors.selectIsPristine(paymentForm),
    newPaymentIsSubmitting: paymentFormSelectors.selectIsSubmitting(paymentForm),
    paymentFormIsLoading: paymentFormSelectors.selectIsLoading(paymentForm),
    tokenType: paymentForm.tokenType,
    ccExpError: paymentFormSelectors.selectCCExpError(paymentForm),
    ccNumberError: paymentFormSelectors.selectCCNumberError(paymentForm),
    cvvError: paymentFormSelectors.selectCVVError(paymentForm),
});

export const mapDispatchToProps = {
    getCustomerInvoice: customerFacingActions.getCustomerInvoice,
    registerPayment: customerFacingActions.registerPayment,
    submitPaymentForm: paymentFormActions.submitPaymentForm,
    setUrls,
    reset: paymentFormActions.reset,
    setCCNumber: paymentFormActions.setCCNumber,
    setExpirationDate: paymentFormActions.setExpirationDate,
    setCardBin: paymentFormActions.setCardBin,
    setCardHash: paymentFormActions.setCardHash,
    setTokenType: paymentFormActions.setTokenType,
    submittedPaymentInfo: paymentFormActions.submittedPaymentInfo,
    validCCNumber: paymentFormActions.validCCNumber,
    validCVVNumber: paymentFormActions.validCVVNumber,
    validExpirationDate: paymentFormActions.validExpirationDate,
    invalidCCNumber: paymentFormActions.invalidCCNumber,
    invalidCVVNumber: paymentFormActions.invalidCVVNumber,
    invalidExpirationDate: paymentFormActions.invalidExpirationDate,
    loadedCollectJS: paymentFormActions.loadedCollectJS,
};

export default customerFacingPageProps;
