import PropTypes from 'prop-types';

const customerInvoiceHeaderProps = {
    businessName: PropTypes.string,
    profileImage: PropTypes.string,
    invoice: PropTypes.object,
    merchant: PropTypes.object,
    isPaid: PropTypes.bool.isRequired,
    isPaymentApproved: PropTypes.bool.isRequired,
    invoiceSettings: PropTypes.object,
};

export default customerInvoiceHeaderProps;
