import queryString from 'query-string';
import { createAxiosWrapper } from 'legacy/utils/axiosWrapper';

/**
 * Retrieve all dispute transactions
 *
 * @typedef DisputeTransaction
 * @property {string} id
 * @property {string} updatedAt
 * @property {string} soCustomerEmailAddress
 * @property {string} transactionId
 * @property {string} status
 * @property {string} sellerResponseDueDate
 * @property {string} amount
 *
 * @returns {DisputeTransaction[]} an object containing dispute transactions
 */
export function getDisputeTransactions(
    { page, startDate, endDate, status } = { page: 1 },
) {
    const qs = queryString.stringify({
        page,
        start_date: startDate,
        end_date: endDate,
        status,
    });

    return createAxiosWrapper().get(`/api/v1/payments/disputes?${qs}`);
}
