import { fetchAccountById } from '../accounts/accounts';
import * as api from './api';

///////////////////////////////////////////////////////////
//
// Initial State
//
///////////////////////////////////////////////////////////

const defaultPaymentMethods = {
    // Data
    paymentMethods: null,   // list of payment methods
    selectedPaymentMethod: null,    // a single payment method

    quickSaleEmail: '',
    isEnabledSendQuickSaleEmail: false,
    isQuickSaleEmailValid: true,

    // Application State
    itemsPerPage: 12,
    currentPage: 1,
    totalPages: 0,

    isLoading: false,
    isSaving: false,
    isDeleting: false,
    isEditing: false,
    isAdding: false,
    isCardOnFile: false,
    isSubmitting: false,
    saveCard: false,
    isCreateAccountEnabled: false,
    isCreatePaymentMethod: false,
    isQuickSale: false,

    // Modal states
    showSaleModal: false,
    showEditModal: false,
    showDeleteConfirmModal: false,
    showReviewModal: false,

    // Application errors
    error: null,    // set with the error information if an error occured

    // Collect JS
    isCollectJSLoading: false,
    isCollectJSSubmitting: false,
    isCollectJSDirty: false,
    collectJSToken: '', // payment token
    collectJSErrors: {
        ccnumber: '',
        ccexp: '',
        cvv: '',
        zip: '',
    },
    collectJSDirtyField: {
        ccnumber: false,
        ccexp: false,
        cvv: false,
    }
};

///////////////////////////////////////////////////////////
//
// Action Types
//
// NOTE: Good practice dictates REDUX actions should be events NOT commands...
// NOTE: Good practice to add a 'source' of the action and add a description of
//  the action. It makes tracing much easier.
//
///////////////////////////////////////////////////////////

const LOAD_BLANK_PAYMENT_METHOD = '[PaymentMethods] Load Blank Payment Method';
const LOADED_BLANK_PAYMENT_METHOD = '[PaymentMethods] Loaded Blank Payment Method';
const ERROR_LOADING_BLANK_PAYMENT_METHOD = '[PaymentMethods] Error Loading Blank Payment Method';
const LOAD_PAYMENT_METHODS = '[PaymentMethods] Load Payment Methods';
const LOADED_PAYMENT_METHODS = '[PaymentMethods] Loaded Payment Methods';
const ERROR_LOADING_PAYMENT_METHODS = '[PaymentMethods] Error Loading Payment Methods';
const SAVE_PAYMENT_METHOD = '[PaymentMethods] Save Payment Method';
const SAVED_PAYMENT_METHOD = '[PaymentMethods] Saved Payment Method';
const ERROR_SAVING_PAYMENT_METHOD = '[PaymentMethods] Error Saving Payment Method';
const DELETE_PAYMENT_METHOD = '[PaymentMethods] Delete Payment Method';
const DELETED_PAYMENT_METHOD = '[PaymentMethods] Deleted Payment Method';
const ERROR_DELETING_PAYMENT_METHOD = '[PaymentMethods] Error Deleting Payment Method';
const CHANGE_PAYMENT_METHOD_PAGE = '[PaymentMethods] Change Payment Method Page';
const ERROR_CHANGE_PAYMENT_METHOD_PAGE = '[PaymentMethods] Error Changing Payment Method Page';
const SHOW_EDIT_PAYMENT_METHOD_MODAL = '[PaymentMethods] Show Edit Payment Method Modal';
const HIDE_EDIT_PAYMENT_METHOD_MODAL = '[PaymentMethods] Hide Edit Payment Method Modal';
const SHOW_ADD_PAYMENT_METHOD_MODAL = '[PaymentMethods] Show Add Payment Method Modal';
const HIDE_ADD_PAYMENT_METHOD_MODAL = '[PaymentMethods] Hide Add Payment Method Modal';
const SHOW_DELETE_PAYMENT_METHOD_MODAL = '[PaymentMethods] Show Delete Payment Method Modal';
const HIDE_DELETE_PAYMENT_METHOD_MODAL = '[PaymentMethods] Hide Delete Payment Method Modal';
const LOADING_COLLECT_JS = '[PaymentMethods] Loading Collect JS Information';
const LOADED_COLLECT_JS = '[PaymentMethods] Loaded Collect JS Information';
const SUBMITTING_COLLECT_JS = '[PaymentMethods] Submitting Collect JS Form';
const SUBMITTED_COLLECT_JS = '[PaymentMethods] Submitted Collect JS Form';
const UPDATE_ZIP = '[PaymentMethods] Update Zip code';
const INVALID_COLLECT_JS_FIELD = '[PaymentMethods] Invalid Collect JS Form Field';
const VALID_COLLECT_JS_FIELD = '[PaymentMethods] Valid Collect JS Form Field';
const UPDATE_SELECTED_BILLING_INFORMATION = '[PaymentMethods] Update Selected Payment Method Billing Information';
const SET_SELECTED_DEFAULT_METHOD = '[PaymentMethods] Set Selected Payment Method Default Payment Method';
const UPDATE_QUICK_SALE_EMAIL = '[PaymentMethods] Update Quick Sale Email Address';
const SET_IS_ENABLED_QUICK_SALE_EMAIL = '[PaymentMethods] Set Is Enabled Send Quick Sale Email Receipt';
const SET_IS_QUICK_SALE_EMAIL_VALID = '[PaymentMethods] Set Is Quick Sale Email Valid';
const RESET_QUICK_SALE_EMAIL = '[PaymentMethods] Reset Quick Sale Email';
const SHOW_CREATE_SALE_MODAL = '[PaymentMethods] Show Create Sale Modal';
const HIDE_CREATE_SALE_MODAL = '[PaymentMethods] Hide Create Sale Modal';
const CLEAR_CREATE_SALE_MODAL_FIELDS = '[PaymentMethods] Clear Create Sale Modal fields';
const SELECT_PAYMENT_METHOD = '[PaymentMethods] Select a Payment Method';
const SHOW_REVIEW_TRANSACTION_MODAL = '[PaymentMethods] Show Review Transaction Modal';
const HIDE_REVIEW_TRANSACTION_MODAL = '[PaymentMethods] Hide Review Transaction Modal';
const SET_IS_CARD_ON_FILE = '[PaymentMethods] Set Is Card On File';
const SUBMITTING_TRANSACTION = '[PaymentMethods] Submitting Transaction';
const SUBMITTED_TRANSACTION = '[PaymentMethods] Submitted Transaction';
const ERROR_SUBMITTING_TRANSACTION = '[PaymentMethods] Error Submitting Transaction';
const SET_SAVE_PAYMENT_METHOD = '[PaymentMethods] Set Save Payment Method';
const SET_CREATE_ACCOUNT = '[PaymentMethods] Set Create Account when Processing Transaction';
const RESET_COLLECT_JS_DIRTY_FLAG = '[PaymentMethods] Reset Collect JS Dirty Flag';
const SET_IS_QUICK_SALE = '[PaymentMethods] Set Is Quick Sale';
const SET_NEW_PAYMENT_METHODS = '[PaymentMethods] Set payment methods';
const RESET_COLLECT_JS_ERRORS = '[PaymentMethods] Reset collect js errors';
const SET_FORM_FIELDS_DIRTY = '[PaymentMethods] set form field dirty';
const RESET_FORM_FIELDS_DIRTY = '[PaymentMethods] reset form field dirty';

///////////////////////////////////////////////////////////
//
// Pure Actions
//
// NOTE: For every action type there should be a pure action
//
///////////////////////////////////////////////////////////

// Load blank payment method
export const loadBlankPaymentMethod = () => ({
    type: LOAD_BLANK_PAYMENT_METHOD,
});

// Reset Collect js Errors
export const resetCollectJSErrors = () => ({
    type: RESET_COLLECT_JS_ERRORS,
});

// Set cc number dirty need it for valid input due to collectjs limitations
export const setFormFieldDirty = (payload) => ({
    type: SET_FORM_FIELDS_DIRTY,
    payload
});

export const resetFormFieldDirty = () => ({
    type: RESET_FORM_FIELDS_DIRTY,
});

// Loaded blank payment method
//  param {Object} method - a blank payment method
export const loadedBlankPaymentMethod = (method) => ({
    type: LOADED_BLANK_PAYMENT_METHOD,
    method,
});

// An error occured loading blank payment method
//  param {Object} error - an error object
export const errorLoadingBlankPaymentMethod = (error) => ({
    type: ERROR_LOADING_BLANK_PAYMENT_METHOD,
    error,
});

// Load payment methods
export const loadPaymentMethods = () => ({
    type: LOAD_PAYMENT_METHODS,
});

// Loaded payment methods
//  param {Object} methods - a list of payment methods
export const loadedPaymentMethods = (methods) => ({
    type: LOADED_PAYMENT_METHODS,
    methods,
});

// An error occured updating payment methods
//  param {Object} error - an error object
export const errorLoadingPaymentMethods = (error) => ({
    type: ERROR_LOADING_PAYMENT_METHODS,
    error,
});

// Saving a payment method
export const savingPaymentMethod = () => ({
    type: SAVE_PAYMENT_METHOD,
});

// The payment method was saved
export const savedPaymentMethod = () => ({
    type: SAVED_PAYMENT_METHOD,
});

// An error occured updating the payment method
//  param {Object} error - an error object
export const errorSavingPaymentMethod = (error) => ({
    type: ERROR_SAVING_PAYMENT_METHOD,
    error,
});

// Deleting a payment method
//  param {string} id - the id of the account
//  param {string} methodId - the id of the payment method to delete
export const deletingPaymentMethod = (id, methodId) => ({
    type: DELETE_PAYMENT_METHOD,
    id,
    methodId,
});

// Deleted a payment method
export const deletedPaymentMethod = () => ({
    type: DELETED_PAYMENT_METHOD,
});

// An error occured deleting the payment method
//  param {Object} error - an error object
export const errorDeletingPaymentMethod = (error) => ({
    type: ERROR_DELETING_PAYMENT_METHOD,
    error,
});

// Go to payment method page
//  param {number} page - the page to select
export const changePaymentMethodPage = (page) => ({
    type: CHANGE_PAYMENT_METHOD_PAGE,
    page,
});

// Error changing pages
//  param {Object} error - an error object
export const errorChangePaymentMethodPage = (error) => ({
    type: ERROR_CHANGE_PAYMENT_METHOD_PAGE,
    error,
});

// Display the edit payment method modal
//  param {Object} method - the payment method to edit
export const showEditPaymentMethodModal = (method) => ({
    type: SHOW_EDIT_PAYMENT_METHOD_MODAL,
    method,
});

// Hide the edit payment method modal
export const hideEditPaymentMethodModal = () => ({
    type: HIDE_EDIT_PAYMENT_METHOD_MODAL,
});

// Display the add payment method modal
//  param {Object} method - an initial payment method
export const showAddPaymentMethodModal = (method) => ({
    type: SHOW_ADD_PAYMENT_METHOD_MODAL,
    method,
});

// Hide the add payment method modal
export const hideAddPaymentMethodModal = () => ({
    type: HIDE_ADD_PAYMENT_METHOD_MODAL,
});

// Display the delete confirmation of payment method modal
//  param {Object} method - the payment method to delete
export const showDeletePaymentMethodModal = (method) => ({
    type: SHOW_DELETE_PAYMENT_METHOD_MODAL,
    method,
});

// Hide the delete confirmation of payment method modal
export const hideDeletePaymentMethodModal = () => ({
    type: HIDE_DELETE_PAYMENT_METHOD_MODAL,
});

// CollectJS is loading
export const loadingCollectJS = () => ({
    type: LOADING_COLLECT_JS,
});

// CollectJS is ready
export const loadedCollectJS = () => ({
    type: LOADED_COLLECT_JS,
});

// Submitting a CollectJS form
export const submittingCollectJSForm = () => ({
    type: SUBMITTING_COLLECT_JS,
});

// CollectJS form is submitted
//  param {string} token - the collect.js payment token
export const submittedCollectJSForm = (token) => ({
    type: SUBMITTED_COLLECT_JS,
    token,
});

// Update the zip code
//  param {string} zip - the updated zip code
export const updateZip = (zip) => ({
    type: UPDATE_ZIP,
    zip,
});

// Invalid Collect JS field
//  param {string} field - the field to set
//  param {string} message - the error message
export const invalidCollectJSFormField = (field, message) => ({
    type: INVALID_COLLECT_JS_FIELD,
    field,
    message,
});

// Valid Collect JS field
//  param {string} field - the field to set
export const validCollectJSFormField = (field) => ({
    type: VALID_COLLECT_JS_FIELD,
    field,
});

// Update the billing method information
//  param {Object} billingInfo - the billing information (address object)
export const updateSelectedBillingInformation = (billingInfo) => ({
    type: UPDATE_SELECTED_BILLING_INFORMATION,
    billingInfo,
});

// Toggle the default payment method on the selected payment method
//  param {boolean} isDefault - the value of the default payment method
export const setSelectedDefaultPaymentMethod = (isDefault) => ({
    type: SET_SELECTED_DEFAULT_METHOD,
    isDefault,
});

// Update the quick sale email address
//  param {string} email - the email address of the receipt recipient
export const updateQuickSaleEmail = (email) => ({
    type: UPDATE_QUICK_SALE_EMAIL,
    email,
});

// Set whether or not an email receipt is enabled for quick sale
//  param {boolean} isEnabled - true if receipt is to be sent to email, else false
export const setIsEnabledQuickSaleEmail = (isEnabled) => ({
    type: SET_IS_ENABLED_QUICK_SALE_EMAIL,
    isEnabled,
});

// Set whether or not a quick sale email address is valid
//  param {boolean} isValid - whether or not the email is valid
export const setIsQuickSaleEmailValid = (isValid) => ({
    type: SET_IS_QUICK_SALE_EMAIL_VALID,
    isValid,
});

// Reset all quick sale email related fields
export const resetQuickSaleEmail = () => ({
    type: RESET_QUICK_SALE_EMAIL,
});

// Display the create sale modal
export const showCreateSaleModal = () => ({
    type: SHOW_CREATE_SALE_MODAL,
});

// Hide the create sale modal
export const hideCreateSaleModal = () => ({
    type: HIDE_CREATE_SALE_MODAL,
});

// Select a payment method
//  param {Object} method - a payment method object
export const selectPaymentMethod = (method) => ({
    type: SELECT_PAYMENT_METHOD,
    method,
});

// Display the review transaction modal
export const showReviewTransactionModal = () => ({
    type: SHOW_REVIEW_TRANSACTION_MODAL,
});

// Hide the review transaction modal
export const hideReviewTransactionModal = () => ({
    type: HIDE_REVIEW_TRANSACTION_MODAL,
});

// Set if the card is on file
//  param {boolean} isOnFile - is the card on file
export const setIsCardOnFile = (isOnFile) => ({
    type: SET_IS_CARD_ON_FILE,
    isOnFile
});

// Submit a transaction
export const submittingTransaction = () => ({
    type: SUBMITTING_TRANSACTION,
});

// A transaction finished submitting
export const submittedTransaction = () => ({
    type: SUBMITTED_TRANSACTION,
});

// An error occured submitting a transaction
//  param {Object} error - an error object
export const errorSubmittingTransaction = (error) => ({
    type: ERROR_SUBMITTING_TRANSACTION,
    error,
});

// Set whether or not the card should be saved on file or not
//  param {boolean} saveCard - true if new card and saving to file, else false
export const setSavePaymentMethod = (saveCard) => ({
    type: SET_SAVE_PAYMENT_METHOD,
    saveCard,
});

export const setCreateAccount = (createAccount) => ({
    type: SET_CREATE_ACCOUNT,
    createAccount,
});

export const resetCollectJSDirty = () => ({
    type: RESET_COLLECT_JS_DIRTY_FLAG,
});

export const setIsQuickSale = (isQuickSale) => ({
    type: SET_IS_QUICK_SALE,
    isQuickSale,
});
//  action to call when adding on subscribers
export const setNewPaymentMethods = (methods) => ({
    type: SET_NEW_PAYMENT_METHODS,
    payload: methods
})

export const clearSaleModalData = () => ({
    type: CLEAR_CREATE_SALE_MODAL_FIELDS,
})

///////////////////////////////////////////////////////////
//
// Actions with side-effects (thunk)
//
///////////////////////////////////////////////////////////

// Select a payment method
//  param {string} id - the id of the account to fetch payment methods for
//  returns {Promise} containing api response
export const getAllPaymentMethods = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(loadPaymentMethods());
        api.fetchPaymentMethods(id)
            .then((paymentMethods) => {
                dispatch(loadedPaymentMethods(paymentMethods));

                resolve(paymentMethods);
            })
            .catch((e) => {
                dispatch(errorLoadingPaymentMethods(e));

                reject(e);
            });
    });
};

export const getPaymentMethodsPage = (id, page) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(loadPaymentMethods());
        api.fetchPaymentMethodsPage(id, page)
            .then((paymentMethods) => {
                dispatch(loadedPaymentMethods(paymentMethods));

                resolve(paymentMethods);
            })
            .catch((e) => {
                dispatch(errorLoadingPaymentMethods(e));

                reject(e);
            });
    });
};

// Create a new payment method
//  param {string} id - the id of the account to select
//  param {Object} method - the new payment method object
//  returns {Promise} containing api response
export const createPaymentMethod = (id, method) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(savingPaymentMethod());
        api.createPaymentMethod(id, method)
            .then((response) => {
                dispatch(getAllPaymentMethods(id));
                dispatch(hideAddPaymentMethodModal());
                /** here is the thing
                 * adding a new payment method does not update the paymentmethods inside accountDetail,
                 * i tried to added manually, but they come different by the BE because the serializePaymentMethod,
                 * so the easy solution is re-fetch the account by the id
                 */
                dispatch(fetchAccountById(id)).then(() => {
                    dispatch(savedPaymentMethod());
                    resolve(response);
                })
            })
            .catch((e) => {
                dispatch(errorSavingPaymentMethod(e));

                reject(e);
            });
    });
};

// Save a payment method
//  param {string} id - the id of the account to select
//  param {string} methodId - the id of the payment method
//  param {Object} method - the payment object to update
//  returns {Promise} containing api response
export const savePaymentMethod = (id, methodId, method) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(savingPaymentMethod());
        api.savePaymentMethod(id, methodId, method)
            .then((response) => {
                dispatch(savedPaymentMethod());
                dispatch(getAllPaymentMethods(id));
                dispatch(hideEditPaymentMethodModal());

                resolve(response);
            })
            .catch((e) => {
                dispatch(errorSavingPaymentMethod(e));

                reject(e);
            });
    });
};

// Delete a payment method
//  param {string} id - the id of the account to select
//  param {string} methodId - the id of the payment method
//  returns {Promise} containing api response
export const deletePaymentMethod = (id, methodId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(deletingPaymentMethod());
        api.deletePaymentMethod(id, methodId)
            .then((response) => {
                dispatch(deletedPaymentMethod());
                dispatch(getAllPaymentMethods(id));
                dispatch(hideDeletePaymentMethodModal());

                resolve(response);
            })
            .catch((e) => {
                dispatch(errorDeletingPaymentMethod(e));

                reject(e);
            });
    });
};

// Get a blank payment method
//  returns {Promise} containing api response
export const getBlankPaymentMethod = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(loadBlankPaymentMethod());
        api.getBlankPaymentMethod()
            .then((blankPaymentMethod) => {
                dispatch(loadedBlankPaymentMethod(blankPaymentMethod));

                resolve(blankPaymentMethod);
            })
            .catch((e) => {
                dispatch(errorLoadingBlankPaymentMethod(e));

                reject(e);
            });
    });
};

// Add a payment method
//  returns {Promise} containing api response
export const addPaymentMethod = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(loadBlankPaymentMethod());
        api.getBlankPaymentMethod()
            .then((blankPaymentMethod) => {
                dispatch(loadedBlankPaymentMethod(blankPaymentMethod));
                dispatch(showAddPaymentMethodModal(blankPaymentMethod));

                resolve(blankPaymentMethod);
            })
            .catch((e) => {
                dispatch(errorLoadingBlankPaymentMethod(e));

                reject(e);
            });
    });
};

// Submit a CollectJS form
export const submitCollectJSForm = () => (dispatch) => {
    dispatch(submittingCollectJSForm());

    window.CollectJS.startPaymentRequest();
};

// Create a transaction from a card on file
//  param {Object} transactionData - a transaction object
//  returns {Promise} the transaction response
export const submitTransaction = (transactionData) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(submittingTransaction());
        api.createTransactionFromPaymentMethod(transactionData)
            .then((response) => {
                dispatch(submittedTransaction());

                resolve(response);
            })
            .catch((e) => {
                dispatch(errorSubmittingTransaction(e));

                reject(e);
            });
    });
};


///////////////////////////////////////////////////////////
//
// Reducer
//
// NOTE: please do not put complex logic here (more than one line) You should
// only be updating a value in the state based on the action payload, or what
// the state dictates.
//
///////////////////////////////////////////////////////////

export default function (state = defaultPaymentMethods, action) {
    switch (action.type) {

        case SET_FORM_FIELDS_DIRTY:
            return {
                ...state,
                collectJSDirtyField: {
                    ...state.collectJSDirtyField,
                    [action.payload]: true
                }
            }

        case LOAD_BLANK_PAYMENT_METHOD: {
            return {
                ...state,
                isLoading: true,
                selectedPaymentMethod: null,
            };
        }

        case LOADED_BLANK_PAYMENT_METHOD: {
            return {
                ...state,
                isLoading: false,
                selectedPaymentMethod: action.method,
            };
        }

        case ERROR_LOADING_BLANK_PAYMENT_METHOD: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }

        case LOAD_PAYMENT_METHODS: {
            return {
                ...state,
                error: null,
                isLoading: true,
            };
        }

        case LOADED_PAYMENT_METHODS: {
            return {
                ...state,
                isLoading: false,
                totalPages: Math.ceil(action.methods.count / state.itemsPerPage),
                paymentMethods: action.methods,
            };
        }

        case ERROR_LOADING_PAYMENT_METHODS: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }

        case SAVE_PAYMENT_METHOD: {
            return {
                ...state,
                isSaving: true,
                error: action.error,
            };
        }

        case SAVED_PAYMENT_METHOD: {
            return {
                ...state,
                isSaving: false,
            };
        }

        case ERROR_SAVING_PAYMENT_METHOD: {
            return {
                ...state,
                isSaving: false,
                error: action.error,
            };
        }

        case DELETE_PAYMENT_METHOD: {
            return {
                ...state,
                isDeleting: true,
                error: null,
            };
        }

        case DELETED_PAYMENT_METHOD: {
            return {
                ...state,
                isDeleting: false,
            };
        }

        case ERROR_DELETING_PAYMENT_METHOD: {
            return {
                ...state,
                isDeleting: false,
                error: action.error,
            };
        }

        case CHANGE_PAYMENT_METHOD_PAGE: {
            return {
                ...state,
                currentPage: action.page,
            };
        }

        case ERROR_CHANGE_PAYMENT_METHOD_PAGE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }

        case SHOW_EDIT_PAYMENT_METHOD_MODAL: {
            return {
                ...state,
                selectedPaymentMethod: action.method,
                showEditModal: true,
                isEditing: true,
            };
        }

        case HIDE_EDIT_PAYMENT_METHOD_MODAL: {
            return {
                ...state,
                selectedPaymentMethod: null,
                showEditModal: false,
                isEditing: false,
            };
        }

        case SHOW_ADD_PAYMENT_METHOD_MODAL: {
            return {
                ...state,
                showEditModal: true,
                selectedPaymentMethod: action.method,
                isAdding: true,
            };
        }

        case HIDE_ADD_PAYMENT_METHOD_MODAL: {
            return {
                ...state,
                selectedPaymentMethod: null,
                showEditModal: false,
                isAdding: false,
            };
        }

        case SHOW_DELETE_PAYMENT_METHOD_MODAL: {
            return {
                ...state,
                selectedPaymentMethod: action.method,
                showDeleteConfirmModal: true,
            };
        }

        case HIDE_DELETE_PAYMENT_METHOD_MODAL: {
            return {
                ...state,
                selectedPaymentMethod: null,
                showDeleteConfirmModal: false,
            };
        }

        case LOADING_COLLECT_JS: {
            return {
                ...state,
                isCollectJSLoading: true,
                collectJSToken: '',
            };
        }

        case LOADED_COLLECT_JS: {
            return {
                ...state,
                isCollectJSLoading: false,
                isCollectJSDirty: false,
            };
        }

        case SUBMITTING_COLLECT_JS: {
            return {
                ...state,
                isCollectJSSubmitting: true,
                collectJSToken: '',
            };
        }

        case SUBMITTED_COLLECT_JS: {
            return {
                ...state,
                isCollectJSSubmitting: false,
                collectJSToken: action.token,
            };
        }

        case UPDATE_ZIP: {
            return {
                ...state,
                selectedPaymentMethod: {
                    ...state.selectedPaymentMethod,
                    address: {
                        ...state.selectedPaymentMethod.address,
                        postalCode: action.zip,
                    },
                },
            };
        }

        case RESET_COLLECT_JS_ERRORS:
            return {
                ...state,
                collectJSErrors: {
                    ...defaultPaymentMethods.collectJSErrors
                }
            }

        case INVALID_COLLECT_JS_FIELD: {
            return {
                ...state,
                isCollectJSDirty: true,
                collectJSErrors: {
                    ...state.collectJSErrors,
                    [action.field]: action.message,
                },
            };
        }

        case VALID_COLLECT_JS_FIELD: {
            return {
                ...state,
                isCollectJSDirty: true,
                collectJSErrors: {
                    ...state.collectJSErrors,
                    [action.field]: '',
                },
            };
        }

        case UPDATE_SELECTED_BILLING_INFORMATION: {
            return {
                ...state,
                selectedPaymentMethod: {
                    ...state.selectedPaymentMethod,
                    address: action.billingInfo,
                },
            };
        }

        case SET_SELECTED_DEFAULT_METHOD: {
            return {
                ...state,
                selectedPaymentMethod: {
                    ...state.selectedPaymentMethod,
                    isDefault: action.isDefault,
                },
            };
        }

        case UPDATE_QUICK_SALE_EMAIL: {
            return {
                ...state,
                quickSaleEmail: action.email,
            };
        }

        case SET_IS_ENABLED_QUICK_SALE_EMAIL: {
            return {
                ...state,
                isEnabledSendQuickSaleEmail: action.isEnabled,
            };
        }

        case SET_IS_QUICK_SALE_EMAIL_VALID: {
            return {
                ...state,
                isQuickSaleEmailValid: action.isValid,
            };
        }

        case RESET_QUICK_SALE_EMAIL: {
            return {
                ...state,
                quickSaleEmail: '',
                isEnabledSendQuickSaleEmail: false,
                isQuickSaleEmailValid: true,
            };
        }

        case SHOW_CREATE_SALE_MODAL: {
            return {
                ...state,
                showSaleModal: true,
            };
        }

        case HIDE_CREATE_SALE_MODAL: {
            return {
                ...state,
                showSaleModal: false,
            };
        }

        case CLEAR_CREATE_SALE_MODAL_FIELDS: {
            return {
                ...state
            };
        }

        case SELECT_PAYMENT_METHOD: {
            return {
                ...state,
                selectedPaymentMethod: action.method,
            };
        }

        case SHOW_REVIEW_TRANSACTION_MODAL: {
            return {
                ...state,
                showReviewModal: true,
            };
        }

        case HIDE_REVIEW_TRANSACTION_MODAL: {
            return {
                ...state,
                showReviewModal: false,
            };
        }

        case SET_IS_CARD_ON_FILE: {
            return {
                ...state,
                isCardOnFile: action.isOnFile,
            };
        }

        case SUBMITTING_TRANSACTION: {
            return {
                ...state,
                isSubmitting: true,
            };
        }

        case SUBMITTED_TRANSACTION: {
            return {
                ...state,
                isSubmitting: false,
            };
        }

        case ERROR_SUBMITTING_TRANSACTION: {
            return {
                ...state,
                isSubmitting: false,
                error: action.error,
            };
        }

        case SET_SAVE_PAYMENT_METHOD: {
            return {
                ...state,
                saveCard: action.saveCard,
            };
        }

        case SET_CREATE_ACCOUNT: {
            return {
                ...state,
                isCreateAccountEnabled: action.createAccount,
            };
        }

        case RESET_COLLECT_JS_DIRTY_FLAG: {
            return {
                ...state,
                isCollectJSDirty: false,
            };
        }

        case SET_IS_QUICK_SALE: {
            return {
                ...state,
                isQuickSale: action.isQuickSale,
            };
        }
        case SET_NEW_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethods: action.payload
            }
        case RESET_FORM_FIELDS_DIRTY: 
            return {
                ...state,
                collectJSDirtyField: {
                    ...defaultPaymentMethods.collectJSDirtyField
                }
            }
        default: {
            return state;
        }
    }
}
