import { createAxiosWrapper } from 'legacy/utils/axiosWrapper';

const PAGE_SIZE = 100000;
/**
 * it fetch all the accounts on vt
 */
export const fetchAllAccounts = () => {
    return createAxiosWrapper().get(
        `/nmi_terminal/accounts?page=${1}&page_size=${PAGE_SIZE}`,
    );
};

export const fetchAccounts = ({ page, search, pageSize }) => {
    return createAxiosWrapper().get('/nmi_terminal/accounts', {
        params: { page, q: search, page_size: pageSize },
    });
};

export const createAccount = (data) => {
    return createAxiosWrapper().post('/nmi_terminal/accounts', data);
};

export const updateAccount = (accountId, data) => {
    return createAxiosWrapper().patch(
        `/nmi_terminal/accounts/${accountId}`,
        data,
    );
};

export const fetchSingleAccount = (accountId) => {
    return createAxiosWrapper().get(`/nmi_terminal/accounts/${accountId}`);
};
