import { createSelector } from 'reselect';

export const selectIsLoadingTransactions = (state) => {
    return state.failedTransactions.isLoading;
};

export const selectErrorTransactions = (state) => {
    return state.failedTransactions.error;
};

export const selectRawTransactions = (state) => {
    return state.failedTransactions;
};

export const getFailedTransactions = createSelector(
    [selectRawTransactions],
    (transactions) => {
        return transactions.allCustomerNames
            .map(name => {
                return transactions.byCustomerName[name].map(t => {
                    return {
                        ...t,
                        groupId: name,
                    };
                });
            });
    },
);

export const getNumberOfFailedTransactions = createSelector(
    [selectRawTransactions],
    (transactions) => {
        return transactions.allCustomerNames.length;
    },
);

export const getHasFailedTransactions = createSelector(
    [getNumberOfFailedTransactions],
    (failedTransactions) => {
        return failedTransactions > 0;
    },
);

export const isOpenConfirmResolveModal = (state) => {
    return state.isOpenConfirmResolveModal;
};

export const isOpenConfirmChargeModal = (state) => {
    return state.isOpenConfirmChargeModal;
};
