import { RESET_TERMINAL_FIELDS } from './shared-actions';

/**
 * INITIAL STATE
 */
const defaultShippingInfo = {
    isSameAsBilling: false,
    firstname: '',
    lastname: '',
    addr1: '',
    addr2: '',
    city: '',
    state: '',
    country: '',
    zip: '',
};

/**
 * ACTION TYPES
 */
const UPDATE_SHIPPING_INFO = 'UPDATE_SHIPPING_INFO';
const UPDATE_SHIPPING_SAME_AS_BILLING = 'UPDATE_SHIPPING_SAME_AS_BILLING';
const UPDATE_SHIPPING_FIRSTNAME = 'UPDATE_SHIPPING_FIRSTNAME';
const UPDATE_SHIPPING_LASTNAME = 'UPDATE_SHIPPING_LASTNAME';
const UPDATE_SHIPPING_ADDR1 = 'UPDATE_SHIPPING_ADDR1';
const UPDATE_SHIPPING_ADDR2 = 'UPDATE_SHIPPING_ADDR2';
const UPDATE_SHIPPING_CITY = 'UPDATE_SHIPPING_CITY';
const UPDATE_SHIPPING_STATE = 'UPDATE_SHIPPING_STATE';
const UPDATE_SHIPPING_COUNTRY = 'UPDATE_SHIPPING_COUNTRY';
const UPDATE_SHIPPING_ZIP = 'UPDATE_SHIPPING_ZIP';
const CLEAR_SHIPPING_INFO = 'CLEAR_SHIPPING_INFO';
/**
 * ACTION CREATORS
 */
export const updateShippingInfo = (shippingInfo) => ({ type: UPDATE_SHIPPING_INFO, shippingInfo });
export const updateSameAsBilling = (isSameAsBilling) => (
    { type: UPDATE_SHIPPING_SAME_AS_BILLING, isSameAsBilling }
);
export const updateFirstname = (firstname) => ({ type: UPDATE_SHIPPING_FIRSTNAME, firstname });
export const updateLastname = (lastname) => ({ type: UPDATE_SHIPPING_LASTNAME, lastname });
export const updateAddr1 = (addr1) => ({ type: UPDATE_SHIPPING_ADDR1, addr1 });
export const updateAddr2 = (addr2) => ({ type: UPDATE_SHIPPING_ADDR2, addr2 });
export const updateCity = (city) => ({ type: UPDATE_SHIPPING_CITY, city });
export const updateState = (state) => ({ type: UPDATE_SHIPPING_STATE, state });
export const updateCountry = (country) => ({ type: UPDATE_SHIPPING_COUNTRY, country });
export const updateZip = (zip) => ({ type: UPDATE_SHIPPING_ZIP, zip });
export const clearShippingInfo = () => ({ type: CLEAR_SHIPPING_INFO });

const trimInfo = (info) => {
    return Object.entries(defaultShippingInfo).reduce((collectedInfo, [key, val]) => {
        if (info[key] === undefined) {
            return { ...collectedInfo, [key]: val };
        }
        return { ...collectedInfo, [key]: info[key] };
    }, {});
};

/**
 * REDUCER
 */
export default function (state = defaultShippingInfo, action) {
    switch (action.type) {
        case UPDATE_SHIPPING_INFO:
            return {
                ...trimInfo(action.shippingInfo),
                isSameAsBilling: state.isSameAsBilling,
            };
        case UPDATE_SHIPPING_SAME_AS_BILLING:
            return {
                ...state,
                ...(action.isSameAsBilling ? {} : trimInfo({})), // clear all data if unchecking
                isSameAsBilling: action.isSameAsBilling,
            };
        case UPDATE_SHIPPING_FIRSTNAME:
            return { ...state, firstname: action.firstname };
        case UPDATE_SHIPPING_LASTNAME:
            return { ...state, lastname: action.lastname };
        case UPDATE_SHIPPING_ADDR1:
            return { ...state, addr1: action.addr1 };
        case UPDATE_SHIPPING_ADDR2:
            return { ...state, addr2: action.addr2 };
        case UPDATE_SHIPPING_CITY:
            return { ...state, city: action.city };
        case UPDATE_SHIPPING_STATE:
            return { ...state, state: action.state };
        case UPDATE_SHIPPING_COUNTRY:
            return { ...state, country: action.country };
        case UPDATE_SHIPPING_ZIP:
            return { ...state, zip: action.zip };
        case CLEAR_SHIPPING_INFO:
            return defaultShippingInfo;
        case RESET_TERMINAL_FIELDS:
            return defaultShippingInfo;
        default:
            return state;
    }
}