import * as api from '../../api/disputeTransactions.data';

export const LOADING_DISPUTE_TRANSACTIONS = '[DisputeTransactions] Loading dispute transactions';
export const LOADED_DISPUTE_TRANSACTIONS = '[DisputeTransactions] Loaded dispute transactions';
export const ERROR_LOADING_DISPUTE_TRANSACTIONS = '[DisputeTransactions] Error loading dispute transactions';

export function loadingDisputeTransactions() {
    return {
        type: LOADING_DISPUTE_TRANSACTIONS,
    };
}

export function loadedDisputeTransactions(transactions) {
    return {
        type: LOADED_DISPUTE_TRANSACTIONS,
        payload: transactions,
    };
}

export function errorLoadingDisputeTransactions(error) {
    return {
        type: ERROR_LOADING_DISPUTE_TRANSACTIONS,
        payload: error,
    };
}

export function getDisputeTransactions(args) {
    return function (dispatch) {
        dispatch(loadingDisputeTransactions());

        return api.getDisputeTransactions(args)
            .then(({ data }) => {
                dispatch(loadedDisputeTransactions(data));
                return data;
            })
            .catch(error => {
                dispatch(errorLoadingDisputeTransactions());
                throw error;
            });
    };
}
