import { objectArrayToMap } from '../../../../utils/utils';

import {
    GET_ACCOUNTS_STARTED,
    GET_ACCOUNTS_SUCCESS,
    GET_ACCOUNTS_FAILED,
    GET_ACCOUNTS_WITH_PARAMS_STARTED,
    GET_ACCOUNTS_WITH_PARAMS_SUCCESS,
    GET_ACCOUNTS_WITH_PARAMS_FAILED,
    CREATE_ACCOUNT_STARTED,
    CREATE_ACCOUNT_SUCCESS,
    CREATE_ACCOUNT_FAILED,
    UPDATE_ACCOUNT_STARTED,
    UPDATE_ACCOUNT_SUCCESS,
    UPDATE_ACCOUNT_FAILED,
    SET_ACCOUNT,
    UPDATE_PAYMENT_INFORMATION,
} from './actions';

const INITIAL_STATE = {
    isLoadingAccounts: false,
    isLoadingAccountsWithParams: false,
    accountsWithParamsFetchError: null,
    accountsWithParamsResult: [],
    accounts: [],
    currentPage: 1,
    totalNumResults: 0,
    totalPages: 0,
    accountError: null,
    selectedAccount: {},
    isSubmittingAccount: false
};

export default function (state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case UPDATE_ACCOUNT_STARTED:
            return {
                ...state,
                isSubmittingAccount: true
            }
        case UPDATE_ACCOUNT_FAILED:
            return {
                ...state,
                isSubmittingAccount: false,
                accountError: payload
            }
        case UPDATE_ACCOUNT_SUCCESS: 
            return {
                ...state,
                isSubmittingAccount: false
            }
        case CREATE_ACCOUNT_STARTED:
            return {
                ...state,
                isSubmittingAccount: true
            };
        case CREATE_ACCOUNT_FAILED: 
            return {
                ...state,
                isSubmittingAccount: false,
                accountError: payload
            };
        case CREATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                isSubmittingAccount: false,
                accounts: [payload, ...state.accounts],
                accountsWithParamsResult: [payload, ...state.accountsWithParamsResult],
            };
        case SET_ACCOUNT:
            return {
                ...state,
                selectedAccount: payload
            };
        case UPDATE_PAYMENT_INFORMATION: {
            return {
                ...state,
                accounts: state.accounts.map((account) => {
                    if (account.id == payload.account.id) {
                        return {
                            ...account,
                            paymentMethods: [payload, ...account.paymentMethods.filter(p => p.id !== payload.id)]
                        };
                    }
                    return account;
                }),
                accountsWithParamsResult: state.accountsWithParamsResult.map((account) => {
                    if (account.id == payload.account.id) {
                        return {
                            ...account,
                            paymentMethods: [payload, ...account.paymentMethods.filter(p => p.id !== payload.id)]
                        };
                    }
                    return account;
                })
            }
        }
        case GET_ACCOUNTS_STARTED:
            return {
                ...state,
                isLoadingAccounts: true,
            };
        case GET_ACCOUNTS_SUCCESS:
            return {
                ...state,
                isLoadingAccounts: false,
                accounts: payload.results,
                currentPage: payload.currentPage,
                totalPages: payload.totalPages,
                totalNumResults: payload.totalNumResults,
            };
        case GET_ACCOUNTS_FAILED:
            return {
                ...state,
                isLoadingAccounts: false,
                accountError: payload,
            };
        case GET_ACCOUNTS_WITH_PARAMS_STARTED:
            return {
                ...state,
                isLoadingAccountsWithParams: true,
            };
        case GET_ACCOUNTS_WITH_PARAMS_SUCCESS:
            return {
                ...state,
                isLoadingAccountsWithParams: false,
                accountsWithParamsResult: payload.results,
            };
        case GET_ACCOUNTS_WITH_PARAMS_FAILED:
            return {
                ...state,
                isLoadingAccountsWithParams: false,
                accountsWithParamsFetchError: payload,
            };
        default: 
            return { 
                ...state 
            };
    }
}
