import { apiCall } from '../../utils/utils';
import {
    deserializeSingleAccount,
    deserializeAccounts,
    serializeSingleAccount,
    serializeRemoveAccount,
} from './serializers';

const PAGE_SIZE = 10;

const formatStatusFilterQs = (statusFilters) => {
    const collection = Object.keys(statusFilters || {})
        .filter(key => statusFilters[key])
        .map(item => `&card_status=${item}`).join('');
    return collection || '';
};

export const fetchAllAccounts = ({ page, name, sortOrder, q, statusFilters }) => {
    const searchString = q ? `&q=${q}` : '';
    const statusFiltersQs = formatStatusFilterQs(statusFilters);
    return apiCall(`/nmi_terminal/accounts?page=${page}&order_by=${name}&order=${sortOrder}&page_size=${PAGE_SIZE}${searchString}${statusFiltersQs}`)
        .then((response) => {
            return {
                currentPage: response.current_page,
                totalPages: response.total_pages,
                totalNumResults: response.total_num_results,
                results: deserializeAccounts(response.results),
            }
        });
};

export const fetchSingleAccount = (id) => {
    return apiCall(`/nmi_terminal/accounts/${id}`)
        .then(deserializeSingleAccount);
};

const createPostHandler = (serializer) => (
    (account) => {
        const serializedData = serializer(account);
        return apiCall('/nmi_terminal/accounts', {
            method: 'POST',
            body: serializedData,
        }).then(data => {
            return deserializeSingleAccount(data);
        });
    }
);

// WARNING: DO NOT PASS ACCOUNT ID BACKEND EXPECTS HOTSPOT CUSTOMER ID
// TODO: account.id -> hotspot_customer_id: 1
const createPatchHandler = (serializer) => (
    (account) => {
        const serializedData = serializer(account);
        return apiCall('/nmi_terminal/accounts/' + account.id + '/', {
            method: 'PATCH',
            body: serializedData,
        }).then(data => {
            if(data.error) {
                const parsedError = JSON.parse(data.content)
                throw new Error(parsedError.detail)
            }
            return deserializeSingleAccount(data);
        });
    }
);

export const createAccount = createPostHandler(serializeSingleAccount);
export const editAccount = createPatchHandler(serializeSingleAccount);
export const removeAccount = createPatchHandler(serializeRemoveAccount);

// Get an account object that hasn't been filled out yet
//  returns {Object} a blank account object
export const getBlankAccount = () => deserializeSingleAccount();

// Search for accounts
//
// Search accounts by 'firstName', 'lastName', 'businessName', and 'email' fields
//
//  param {string} searchString - a string to search for
//  returns {Promise<Object[]>} a list of accounts that match the query
export const searchAccounts = (searchString) => {
    return apiCall(`/nmi_terminal/accounts?q=${encodeURIComponent(searchString)}&page_size=${PAGE_SIZE}`)
        .then((response) => {
            return deserializeAccounts(response.results);
        })
        .catch((e) => {
            console.warn('[Error] Failed to search accounts');
        });
};

// Check if an account is a duplicate
//  param {string} firstName - the first name of the account owner
//  param {string} lastName - the last name of the account owner
//  param {string} email - the email of the account owner
//  param {string} companyName - the company name of the account (optional)
export const checkAccountDuplicate = (firstName, lastName, email, companyName = '') => {
    let queryString = `?first_name=${encodeURIComponent(firstName)}&last_name=${encodeURIComponent(lastName)}&email=${encodeURIComponent(email)}`;

    if (companyName !== '') {
        queryString += `&company_name=${encodeURIComponent(companyName)}`;
    }

    return apiCall(`/nmi_terminal/accounts${queryString}`)
        .then((response) => {
            return deserializeAccounts(response.results);
        })
        .catch((e) => {
            console.warn('[Error] Failed to check for duplicate accounts');
        });
};
