import * as failedTransactionsApi from '../../api/failedTransactions.data';
import addNotification, { DANGER, SUCCESS } from '../../../common/utils/notificationHelper';
import get from 'lodash.get';

export const LOADING_FAILED_TRANSACTIONS = '[FailedTransactions] Loading failed transactions';
export const LOADED_FAILED_TRANSACTIONS = '[FailedTransactions] Loaded failed transactions';
export const ERROR_LOADING_FAILED_TRANSACTIONS = '[FailedTransactions] Error loading failed transactions';
export const OPEN_CONFIRM_RESOLVE_MODAL = '[FailedTransactions] Open confirm resolve modal';
export const CLOSE_CONFIRM_RESOLVE_MODAL = '[FailedTransactions] Close confirm resolve modal';
export const OPEN_CONFIRM_CHARGE_MODAL = '[FailedTransactions] Open confirm charge modal';
export const CLOSE_CONFIRM_CHARGE_MODAL = '[FailedTransactions] Close confirm charge modal';
export const RESOLVING_FAILED_TRANSACTIONS = '[FailedTransactions] Resolving failed transactions';
export const RESOLVED_FAILED_TRANSACTIONS = '[FailedTransactions] Resolved failed transactions';
export const ERROR_RESOLVING_FAILED_TRANSACTIONS = '[FailedTransactions] Error resolving failed transactions';
export const CHARGING_FAILED_TRANSACTIONS = '[FailedTransactions] Charging failed transactions';
export const CHARGED_FAILED_TRANSACTIONS = '[FailedTransactions] Charged failed transactions';
export const ERROR_CHARGING_FAILED_TRANSACTIONS = '[FailedTransactions] Error charging failed transactions';
export const SET_SELECTED_PAYMENT_METHOD = '[FailedTransactions] Set selected payment method';

export function openConfirmResolveModal() {
    return {
        type: OPEN_CONFIRM_RESOLVE_MODAL,
    };
}

export function closeConfirmResolveModal() {
    return {
        type: CLOSE_CONFIRM_RESOLVE_MODAL,
    };
}

export function openConfirmChargeModal() {
    return {
        type: OPEN_CONFIRM_CHARGE_MODAL,
    };
}

export function closeConfirmChargeModal() {
    return {
        type: CLOSE_CONFIRM_CHARGE_MODAL,
    };
}

export function loadingFailedTransactions() {
    return {
        type: LOADING_FAILED_TRANSACTIONS,
    };
}

export function loadedFailedTransactions(transactions) {
    return {
        type: LOADED_FAILED_TRANSACTIONS,
        payload: transactions,
    };
}

export function errorLoadingFailedTransactions(error) {
    return {
        type: ERROR_LOADING_FAILED_TRANSACTIONS,
        payload: error,
    };
}

export function resolvingFailedTransactions() {
    return {
        type: RESOLVING_FAILED_TRANSACTIONS,
    };
}

export function resolvedFailedTransactions(resolvedTransactions) {
    return {
        type: RESOLVED_FAILED_TRANSACTIONS,
        payload: resolvedTransactions,
    };
}

export function errorResolvingFailedTransactions(error) {
    return {
        type: ERROR_RESOLVING_FAILED_TRANSACTIONS,
        payload: error,
    };
}

export function chargingFailedTransactions() {
    return {
        type: CHARGING_FAILED_TRANSACTIONS,
    };
}

export function chargedFailedTransactions(chargedTransactions) {
    return {
        type: CHARGED_FAILED_TRANSACTIONS,
        payload: chargedTransactions,
    };
}

export function errorChargingFailedTransactions(error) {
    return {
        type: ERROR_CHARGING_FAILED_TRANSACTIONS,
        payload: error,
    };
}

export function setSelectedPaymentMethod(paymentMethod) {
    return {
        type: SET_SELECTED_PAYMENT_METHOD,
        payload: paymentMethod,
    };
}

export function getFailedTransactions(exportFormat) {
    return function (dispatch) {
        dispatch(loadingFailedTransactions());

        return failedTransactionsApi.getFailedTransactions(exportFormat)
            .then(res => {
                dispatch(loadedFailedTransactions(res.data));
            })
            .catch(error => {
                dispatch(errorLoadingFailedTransactions(error));
            });
    };
}

export function chargeFailedTransactions(
    subscriptionId,
    paymentMethodId,
    transactionIds
) {
    return function (dispatch) {
        dispatch(chargingFailedTransactions());

        return failedTransactionsApi.chargeFailedTransactions(
            subscriptionId,
            paymentMethodId,
            transactionIds,
        )
            .then(res => {
                dispatch(chargedFailedTransactions(res.data));
                addNotification({
                    title: 'Success!',
                    message: 'You have successfully charged',
                    type: SUCCESS,
                });
            })
            .catch(error => {
                get(error, 'response.data.error')
                console.dir(error)
                dispatch(errorChargingFailedTransactions(error));
                addNotification({
                    title: get(error, 'response.data.error', 'Something went wrong'),
                    type: DANGER,
                });
            });
    };
}

export function resolveFailedTransactions(
    subscriptionId,
    transactionIds
) {
    return function (dispatch) {
        dispatch(resolvingFailedTransactions());

        return failedTransactionsApi.resolveFailedTransactions(
            subscriptionId,
            transactionIds,
        )
            .then(res => {
                dispatch(resolvedFailedTransactions(res.data));
                addNotification({
                    title: 'Success!',
                    message: 'You have successfully resolved a transaction',
                    type: SUCCESS,
                });
            })
            .catch(error => {
                dispatch(errorResolvingFailedTransactions(error));
                addNotification({
                    title: get(error, 'response.data.error', 'Something went wrong'),
                    type: DANGER,
                });
            });
    };
}
