let cookiePrefix = "";

/**
 * We need to prefix the cookie with QA in only the QA environment or else SSO will not work
 */
export function getCookieName(name) {
    return cookiePrefix + name;
}

// https://stackoverflow.com/a/25490531
export function getCookieValue(name) {
    const cookie = getCookieName(name);
    const b = document.cookie.match("(^|;)\\s*" + cookie + "\\s*=\\s*([^;]+)");

    return b ? b.pop() : undefined;
}

export function setCookiePrefix(prefix) {
    cookiePrefix = prefix;
}