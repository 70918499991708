import {
    GET_CUSTOMER_FACING_INVOICE_FAILED,
    GET_CUSTOMER_FACING_INVOICE_SUCCESS,
    GET_CUSTOMER_FACING_INVOICE_STARTED,
    REGISTER_PAYMENT_STARTED,
    REGISTER_PAYMENT_SUCCESS,
    REGISTER_PAYMENT_FAILED,
} from "./customerFacing.actions";

const initialState = {
    tokenizationKey: '',
    invoice: {},
    account: {},
    merchant: {},
    isLoadingPaymentLink: false,
    isSubmittingLoading: false,
    submittedPayment: {},
    error: null,
};

function customerFacing(
    state = initialState,
    {type, payload},
) {
    switch (type) {
        case GET_CUSTOMER_FACING_INVOICE_STARTED:
            return {
                ...state,
                isLoadingPaymentLink: true,
                error: null,
            }
        case GET_CUSTOMER_FACING_INVOICE_SUCCESS:
            return {
                ...state,
                isLoadingPaymentLink: false,
                account: payload.account,
                tokenizationKey: payload.tokenizationKey,
                invoice: payload.invoice,
                merchant: payload.merchant,
                invoiceSettings: payload.invoiceSettings,
            }
        case GET_CUSTOMER_FACING_INVOICE_FAILED:
            return {
                ...state,
                isLoadingPaymentLink: false,
                error: payload,
            }
        case REGISTER_PAYMENT_STARTED:
            return {
                ...state,
                isSubmittingLoading: true,
            }
        case REGISTER_PAYMENT_SUCCESS:
            return {
                ...state,
                isSubmittingLoading: false,
                submittedPayment: payload,
            }
        case REGISTER_PAYMENT_FAILED:
            return {
                ...state,
                isSubmittingLoading: false,
                error: payload,
            }
        default:
            return state;
    }
}

export default customerFacing;
