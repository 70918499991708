import React from 'react';
import PropTypes from 'prop-types';

import styles from './errorState.module.scss';

/**
 * @param {Number} errorNumber to mapo the error
 */

const ERROR_CONTENT_MAP = {
    500: {
        img: styles.ErrorState_img___error500,
        title: 'Internal Service Error.',
        content_p1: '500 | An unexpected error occurred',
        content_p2: 'Please try again later.'
    },
    504: {
        img: styles.ErrorState_img___error504,
        title: 'That took longer than expected.',
        content_p1: '504 | Timeout Please',
        content_p2: 'Please try again later.'
    },
    404: {
        img: styles.ErrorState_img___error404,
        title: 'Unable to find what you’re looking for.',
        content_p1: '404 | Page Not Found',
        content_p2: 'Page cannot be found or no longer exists.'
    },

}
class ErrorState extends React.Component {
    static propTypes = {
        /** This is the error number from backend api call, that work for mapping content of component */
        errorNumber: PropTypes.number,
    };

    static defaultProps = {
        errorNumber: 500,
    };

    render() {
        const {
            errorNumber,
        } = this.props;

        const errorContent = ERROR_CONTENT_MAP[errorNumber] || ERROR_CONTENT_MAP[500];
        return (
            <div className={styles.ErrorState}>
                <span className={` ${styles.ErrorState_img} ${errorContent.img}`} />
                <h3 className={styles.ErrorState_title}>{errorContent.title}</h3>
                <p className={styles.ErrorState_subtitle}>{errorContent.content_p1}</p>
                <p className={styles.ErrorState_subtitle}>{errorContent.content_p2}</p>
            </div>
        );
    }
}

export default ErrorState;
